import React from "react";
import clsx from "clsx";

import styles from "./SizeButton.module.scss";
import { ClickType } from "../../../state/settings/settingsState";
import { OrderSide } from "../../../state/orders/types";
import { toPriceShorthand } from "../../../lib/formatUtil";

type SizeButtonProps = {
  clickType: any;
  depthTradingEnabled: boolean;
  onQuickTrade: any;
  priceNum: any;
  quantity: any;
  size: any;
  testid?: string;
};

export const SizeButton = ({
  clickType,
  depthTradingEnabled,
  onQuickTrade,
  priceNum,
  size,
  quantity,
  testid,
}: SizeButtonProps) => {
  const sizeFinal = size || "sm";
  const optionalAttribute = testid ? { "data-testid": testid } : {};

  const sizeButton = !depthTradingEnabled
    ? <div
      className={clsx(
        sizeFinal === "lg" ? `numberLg ${styles.numberLg}` : `numberSm ${styles.numberSm}`,
        styles.sizeDisabled
      )}
    >
      <span className={`size ${styles.size}`} {...optionalAttribute}>
        {quantity ? toPriceShorthand(quantity) : <></>}
      </span>
    </div>
    : <div
      className={clsx(
        "noDrag",
        styles.sizeCell,
        !quantity && styles.disabled,
        sizeFinal === "lg" ? `numberLg ${styles.numberLg}` : `numberSm ${styles.numberSm}`
      )}
      onDoubleClick={() => {
        if (clickType === ClickType.Double) {
          if (priceNum && quantity) {
            onQuickTrade(priceNum, OrderSide.Sell, quantity);
          } else {
            return null;
          }
        }
      }}
      onClick={() => {
        if (clickType === ClickType.Single) {
          if (priceNum && quantity) {
            onQuickTrade(priceNum, OrderSide.Sell, quantity);
          } else {
            return null;
          }
        }
      }}
    >
      <div className={`size ${styles.size}`} {...optionalAttribute}>
        {quantity ? toPriceShorthand(quantity) : <></>}
      </div>
    </div>;

  return sizeButton;
};

import React from "react";
import clsx from "clsx";

import { RadioProvider, RadioContextProps, useRadio } from "./radio-context";
import styles from "./Radio.module.scss";

/* Types */

type RadioGroupProps = RadioContextProps;

type RadioButtonProps = {
  name: string;
  block?: boolean;
  size?: "sm" | "md" | "lg";
  children: any;
  style?: string;
};

/**
 * Used to declare an instance of the RadioContext Provider, nothing more
 */
export const RadioGroup = (props: RadioGroupProps) => <RadioProvider {...props} />;

/**
 * Button component that has access to its immediate parent RadioContext
 */
export const RadioButton = ({ name, block = false, size = "sm", children, style }: RadioButtonProps) => {
  const { selected, toggle } = useRadio();
  const isSelected = selected.includes(name);
  const className = clsx(
    styles.radio,
    size === "sm" && styles.sm,
    size === "md" && styles.md,
    size === "lg" && styles.lg,
    block && styles.radioBlock,
    isSelected && styles.radioActive,
    style && styles[style]
  );

  return (
    <button className={className} onClick={() => toggle(name)}>
      {children}
    </button>
  );
};

import React from "react";
import { connect } from "react-redux";

import { Menu } from "antd";

import styles from "./FlywheelMenu.module.scss";
import clsx from "clsx";
import { OrderSide } from "../../../state/orders/types";
import { ClickType } from "../../../state/settings/settingsState";
import { currencyPairByIdSelector } from "../../../state/currency/currencyState";

export const FlywheelItem = props => {
  const {
    instrumentId,
    clickType,
    actionOrder,
    item,
    itemObj,
    orderSide,
    quantity,
    longestLength,
    currencyPair,
    ...allMenuItemNativeProps
  } = props;

  // We are removing the dispatch field from the native props because it's an invalid value on a <li> tag (Menu.Item generates a <li> tag)
  const { dispatch, ...menuItemNativeProps } = allMenuItemNativeProps;

  const itemString: string = item.toString();
  const itemLength: number = itemString.length;

  // HERE: is this needed anymore?
  // If the price is short -> we don't want to highlight the last number, we leave it as it is
  const isShort: boolean = itemLength < longestLength;

  const getFlywheelItemClassname = () => {
    if (orderSide === OrderSide.Buy) {
      if (item > currencyPair?.bestAskPrice) {
        return `${styles.highlightedItem} highlightedItem`;
      } else if (item < currencyPair?.bestAskPrice) {
        return `${styles.lowlightedItem} lowlightedItem`;
      }
    }

    if (orderSide === OrderSide.Sell) {
      if (item < currencyPair?.bestBidPrice) {
        return `${styles.highlightedItem} highlightedItem`;
      } else if (item > currencyPair?.bestBidPrice) {
        return `${styles.lowlightedItem} lowlightedItem`;
      }
    }

    return "";
  };

  return (
    <Menu.Item
      {...menuItemNativeProps}
      // disabled={readOnly}
      disabled={quantity === null || quantity === "" || quantity === 0}
      onClick={() => {
        if (clickType === ClickType.Single && quantity !== null && quantity !== "" && quantity != 0) {
          actionOrder(item, orderSide, quantity);
        }
      }}
      onDoubleClick={() => {
        if (clickType === ClickType.Double && quantity !== null && quantity !== "" && quantity != 0) {
          actionOrder(item, orderSide, quantity);
        }
      }}
      className={clsx(
        `${styles.flywheelMenuItem} flywheelMenuItem`,
        !itemObj?.varBig && styles.flywheelMenuItemNoHighlights
      )}
      key={itemString}
    >
      {isShort ? (
        // <span>{itemString}</span>
        <>
          <span className={clsx(styles.basePrice, !itemObj?.varBig && getFlywheelItemClassname())}>
            {itemObj.basePrice}
          </span>
          {itemObj?.varBig && (
            <div className={clsx(styles.specialNumbersContainer, getFlywheelItemClassname())}>
              <span className={`${styles.bigNumber} bigNumber`}>{itemObj.varBig}</span>
              <span className={`${styles.smallNumber} smallNumber`}>{itemObj.varSm}</span>
            </div>
          )}
        </>
      ) : (
        <>
          {/* <span>{itemString.slice(0, itemLength - 1)}</span>
          <span className={`${styles.smallNumber} smallNumber`}>{itemString.slice(itemLength - 1)}</span> */}
          <span className={clsx(styles.basePrice, !itemObj?.varBig && getFlywheelItemClassname())}>
            {itemObj.basePrice}
          </span>
          {itemObj?.varBig && (
            <div className={clsx(styles.specialNumbersContainer, getFlywheelItemClassname())}>
              <span className={`${styles.bigNumber} bigNumber`}>{itemObj.varBig}</span>
              <span className={`${styles.smallNumber} smallNumber`}>{itemObj.varSm}</span>
            </div>
          )}
        </>
      )}
    </Menu.Item>
  );
};

export default connect((state: any, ownProps: any) => {
  return {
    currencyPair: currencyPairByIdSelector(state, ownProps.instrumentId),
  };
})(FlywheelItem);

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import { store } from "./state/configureStore";
import { ENV, SENTRY_KEY } from "./environment";

import App from "./App";
import "./index.css";

if (ENV !== "local") {
  Sentry.init({ dsn: SENTRY_KEY, environment: ENV });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

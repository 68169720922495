import React from "react";
import isEqual from "lodash/isEqual";
export type RadioContextState = string[];
export type RadioContextProps = {
  defaultSelected?: string | string[];
  selected?: string | string[];
  allowMultiple?: boolean;
  allowNone?: boolean;
  onChange?: any;
  children?: any;
};
export type RadioContextApi = {
  selected: string[];
  toggle: any;
};
/**
 * Declare the context object
 */
const RadioContext = React.createContext<RadioContextApi | undefined>(undefined);
/**
 * State of the radio provider context
 */
export const radioReducer = (state: RadioContextState = [], action): RadioContextState => {
  return isEqual(state, action) ? state : action;
};
/** Utility helper to turn a possible string into an array */
const selectedToArray = (prop: string | string[]) => (Array.isArray(prop) ? [...prop] : [prop]);
// provider component
export const RadioProvider = React.memo(
  ({
    defaultSelected = [],
    selected,
    allowMultiple = false,
    allowNone = false,
    onChange = () => null,
    children,
  }: RadioContextProps) => {
    // defaultSelected can either be a string, or an array of strings
    const [state, dispatch] = React.useReducer(radioReducer, selectedToArray(selected || defaultSelected));
    // if we're controlled, update our state when the prop changes
    React.useEffect(() => {
      if (selected) {
        dispatch(selectedToArray(selected));
      }
    }, [selected]);
    const toggle = (name: string) => {
      let nextState: RadioContextState;
      if (state.includes(name)) {
        nextState = state.filter(selected => selected !== name);
        nextState =
          nextState.length === 0 && !allowNone
            ? state // no selection is not allowed: keep last item
            : nextState; // all other cases, return filtered items
      } else {
        // otherwise we have a new item
        nextState = allowMultiple
          ? [...state, name] // multiple selection is allowed: add new item to state
          : [name]; // otherwise swap with old item
      }
      // if we don't have selected, we're uncontrolled, so we update our own state
      if (selected === undefined) {
        dispatch(nextState);
      }
      // either way, tell our listeners we've updated
      onChange(nextState);
    };
    const contextValue = {
      selected: state,
      toggle,
    };
    return <RadioContext.Provider value={contextValue}>{children}</RadioContext.Provider>;
  }
);
// provider access hook
export const useRadio = (): RadioContextApi => {
  const context = React.useContext(RadioContext);
  if (context === undefined) {
    throw new Error(`useRadio() must be used within an <AuthProvider />`);
  }
  return context;
};

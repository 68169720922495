import { OrderStatus } from "../state/orders/types";

// name map for displayOrderStatus
export const orderStatusMap: { [key in OrderStatus]: string } = {
  [OrderStatus.Canceling]: "Canceling",
  [OrderStatus.Filled]: "Filled",
  [OrderStatus.PartialCancel]: "Partial cancel",
  [OrderStatus.PartialFill]: "Partial fill",
  [OrderStatus.Pending]: "Pending",
  [OrderStatus.Rejected]: "Rejected",
  [OrderStatus.Replacing]: "Replacing",
  [OrderStatus.UnsolCancel]: "Unsol cancel",
  [OrderStatus.UnsolPartial]: "Unsol partial",
  [OrderStatus.UserCancel]: "User cancel",
  [OrderStatus.Working]: "Working",

  // HERE
  [OrderStatus.Paused]: "Paused",
};

const orderStatusMapUnabridged: { [key in OrderStatus]: string } = {
  [OrderStatus.Canceling]: "Canceling",
  [OrderStatus.Filled]: "Filled",
  [OrderStatus.PartialCancel]: "Partial cancel",
  [OrderStatus.PartialFill]: "Partial fill",
  [OrderStatus.Pending]: "Pending",
  [OrderStatus.Rejected]: "Rejected",
  [OrderStatus.Replacing]: "Replacing",
  [OrderStatus.UnsolCancel]: "Unsolicited cancel",
  [OrderStatus.UnsolPartial]: "Unsolicited partial",
  [OrderStatus.UserCancel]: "User cancel",
  [OrderStatus.Working]: "Working",
  
  // HERE
  [OrderStatus.Paused]: "Paused",
};

/** Returns order status value as a readable title */
export const displayOrderStatus = (status: OrderStatus | string, unabridged?: boolean) =>
  unabridged ? orderStatusMapUnabridged[status] : orderStatusMap[status];

import React from "react";
import clsx from "clsx";

// types / states
import { OrderStatus } from "../state/orders/types";
import { displayOrderStatus } from "../lib/formatters";

// styles
import styles from "./OrderStatusLabel.module.scss";

type OrderStatusLabelProps = {
  labelOverride?: String | null;
  status: OrderStatus;
  type?: "text" | "pill";
};

/**
 * Renders a order status as a pill shaped, color UI component
 */
const OrderStatusLabel = ({ status, type = "text", labelOverride }: OrderStatusLabelProps) => {
  const typeClassMap = {
    pill: styles.pill,
    text: styles.text,
  };

  const colorClassMap: { [key in OrderStatus]: string } = {
    [OrderStatus.Canceling]: styles.yellow,
    [OrderStatus.Filled]: styles.teal,
    [OrderStatus.PartialCancel]: styles.orange,
    [OrderStatus.PartialFill]: styles.teal,
    [OrderStatus.Pending]: styles.grey,
    [OrderStatus.Rejected]: styles.red,
    [OrderStatus.Replacing]: styles.grey,
    [OrderStatus.UnsolCancel]: styles.grey,
    [OrderStatus.UnsolPartial]: styles.orange,
    [OrderStatus.UserCancel]: styles.grey,
    [OrderStatus.Working]: styles.yellow,
    [OrderStatus.Paused]: styles.orange,
  };

  const className = clsx(styles.statusLabel, colorClassMap[status] || null, typeClassMap[type] || null);

  return (
    <div className={className}>
      {labelOverride
        ? labelOverride.length > 10
          ? `${labelOverride.slice(0, 10)}...`
          : labelOverride
        : displayOrderStatus(status)}
    </div>
  );
};

export default OrderStatusLabel;

import React, { useEffect, useState, useRef } from "react";
import { Button, Icon, Input } from "antd";
import "../components/currency/cards.scss";
import clsx from "clsx";
import { useOnClickOutside } from "../lib/util";
import styles from "./Dropdown.module.scss";
import "./Dropdown.scss";

type DropdownProps = {
  onChange?: any;
  options: any;
  value?: any;
};

function Dropdown({ onChange, options, value }: DropdownProps) {
  const [selectedOption, setSelectedOption] = useState<String>("");
  const [showOptions, setShowOptions] = useState<Boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any>("");
  const handleChangeSearch = event => {
    setSearchTerm(event.target.value);
  };
  const dropdownMenu = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(dropdownMenu, () => setShowOptions(false));

  useEffect(() => {
    const results = options.filter(option => option.toLowerCase().includes(searchTerm));
    setSearchResults(results);
  }, [searchTerm, options]);

  useEffect(() => {
    if(value) {
      setSelectedOption(value);
    }
  }, [value]);

  const handleChange = value => {
    setSelectedOption(value);
    setShowOptions(false);
    onChange(value);
  };

  return (
    <div className={styles.dropdown}>
      <Button
        data-testid="currency_slippage_currency_select"
        className={clsx("noDrag", styles.button)}
        size="small"
        onClick={() => setShowOptions(!showOptions)}
      >
        <span className={clsx(styles.buttonContent, "selectedCurrency")}>{selectedOption}</span>
        {Icon({ type: "caret-down" })}
      </Button>
      {showOptions && (
        <div className={styles.dropdownSection} ref={dropdownMenu}>
          <div className={clsx(styles.dropdownContent, "slippageDropdown", "noDrag")} tabIndex={0}>
            <div className={styles.filterInput}>
              <Input placeholder={"Filter"} value={searchTerm} onChange={handleChangeSearch} />
            </div>
            <ul className={styles.dropdownMenu}>
              {searchResults &&
                searchResults.map((result, i) => (
                  <li
                    key={`${result}.${i}`}
                    value={result}
                    onClick={handleChange.bind(null, result)}
                    className={clsx(styles.listItem, selectedOption === result ? styles.selectedItem : "")}
                  >
                    <div className={clsx(selectedOption === result && styles.selected)}></div>
                    <span>{result}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dropdown;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Blotter from "../../../shared/blotter/Blotter";
import { getMarginsData } from "../../../state/margins/marginsState";
import CustomAggregationHeader from "./CustomAggregationHeader";

const AggregatedPNL = ({ id, marginsData, saveSettings, tableSettings, setDataLoaded, tabType }) => {
  const [aggregatedPnlTableSettings, setAggregatedPnlTableSettings] = useState<any>(tableSettings);

  const setTableSettings = ({ columns, filters, sorts }) => {
    tableSettings = { columns, filters, sorts };
    saveSettings(id, tableSettings);
  };

  useEffect(() => {
    setAggregatedPnlTableSettings(tableSettings);
  }, [tableSettings]);

  return (
    <Blotter
      blotterTableSettings={aggregatedPnlTableSettings}
      columnDefs={columnDefs}
      customFrameworkComponents={{ customAggregationHeader: CustomAggregationHeader }}
      rowData={marginsData}
      setBlotterTableSettings={setTableSettings}
      setDataLoaded={setDataLoaded}
      tabType={tabType}
    />
  );
};
export default connect((state: any) => ({
  marginsData: getMarginsData(state),
}))(AggregatedPNL);

const priceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

const columnDefs = [
  {
    headerName: "Total Label",
    headerGroupComponent: "customAggregationHeader",
    headerGroupComponentParams: {
      columnName: "currencyPair",
    },
    children: [
      {
        headerName: "Instruments",
        field: "currencyPair",
        colId: "currencyPair",
        filter: "agTextColumnFilter",
        minWidth: 142,
      },
    ],
  },
  {
    headerName: "Total Realized PnL",
    headerGroupComponent: "customAggregationHeader",
    headerGroupComponentParams: {
      columnName: "realizedPnlUsd",
    },
    children: [
      {
        headerName: "Realized PnL USD",
        field: "realizedPnlUsd",
        colId: "realizedPnlUsd",
        filter: "agTextColumnFilter",
        valueFormatter: ({ value }) => value && priceFormatter.format(value).replace("$", ""),
        minWidth: 176,
      },
    ],
  },
  {
    headerName: "Total UnRealized PnL",
    headerGroupComponent: "customAggregationHeader",
    headerGroupComponentParams: {
      columnName: "unrealizedPnlUsd",
    },
    children: [
      {
        headerName: "Unrealized PnL USD",
        field: "unrealizedPnlUsd",
        colId: "unrealizedPnlUsd",
        filter: "agTextColumnFilter",
        valueFormatter: ({ value }) => value && priceFormatter.format(value).replace("$", ""),
        minWidth: 158,
      },
    ],
  },
  {
    headerName: "Total Total PnL USD",
    headerGroupComponent: "customAggregationHeader",
    headerGroupComponentParams: {
      columnName: "totalPnlUsd",
    },
    children: [
      {
        headerName: "Total PnL USD",
        field: "totalPnlUsd",
        colId: "totalPnlUsd",
        filter: "agTextColumnFilter",
        valueFormatter: ({ value }) => value && priceFormatter.format(value).replace("$", ""),
        minWidth: 154,
      },
    ],
  },
  {
    headerName: "Total Position",
    headerGroupComponent: "customAggregationHeader",
    headerGroupComponentParams: {
      columnName: "position",
    },
    children: [
      {
        headerName: "Position",
        field: "position",
        colId: "position",
        filter: "agTextColumnFilter",
        valueFormatter: ({ value }) => value && priceFormatter.format(value).replace("$", ""),
        minWidth: 118,
      },
    ],
  },
  {
    headerName: "Total Position USD",
    headerGroupComponent: "customAggregationHeader",
    headerGroupComponentParams: {
      columnName: "positionUsd",
    },
    children: [
      {
        headerName: "Position USD",
        field: "positionUsd",
        colId: "positionUsd",
        filter: "agTextColumnFilter",
        valueFormatter: ({ value }) => value && priceFormatter.format(value).replace("$", ""),
        minWidth: 146,
      },
    ],
  },
];

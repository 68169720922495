import { Observable, throwError, timer } from "rxjs";
import { mergeMap } from "rxjs/operators";

export const retryStrategy = ({ maxRetryAttempts = 2, scalingDuration = 100, dispatch }) => (
  attempts: Observable<any>
) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      if (retryAttempt > maxRetryAttempts) {
        return throwError("MAX_RETRY_EXCEEDED");
      }
      const message = `Attempting reconnect ${retryAttempt} of ${maxRetryAttempts}...`;
      console.log(message);

      return timer(retryAttempt * scalingDuration);
    })
  );
};

// constants
export const INIT = "INIT";
export const UPDATE_SELECTED_ORDER_HISTORY_ROWS = "UPDATE_SELECTED_ORDER_HISTORY_ROWS";
export const USER_INFO = "USER_INFO";
export const SET_IS_SCREEN_LOCKED = "SET_IS_SCREEN_LOCKED";

// actions
export const init = () => ({
  type: INIT,
});

export const setIsScreenLocked = (isScreenLocked: boolean) => ({
  type: SET_IS_SCREEN_LOCKED,
  payload: {
    isScreenLocked,
  },
});

export const updateSelectedOrderHistoryRows = updatedRowData => ({
  type: UPDATE_SELECTED_ORDER_HISTORY_ROWS,
  payload: updatedRowData,
});

export const updateUserInfo = userInfo => ({
  type: USER_INFO,
  payload: userInfo,
});

// reducer
const initialState = {
  isInit: false,
  analyticsPanel: {
    orderHistorySelectedRows: [],
  },
  isScreenLocked: false,
  // selectedOrders: []
};

const appState = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_SELECTED_ORDER_HISTORY_ROWS: {
      return { ...state, analyticsPanel: { orderHistorySelectedRows: action.payload } };
    }
    case USER_INFO: {
      return { ...state, userInfo: action.payload };
    }
    case SET_IS_SCREEN_LOCKED: {
      const { isScreenLocked } = action.payload;
      return {
        ...state,
        isScreenLocked,
      };
    }
    default:
      return state;
  }
};

export default appState;

// selectors
export const isInitSelector = (state: any) => state.app.isInit;
export const orderHistorySelectedRows = (state: any) => state.app.analyticsPanel.orderHistorySelectedRows;
export const userInfoData = (state: any) => state.app.userInfo;
export const getIsScreenLocked = state => state.app.isScreenLocked;

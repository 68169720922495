export const OPEN_CONNECTION = "OPEN_CONNECTION";
export const CONNECTING = "CONNECTING";
export const MAX_RETRY_EXCEEDED = "MAX_RETRY_EXCEEDED";
export const RECONNECT = "RECONNECT";
export const CONNECTED = "CONNECTED";
export const NO_INTERNET = "NO_INTERNET";
export const CONNECTION_CLOSED = "CONNECTION_CLOSED";
export const CONNECTION_FAILED = "CONNECTION_FAILED";
export const DISCONNECTED = "DISCONNECTED";
export const SEND_DATA = "SEND_DATA";
export const APP_LOAD_COMPLETE = "APP_LOAD_COMPLETE";
export const APP_LOAD_START = "APP_LOAD_START";

export const APP_IS_LOADING = "APP_IS_LOADING";
export const APP_LOAD_SUCCESS = "APP_LOAD_SUCCESS";
export const APP_LOAD_FAIL = "APP_LOAD_FAIL";
export const SET_HAS_INTERNET = "SET_HAS_INTERNET";

export const setIsConnected = (hasInternet: boolean) => ({
  type: SET_HAS_INTERNET,
  payload: {
    hasInternet,
  },
});

const initState = {
  appLoadingState: APP_IS_LOADING,
  status: DISCONNECTED,
};

export const openConnection = (isInitialLoad: boolean = false) => ({ type: OPEN_CONNECTION, isInitialLoad });
export const sendData = (data: {}) => ({ type: SEND_DATA, payload: data });

const connection = (state = initState, action) => {
  switch (action.type) {
    case SET_HAS_INTERNET: {
      const { hasInternet } = action.payload;
      return { ...state, status: hasInternet ? CONNECTED : DISCONNECTED, message: action.payload };
    }
    case APP_LOAD_START: {
      return { ...state, appLoadingState: APP_IS_LOADING };
    }
    case OPEN_CONNECTION: {
      return { ...state, status: CONNECTING, message: action.payload };
    }
    case RECONNECT: {
      return { ...state, status: CONNECTING, message: action.payload };
    }
    case CONNECTED: {
      return { ...state, status: CONNECTED };
    }
    case DISCONNECTED: {
      return { ...state, status: DISCONNECTED };
    }
    case APP_LOAD_SUCCESS: {
      return { ...state, appLoadingState: APP_LOAD_SUCCESS, status: CONNECTED };
    }
    case APP_LOAD_FAIL: {
      return { ...state, appLoadingState: APP_LOAD_FAIL, status: DISCONNECTED };
    }
    case CONNECTION_FAILED: {
      return { ...state, status: CONNECTION_FAILED };
    }
    default:
      return state;
  }
};

export default connection;

import React, { useEffect } from "react";
import clsx from "clsx";
import { Button } from "antd";

import styles from "../AuctionOrderFormClassic.module.scss";
import { OrderType } from "../../../../state/orders/types";
import { positiveNumberEnforcer } from "../../../../lib/formatUtil";
import { isNDFCurrency } from "../../../../lib/util";
import { OrderFieldsEnum } from "../fields";
import { ReactComponent as PriceRefresh } from "../../../../assets/images/PriceRefresh-24.svg";

const PriceOco = ({
  priceName,
  priceType,
  isPriceFieldDisabledByOrderType,
  order,
  auctionOrderStatePriceByOrderType,
  setAuctionOrderState,
  handleIncrementDecrement,
  instrumentId,
  instrumentPipValue,
  currencyPair,
  refreshPrice,
}) => {
  return (
    <div
      className={clsx(
        styles.inputBlock,
        styles.inputBlockTwoCol,
        isPriceFieldDisabledByOrderType && styles.inputDisabled
      )}
    >
      {priceType === OrderFieldsEnum.price ? <label>Limit Price</label> : <label>Stop Price</label>}

      <div
        className={clsx(
          styles.inputSection,
          styles.inputSectionSmall,
          isPriceFieldDisabledByOrderType && styles.inputDisabled
        )}
      >
        <div className="ant-input-number ant-input-number-sm ">
          <input
            data-testid={`auction_price`}
            className={clsx("ant-input-number-input", "ant-input-number-sm", styles.inputPriceTwoCol)}
            type="text"
            disabled={isPriceFieldDisabledByOrderType}
            autoFocus={!order}
            value={auctionOrderStatePriceByOrderType}
            onKeyDown={event => {
              if (
                !(
                  ["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(event.key) ||
                  (auctionOrderStatePriceByOrderType &&
                    auctionOrderStatePriceByOrderType !== "" &&
                    !auctionOrderStatePriceByOrderType.toString().includes(".") &&
                    event.key === ".")
                )
              ) {
                positiveNumberEnforcer(event);
              }
            }}
            onChange={(event: any) => {
              setAuctionOrderState(prevState => ({
                ...prevState,
                [OrderType.Oco]: { ...prevState[OrderType.Oco], [priceName]: event.target.value },
              }));
            }}
            onFocus={event => {
              const target = event.target;
              // if for whatever reason someone tabs into this input, the text won't hightlight and the input curson will go to the end
              event.target.selectionStart = event.target.selectionEnd = event.target.value.length;
              // necessary for returning the cursor to the end if the user left the input with the cursor not at the end
              setTimeout(() => {
                target.setSelectionRange(target.value.length, target.value.length);
              }, 0);
            }}
          />
        </div>
        <Button
          className={styles.priceAdjustButton}
          size="small"
          onClick={() =>
            handleIncrementDecrement(
              "decrement",
              isNDFCurrency(instrumentId) ? instrumentPipValue : instrumentPipValue / 10,
              priceName
            )
          }
          disabled={isPriceFieldDisabledByOrderType}
          icon="minus"
        />
        <Button
          className={styles.priceAdjustButton}
          size="small"
          onClick={() =>
            handleIncrementDecrement(
              "increment",
              isNDFCurrency(instrumentId) ? instrumentPipValue : instrumentPipValue / 10,
              priceName
            )
          }
          disabled={isPriceFieldDisabledByOrderType}
          icon="plus"
        />
        {currencyPair && currencyPair.symbol && (
          <Button
            data-testid="auction_refresh"
            className={styles.priceRefresh}
            size="small"
            onClick={() => refreshPrice(priceName)}
            disabled={isPriceFieldDisabledByOrderType}
          >
            <PriceRefresh />
          </Button>
        )}
      </div>
    </div>
  );
};

export default PriceOco;

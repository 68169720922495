import React from "react";
import { Switch, Select, Icon } from "antd";

import styles from "./Settings.module.scss";
import { DisplayOrdersByTimeType } from "../../state/settings/settingsState";

type BSProps = {
  saveSettings: any;
  settings: any;
};

const BlotterSettings = ({ saveSettings, settings }: BSProps) => {
  const displayOrdersByTimeOptions = [
    {
      label: "Today only",
      value: DisplayOrdersByTimeType.today,
    },
    {
      label: "All",
      value: DisplayOrdersByTimeType.all,
    },
  ];

  return (
    <div className={styles.settingsContent}>
      <section className={styles.formBlock}>
        <h5>display</h5>
        <div className={styles.switchBlock}>
          <label>Blotter</label>
          <Switch
            checked={settings.showBlotter}
            onClick={() => {
              saveSettings({ ...settings, showBlotter: !settings.showBlotter });
            }}
          />
        </div>
        <div className={styles.switchBlock}>
          <label>Aggregated PnL</label>
          <Switch
            checked={settings.showAggregatedPnl}
            onClick={() => {
              saveSettings({ ...settings, showAggregatedPnl: !settings.showAggregatedPnl });
            }}
          />
        </div>
        <div className={styles.switchBlock}>
          <label>Ticker</label>
          <Switch
            checked={settings.showTicker}
            onClick={() => {
              saveSettings({ ...settings, showTicker: !settings.showTicker });
            }}
          />
        </div>
        <div className={styles.inputBlock}>
          <label>Display orders by time</label>
          <Select
            size="large"
            style={{ width: "100%" }}
            value={settings.displayOrdersByTime}
            onSelect={(value: string) => {
              saveSettings({ ...settings, displayOrdersByTime: value });
            }}
            suffixIcon={Icon({ type: "caret-down" })}
          >
            {displayOrdersByTimeOptions.map((option, i) => (
              <Select.Option key={`${option.value}.${i}`} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </section>
    </div>
  );
};

export default BlotterSettings;

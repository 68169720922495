import {
  LOAD_ORDERS_PROCESSED,
  REMOVE_CLOSED_ORDERS_FROM_STORE,
  REMOVE_ORDER,
  SUBMIT_NEW_ORDER,
  SUBMIT_REPLACE_ORDER,
  UPDATE_ORDER
} from "./ordersState";
import { ClosedOrderStatus, OrderModel, OrderType } from "./types";

const initialState = [];

const statusOrderRank = {
  pending: 0,
  working: 1,
  partialFill: 2,
  filled: 3,
  unsolCancel: 4,
  unsolPartial: 5,
  userCancel: 6,
  partialCancel: 7,
  rejected: 8,
};

// direction values, 1: ASC, -1: DESC
const sortBy = [
  // {
  //   prop: "status",
  //   direction: 1,
  // },
  {
    prop: "createdDate",
    direction: -1,
  },
];

const updateOrdersWithOrderUpdate = (existingOrders, action) => {
  const orderUpdate = action.payload.data;
  // if it's a single order it's a new order or update to an existing order
  // so check to see if we already have it
  let foundOrder = false;

  // pauseResumeHelper
  // console.log("updateOrdersWithOrderUpdate");

  const updatedOrdersList = existingOrders.map(existingOrder => {
    if (existingOrder.correlation === orderUpdate.correlation) {
      foundOrder = true;
      const foundOrderObj = orderUpdate;
      return foundOrderObj;
    }
    return existingOrder;
  });

  if (foundOrder) {
    return updatedOrdersList;
  } else {
    return [...updatedOrdersList, orderUpdate];
  }
};

export const orderOrders = ordersArr => {

  return ordersArr.sort((a, b) => {
    let i = 0;
    let result = 0;
    while (i < sortBy.length && result === 0) {
      if (
        sortBy[i].prop === "createdDate" &&
        (!Number.isInteger(a["createdDate"]) || !Number.isInteger(b["createdDate"]))
      ) {
        return ordersArr;
      }
      result =
        sortBy[i].direction *
        (sortBy[i].prop === "status"
          ? statusOrderRank[a[sortBy[i].prop]] < statusOrderRank[b[sortBy[i].prop]]
            ? -1
            : statusOrderRank[a[sortBy[i].prop]] > statusOrderRank[b[sortBy[i].prop]]
            ? 1
            : 0
          : a[sortBy[i].prop] < b[sortBy[i].prop]
          ? -1
          : a[sortBy[i].prop] > b[sortBy[i].prop]
          ? 1
          : 0);
      i++;
    }

    return result;
  });
};

const ordersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOAD_ORDERS_PROCESSED: {
      return orderOrders(action.payload.data);
    }
    case UPDATE_ORDER: {
      return orderOrders(updateOrdersWithOrderUpdate(state, action));
    }
    case SUBMIT_NEW_ORDER: {
      const { takerName } = action.payload.localOrderData;
      return orderOrders([...state, { ...action.payload.order, createdDate: Date.now(), takerName }]);
    }
    case SUBMIT_REPLACE_ORDER: {
      const replaceOrder = action.payload.order;
      return state.map((order: OrderModel) => {

        let ocoData: any = {};

        if (order.type === OrderType.Oco) {
          ocoData = {
            priceLeg2: replaceOrder?.priceLeg2,
            stopLossPriceLeg2: replaceOrder?.stopLossPriceLeg2,
          };
        }

        if (replaceOrder.id === order.id) {
          return {
            ...order,
            ...ocoData,
            price: replaceOrder.price,
            stopLossPrice: replaceOrder.stopLossPrice,
            quantity: replaceOrder.quantity,
          };
        }
        return order;
      });
    }
    case REMOVE_ORDER: {
      const correlation = action.payload;
      return state.map((order: OrderModel) => {
        if (correlation === order.correlation) {
          return { ...order, isComplete: true };
        }
        return order;
      });
    }

    case REMOVE_CLOSED_ORDERS_FROM_STORE: {

      const orderIds = action.payload;
      if ((orderIds?.length > 0)) {
        return state.map((order: any) => {
          if (orderIds.includes(order.id)) {
            return { ...order, isComplete: true };
          }
          return order;
        });
     
      }
      
    }

    default:
      return orderOrders(state);
  }
};

export default ordersReducer;

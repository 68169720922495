import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";

// sub states
import appReducer from "./app/appState";
import settingsReducer from "./settings/settingsState";
import currencyGroupsReducer from "./currency/currencyState";
import allCurrencyPairsReducer from "./currency/symbols/symbolsState";
import { refreshAuctionCardPriceEpic, undockCardEpic } from "./currency/currencyEpics";
import {
  cancelOrderEpic,
  lastNewOrderReducer,
  loadOrdersEpic,
  orderSuccessNotificationEpic,
  playCanceledNotificationSound,
  playFilledNotificationSound,
  removeCancelledOrdersEpic,
  removeFilledOrdersEpic,
  submitNewOrderEpic,
  submitReplaceOrderEpic,
  updateOrdersWithServerOrdersEpic,
  postProcessUpdateOrdersEpic,
  initAllOrdersBasicInfoEpic,
  removeClosedOrdersEpic,
} from "./orders/ordersState";
import ordersReducer from "./orders/ordersReducer";

import { cancelOrdersEpic, checkOrdersVisibilityAndToggleVisibleOrdersEpic, pauseOrdersEpic, resumeOrdersEpic } from "./orders/pauseResumeOrders/pauseResumeOrdersActions";
import pauseResumeOrdersReducer from "./orders/pauseResumeOrders/pauseResumeOrdersState";

import { messagesEpic, messagesReducer, ordersMessageEpic } from "./connection/messagesState";
import { openConnectionEpic, disconnectedEpic } from "./connection/connectionEpic";
import connectionReducer from "./connection/connectionState";
import {
  clearMarketDataOnTakerChangeEpic,
  loadSettingsEpic,
  saveSettingsToProfileEpic,
} from "./settings/settingsEpics";
import marginsState from "./margins/marginsState";
import { loadSymbolsPipsFromAssetServerEpic } from "./currency/symbols/symbolsActions";


// create root states
export const rootEpics = combineEpics(
  cancelOrderEpic,
  loadOrdersEpic,
  clearMarketDataOnTakerChangeEpic,
  disconnectedEpic,
  loadSettingsEpic,
  messagesEpic,
  openConnectionEpic,
  ordersMessageEpic,
  orderSuccessNotificationEpic,
  playCanceledNotificationSound,
  playFilledNotificationSound,
  refreshAuctionCardPriceEpic,
  removeCancelledOrdersEpic,
  removeFilledOrdersEpic,
  saveSettingsToProfileEpic,
  submitNewOrderEpic,
  submitReplaceOrderEpic,
  undockCardEpic,
  updateOrdersWithServerOrdersEpic,
  postProcessUpdateOrdersEpic,
  initAllOrdersBasicInfoEpic,
  pauseOrdersEpic,
  resumeOrdersEpic,
  cancelOrdersEpic,
  checkOrdersVisibilityAndToggleVisibleOrdersEpic,
  removeClosedOrdersEpic,
  loadSymbolsPipsFromAssetServerEpic
);

export const rootReducers = combineReducers({
  allCurrencyPairs: allCurrencyPairsReducer,
  app: appReducer,
  connectionState: connectionReducer,
  currencyGroups: currencyGroupsReducer,
  lastNewOrder: lastNewOrderReducer,
  margins: marginsState,
  messages: messagesReducer,
  orders: ordersReducer,
  pauseResumeOrders: pauseResumeOrdersReducer,
  settings: settingsReducer,
});

import React, { useEffect, useState, useCallback } from 'react';
import cx from 'clsx';

import { ReactComponent as CloseIcon } from "../../assets/images/CloseIcon-16.svg";

import styles from './Notifications.module.scss';
import { Notification } from './Notifications.types';
import { notifications } from './useNotifications';
import { connect } from 'react-redux';
import { getNotificationSettings } from '../../state/settings/settingsState';

type NotificationCardProps = Notification;

/**
 * Renders a notification. Notifications automatically start a timer to dismiss themselves on creation.
 * Timer can be interrupted by either:
 * - Clicking the 'close' button on the notification itself
 * - Provider auto removing the oldest visible notifications after a max threshold is reached.
 * - Provider auto removing old notifications after a threshold count is reached (near impossible for this to be the reason)
 */
const _NotificationCard = ({ id, title, level, message, orderNotificationDelay }: NotificationCardProps) => {
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (orderNotificationDelay) {
      let duration = orderNotificationDelay * 1000;
      let timer = setTimeout(() => {
        setIsExpired(true);
      }, duration);
      return () => {
        clearTimeout(timer);
      };
    }

  }, [orderNotificationDelay]);

  const dismissSelf = useCallback(() => {
    notifications.dismiss(id);
  }, [id]);

  // When our timer expires, tell state to mark us as inactive
  useEffect(() => {
    if (isExpired) {
      dismissSelf();
    }
  }, [isExpired, dismissSelf]);

  return (
    <div className={styles.notification}>
      <div className={cx(styles.indicator, styles[level])} />
      <div className={styles.content}>
        <div className={styles.header}>{title}</div>
        {message != null ? <div className={styles.message}>{message}</div> : null}
      </div>
      <div className={styles.actions}>
        <CloseIcon style={{ color: 'var(--ink-90)' }} onClick={dismissSelf} />
      </div>
    </div>
  );
};

export const NotificationCard = connect((state: any) => ({
  orderNotificationDelay: getNotificationSettings(state).displayOrderAcknowlegedBannerInSeconds,

}))(_NotificationCard);

import { of } from "rxjs";
import { map, mergeMap, withLatestFrom } from "rxjs/operators";
import { ofType } from "redux-observable";
import React from "react";
import ReactDOM from "react-dom";
import {
  UNDOCK_CARD,
  CARD_UNDOCKED,
  REFRESH_AUCTION_PRICE,
  setAuctionCard,
  SET_CURRENT_PRICE,
  setCurrentPrice,
} from "./currencyActions";
import { store } from "../configureStore";
import { Provider } from "react-redux";

import Window from "../../components/currency/Window";
import { OrderSide } from "../orders/types";
import { currencyPairByIdSelector } from "./currencyState";

export const undockCardEpic = (action$, state$) => {
  return action$.pipe(
    ofType(UNDOCK_CARD),
    withLatestFrom(state$),
    mergeMap(([action, state]: any) => {
      const element = document.createElement("div");
      const { marketSettings } = state.settings;
      const {
        depthDisplay,
        displaySpreadOnDepthOfBook,
        showSlippage,
        showPriceTrendAndSpread,
        cardSize,
      } = marketSettings;
      ReactDOM.render(
        <Provider store={store}>
          <Window
            symbol={action.payload.symbol}
            depthDisplay={depthDisplay}
            displaySpreadOnDepthOfBook={displaySpreadOnDepthOfBook}
            showSlippage={showSlippage}
            showPriceTrendAndSpread={showPriceTrendAndSpread}
            cardSize={cardSize}
          />
        </Provider>,
        element
      );
      return of({ type: CARD_UNDOCKED, payload: action.payload.symbol });
    })
  );
};

export const refreshAuctionCardPriceEpic = (action$, state$) => {
  return action$.pipe(
    ofType(REFRESH_AUCTION_PRICE),
    withLatestFrom(state$),
    map(([action, state]: any) => {
      const auctionOrderState = action.payload.orderState;
      const priceName = action.payload.fieldName;
      const currency = auctionOrderState.instrumentId;
      const currencyPair = currencyPairByIdSelector(state, currency);

      return setAuctionCard({
        ...auctionOrderState,
        [priceName]:
          auctionOrderState.side === OrderSide.Buy
            ? currencyPair.bestBidPrice.toString()
            : currencyPair.bestAskPrice.toString(),
      });
    })
  );
};

export const refreshCurrentPriceEpic = (action$, state$) => {
  return action$.pipe(
    ofType(SET_CURRENT_PRICE),
    // withLatestFrom(state$),
    map(([action]: any) => {
      // const auctionOrderState = action.payload;
      // const currency = auctionOrderState.instrumentId;
      // const currencyPair = currencyPairByIdSelector(state, currency);

      return setCurrentPrice(action.payload);
    })
  );
};

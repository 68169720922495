import React from "react";

import styles from "./Settings.module.scss";
import { positiveNumberEnforcer } from "../../lib/formatUtil";
import { InputNumber, Switch } from "antd";

type NotificationsSettingsProps = {
  saveSettings: any;
  settings: any;
};

const NotificationsSettings = ({ saveSettings, settings }: NotificationsSettingsProps) => {

  return (
    <div className={styles.settingsContent}>
      <section className={styles.formBlock}>
        <h5>sound notifications</h5>

        <div className={styles.switchBlock}>
          <label>Filled orders</label>
          <Switch
            checked={settings.filledOrders}
            onClick={() => {
              saveSettings({ ...settings, filledOrders: !settings.filledOrders });
            }}
          />
        </div>

        <div className={styles.inputBlock}>
          <label>Ignore following notifications for (in seconds)</label>
          <InputNumber
            size="large"
            value={settings.ignoreFollowingFilledInSeconds}
            disabled={!settings.filledOrders}
            min={0}
            max={3600}
            onKeyDown={event => {
              positiveNumberEnforcer(event);
            }}
            onChange={value => {
              saveSettings({ ...settings, ignoreFollowingFilledInSeconds: value === null ? 0 : value });
            }}
          />
        </div>

        <div className={styles.switchBlock}>
          <label>Canceled or rejected orders</label>
          <Switch
            checked={settings.cancelOrRejectedOrders}
            onClick={() => {
              saveSettings({ ...settings, cancelOrRejectedOrders: !settings.cancelOrRejectedOrders });
            }}
          />
        </div>

        <div className={styles.inputBlock}>
          <label>Ignore following notifications for (in seconds)</label>
          <InputNumber
            size="large"
            value={settings.ignoreFollowingCancelOrRejectedInSeconds}
            disabled={!settings.cancelOrRejectedOrders}
            min={0}
            max={3600}
            onKeyDown={event => {
              positiveNumberEnforcer(event);
            }}
            onChange={value => {
              saveSettings({ ...settings, ignoreFollowingCancelOrRejectedInSeconds: value === null ? 0 : value });
            }}
          />
        </div>
      </section>

      <section className={styles.formBlock}>
        <h5>banner notifications</h5>

        <div className={styles.switchBlock}>
          <label>Order acknowledged</label>
          <Switch
            checked={settings.showOrderAcknowlegedBanner}
            onClick={() => {
              saveSettings({ ...settings, showOrderAcknowlegedBanner: !settings.showOrderAcknowlegedBanner });
            }}
          />
        </div>

        <div className={styles.inputBlock}>
          <label>Display notification in seconds</label>
          <InputNumber
            size="large"
            value={settings.displayOrderAcknowlegedBannerInSeconds}
            disabled={!settings.showOrderAcknowlegedBanner}
            min={0}
            max={3600}
            onKeyDown={event => {
              positiveNumberEnforcer(event);
            }}
            onChange={value => {
              saveSettings({ ...settings, displayOrderAcknowlegedBannerInSeconds: value === null ? 0 : value });
            }}
          />
        </div>
      </section>
    </div>
  );
}

export default NotificationsSettings

import Big from "big.js";

export const UPDATE_MARGINS = "UPDATE_MARGINS";

export const updateMargins = data => ({
  type: UPDATE_MARGINS,
  payload: data,
});

const updateMarginsWithServerMargins = (marginsInLocalState, action) => {
  const serverMargins = action.payload;
  const existingMargins = marginsInLocalState.slice();

  if (existingMargins.length === 0) {
    return serverMargins;
  } else {
    serverMargins.forEach(serverMargin => {
      const foundIndex = existingMargins.findIndex(existingMargin => {
        return (
          existingMargin.currencyPair === serverMargin.currencyPair &&
          existingMargin.taker === serverMargin.taker &&
          existingMargin.account === serverMargin.account
        );
      });

      if (foundIndex >= 0) {
        existingMargins[foundIndex] = serverMargin;
      } else {
        existingMargins.push(serverMargin);
      }
    });

    return existingMargins;
  }
};

const initialState = [
  // {
  //   account: "jharris",
  //   askWap: 0,
  //   bidWap: 0,
  //   conversionRate: 1,
  //   convRatePos: 1.32956,
  //   cost: -1391280,
  //   currencyPair: "GBP/USD",
  //   lastOrderTs: {
  //     month: "APRIL",
  //     dayOfWeek: "MONDAY",
  //     dayOfYear: 95,
  //     nano: 311000000,
  //     year: 2021,
  //     monthValue: 4,
  //     dayOfMonth: 5,
  //     hour: 13,
  //     minute: 57,
  //     second: 13,
  //     chronology: {
  //       calendarType: "iso8601",
  //       id: "ISO",
  //     },
  //   },
  //   midPrice: 0,
  //   position: -1000000,
  //   positionUsd: 184466111177.09,
  //   realizedPnl: -30,
  //   realizedPnlUsd: -30,
  //   taker: "SPOT_UI_T1",
  //   timestamp: {
  //     month: "DECEMBER",
  //     dayOfWeek: "WEDNESDAY",
  //     dayOfYear: 365,
  //     nano: 0,
  //     year: 1969,
  //     monthValue: 12,
  //     dayOfMonth: 31,
  //     hour: 19,
  //     minute: 0,
  //     second: 0,
  //     chronology: {
  //       calendarType: "iso8601",
  //       id: "ISO",
  //     },
  //   },
  //   totalPnl: 1391250,
  //   totalPnlUsd: -84489.5258968,
  //   unrealizedPnl: 1391280,
  //   unrealizedPnlUsd: -84459.5258968,
  // },
  // {
  //   account: "jharris",
  //   askWap: 0,
  //   bidWap: 0,
  //   conversionRate: 1,
  //   convRatePos: 1.11582,
  //   cost: 1224860,
  //   currencyPair: "EUR/USD",
  //   lastOrderTs: {
  //     month: "APRIL",
  //     dayOfWeek: "MONDAY",
  //     dayOfYear: 95,
  //     nano: 334000000,
  //     year: 2021,
  //     monthValue: 4,
  //     dayOfMonth: 5,
  //     hour: 14,
  //     minute: 32,
  //     second: 37,
  //     chronology: {
  //       calendarType: "iso8601",
  //       id: "ISO",
  //     },
  //   },
  //   midPrice: 0,
  //   position: 1000000,
  //   positionUsd: 1115820,
  //   realizedPnl: 0,
  //   realizedPnlUsd: 0,
  //   taker: "SPOT_UI_T1",
  //   timestamp: {
  //     month: "DECEMBER",
  //     dayOfWeek: "WEDNESDAY",
  //     dayOfYear: 365,
  //     nano: 0,
  //     year: 1969,
  //     monthValue: 12,
  //     dayOfMonth: 31,
  //     hour: 19,
  //     minute: 0,
  //     second: 0,
  //     chronology: {
  //       calendarType: "iso8601",
  //       id: "ISO",
  //     },
  //   },
  //   totalPnl: -1224860,
  //   totalPnlUsd: 66412.0851597,
  //   unrealizedPnl: -1224860,
  //   unrealizedPnlUsd: 66412.0851597,
  // },
];

const margins = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_MARGINS: {
      return updateMarginsWithServerMargins(state, action);
    }
    default: {
      return state;
    }
  }
};

export default margins;

// selectors

export const getMarginsData = state =>
  state.margins.filter(marginRecord => {
    return marginRecord.taker === state.settings.activeTaker && marginRecord.account === state.settings.activeTrader;
  });

const marginTotalsReducer = (accumulator, current) => {
  return {
    currencyPair: "Totals",
    realizedPnlUsd: Number(
      Big(accumulator.realizedPnlUsd)
        .add(current.realizedPnlUsd)
        .toString()
    ),
    unrealizedPnlUsd: Number(
      Big(accumulator.unrealizedPnlUsd)
        .add(current.unrealizedPnlUsd)
        .toString()
    ),
    totalPnlUsd: Number(
      Big(accumulator.totalPnlUsd)
        .add(current.totalPnlUsd)
        .toString()
    ),
    position: Number(
      Big(accumulator.position)
        .add(current.position)
        .toString()
    ),
    positionUsd: Number(
      Big(accumulator.positionUsd)
        .add(current.positionUsd)
        .toString()
    ),
  };
};
const marginTotals = state => {
  const filteredMargins = state.margins.filter(marginRecord => {
    return marginRecord.taker === state.settings.activeTaker && marginRecord.account === state.settings.activeTrader;
  });

  if (filteredMargins.length > 0) {
    const reducedMargins = filteredMargins.reduce(marginTotalsReducer, {
      currencyPair: "Totals",
      realizedPnlUsd: 0,
      unrealizedPnlUsd: 0,
      totalPnlUsd: 0,
      position: 0,
      positionUsd: 0,
    });
    return { taker: state.settings.activeTaker, ...reducedMargins };
  } else {
    return null;
  }
};

export const getMarginsTotals = marginTotals;

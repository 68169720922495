import React, { useEffect, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import moment from "moment-timezone";

// import { AllModules } from "@ag-grid-enterprise/all-modules";
import clsx from "clsx";
import { Button } from "antd";
import { getPriceStringWithPrecision } from "../../lib/util";
import { getCheckOrdersVisibility } from "../../state/orders/pauseResumeOrders/pauseResumeOrdersState";
import { connect } from "react-redux";
import {
  setCheckOrdersVisibilityAction,
  setOrdersVisibilityAfterFilteringAction,
} from "../../state/orders/pauseResumeOrders/pauseResumeOrdersActions";
import { getOrdersReadOnly } from "../../state/settings/settingsState";
import { OrderType } from "../../state/orders/types";

// Not sure if we can pass icons as React Components to AgGrid icon object, so for now
// they are hardcoded as strings (noted in a debt ticket)
// TODO temporary until we figure out the icon font situation
const filterIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M19 9C19.5523 9 20 8.55229 20 8C20 7.44772 19.5523 7 19 7H5C4.44771 7 4 7.44772 4 8C4 8.55228 4.44771 9 5 9L19 9ZM17 12C17 12.5523 16.5523 13 16 13L8 13C7.44771 13 7 12.5523 7 12C7 11.4477 7.44771 11 8 11L16 11C16.5523 11 17 11.4477 17 12ZM14 16C14 16.5523 13.5523 17 13 17H11C10.4477 17 10 16.5523 10 16C10 15.4477 10.4477 15 11 15L13 15C13.5523 15 14 15.4477 14 16Z" fill="white"/>
</svg>`;

const menuIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M19 8C19 7.44772 18.5523 7 18 7H6C5.44772 7 5 7.44771 5 8C5 8.55228 5.44772 9 6 9L18 9C18.5523 9 19 8.55228 19 8ZM18 11C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13L6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11L18 11ZM18 15C18.5523 15 19 15.4477 19 16C19 16.5523 18.5523 17 18 17L6 17C5.44772 17 5 16.5523 5 16C5 15.4477 5.44772 15 6 15L18 15Z" fill="white"/>
</svg>`;

const columnsIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6 6C5.44772 6 5 6.44772 5 7V17C5 17.5523 5.44772 18 6 18C6.55228 18 7 17.5523 7 17V7C7 6.44772 6.55228 6 6 6ZM10 6C9.44772 6 9 6.44772 9 7V17C9 17.5523 9.44772 18 10 18C10.5523 18 11 17.5523 11 17V7C11 6.44772 10.5523 6 10 6ZM14 6C13.4477 6 13 6.44772 13 7V17C13 17.5523 13.4477 18 14 18C14.5523 18 15 17.5523 15 17V7C15 6.44772 14.5523 6 14 6ZM17 7C17 6.44772 17.4477 6 18 6C18.5523 6 19 6.44772 19 7V17C19 17.5523 18.5523 18 18 18C17.4477 18 17 17.5523 17 17V7Z" fill="white"/>
</svg>`;

const ascIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11 5C10.4477 5 10 5.44772 10 6C10 6.55228 10.4477 7 11 7H14C14.5523 7 15 6.55228 15 6C15 5.44772 14.5523 5 14 5H11ZM11 9C10.4477 9 10 9.44772 10 10C10 10.5523 10.4477 11 11 11H16C16.5523 11 17 10.5523 17 10C17 9.44772 16.5523 9 16 9H11ZM10 14C10 13.4477 10.4477 13 11 13H18C18.5523 13 19 13.4477 19 14C19 14.5523 18.5523 15 18 15H11C10.4477 15 10 14.5523 10 14ZM11 17C10.4477 17 10 17.4477 10 18C10 18.5523 10.4477 19 11 19H20C20.5523 19 21 18.5523 21 18C21 17.4477 20.5523 17 20 17H11ZM3.4229 9.54289C3.81342 9.93342 4.44659 9.93342 4.83711 9.54289L6 8.38001L6 18C6 18.5523 6.44772 19 7 19C7.55228 19 8 18.5523 8 18L8 6C8 5.44772 7.55229 5 7 5C6.93222 5 6.86601 5.00674 6.80201 5.0196C6.60042 5.05135 6.40667 5.1449 6.25133 5.30025L3.4229 8.12868C3.03237 8.5192 3.03237 9.15237 3.4229 9.54289Z" fill="#002851"/>
</svg>
`;

const desIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11 19C10.4477 19 10 18.5523 10 18C10 17.4477 10.4477 17 11 17H14C14.5523 17 15 17.4477 15 18C15 18.5523 14.5523 19 14 19H11ZM4.83711 14.4571C4.44659 14.0666 3.81342 14.0666 3.4229 14.4571C3.03237 14.8476 3.03237 15.4808 3.4229 15.8713L6.25133 18.6997C6.40667 18.8551 6.60042 18.9486 6.80202 18.9804C6.86601 18.9933 6.93222 19 7 19C7.55229 19 8 18.5523 8 18L8 6C8 5.44771 7.55228 5 7 5C6.44772 5 6 5.44772 6 6L6 15.62L4.83711 14.4571ZM11 15C10.4477 15 10 14.5523 10 14C10 13.4477 10.4477 13 11 13H16C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15H11ZM10 10C10 10.5523 10.4477 11 11 11H18C18.5523 11 19 10.5523 19 10C19 9.44771 18.5523 9 18 9H11C10.4477 9 10 9.44771 10 10ZM11 7C10.4477 7 10 6.55229 10 6C10 5.44771 10.4477 5 11 5H20C20.5523 5 21 5.44771 21 6C21 6.55229 20.5523 7 20 7H11Z" fill="#002851"/>
</svg>
`;

type BlotterProps = {
  columnDefs: any;
  customFrameworkComponents?: object;
  defaultColDefs?: any;
  optionalRef?: any;
  partialFillColumnDefs?: any;
  pinnedTopRowData?: any;
  rowData: any;
  setBlotterTableSettings: (settings) => void;
  blotterTableSettings: any;
  setDataLoaded: any;
  rowSelectionEnabled?: boolean;
  tabType: string;
  onRowSelected?: any;
  setOrdersVisibilityAfterFiltering?: any;
  checkOrdersVisibility: boolean;
  setCheckOrdersVisibility: any;
  // setNewVisibleOrder: any;
  readOnly: boolean;
};

/**
 * Renders the blotter, partially exposing the same data fields as AgGrid
 */
function Blotter({
  columnDefs,
  customFrameworkComponents,
  defaultColDefs,
  optionalRef,
  partialFillColumnDefs,
  pinnedTopRowData,
  rowData,
  blotterTableSettings,
  setBlotterTableSettings,
  setDataLoaded,
  rowSelectionEnabled,
  tabType,
  onRowSelected,
  setOrdersVisibilityAfterFiltering,
  checkOrdersVisibility,
  setCheckOrdersVisibility,
  // setNewVisibleOrder,
  readOnly,
}: BlotterProps) {
  const [gridApi, setGridApi] = useState<any>({});

  let isLoaded = false;
  const hasOrders = rowData.length > 0;

  const setSettings = params => {
    if (isLoaded) {
      const settings = {
        columns: params.columnApi.getColumnState(),
        filters: JSON.stringify(params.api.getFilterModel()),
        sorts: params.api.getSortModel(),
      };

      setBlotterTableSettings(settings);
    }
  };

  /** Handles any post-load grid api calls. */
  const handleOnGridReady = params => {
    const api = params.api;
    const columnApi = params.columnApi;

    // if we have settings, apply them. if we don't save the default state
    if (blotterTableSettings) {
      columnApi.setColumnState(blotterTableSettings.columns);
      api.setFilterModel(JSON.parse(blotterTableSettings.filters));
      api.setSortModel(blotterTableSettings.sorts);
    }
    if (optionalRef) {
      optionalRef.current.grid = params.api;
      optionalRef.current.column = params.columnApi;
    }

    // We need the params to access them from the exportData function
    setGridApi(params?.api);
  };

  const handleOnFirstDataRendered = params => {
    isLoaded = true;
    setDataLoaded();
    autoSizeAll(params.columnApi, false);
    params.columnApi.autoSizeAllColumns();

    // now that data is loaded, add the event listeners. adding directly to gridOptions in the config
    // caused the functions to get a stale reference to state
    params.api.addEventListener("columnVisible", setSettings);
    params.api.addEventListener("columnMoved", setSettings);
    params.api.addEventListener("filterChanged", setSettings);
    params.api.addEventListener("sortChanged", setSettings);
    params.api.addEventListener("columnResized", setSettings);
  };

  const getMainMenuItems = params => {
    // remove items from filter menu
    const limitedMenuItems: string[] = [];
    const itemsToExclude = ["pinSubMenu", "separator"];

    params.defaultItems.forEach(item => {
      if (itemsToExclude.indexOf(item) < 0) {
        limitedMenuItems.push(item);
      }
    });
    return limitedMenuItems;
  };

  const optionalFrameworkComponents = {
    frameworkComponents: customFrameworkComponents,
  };
  const optionalTopPinnedRowData = {
    pinnedTopRowData,
  };
  const enableDetailTable = partialFillColumnDefs ? true : false; // if no partialFillColumnDefs, don't show details table

  const autoSizeAll = (columnApi, skipHeader) => {
    columnApi.autoSizeColumns(["rejectReasonText"], skipHeader);
  };

  const rowSelectionProps: {
    rowMultiSelectWithClick?: boolean;
    rowSelection?: string;
  } = rowSelectionEnabled
    ? {
        rowSelection: "multiple",
        rowMultiSelectWithClick: true,
      }
    : {};

  const refDefinition = optionalRef
    ? {
        ref: optionalRef,
      }
    : {};

  const processCellValue = params => {
    if (params && params.value) {
      const gridDates = ["creationDate"];
      const gridUTCDates = ["fixingDate", "settleDate"];
      const gridTimes = ["creationTime", "startTime", "endTime"];
      const gridDateTimes = ["tradeDate"];

      const valueName = params.column.colId;
      let value = params.value;

      if (gridDates.includes(valueName)) {
        value = moment(value)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD");
      }

      if (gridUTCDates.includes(valueName)) {
        value = moment(value)
          .utc()
          .format("YYYY-MM-DD");
      }

      if (gridTimes.includes(valueName)) {
        value = moment(value)
          .tz(moment.tz.guess())
          .format("HH:mm:ss.SSS z");
      }

      if (gridDateTimes.includes(valueName)) {
        value = moment(value)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD HH:mm:ss.SSS z");
      }

      if (valueName === "averagePrice") {
        value = Number(getPriceStringWithPrecision(value, params.node.data.instrumentId).replaceAll(",", ""));
      }

      if (valueName === "notional") {
        value = Number(value.toLocaleString(undefined, { maximumFractionDigits: 4 }).replaceAll(",", ""));
      }

      return value;
    }

    return "";
  };

  // const myRef = React.useRef<number>(0);

  // Listen to the checkOrdersVisibility flag. If an action has been fired (pause/resume/cancel) then call the filter function to set the new orders' visibility.
  useEffect(() => {
    if (checkOrdersVisibility && Object.keys(gridApi).length > 0) {
      // pauseResumeHelper
      // console.log("useEffect");
      gridApi.onFilterChanged();
      // setNewVisibleOrder(checkOrdersVisibility);
    }
  }, [checkOrdersVisibility]);

  const exportData = () => {
    const exportParams = {
      fileName: `export_${moment().valueOf()}`,
      processCellCallback: processCellValue,
    };

    gridApi?.exportDataAsExcel(exportParams);
  };

  return (
    <div className={clsx("ag-theme-balham-dark ag-theme-prism", !hasOrders && "no-blotter")}>
      {hasOrders ? (
        <>
          <div className="exportContainer">
            <Button onClick={exportData} className="exportButton">
              Export table
            </Button>
          </div>

          <AgGridReact
            {...onRowSelected}
            {...refDefinition}
            columnDefs={columnDefs}
            {...rowSelectionProps}
            {...optionalFrameworkComponents}
            {...optionalTopPinnedRowData}
            rowData={rowData}
            immutableData={true}
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: true, // if you don't specify a filter type in the coldef, the filter will default to a checkbox select filter for the column
              filterParams: { suppressMiniFilter: true, suppressSelectAll: true, suppressFilterSearch: true },
              menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab"], // can be overridden on each column
              ...defaultColDefs,
            }}
            onGridReady={handleOnGridReady}
            onFirstDataRendered={handleOnFirstDataRendered}
            getMainMenuItems={getMainMenuItems}
            gridOptions={{
              // layout configs
              headerHeight: 24,
              getRowHeight: params => (params.data.type === OrderType.Oco ? 48 : 24),
              alwaysShowVerticalScroll: true,
              // cells
              suppressCellSelection: true,
              enableCellTextSelection: true,
              suppressContextMenu: true,
              // don't auto scroll when new rows are added to the grid
              suppressScrollOnNewData: true,
              // smooth react component refresh for safe measure
              suppressAnimationFrame: true,
              suppressColumnVirtualisation: true, // do we need this?
              // requires for the delta to work
              getRowNodeId: data => {
                // the row id has to be unique, otherwise ag-grid will generate a new one on every execution
                // which then has the negative effect of undoing deltaRowDataMode
                // execId is the unique id for an order's child orders

                let dataId = "";
                if (tabType === "aggregatedPNL") {
                  dataId = data.currencyPair;
                } else if (["blotter", "ticker"].includes(tabType)) {
                  dataId = (data.id && data.id) || (data.execId && data.execId);
                }

                return dataId;
              },
              // Do not select the row if the column is blotterActions. We want to be able to click the button without selecting the row
              onCellClicked: event => {
                if (event.column.getColId() === "blotterActions" || event.column.getColId() === "checkboxCol") {
                  event.node.setSelected(false);
                }
              },

              // Each time we filter the rows we set which orders are visible
              onFilterChanged: (event, id) => {
                // pauseResumeHelper
                // console.log("ON FILTER CHANGED");
                // console.log(checkOrdersVisibility);

                if (setOrdersVisibilityAfterFiltering) {
                  let ordersIds: any = [];
                  event.api.forEachNodeAfterFilter(node => {
                    const nodeId = node?.data?.id;
                    if (nodeId) {
                      ordersIds.push(node.data.id);
                    }
                  });

                  // pauseResumeHelper
                  // console.log(ordersIds);
                  // console.log("NUMBER OF ROWS DISPLAYED");
                  // console.log(event.api.getDisplayedRowCount());
                  // console.log(typeof event.api.getDisplayedRowCount());

                  if (ordersIds.length > 0 || (ordersIds.length === 0 && event.api.getDisplayedRowCount() === 0)) {
                    setOrdersVisibilityAfterFiltering(ordersIds, readOnly);
                  }

                  // Reset the checkOrdersVisibility flag (this is needed after a new order is submitted and the filter has been already called)
                  setCheckOrdersVisibility(false);
                }
              },
              // pass in icon strings to swap out the default ones
              icons: {
                filter: filterIcon,
                columns: columnsIcon,
                menu: menuIcon,
                sortAscending: ascIcon,
                sortDescending: desIcon,
                sortUnSort: ascIcon,
              },
              detailRowAutoHeight: true,
            }}
            // for the nested table of partial fills
            masterDetail={enableDetailTable}
            detailCellRendererParams={{
              detailGridOptions: {
                defaultColDef: { suppressMenu: true },
                columnDefs: partialFillColumnDefs,
                headerHeight: 24,
                rowHeight: 24,
                suppressColumnVirtualisation: true, // seems to be all I need to prevent the flickering as table updates
                suppressContextMenu: true,
              },

              getDetailRowData(params) {
                params.successCallback(params.data.fills);
              },
            }}
          />
        </>
      ) : (
        <div className="blank-order-wrapper">
          <span className="blank-text">No orders placed yet.</span>
        </div>
      )}
    </div>
  );
}

export default connect(
  (state: any, props: any) => ({
    checkOrdersVisibility: getCheckOrdersVisibility(state),
    readOnly: getOrdersReadOnly(state),
  }),
  {
    setOrdersVisibilityAfterFiltering: setOrdersVisibilityAfterFilteringAction,
    setCheckOrdersVisibility: setCheckOrdersVisibilityAction,
    // setNewVisibleOrder: setNewVisibleOrderAction,
  }
)(Blotter);

import React from "react";
import { flow, isEqual, isObject, transform } from "lodash";
import Big from "big.js";

import { addCommas, ccyPairIdStripper, positiveNumberEnforcer } from "./formatUtil";
import { NDFCurrencies, cryptoCurrencies, resumableStatuses, staticAssetServerURL } from "./constants";
import { CardSize } from "../state/settings/settingsState";

import symbolsPriceFormat from "../commonFiles/symbolsPriceFormat.json";
import { ClosedOrderStatus, OrderAction, OrderLifespan, OrderStatus } from "../state/orders/types";

let SYMBOL_PRICE_FORMAT = symbolsPriceFormat;

const getSymbolsPriceFormatFromAssetServer = () => {
  fetch(`${staticAssetServerURL}/symbolsPriceFormat.json`)
    .then(response => {
      return response.json();
    })
    .then(data => {
      SYMBOL_PRICE_FORMAT = data;
    })
    .catch(error => {
      console.error(
        `Warning: issue accessing the asset server URL for '${staticAssetServerURL}/symbolsPriceFormat.json', loading the local file instead.`
      );
    });
};

getSymbolsPriceFormatFromAssetServer();

export const getPriceStringWithPrecision = (pPrice: number, symbol: string | null, noCommas?: boolean) => {
  if (pPrice === undefined || pPrice === null) {
    return "";
  }
  const priceNormalized = pPrice === 0 ? 0.0 : pPrice;
  const formatData = getSymbolPriceFormatting(symbol);
  const { precision } = formatData || {};
  const tokenizedPrice = priceNormalized.toString().split(".");

  const price = noCommas
    ? `${tokenizedPrice[0]}${getPriceDecimalStringWithPrecision(tokenizedPrice[1] || "", precision)}`
    : `${addCommas(tokenizedPrice[0])}${getPriceDecimalStringWithPrecision(tokenizedPrice[1] || "", precision)}`;

  return price || "";
};

// input: {symbol: USD/PHP-1M, pPrice: 12.34} output: 12.340
export const getPriceDecimalStringWithPrecision = (decimalValue, precision) => {
  const decimalLength = decimalValue ? decimalValue.length : 0;
  let newValue: string | null = decimalValue;
  // // add zeros if value < length
  if (decimalLength < precision) {
    let trailingZeros = "";
    for (let i = 0; i < precision - decimalLength; i++) {
      trailingZeros += "0";
    }
    newValue = `${decimalValue}${trailingZeros}`;
  } else if (decimalLength > precision) {
    newValue = decimalValue.slice(0, precision);
  }
  return newValue ? `.${newValue}` : "";
};

// input: {symbol: USD/PHP-1M (precision=3), pPrice: 12.34567, doTruncate=true} output: 3
export const getPriceDecimalPrecisionNumber = (symbol: string | null) => {
  const formatData = getSymbolPriceFormatting(symbol);
  const { precision } = formatData || {};
  return precision;
};

const getFormatParsing = (value, startBigIndex, startSmallIndex) => {
  const basePrice = value.slice(0, startBigIndex);
  const varBig = value.slice(startBigIndex, startSmallIndex);
  const varSm = value.slice(startSmallIndex);
  return { basePrice, varBig, varSm };
};

const getCharacterCount = (pString: string, character: string) => {
  return pString.split(character).length - 1;
};

export const getSymbolPriceFormatting = (symbol: string | null) => {
  const DEFAULT_SYMBOL = "EUR/USD";
  const formatData = SYMBOL_PRICE_FORMAT[symbol || ""] || SYMBOL_PRICE_FORMAT[DEFAULT_SYMBOL];
  return formatData;
};

export const decimalToPriceObjectConverterBySymbol = (pPrice: number, symbol: string | null) => {
  if (!pPrice) {
    return null;
  }
  const DEFAULT_SYMBOL = "EUR/USD";
  const symbolWithoutId: string = ccyPairIdStripper(symbol);

  const formatData = SYMBOL_PRICE_FORMAT[symbolWithoutId || ""] || SYMBOL_PRICE_FORMAT[DEFAULT_SYMBOL];
  const { significantEnd, significantLength } = formatData || {};
  let price = getPriceStringWithPrecision(pPrice, symbolWithoutId);
  let formattedPrice: any = {};

  // If we don't want to highlight the numbers then the significantLength of the ccy pair would be -1. It's in the file symbolsPriceFormat.json
  if (significantLength === -1) {
    formattedPrice.basePrice = price;
    formattedPrice.varBig = "";
    formattedPrice.varSm = "";

    return { ...formattedPrice };
  }

  // account for delimiters (, / .) in index splitting
  // interate stepping until no , or . is found
  let varBigStartIndex = 0;
  let varSmStartIndex = 0;
  let varSmDelimiterDeltaShift = 0;
  let varBigDelimiterDeltaShift = 0;
  let doTerminateLoop = false;
  while (doTerminateLoop === false) {
    varSmStartIndex = price.length - significantEnd - varSmDelimiterDeltaShift;
    varBigStartIndex = price.length - significantEnd - significantLength - varBigDelimiterDeltaShift;
    formattedPrice = getFormatParsing(price, varBigStartIndex, varSmStartIndex);
    const newVarSmDelimiterDeltaShift = formattedPrice.varSm
      ? getCharacterCount(formattedPrice.varSm, ".") + getCharacterCount(formattedPrice.varSm, ",")
      : 0;
    const newVarBigDelimiterDeltaShift = formattedPrice.varBig
      ? getCharacterCount(formattedPrice.varBig, ".") + getCharacterCount(formattedPrice.varBig, ",")
      : 0;

    doTerminateLoop =
      varSmDelimiterDeltaShift === newVarSmDelimiterDeltaShift &&
      varBigDelimiterDeltaShift === newVarBigDelimiterDeltaShift;

    varSmDelimiterDeltaShift = newVarSmDelimiterDeltaShift;
    varBigDelimiterDeltaShift = newVarBigDelimiterDeltaShift;
  }

  return { ...formattedPrice };
};

export const useWindowEnter = () => {
  const [notify, setNotify] = React.useState(false);
  React.useEffect(() => {
    const handleKeypress = event => {
      if (event.keyCode === 13) {
        event.preventDefault();
        setNotify(state => !state);
      }
    };
    window.addEventListener("keypress", handleKeypress);
    return () => {
      window.removeEventListener("keypress", handleKeypress);
    };
  }, []);
  return { notify, setNotify };
};

// the ultimate function for checking if you clicked outside of a div
// used for closing dropdowns or context menus when you click outside of it
// ref is the useRef you created for the div being opened
// the handler is whatever function you use to close said div
export const useOnClickOutside = (ref, handler, symbol?) => {
  React.useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };

      const myWindow =
        window && window["externalPanels"] && window["externalPanels"][symbol]
          ? window["externalPanels"][symbol]
          : window;

      myWindow.addEventListener("mousedown", listener);
      return () => {
        myWindow.removeEventListener("mousedown", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
};

export const useWindowClose = (symbol, handleCloseFunction, ifConditionMet) => {
  React.useEffect(() => {
    if (ifConditionMet) {
      const myWindow =
        window["externalPanels"] && window["externalPanels"][symbol] ? window["externalPanels"][symbol] : window;
      myWindow.addEventListener("beforeunload", handleCloseFunction);
      return () => {
        myWindow.removeEventListener("beforeunload", handleCloseFunction);
      };
    }
  }, []);
};

export const getCardPanelHeight = (
  depthDisplay,
  displaySpreadOnDepthOfBook,
  showSlippage,
  showPriceTrendAndSpread,
  cardSize
) => {
  let baseHeight = 98;
  let priceDepthBookHeight = 33;
  let spreadDepthBookHeight = 10;
  let slippageHeight = 10;
  let priceTrendAndSpreadHeight = 24;
  let extraSpaceHeight = 1;

  if (cardSize === CardSize.Medium) {
    spreadDepthBookHeight = 20;
  } else if (cardSize === CardSize.Large) {
    spreadDepthBookHeight = 32;
  }

  const height =
    baseHeight +
    (depthDisplay ? depthDisplay * priceDepthBookHeight : 0) +
    (depthDisplay && displaySpreadOnDepthOfBook ? depthDisplay * spreadDepthBookHeight : 0) +
    (showSlippage ? slippageHeight : 0) +
    (showPriceTrendAndSpread ? priceTrendAndSpreadHeight : 0) +
    (depthDisplay || showSlippage || showPriceTrendAndSpread ? extraSpaceHeight : 0);
  return height;
};

// can be used to compare nested objects for debugging
export const difference = (object, base) => {
  function changes(object, base) {
    return transform(object, (result: any, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
};

export const spreadToPipCalculator = (spread, pip) => {
  const result = Number(
    Big(spread)
      .div(pip)
      .toString()
  );

  return Number.isInteger(result) ? result.toFixed(1) : result;
};

export const moveCursorToEnd = event => {
  const target = event.target;
  // if for whatever reason someone tabs into this input, the text won't hightlight and the input curson will go to the end
  event.target.selectionStart = event.target.selectionEnd = event.target.value.length;
  // necessary for returning the cursor to the end if the user left the input with the cursor not at the end
  setTimeout(() => {
    target.setSelectionRange(target.value.length, target.value.length);
  }, 0);
};

export const allowOnlyNumbers = event => {
  if (!["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(event.key)) {
    positiveNumberEnforcer(event);
  }
};

// export const topMaxChars = event => {

//   if((event.target.value.length >= 64) && (event.key !== "Backspace")){
//     event.preventDefault();
//   }

// }

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  // The maximum is exclusive and the minimum is inclusive
  return Math.floor(Math.random() * (max - min) + min);
};

export const percentValue = event => {
  if (!event.target.value) {
    return null;
  }

  if (parseInt(event.target.value, 10) < 0) {
    return 0;
  }

  if (parseInt(event.target.value, 10) > 100) {
    return 100;
  }

  return parseInt(event.target.value, 10);
};

export const zeroToNinetyNine = event => {
  if (!event.target.value) {
    return null;
  }

  if (parseInt(event.target.value, 10) < 0) {
    return 0;
  }

  if (parseInt(event.target.value, 10) > 99) {
    return 99;
  }

  return parseInt(event.target.value, 10);
};

export const convertToNumber = event => {
  return event.target.value ? parseInt(event.target.value.replace(/,/g, ""), 10) : null;
};

export const minValueZero = event => {
  return Math.max(0, parseInt(event.target.value.replace(/,/g, ""), 10) || 0);
};

export const isNDFCurrency = (symbol: string): boolean => {
  const symbolWithoutId = ccyPairIdStripper(symbol);
  return NDFCurrencies.includes(symbolWithoutId);
};

export const isCryptoCurrency = (symbol: string): boolean => {
  const symbolWithoutId = ccyPairIdStripper(symbol);
  return cryptoCurrencies.includes(symbolWithoutId);
};

export const calculateSlippageToPip = (slippage, pip) => {
  if (slippage && pip) {
    const safePip = Big(pip);
    return Number(safePip?.times(slippage).toString());
  }

  return 0;
};

export const getOrdersIdsByStatuses = (selectedOrders, orderAction, readOnly) => {
  return flow([
    Object.entries,
    arr =>
      arr.filter(
        ([key, value]) =>
          value.selected === true &&
          (orderAction === OrderAction.Pause
            ? isOrderPausable(value.status, value.lifespan, readOnly)
            : isOrderResumable(value.status, readOnly))
      ),
    Object.fromEntries,
    Object.keys,
  ])(selectedOrders);
};

export const isOrderResumable = (status, readOnly) => {
  return resumableStatuses.includes(status) && !readOnly;
};

export const isOrderPausable = (status, lifespanInput, readOnly) => {
  return isOrderCancelableOrPausableHelper(status, lifespanInput, readOnly) && status !== OrderStatus.Paused;
};

export const isOrderCancelable = (status, lifespanInput, readOnly) => {
  return isOrderCancelableOrPausableHelper(status, lifespanInput, readOnly);
};

const isOrderCancelableOrPausableHelper = (status, lifespanInput, readOnly) => {
  if (status) {
    const isOrderStatusClosed = Object.values(ClosedOrderStatus).includes(status);
    const isInFlight = [OrderStatus.Pending, OrderStatus.Replacing, OrderStatus.Canceling].includes(status);
    const lifespan = typeof lifespanInput === "string" ? [lifespanInput] : lifespanInput ?? [];
    const isUnCancelableStrategy = lifespan.includes(OrderLifespan.FOK) || lifespan.includes(OrderLifespan.IOC);

    return !(isOrderStatusClosed || isUnCancelableStrategy || isInFlight || readOnly);
  }

  return false;
};

export const getOrdersIdsByCancelableOrders = (selectedOrders, readOnly) => {
  const cancelableOrders: Array<any> = [];

  for (const key in selectedOrders) {
    if (
      selectedOrders[key].selected === true &&
      isOrderCancelable(selectedOrders[key]?.status, selectedOrders[key]?.lifespan, readOnly)
    ) {
      cancelableOrders.push(key);
    }
  }

  return cancelableOrders;
};

import React from "react";
import { Button } from "antd";

import styles from "./FrameTitleBar.module.scss";

function FrameTitleBar({ primaryWindow, title }) {
  // const primaryWindow = window.fin.Window.getCurrentSync().isMainWindow();

  const closeWindow = () => {
    window.close();
  };

  const dockWindow = () => {};

  const maximizeWindow = () => {
    window.moveTo(10, 10);
    window.resizeTo(window.screen.width, window.screen.height);
  };

  const minimizeWindow = async () => {
    const win = await window.fin.Window.getCurrent();
    return win.minimize();
  };

  return (
    <div className={styles.titleBar}>
      <div></div>
      <div className={styles.title}>{title}</div>

      <div className={styles.buttonGroup}>
        <Button size="small" onClick={minimizeWindow}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 8.5H3" stroke="#788DA3" strokeLinecap="round" />
          </svg>
        </Button>
        <Button size="small" onClick={maximizeWindow}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.5 6V8.5H6M8.5 6V3.5H6" stroke="#788DA3" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Button>
        {primaryWindow && (
          <Button size="small" onClick={closeWindow}>
            <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.5 3.5L8.5 8.5" stroke="#788DA3" strokeLinecap="round" />
              <path d="M8.5 3.5L3.5 8.5" stroke="#788DA3" strokeLinecap="round" />
            </svg>
          </Button>
        )}
        {!primaryWindow && (
          <Button size="small" onClick={dockWindow}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 4H9L6 8L3 4Z" stroke="#788DA3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Button>
        )}
      </div>
    </div>
  );
}

export default FrameTitleBar;

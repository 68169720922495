import { Observable } from "rxjs";
import { SERVER_URL } from "../../environment";
import shortid from "shortid";

const URL = SERVER_URL; //"ws://localhost:60002";
var instance;

export const connect = () => {

  console.log("connect()me");
  return Observable.create(async observer => {

    instance = new WebSocket(URL);
    instance.addEventListener("open", event => {
      sendMessage("subscribe", { application: "SPOT" });
      observer.next(createMessages$(instance));
      observer.complete();
    });

    instance.addEventListener("close", e => {
      observer.error(e);
    });
  });
};

const createMessages$ = ws => {
  return Observable.create(observer => {
    ws.addEventListener("message", event => {
      let data = JSON.parse(event.data);
      sendMessage("echo", {
        receiveTimeStamp: data.timeStamp,
        transmitTimeStamp: new Date().getTime(),
      });
      observer.next(data);
    });

    ws.addEventListener("close", e => {
      observer.error(e);
    });
    ws.addEventListener("error", e => {
      observer.error(e);
    });
  });
};

export const sendMessage = (type, data, correlation?) => {
  const nextMessage = {
    type,
    data,
    correlation: correlation || shortid.generate(),
  };
  try {
    const nextMessageJSON = JSON.stringify(nextMessage);
    instance.send(nextMessageJSON);
    return nextMessage;
  } catch (err) {
    return err;
  }
};

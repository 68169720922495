import { OrderFieldsEnum } from "./fields";
import { OrderStrategy } from "../../../state/orders/types";

export const CommonStrategyFields = [
  OrderFieldsEnum.currency,
  OrderFieldsEnum.lifespan,
  OrderFieldsEnum.price,
  OrderFieldsEnum.marketPrice,
  OrderFieldsEnum.stopLossPrice,
  OrderFieldsEnum.quantity,
  OrderFieldsEnum.side,
  OrderFieldsEnum.strategy,
  OrderFieldsEnum.type,
  OrderFieldsEnum.strategyConfig,
];

export const TWAPStrategyFields = [
  OrderFieldsEnum.endTime, // leave date field in so the field gets used as part of submit
  OrderFieldsEnum.endTimeTime,
  OrderFieldsEnum.maxParticipationRate,
  OrderFieldsEnum.randomizationPercent,
  OrderFieldsEnum.sliceInterval,
  OrderFieldsEnum.sliceSize,
  OrderFieldsEnum.startTime, // leave date field in so the field gets used as part of submit
  OrderFieldsEnum.startTimeTime,
  OrderFieldsEnum.stopLossPrice,
  OrderFieldsEnum.wouldPrice,
  OrderFieldsEnum.wouldSizePercent,
  OrderFieldsEnum.urgency,
];

export const VWAPStrategyFields = [
  OrderFieldsEnum.endTime,
  OrderFieldsEnum.endTimeTime,
  OrderFieldsEnum.maxParticipationRate,
  OrderFieldsEnum.sliceSize,
  OrderFieldsEnum.startTime,
  OrderFieldsEnum.startTimeTime,
  OrderFieldsEnum.stopLossPrice,
  OrderFieldsEnum.wouldPrice,
  OrderFieldsEnum.wouldSizePercent,
  OrderFieldsEnum.urgency,
];

export const POVStrategyFields = [
  OrderFieldsEnum.endTime,
  OrderFieldsEnum.endTimeTime,
  OrderFieldsEnum.lowerBandPrice,
  OrderFieldsEnum.lowerBandTargetPercentVolume,
  OrderFieldsEnum.sliceSize,
  OrderFieldsEnum.startTime,
  OrderFieldsEnum.startTimeTime,
  OrderFieldsEnum.stopLossPrice,
  OrderFieldsEnum.targetParticipationRate,
  OrderFieldsEnum.upperBandPrice,
  OrderFieldsEnum.upperBandTargetPercentVolume,
  OrderFieldsEnum.urgency,
  OrderFieldsEnum.wouldPrice,
  OrderFieldsEnum.wouldSizePercent,
];

export const ISEEKStrategyFields = [
  OrderFieldsEnum.endTime,
  OrderFieldsEnum.endTimeTime,
  OrderFieldsEnum.maxParticipationRate,
  OrderFieldsEnum.sliceSize,
  OrderFieldsEnum.startTime,
  OrderFieldsEnum.startTimeTime,
  OrderFieldsEnum.urgency,
  OrderFieldsEnum.wouldPrice,
  OrderFieldsEnum.wouldSizePercent,
];

export const SORStrategyFields = [
  OrderFieldsEnum.endTime,
  OrderFieldsEnum.endTimeTime,
  OrderFieldsEnum.maximumFloor,
  OrderFieldsEnum.minimumExecutedQuantity,
  OrderFieldsEnum.priceType,
  OrderFieldsEnum.startTime,
  OrderFieldsEnum.startTimeTime,
];

export const ICEBERGStrategyFields = [
  OrderFieldsEnum.endTime,
  OrderFieldsEnum.endTimeTime,
  OrderFieldsEnum.maximumFloor,
  OrderFieldsEnum.startTime,
  OrderFieldsEnum.startTimeTime,
];

export const CLOSEStrategyFields = [OrderFieldsEnum.maxParticipationRate];

export const FIXINGStrategyFields = [OrderFieldsEnum.allocationBeforeFix, OrderFieldsEnum.startTimeTime];

export const PEGStrategyFields = [
  OrderFieldsEnum.endTimeTime,
  OrderFieldsEnum.maximumFloor,
  OrderFieldsEnum.minimumExecutedQuantity,
  OrderFieldsEnum.pegFloorFloatingPoints,
  OrderFieldsEnum.pegFloorPrice,
  OrderFieldsEnum.pegFloorType,
  OrderFieldsEnum.pegOffset,
  OrderFieldsEnum.pegReferencePriceType,
  OrderFieldsEnum.startTimeTime,
];

export const BFIXStrategyFields = [OrderFieldsEnum.startTimeTime, OrderFieldsEnum.sliceSize];

export const StrategyMapper = {
  [OrderStrategy.Bfix]: BFIXStrategyFields,
  [OrderStrategy.Close]: CLOSEStrategyFields,
  [OrderStrategy.Common]: CommonStrategyFields,
  [OrderStrategy.Fixing]: FIXINGStrategyFields,
  [OrderStrategy.ISeek]: ISEEKStrategyFields,
  [OrderStrategy.Iceberg]: ICEBERGStrategyFields,
  [OrderStrategy.Peg]: PEGStrategyFields,
  [OrderStrategy.Pov]: POVStrategyFields,
  [OrderStrategy.Sor]: SORStrategyFields,
  [OrderStrategy.Twap]: TWAPStrategyFields,
  [OrderStrategy.Vwap]: VWAPStrategyFields,
};

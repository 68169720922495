import { createStore, applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";

// root states
import { rootReducers, rootEpics } from "./rootState";

// middlewares
const epicMiddleware = createEpicMiddleware();

// enable dev tools: https://github.com/zalmoxisus/redux-devtools-extension
const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});

// create the app store
export default function configureStore() {
  const store = createStore(rootReducers, composeEnhancers(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpics);

  return store;
}

const store = configureStore();

export { store };

import { getAuthUrls } from "./lib/service-url";

const env = process.env.REACT_APP_ENVIRONMENT;
const realm = process.env.REACT_APP_REALM || "ny4";
const version = process.env.REACT_APP_VERSION || "0.1.0-local";
const client = process.env.REACT_APP_CLIENT || "";

const clientVertexAPIURL = {
  multibank: "ld4-exchange-api.mbx.trading",
  flowtraders: "flowtraders-api-ld4.lucera.com",
  lucera: "sg1-exchange-api.lucera.com",
  "ld4-makor": "makor-ld4-exchange-api.lucera.com",
  "ny4-makor": "makor-ny4-exchange-api.lucera.com",
  fxnexus: "fxnexus-ny4-exchange-api.lucera.com",
};

const sentryKey = "https://2020d181c9e94e20a96032b35587993c@o378962.ingest.sentry.io/5255370";
const domain = "lucera.com";

const isProd = environment => environment && environment.includes("prod");

const rewriteUrlVertex = (urlType, environment, isGlobal) => {
  if (isProd(environment)) {
    if(client && clientVertexAPIURL[client]) {
      return clientVertexAPIURL[client];
    } else {
      return `${!isGlobal ? realm + "-" : ""}${urlType}.${domain}`; //ny4-vertex-fx.lucera.com
    }
  }

  return `${environment && (environment === "local" ? "dev" : environment) + "-"}${urlType}.${domain}`;
};

export const ENV = isProd(env) ? "prod" : env || "local";
export const SENTRY_KEY = sentryKey;
export const IS_AUTH_ENABLED = true;
export const APP_VERSION = version;
export const REALM = isProd(env) ? realm : "ny4";
export const SERVER_URL = `wss://${rewriteUrlVertex("vertex-fx", env, false)}/vertex`;

if (!window.location) {
  throw new Error("Error parsing url in env.ts - window.location is not available, or somehow not set.");
}

const authUrls = getAuthUrls(window.location.origin);

export const SSO_URL = authUrls.sso;
export const IDENTITY_SERVER_URL = authUrls.identity;

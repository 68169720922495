import { connect } from "react-redux";
import clsx from "clsx";
import { Button } from "antd";
import styles from "../AuctionOrderForm.module.scss";
import { isNDFCurrency } from "../../../../lib/util";

import { ReactComponent as PriceRefresh } from "../../../../assets/images/PriceRefresh-24.svg";
import { currencyPairByIdSelector } from "../../../../state/currency/currencyState";

const PriceAdjusterButtons = ({
  field,
  handleIncrementDecrement,
  instrumentId,
  instrumentPipValue,
  refreshPrice,
  currencyPair,
}) => {
  const fieldName = field.dataFieldName;

  return (
    <div className={styles.inputBlock} key={`${fieldName}AdjustButtons`}>
      <label />
      {/* when editing an order, we need to use the field next to price to include buttons */}
      <div className={styles.modifyPriceSection}>
        <Button
          className={clsx(styles.priceAdjustButton, styles.priceAdjustButton2)} // priceAdjustButton2 required for css specificity
          size="small"
          onClick={() =>
            handleIncrementDecrement(
              "decrement",
              isNDFCurrency(instrumentId) ? instrumentPipValue : instrumentPipValue / 10,
              fieldName
            )
          }
          icon="minus"
        />
        <Button
          className={clsx(styles.priceAdjustButton, styles.priceAdjustButton2)} // priceAdjustButton2 required for css specificity
          size="small"
          onClick={() =>
            handleIncrementDecrement(
              "increment",
              isNDFCurrency(instrumentId) ? instrumentPipValue : instrumentPipValue / 10,
              fieldName
            )
          }
          icon="plus"
        />
        <Button
          data-testid="auction_refresh"
          className={styles.priceRefresh}
          size="small"
          onClick={() => refreshPrice(fieldName, currencyPair)}
        >
          <PriceRefresh />
        </Button>
      </div>
    </div>
  );
};

export default connect((state, ownProps: any) => ({
  currencyPair: currencyPairByIdSelector(state, ownProps.instrumentId),
}))(PriceAdjusterButtons);

import { of, interval, from } from "rxjs";
import {
  mergeMap,
  catchError,
  filter,
  delay,
  map,
  tap,
  withLatestFrom,
  ignoreElements,
  throttle,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import shortid from "shortid";
import { createSelector } from "reselect";

import { sendMessage } from "../connection/webSocketService";
import { setAuctionCard } from "../currency/currencyActions";
import {
  CancelledClosedOrderStatus,
  ClosedOrderStatus,
  FilledClosedOrderStatus,
  OpenOrderStatus,
  OrderModel,
  OrderSide,
  OrderStatus,
  OrderStrategy,
  OrderType,
  ReplaceOrderModel,
  ReplaceOrderModelClassic,
} from "./types";
import { OrderDelayValues } from "../settings/settingsState";
import { orderCancelSound, orderFillSound } from "../../assets/audio";
import { handleOrdersLoaded } from "../connection/messagesState";
import { notifications } from "../../shared/notifications";
import { toMShorthand } from "../../lib/formatUtil";
import moment from "moment";
import { currencyPairByIdSelector } from "../currency/currencyState";
import { initAllOrdersBasicInfoAction } from "./pauseResumeOrders/pauseResumeOrdersActions";

export const LOAD_ORDERS = "LOAD_ORDERS";
export const LOAD_ORDERS_PROCESSED = "LOAD_ORDERS_PROCESSED";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const ON_POST_PROCESS_UPDATE_ORDERS_FAILURE = "ON_POST_PROCESS_UPDATE_ORDERS_FAILURE";
export const SUBMIT_NEW_ORDER = "SUBMIT_NEW_ORDER";
export const SUBMIT_REPLACE_ORDER = "SUBMIT_REPLACE_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const CANCEL_ALL_ORDERS = "CANCEL_ALL_ORDERS";
export const SUBMIT_NEW_ORDER_DONE = "SUBMIT_NEW_ORDER_DONE";
export const SUBMIT_NEW_ORDER_FAIL = "SUBMIT_NEW_ORDER_FAIL";
export const SET_ACTIVE_ORDER_TAB = "SET_ACTIVE_ORDER_TAB";
export const REMOVE_ORDER = "REMOVE_ORDER";
export const REMOVE_CLOSED_ORDERS = "REMOVE_CLOSED_ORDERS";
export const REMOVE_CLOSED_ORDERS_FROM_STORE = "REMOVE_CLOSED_ORDERS_FROM_STORE";
export const CLEAR_CLOSED_ORDERS = "CLEAR_CLOSED_ORDERS";

// CLEANUP
export const ORDER = "order";
export const CANCEL_SERVER_ORDERS = "CANCEL_SERVER_ORDERS";

// actions
export const loadOrders = data => {
  return {
    type: LOAD_ORDERS,
    payload: {
      data,
    },
  };
};

export const loadOrdersProcessed = (data: OrderModel[]) => {
  return {
    type: LOAD_ORDERS_PROCESSED,
    payload: {
      data,
    },
  };
};

export const updateOrder = (data: OrderModel, readOnly: boolean, delayValue?: number) => {
  return {
    type: UPDATE_ORDER,
    payload: {
      data,
      readOnly,
      delayValue,
    },
  };
};

export const clearClosedOrders = (data: OrderModel, readOnly: boolean, delayValue?: number) => {
  return {
    type: CLEAR_CLOSED_ORDERS,
    payload: {
      data,
      readOnly,
      delayValue,
    },
  };
};

// localOrderData = order data not part of order payload request data
export const submitNewOrder = (orderPartial?: OrderModel, localOrderData?: any, includeDates?: boolean) => {
  const orderId = shortid.generate();
  return {
    type: SUBMIT_NEW_ORDER,
    payload: {
      order: {
        id: orderId,
        correlation: orderId,
        venues: [],
        status: OrderStatus.Pending,
        statusLeg2: OrderStatus.Pending,
        strategy: OrderStrategy.Common,
        fills: [],
        ...orderPartial,
        ...localOrderData
      },
      localOrderData: {
        ...localOrderData, // takerName
      },
      includeDates: includeDates ? includeDates : false,
    },
  };
};

export const submitReplaceOrder = (orderData: ReplaceOrderModel) => {
  return {
    type: SUBMIT_REPLACE_ORDER,
    payload: {
      order: {
        ...orderData,
      },
    },
  };
};

export const submitReplaceOrderClassic = (orderData: ReplaceOrderModelClassic) => {
  return {
    type: SUBMIT_REPLACE_ORDER,
    payload: {
      order: {
        ...orderData,
      },
    },
  };
};

export const cancelOrderAction = (orderId: string) => ({
  type: CANCEL_ORDER,
  payload: {
    type: "ALL",
    orderIds: [orderId],
  },
});

export const cancelAllOrders = () => ({
  type: CANCEL_ALL_ORDERS,
  payload: {
    type: "ALL",
    orderIds: null,
  },
});

export const setActiveOrderTab = (currencyName: "string") => {
  return {
    type: SET_ACTIVE_ORDER_TAB,
    payload: {
      currencyName,
    },
  };
};

export const removeOrderFromActiveOrders = data => ({
  type: REMOVE_ORDER,
  payload: data,
});

export const removeClosedOrdersFromActiveOrders = orderId => ({
  type: REMOVE_CLOSED_ORDERS,
  payload: orderId,
});

export const removeClosedOrdersFromStore = orderId => ({
  type: REMOVE_CLOSED_ORDERS_FROM_STORE,
  payload: orderId,
});

export const submitNewOrderDone = () => ({ type: SUBMIT_NEW_ORDER_DONE });
export const submitNewOrderFail = () => ({ type: SUBMIT_NEW_ORDER_FAIL });

// epics
export const submitNewOrderEpic = (action$, state$) => {
  return action$.pipe(
    ofType(SUBMIT_NEW_ORDER),
    withLatestFrom(state$),
    // we'll only ever get one response from each sendMessage attempt
    mergeMap(([action, state]: any) => {
      const currencyPair = currencyPairByIdSelector(state, action.payload.order.instrumentId);

      const settleDate = currencyPair?.settlementDate ?? null;
      const fixingDate = currencyPair?.fixingDate ?? null;

      let order = action.payload.order;
      order = { ...order, settleDate, fixingDate };

      return of(
        sendMessage(
          ORDER,
          {
            action: "create",
            data: order,
          },
          action.payload.order.correlation
        )
      ).pipe(
        mergeMap(response => [submitNewOrderDone(), setAuctionCard(null)]),
        catchError(err => {
          return of(submitNewOrderFail());
        })
      );
    })
  );
};

export const submitReplaceOrderEpic = (action$, state$) => {
  return action$.pipe(
    ofType(SUBMIT_REPLACE_ORDER),
    withLatestFrom(state$),
    // we'll only ever get one response from each sendMessage attempt
    mergeMap(([action, state]: any) => {
      const currency = action.payload.order.instrumentId;
      let order = action.payload.order;
      if (currency) {
        const currencyPair = currencyPairByIdSelector(state, currency);

        const settleDate = currencyPair?.settlementDate ?? null;
        const fixingDate = currencyPair?.fixingDate ?? null;

        order = { ...order, settleDate, fixingDate };
      }

      return of(
        sendMessage(
          ORDER,
          {
            action: "replace",
            data: order,
          },
          action.payload.order.correlation
        )
      ).pipe(
        mergeMap(response => [submitNewOrderDone(), setAuctionCard(null)]),
        catchError(err => {
          return of(submitNewOrderFail());
        })
      );
    })
  );
};

const getDelayValue = (state, order) => {
  const delayValue = Object.values(CancelledClosedOrderStatus).includes(order.status)
    ? state.settings.ordersSettings.removeCancelled === OrderDelayValues.Time
      ? state.settings.ordersSettings.removeCancelledTime
      : 0
    : state.settings.ordersSettings.removeFilled === OrderDelayValues.Time
    ? state.settings.ordersSettings.removeFilledTime
    : 0;
  return delayValue;
};

export const updateOrdersWithServerOrdersEpic = (action$, state$) => {
  return action$.pipe(
    ofType(LOAD_ORDERS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const serverOrders = action.payload.data.data;
      const completedOrders = serverOrders.map(order => {
        const isClosedOrder = Object.values(ClosedOrderStatus).includes(order.status);
        const now = Date.now();
        const delayValue = getDelayValue(state, order);
        const hasRemainingRemoveDelay =
          order.tradeDate !== 0 && now - order.fills[order.fills.length - 1]?.tradeDate <= delayValue * 1000;

        if ((!hasRemainingRemoveDelay && isClosedOrder) || order.manuallyCleared) {
          return { ...order, isComplete: true };
        }

        return order;
      });
      // send a message to load all of the orders into redux
      return [loadOrdersProcessed(completedOrders)];
    })
  );
};

export const postProcessUpdateOrdersEpic = (action$, state$) => {
  return action$.pipe(
    ofType(LOAD_ORDERS_PROCESSED),
    withLatestFrom(state$),
    map(([action, state]: any) => {
      //get list of orders
      const orders = action.payload.data;

      //filter out incomplete orders
      return orders
        .filter(order => {
          const isClosedOrder = Object.values(ClosedOrderStatus).includes(order.status);
          const now = Date.now();
          const delayValue = getDelayValue(state, order);
          return (
            isClosedOrder &&
            order.tradeDate !== 0 &&
            now - order.fills[order.fills.length - 1].tradeDate <= delayValue * 1000
          ); // all in milliseconds
        })
        .map(order => {
          const now = Date.now();
          const delayValue = getDelayValue(state, order);

          return clearClosedOrders(
            order,
            state$.value.settings.ordersReadOnly,
            delayValue * 1000 - (now - order.fills[order.fills.length - 1].tradeDate)
          );

        });
    }),
    mergeMap((visibleOrdersActions: any) => {
      return from(visibleOrdersActions);
    })
  );
};

export const initAllOrdersBasicInfoEpic = (action$, state$) => {
  return action$.pipe(
    ofType(LOAD_ORDERS_PROCESSED),
    withLatestFrom(state$),
    map(([action, state]: any) => {
      //get list of orders
      const orders = action.payload.data;

      return initAllOrdersBasicInfoAction(orders, state.settings.blotterSettings.displayOrdersByTime);
    })
  );
};


export const removeCancelledOrdersEpic = (action$, state$) => {
  return action$.pipe(
    ofType(UPDATE_ORDER, CLEAR_CLOSED_ORDERS),
    filter(
      (action: any) =>
        action.payload.data && Object.values(CancelledClosedOrderStatus).includes(action.payload.data.status)
    ),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const delayTime = action.payload.delayValue
        ? action.payload.delayValue
        : state.settings.ordersSettings.removeCancelled !== OrderDelayValues.Now
        ? state.settings.ordersSettings.removeCancelledTime * 1000
        : 0;
      return of(delayTime).pipe(
        delay(delayTime),
        map(_ => action)
      );
    }),
    map((action: any) => removeOrderFromActiveOrders(action.payload.data.correlation))
  );
};

export const removeFilledOrdersEpic = (action$, state$) => {
  return action$.pipe(
    ofType(UPDATE_ORDER, CLEAR_CLOSED_ORDERS),
    filter(
      (action: any) =>
        action.payload.data && Object.values(FilledClosedOrderStatus).includes(action.payload.data.status)
    ),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const delayTime = action.payload.delayValue
        ? action.payload.delayValue
        : state.settings.ordersSettings.removeFilled !== OrderDelayValues.Now
        ? state.settings.ordersSettings.removeFilledTime * 1000
        : 0;

      return of(delayTime).pipe(
        delay(delayTime),
        map(_ => action)
      );
    }),
    map((action: any) => removeOrderFromActiveOrders(action.payload.data.correlation))
  );
};

const fillAudio = new Audio(orderFillSound);
const cancelAudio = new Audio(orderCancelSound);

const playFilledSound = () => {
  fillAudio.currentTime = 0;
  fillAudio.play();
};

const playCanceledSound = () => {
  cancelAudio.currentTime = 0;
  cancelAudio.play();
};

export const playFilledNotificationSound = (action$, state$) => {
  return action$.pipe(
    ofType(UPDATE_ORDER),
    withLatestFrom(state$),
    filter(([action, state]: any) => {
      const order = action.payload.data;
      return (
        order &&
        Object.values(FilledClosedOrderStatus).includes(order.status) &&
        state.settings.notificationSettings.filledOrders
      );
    }),
    mergeMap(([action, state]: any) => {
      const delayFilledTime = state.settings.notificationSettings.ignoreFollowingFilledInSeconds * 1000 || 0;
      return of(delayFilledTime);
    }),
    throttle((val: number) => interval(val)),
    tap(() => {
      playFilledSound();
    }),
    ignoreElements()
  );
};

export const playCanceledNotificationSound = (action$, state$) => {
  return action$.pipe(
    ofType(UPDATE_ORDER),
    withLatestFrom(state$),
    filter(([action, state]: any) => {
      const order = action.payload.data;
      return (
        order &&
        Object.values(CancelledClosedOrderStatus).includes(order.status) &&
        state.settings.notificationSettings.cancelOrRejectedOrders
      );
    }),
    mergeMap(([action, state]: any) => {
      const delayCanceledTime =
        state.settings.notificationSettings.ignoreFollowingCancelOrRejectedInSeconds * 1000 || 0;
      return of(delayCanceledTime);
    }),
    throttle((val: number) => interval(val)),
    tap(() => {
      playCanceledSound();
    }),
    ignoreElements()
  );
};

export const loadOrdersEpic = action$ => {
  return action$.pipe(
    ofType(LOAD_ORDERS),
    map(() => handleOrdersLoaded())
  );
};

const generatePriceInfo = (orderType, price, stopLossPrice) => {

  switch (orderType){
    case OrderType.Market:
      return `market ${price}`;
    case OrderType.Stop:
      return `stop ${stopLossPrice}`;
    case OrderType.StopLimit:
      return `stop ${stopLossPrice}, limit ${price}`;
    default:
      return `limit ${price}`;

  }

}

const generateSuccessMessage = (status, side, quantity, price, stopLossPrice, traderName, leg, orderType, orderTypeLeg) => {

  const legInfo: string = orderType === OrderType.Oco ? `for ${leg}` : "";
  const priceInfo: string = orderType === OrderType.Oco ? generatePriceInfo(orderTypeLeg, price, stopLossPrice) : generatePriceInfo(orderType, price, stopLossPrice);
  return `${status === OrderStatus.Filled ? "Filled " : ""}${side === OrderSide.Buy ? "Buy" : "Sell"} ${toMShorthand(quantity)} @ ${priceInfo} ${legInfo} on behalf of ${traderName}`;

}

export const orderSuccessNotificationEpic = (action$, state$) => {
  return action$.pipe(
    ofType(UPDATE_ORDER),
    withLatestFrom(state$),
    tap(([action]: any) => {
      const order = action.payload.data;

      if (order.status === OrderStatus.Working || order.status === OrderStatus.Filled) {
        notifications.success({
          title: "order request",
          message: generateSuccessMessage(order.status, order.side, order.quantity, order.price, order.stopLossPrice, order.traderName, "leg 1", order.type, order.orderTypeLeg1),
        });
      }

      if (order.statusLeg2 === OrderStatus.Working || order.statusLeg2 === OrderStatus.Filled) {
        notifications.success({
          title: "order request",
          message: generateSuccessMessage(order.statusLeg2, order.sideLeg2, order.quantity, order.priceLeg2, order.stopLossPriceLeg2, order.traderName, "leg 2", order.type, order.orderTypeLeg2),
        });
      }

    }),
    ignoreElements()
  );
};

export const cancelOrderEpic = action$ => {
  return action$.pipe(
    ofType(CANCEL_ORDER, CANCEL_ALL_ORDERS),
    mergeMap((action: any) => {
      return of(
        sendMessage(ORDER, {
          action: "cancel",
          data: action.payload,
        })
      ).pipe(
        mergeMap(response => [submitNewOrderDone(), setAuctionCard(null)]),
        catchError(err => of(submitNewOrderFail()))
      );
    })
  );
};

const meetsCriteriaToRemove = (state, order) => {
  const isClosedOrder = Object.values(ClosedOrderStatus).includes(order.status);
  const delayValue = getDelayValue(state, order);
  const hasRemainingRemoveDelay =
    order.tradeDate !== 0 ? Date.now() - order.fills[order.fills.length - 1]?.tradeDate <= delayValue * 1000 : true;
  const isCompleted = order.isComplete;
  
  return hasRemainingRemoveDelay && isClosedOrder && !isCompleted;
};

export const removeClosedOrdersEpic = (action$, state$) => {
  return action$.pipe(
    ofType(REMOVE_CLOSED_ORDERS),
    withLatestFrom(state$),
    mergeMap(([action, state]: any) => {
      const orderId = action.payload;
      let orderIds: any = [];

      if (orderId !== null) {
        const order = state.orders.find(order => order.id === orderId);
        if (meetsCriteriaToRemove(state, order)) {
          orderIds = [orderId];
        }
      } else {
        state.orders.forEach(order => {
          if (meetsCriteriaToRemove(state, order)) {
            orderIds = [...orderIds, order.id];
          }
        });
      }

      return of(
        sendMessage(ORDER, {
          action: "manuallyClearOrders",
          data: { orderIds: orderIds },
        })
      ).pipe(mergeMap(response => [removeClosedOrdersFromStore(orderIds)]));
    })
  );
};

// reducer
export type OrdersState = OrderModel[];
export type LastNewOrderState = string;

const initialLastNewOrderState = "";

export const lastNewOrderReducer = (state: LastNewOrderState = initialLastNewOrderState, action: any) => {
  switch (action.type) {
    case SET_ACTIVE_ORDER_TAB: {
      return action.payload.currencyName;
    }
    default:
      return state;
  }
};

// selectors
export const allOrdersSelector = state => state.orders || [];
export const orderByIdSelector = (state, id) => state.orders.find(o => o.id != null && o.id === id);

// HEREZ 
export const orderByCorrelationSelector = (state, correlation) =>
  state.orders.find(o => o.correlation != null && o.correlation === correlation);

  
export const ordersByCurrencySelector = (state, instrumentId) =>
  state.orders.filter(order => order.instrumentId === instrumentId);
export const ordersByCurrencySelectorAndOpen = (state, instrumentId) =>
  state.orders.filter(order => order.instrumentId === instrumentId && OpenOrderStatus[order.status]);
export const uniqueOrderCurrenciesSelector = (state): any[] => {
  const uniqueCurrencies = new Set();
  state.orders.forEach((order: any) => {
    uniqueCurrencies.add(order.instrumentId);
  });
  return Array.from(uniqueCurrencies);
};
export const getFilledDelayTime = state =>
  state.settings.notificationSettings.ignoreFollowingFilledInSeconds * 1000 || 0;
export const getSelectedRowsOrderData = state => {
  const rows = state.orders.filter(order => {
    const selectedRows: string[] = [];
    state.app.analyticsPanel.orderHistorySelectedRows.forEach((row: any) => {
      return selectedRows.push(row.id);
    });
    const retval = selectedRows.includes(order.id);
    return retval;
  });

  return rows;
};

export const liveOrdersSelector = createSelector(
  allOrdersSelector,
  allOrders =>
    allOrders.filter(
      // 0 implies the order is pending or working status, before a tradeDate is assigned
      order => !order.isComplete && (moment(order.tradeDate).isSame(Date.now(), "day") || order.tradeDate === 0)
    ) || []
);

export const getAllOrdersSelector = (state: any) => state.orders || [];

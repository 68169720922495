import { OrderStrategy } from "../../state/orders/types";

export const orderStrategyOptions = [
  {
    label: "WMFIX",
    value: OrderStrategy.Fixing,
  },
  {
    label: "ICEBERG",
    value: OrderStrategy.Iceberg,
  },
  {
    label: "PEG",
    value: OrderStrategy.Peg,
  },
  {
    label: "POV",
    value: OrderStrategy.Pov,
  },
  {
    label: "iSEEK",
    value: OrderStrategy.ISeek,
  },
  {
    label: "Smart Sweep",
    value: OrderStrategy.Sor,
  },
  {
    label: "TWAP",
    value: OrderStrategy.Twap,
  },
  {
    label: "VWAP",
    value: OrderStrategy.Vwap,
  },
];

// Reminder: only CantorAlgo users have access to the dropdown "Default Aggressive Order"
export enum DefaultAggressiveOrderStrategy {
  standardUser = OrderStrategy.Common,
  cagoUser = OrderStrategy.Sor,
}

import React from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { allSymbolsWithoutTenorsSelector } from "../state/currency/symbols/symbolsState";
import { activeGroupSymbolsSelector } from "../state/settings/settingsState";

import styles from "./CurrencyPairsModal.module.scss";
import { SymbolsList } from "./symbolsList/SymbolsList";

/**
 * config:
 * {
 *  listHeight: '200px',
 *  title: 'Add new pairs',
 *  footerButtonSize: 'small',
 *  footerActionLabel: 'Add'
 *  filterPlaceholder: 'Filter'
 * }
 */

function SymbolsModal({ symbols, selectedSymbols, isVisible, onOk, onCancel }: any) {
  return (
    <Modal className={styles.modal} closable={false} visible={isVisible} footer={null}>
      {isVisible && (
        <SymbolsList
          allSymbols={symbols}
          selectedSymbols={selectedSymbols}
          onOk={onOk}
          onCancel={onCancel}
          config={{
            listHeight1: "332px",
            listHeight2: "377px",
            title: "Add new pairs",
            footerButtonSize: "large",
            footerActionLabel: "Add new pairs",
            filterPlaceholder: "Search",
          }}
        />
      )}
    </Modal>
  );
}

export default connect(
  (state, ownProps: { isVisible: boolean; onOk: any; onCancel: any }) => ({
    // symbols: allSymbolsSelector(state),
    symbols: allSymbolsWithoutTenorsSelector(state),
    selectedSymbols: activeGroupSymbolsSelector(state),
    isVisible: ownProps.isVisible,
    onOk: ownProps.onOk,
    onCancel: ownProps.onCancel,
  }),
  null
)(SymbolsModal);

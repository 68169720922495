import { ModuleRegistry } from "@ag-grid-community/core";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";

export const setupGrid = (license: string | undefined = undefined) => {
  let modules = [
    ClientSideRowModelModule,
    // allow row detail expanding
    MasterDetailModule,
    // filter modules
    SetFilterModule,
    // tabs inside of a column menu
    MenuModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    ExcelExportModule,
  ];

  ModuleRegistry.registerModules(modules);

  // the grid works fine without a license, but will have a watermark until a license is provided
  if (license) {
    LicenseManager.setLicenseKey(license);
  }
};

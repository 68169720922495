import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import { isEqual } from "lodash";

import styles from "./Settings.module.scss";
import SettingsActions from "./SettingsActions";
import BlotterSettings from "./BlotterSettings";
import MarketSettings from "./MarketSettings";
import OrdersSettings from "./OrdersSettings";
import OrderStatusesSettings from "./OrderStatusesSettings";
import NotificationsSettings from "./NotificationsSettings";

import {
  getSettings,
  DisplayOrdersByTimeType,
  CUSTOM_SIZE_UNIT_OPTIONS,
  getTraders,
  getCantorAlgoEnabled,
  ClickType,
  SpreadValue,
  CardSize,
} from "../../state/settings/settingsState";

// env
import { APP_VERSION } from "../../environment";
import OrderRibbonSettings from "./OrderRibbonSettings";
import { OrderLifespan, OrderStatus, OrderType, OrderStrategy } from "../../state/orders/types";
import { updateSelectedOrderHistoryRows, userInfoData } from "../../state/app/appState";
import { saveAllSettings, setActiveTrader } from "../../state/settings/settingsActions";
import {
  initAllOrdersBasicInfoAction,
  updateOrdersVisibilityAction,
} from "../../state/orders/pauseResumeOrders/pauseResumeOrdersActions";
import { allOrdersSelector } from "../../state/orders/ordersState";

type SettingsProps = {
  closeDrawer: any;
  isOpen: boolean;
  onSaveAllSettings?: any;
  saveAllSettings: any;
  setActiveTrader: (traderName: string) => void;
  settings: any;
  traders: any;
  updateSelectedOrderHistoryRows: any;
  userInfo: any;
  cantorAlgoEnabled: boolean;
  updateOrdersVisibility: any;
  initAllOrdersBasicInfo: any;
  allOrders: any;
};

const SettingsView = ({
  closeDrawer,
  isOpen,
  onSaveAllSettings,
  saveAllSettings,
  setActiveTrader,
  settings,
  traders,
  updateSelectedOrderHistoryRows,
  userInfo,
  cantorAlgoEnabled,
  updateOrdersVisibility,
  initAllOrdersBasicInfo,
  allOrders,
}: SettingsProps) => {
  const [blotterSettings, setBlotterSettings] = useState({
    showBlotter: false,
    showOHLC: false,
    showTicker: false,
    orderTypes: "executions",
    displayOrdersByTime: DisplayOrdersByTimeType.today,
  });
  const [marketSettings, setMarketSettings] = useState({
    cardSize: CardSize.Small,
    clickType: ClickType.Double,
    defaultWidget: "ccypair",
    depthDisplay: 0,
    displaySpreadOnDepthOfBook: true,
    enableDepthOfBookTrading: true,
    enablePriceFiltering: true,
    getAllPriceLevels: true,
    notional: 1000000,
    showPriceTrendAndSpread: true,
    showSizeAbbreviation: true,
    showSlippage: true,
    slippageLeftIncrement: 1.0,
    slippageRightIncrement: 0.1,
    spreadValue: SpreadValue.Pip,
  });
  const [notificationSettings, setNotificationSettings] = useState({
    cancelOrRejectedOrders: true,
    displayOrderAcknowlegedBannerInSeconds: 0,
    filledOrders: true,
    ignoreFollowingCancelOrRejectedInSeconds: 0,
    ignoreFollowingFilledInSeconds: 0,
    showOrderAcknowlegedBanner: true,
  });
  const [ordersSettings, setOrdersSettings] = useState({
    showOrders: false,
    confirmCancelation: false,
    ordersListOrder: "recent",
    removeCancelled: "now",
    removeCancelledTime: 0,
    removeFilled: "now",
    removeFilledTime: 0,
    marketOrdersEnabled: false,
    // HERE
    priceSlippageSeparated: false,
    defaultPassiveOrderType: OrderType.Limit,
    defaultPassiveOrderLifespan: OrderLifespan.Day,
    defaultAggressiveOrderStrategy: OrderStrategy.Common,
    defaultAggressiveOrderLifespan: OrderLifespan.Day,
  });
  const [orderRibbonSettings, setOrderRibbonSettings] = useState({
    customSizes: [
      { customSize: "5", multiplier: CUSTOM_SIZE_UNIT_OPTIONS.M },
      { customSize: "10", multiplier: CUSTOM_SIZE_UNIT_OPTIONS.M },
      { customSize: "15", multiplier: CUSTOM_SIZE_UNIT_OPTIONS.M },
      { customSize: "30", multiplier: CUSTOM_SIZE_UNIT_OPTIONS.M },
      { customSize: "60", multiplier: CUSTOM_SIZE_UNIT_OPTIONS.M },
    ],
    hideIceberg: false,
    icebergQuantity: 1000000,
    onBehalfOfButtons: [
      { index: 0, value: "none" },
      { index: 1, value: "none" },
      { index: 2, value: "none" },
    ],
    orderSizes: ["5", "10", "15", "30", "60"],
    selectedDefaultOrderType: OrderType.Market,
    selectedDefaultOrderLifespan: [OrderLifespan.Day],
    selectedDefaultQuantity: null,
    showOnBehalfOfButtons: true,
    showOrderRibbon: false,
    useIceberg: [],
  });
  const [orderStatusesSettings, setOrderStatusesSettings] = useState({
    statusOverrides: {
      [OrderStatus.Canceling]: null,
      [OrderStatus.Filled]: null,
      [OrderStatus.PartialCancel]: null,
      [OrderStatus.PartialFill]: null,
      [OrderStatus.Pending]: null,
      [OrderStatus.Rejected]: null,
      [OrderStatus.Replacing]: null,
      [OrderStatus.UnsolCancel]: null,
      [OrderStatus.UnsolPartial]: null,
      [OrderStatus.UserCancel]: null,
      [OrderStatus.Working]: null,
    },
  });

  useEffect(() => {
    // when we open the drawer, load state from Redux store. this will overwrite changes they may have made but did not save.
    if (isOpen || settings) {
      setBlotterSettings(settings.blotterSettings);
      setMarketSettings(settings.marketSettings);
      setNotificationSettings(settings.notificationSettings);
      setOrderRibbonSettings(settings.orderRibbonSettings);
      setOrdersSettings(settings.ordersSettings);
      setOrderStatusesSettings(settings.orderStatusesSettings);
      if (!settings.blotterSettings.showBlotter && !settings.blotterSettings.showTicker) {
        updateSelectedOrderHistoryRows([]);
      }
    } else {
      // when order ribbon is disabled the active trader should be whoever is logged in
      setActiveTrader(userInfo.userName);
    }
  }, [isOpen, settings]);

  const isDisabled = () => {
    return (
      isEqual(blotterSettings, settings.blotterSettings) &&
      isEqual(marketSettings, settings.marketSettings) &&
      isEqual(notificationSettings, settings.notificationSettings) &&
      isEqual(orderRibbonSettings, settings.orderRibbonSettings) &&
      isEqual(ordersSettings, settings.ordersSettings) &&
      isEqual(orderStatusesSettings, settings.orderStatusesSettings)
    );
  };

  const setOrdersSettingsRibbonUpdates = newRibbonSettings => {
    setOrderRibbonSettings({ ...orderRibbonSettings, ...newRibbonSettings });
  };

  return (
    <>
      <div className={styles.drawerTitle}>
        <h3>Settings</h3>
      </div>

      <Tabs defaultActiveKey="1" tabPosition={"left"} className={styles.settingsTabs}>
        <Tabs.TabPane forceRender={true} tab={"Blotter"} key={"1"}>
          <BlotterSettings saveSettings={setBlotterSettings} settings={blotterSettings} />
        </Tabs.TabPane>
        <Tabs.TabPane forceRender={true} tab={"Market"} key={"2"}>
          <MarketSettings
            saveSettings={setMarketSettings}
            settings={marketSettings}
            disableTypeSelection={orderRibbonSettings.showOrderRibbon}
          />
        </Tabs.TabPane>
        <Tabs.TabPane forceRender={true} tab={"Notifications"} key={"3"}>
          <NotificationsSettings saveSettings={setNotificationSettings} settings={notificationSettings} />
        </Tabs.TabPane>
        <Tabs.TabPane forceRender={true} tab={"Orders"} key={"4"}>
          <OrdersSettings
            saveSettings={setOrdersSettings}
            setOrdersSettingsRibbonUpdates={setOrdersSettingsRibbonUpdates}
            settings={ordersSettings}
            cantorAlgoEnabled={cantorAlgoEnabled}
          />
        </Tabs.TabPane>
        <Tabs.TabPane forceRender={true} tab={"Order Ribbon"} key={"5"}>
          <OrderRibbonSettings saveSettings={setOrderRibbonSettings} settings={orderRibbonSettings} traders={traders} />
        </Tabs.TabPane>
        <Tabs.TabPane forceRender={true} tab={"Order Statuses"} key={"6"}>
          <OrderStatusesSettings saveSettings={setOrderStatusesSettings} settings={orderStatusesSettings} />
        </Tabs.TabPane>
      </Tabs>

      <div className={styles.drawerFooter}>
        <div className={styles.version}>version {APP_VERSION}</div>
        <SettingsActions
          saveAction={() => {
            const settingsObject = {
              blotterSettings,
              marketSettings,
              notificationSettings,
              ordersSettings,
              orderRibbonSettings,
              orderStatusesSettings,
            };
            saveAllSettings(settingsObject);
            onSaveAllSettings(settingsObject);

            // Regenerate the object when saving the settings, only when the user changes the blotter settings, we should only do this when it changes for performance.
            if (settings.blotterSettings.displayOrdersByTime !== settingsObject.blotterSettings.displayOrdersByTime) {
              initAllOrdersBasicInfo(allOrders, settingsObject.blotterSettings.displayOrdersByTime);
            }

            updateOrdersVisibility(blotterSettings.displayOrdersByTime);
          }}
          closeDrawer={closeDrawer}
          isDisabled={isDisabled()}
        />
      </div>
    </>
  );
};

const Settings = connect(
  (state: any, ownProps: any) => ({
    closeDrawer: ownProps.closeDrawer,
    settings: getSettings(state),
    traders: getTraders(state) || [],
    userInfo: userInfoData(state),
    cantorAlgoEnabled: getCantorAlgoEnabled(state),
    allOrders: allOrdersSelector(state),
  }),
  {
    saveAllSettings, //
    setActiveTrader,
    updateSelectedOrderHistoryRows,
    updateOrdersVisibility: updateOrdersVisibilityAction,
    initAllOrdersBasicInfo: initAllOrdersBasicInfoAction,
  }
)(SettingsView);

export default Settings;

import React from "react";
import { Input } from "antd";

import styles from "./Settings.module.scss";
import clsx from "clsx";
import { displayOrderStatus } from "../../lib/formatters";

type OrderStatusesSettingsProps = {
  saveSettings: any;
  settings: any;
};

const OrderStatusesSettings = ({ saveSettings, settings }: OrderStatusesSettingsProps) => {
  return (
    <div className={clsx(styles.settingsContent, styles.orderStatuses)}>
      <section className={styles.formBlock}>
        <h5>status overrides</h5>
        {Object.keys(settings.statusOverrides).map((status, i) => {
          return (
            <div className={styles.inputBlock} key={i}>
              <label>{displayOrderStatus(status, true)}</label>
              <Input
                type="text"
                size="large"
                value={settings.statusOverrides[status]}
                onChange={event => {
                  if (settings.statusOverrides[status] !== event.target.value) {
                    saveSettings({
                      ...settings,
                      statusOverrides: { ...settings.statusOverrides, [status]: event.target.value },
                    });
                  }
                }}
              />
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default OrderStatusesSettings;

import { concat, of } from "rxjs";
import {
  mergeMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import { sendMessage } from "../../connection/webSocketService";
import { ORDER } from "../ordersState";
import { INIT_ALL_ORDERS_BASIC_INFO, SET_CHECK_ORDERS_VISIBILITY, SET_ORDERS_VISIBILITY_AFTER_FILTERING, TOGGLE_VISIBLE_ORDERS, UPDATE_ORDERS_VISIBILITY, SELECT_ORDER, DESELECT_ORDERS, PAUSE_ORDERS, RESUME_ORDERS, CANCEL_ORDERS, SET_NEW_VISIBLE_ORDER, CHECK_ORDERS_VISIBILITY_AND_TOGGLE_VISIBLE_ORDERS } from "./pauseResumeOrdersState";

export const initAllOrdersBasicInfoAction = (orders: any, displayOrdersByTime: string) => ({
  type: INIT_ALL_ORDERS_BASIC_INFO,
  payload: {
    orders,
    displayOrdersByTime
  }
});

export const selectOrderAction = (orderId, readOnly) => ({
  type: SELECT_ORDER,
  payload: {
    orderId,
    readOnly: readOnly
  } 
});

export const deselectOrdersAction = (orderIds, readOnly) => ({
  type: DESELECT_ORDERS,
  payload: {
    orderIds,
    readOnly: readOnly
  }  
});

export const pauseOrdersAction = (orderIds: any) => ({
  type: PAUSE_ORDERS,
  payload: orderIds
});

export const resumeOrdersAction = (orderIds: any) => ({
  type: RESUME_ORDERS,
  payload: orderIds
});

export const cancelOrdersAction = (orderIds: any) => ({
  type: CANCEL_ORDERS,
  payload: orderIds
});

export const setOrdersVisibilityAfterFilteringAction = (orderIds: any, readOnly) => ({
  type: SET_ORDERS_VISIBILITY_AFTER_FILTERING,
  payload: {
    orderIds,
    readOnly: readOnly
  }
});

export const toggleVisibleOrdersAction = (isSelectAll: boolean, readOnly: boolean) => ({
  type: TOGGLE_VISIBLE_ORDERS,
  payload: {
    isSelectAll,
    readOnly: readOnly
  }
});

export const updateOrdersVisibilityAction = (displayOrdersByTime: string) => ({
  type: UPDATE_ORDERS_VISIBILITY,
  payload: displayOrdersByTime
});

export const setCheckOrdersVisibilityAction = (checkOrdersVisibility: boolean) => ({
  type: SET_CHECK_ORDERS_VISIBILITY,
  payload: checkOrdersVisibility
});

export const checkOrdersVisibilityAndToggleVisibleOrdersAction = (checkOrdersVisibility: boolean, isSelectAll: boolean, readOnly: boolean) => ({
  type: CHECK_ORDERS_VISIBILITY_AND_TOGGLE_VISIBLE_ORDERS,
  payload: {
    checkOrdersVisibility,
    isSelectAll,
    readOnly
  }
});

// export const setNewVisibleOrderAction = (order) => ({
//   type: SET_NEW_VISIBLE_ORDER,
//   payload: order
// });



// Epics 
export const pauseOrdersEpic = (action$, state$) => {
  return action$.pipe(
    ofType(PAUSE_ORDERS),
    mergeMap((action: any) => {

      // pauseResumeHelper
      // console.log("PAUSE ORDERS EPIC");

      const readOnly = state$.value.settings.ordersReadOnly;
      const orderIds = action.payload;

      // pauseResumeHelper
      // console.log(orderIds);

      return of(
        sendMessage(ORDER, {
          action: "pause",  
          data: {
            orderIds: orderIds
          }
        })
      ).pipe(
        mergeMap(response => [deselectOrdersAction(orderIds, readOnly)]),
      );
    })
  );
};


export const resumeOrdersEpic = (action$, state$) => {
  return action$.pipe(
    ofType(RESUME_ORDERS),
    mergeMap((action: any) => {

      // pauseResumeHelper
      // console.log("RESUME ORDERS EPIC");
      // console.log(action.payload);

      const readOnly = state$.value.settings.ordersReadOnly;
      const orderIds = action.payload;

      // console.log(orderIds);

      return of(
        sendMessage(ORDER, {
          action: "resume",
          data: {
            orderIds: orderIds
          }
        })
      ).pipe(
        mergeMap(response => [deselectOrdersAction(orderIds, readOnly)]),
      );

    })
  );
};


export const cancelOrdersEpic = (action$, state$) => {
  return action$.pipe(
    ofType(CANCEL_ORDERS),
    mergeMap((action: any) => {

      // pauseResumeHelper
      // console.log("CANCEL ORDERS EPIC");
      // console.log(action.payload);

      const readOnly = state$.value.settings.ordersReadOnly;
      const orderIds = action.payload;

      // pauseResumeHelper
      // console.log(orderIds);

      return of(
        sendMessage(ORDER, {
          action: "cancel",
          data: {
            orderIds: orderIds
          }
        })
      ).pipe(
        mergeMap(response => [deselectOrdersAction(orderIds, readOnly)]),
      );

    })
  );
};


export const checkOrdersVisibilityAndToggleVisibleOrdersEpic = (action$, state$) => {
  return action$.pipe(
    ofType(CHECK_ORDERS_VISIBILITY_AND_TOGGLE_VISIBLE_ORDERS),
    mergeMap((action: any) => {

      // pauseResumeHelper
      // console.log("CHECK_ORDERS_VISIBILITY_AND_TOGGLE_VISIBLE_ORDERS");

      const checkOrdersVisibility = action.payload.checkOrdersVisibility;
      const isSelectAll = action.payload.isSelectAll;
      const readOnly = state$.value.settings.ordersReadOnly;

      return concat(
        of(setCheckOrdersVisibilityAction(checkOrdersVisibility)), 
        of(toggleVisibleOrdersAction(isSelectAll, readOnly))
      );
    })
  );
};






/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Icon, Input, InputNumber, Menu, Radio, Modal } from "antd";
import Big from "big.js";
import clsx from "clsx";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { appLocale } from "../../lib/constants";
import { ReactComponent as IconClose } from "../../assets/images/CloseIcon-16.svg";
import { FlywheelMenu } from "./Flywheel/FlywheelMenu";

import {
  addCommas,
  ccyPairIdStripper,
  displayInstrumentLabel,
  formatSizeBasedOnSettings,
  getQuantityBasedOnSettings,
  ndfInstrumentStripper,
  positiveNumberEnforcer,
  priceFormatValidator,
  removeCommasAndAddMultiplierShortcuts,
  strippedCurrencyLabel,
} from "../../lib/formatUtil";
import {
  calculateSlippageToPip,
  decimalToPriceObjectConverterBySymbol,
  isCryptoCurrency,
  isNDFCurrency,
  spreadToPipCalculator,
  useOnClickOutside,
  useWindowClose,
} from "../../lib/util";
import { getIsScreenLocked } from "../../state/app/appState";
import { currencyPairRemoved, dockCard, setAuctionCard, setCurrentPrice } from "../../state/currency/currencyActions";
import { currencyPairByIdSelector } from "../../state/currency/currencyState";
import { cancelOrderAction, submitNewOrder } from "../../state/orders/ordersState";
import { OrderLifespan, OrderSide, OrderSideLabel, OrderType, ProductType } from "../../state/orders/types";
import {
  getCantorAlgoEnabled,
  getCurrencySettingsBySymbol,
  getDefaultOrderQuantityFromIndex,
  getOrdersReadOnly,
  getSettings,
  SpreadValue,
} from "../../state/settings/settingsState";
import "./cards.scss";

import { ReactComponent as AddIcon } from "../../assets/images/Add-16.svg";
import { ReactComponent as RemoveIcon } from "../../assets/images/Remove-16.svg";
import { pipForSymbol } from "../../state/currency/symbols/symbolsState";
import { currencyPairsToggled, saveCurrencySettings } from "../../state/settings/settingsActions";
import { CurrencyGridProps, FinalPrice } from "./card.types";
import CurrencyPairSelector from "./CurrencyPairSelector";
import { HotButton } from "./HotButton/HotButton";
import { PriceButton } from "./PriceButton/PriceButton";
import { SizeButton } from "./SizeButton/SizeButton";
// import { subBusinessDays } from "date-fns";
// import { cryptoCurrencies } from "../../lib/constants";

function CurrencyCard({
  cancelOrder,
  cardIndex,
  currencyPair,
  currencyPairRemoved,
  currencyPairsToggled,
  currencySettingsBySymbol,
  dockCard,
  isOpenFin,
  isPortal,
  pip,
  readOnly,
  saveCurrencySettings,
  setAuctionCard,
  settings,
  submitNewOrder,
  symbol,
  undockCard,
  cantorAlgoEnabled,
  activeCard,
  setActiveCard,
  activeCardSide,
  setActiveCardSide,
}: CurrencyGridProps) {
  const [isEditingCurrencyPair, setIsEditingCurrencyPair] = useState<boolean>(false);
  const { ordersSettings, marketSettings, orderRibbonSettings } = settings;
  const [cardState, setCardState] = useState<any>({
    currencySelected: currencySettingsBySymbol.currencySelected || symbol.split("/")[0],
    slippage: currencySettingsBySymbol.slippage || 0,
    flywheelIncrement: currencySettingsBySymbol.flywheelIncrement || 0,
    hotButtonIncrement: currencySettingsBySymbol.hotButtonIncrement || 0,
    quantity: 0,
  });

  // needed for the currency side dropdown in the slippage section
  const [showCurrencySlippageDropdown, setShowCurrencySlippageDropdown] = useState<boolean>(false);
  const currencySlippageSelectRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(currencySlippageSelectRef, () => setShowCurrencySlippageDropdown(false), symbol);

  // this might not be needed anymore now that card and the auction card are independent
  const [orderState] = useState<any>({
    order: null,
  });

  const [cardSettingsVisible, setCardSettingsVisible] = useState<boolean>(false);

  const showCardSettings = () => {
    setCardSettingsVisible(true);
  };

  const saveCardSettings = settingsNames => {
    let cardStateSetting = 0;

    settingsNames.forEach(settingName => {
      cardStateSetting = cardState[settingName];

      if (
        cardStateSetting &&
        !cardStateSetting.toString().endsWith(".") &&
        cardStateSetting !== currencySettingsBySymbol[settingName]
      ) {
        saveSettings(cardStateSetting, settingName);
      } else if (cardStateSetting && cardStateSetting.toString().endsWith(".")) {
        saveSettings(cardStateSetting.toString().slice(0, -1) || 0, settingName);
      } else if (!cardStateSetting) {
        saveSettings(0, settingName);
      }
      // else don't save it
    });

    setCardSettingsVisible(false);
  };

  const handleWindowClose = () => {
    if (isPortal) {
      dockCard(symbol);
    }
  };

  const handleDock = () => {
    // on window close, handleWindowClose always gets called. so let them handle it
    window["externalPanels"][symbol].close();
  };

  // only add the event handler to portal windows, not the main window
  useWindowClose(symbol, handleWindowClose, isPortal);

  useEffect(() => {
    if (currencySettingsBySymbol) {
      setCardState({ ...cardState, ...currencySettingsBySymbol });
    }
  }, [currencySettingsBySymbol]);

  useEffect(() => {
    const quantity = getQuantityBasedOnSettings(
      orderRibbonSettings,
      currencySettingsBySymbol,
      marketSettings.notional,
      true
    );
    setCardState({ ...cardState, quantity });
  }, [orderRibbonSettings, marketSettings.notional]);

  currencyPair = currencyPair || { asks: [], bids: [] }; // normalize this so we don't have to null check everywhere

  const flatBook = (bids, asks, depth, symbol) => {
    const bookDepth = new Array(depth || 1).fill(1);
    const book = bookDepth.map((_, i) => ({
      askPriceObj: decimalToPriceObjectConverterBySymbol(asks[i]?.price, symbol),
      askPriceNum: asks[i]?.price,
      askSize: asks[i]?.size,
      bidPriceObj: decimalToPriceObjectConverterBySymbol(bids[i]?.price, symbol),
      bidPriceNum: bids[i]?.price,
      bidSize: bids[i]?.size,
      isCrypto: isCryptoCurrency(symbol),
    }));
    return book;
  };
  const filteredBook = (bids, asks, depth, symbol, quantity) => {
    if (quantity && quantity > 0) {
      bids = bids.filter(bid => bid.size >= quantity);
      asks = asks.filter(ask => ask.size >= quantity);
    }
    return flatBook(bids, asks, depth, symbol);
  };

  const book = marketSettings.enablePriceFiltering
    ? filteredBook(currencyPair.bids, currencyPair.asks, marketSettings.depthDisplay, symbol, cardState.quantity)
    : flatBook(currencyPair.bids, currencyPair.asks, marketSettings.depthDisplay, symbol);
  // filtering only occurs on PriceRow
  const priceTableBook = flatBook(currencyPair.bids, currencyPair.asks, marketSettings.depthDisplay, symbol);
  const product = isNDFCurrency(symbol) ? ProductType.Ndf : ProductType.Spot;
  const topOfBook = book[0];
  const bidPriceTrend = currencyPair.bidTrend;
  const askPriceTrend = currencyPair.askTrend;

  const closeModal = () => {
    setCardState({ ...cardState, order: null });
    setAuctionCard(null);
  };

  const currencies = symbol ? ndfInstrumentStripper(symbol).split("/") : [];

  const cardActions = {
    initial: [
      {
        action: showCardSettings,
        displayValue: "Card Settings",
      },
      {
        action: undockCard,
        displayValue: "Undock",
      },
      {
        action: currencyPairRemoved.bind(null, symbol),
        displayValue: "Remove this card",
      },
    ],
    unDocked: [
      {
        action: handleDock,
        displayValue: "Dock",
      },
    ],
    hasOrder: [
      {
        action: setAuctionCard, // close order
        displayValue: "Return to order dock",
      },
      {
        action: null,
        displayValue: "Amend transaction",
      },
      {
        action: null,
        displayValue: "Pause transaction",
      },
      {
        action: orderState.order ? cancelOrder.bind(null, orderState.order.id) : null,
        displayValue: "Cancel transaction",
      },
    ],
  };
  const actions =
    isOpenFin && orderState.order ? cardActions.hasOrder : isPortal ? cardActions.unDocked : cardActions.initial;

  // HERE - Price and slippage are now sent separately. So we are not adding/subtracting the slippage to the price anymore before sending it]
  // For now we are calculating the final price (submitPrice) using the slippage for both handleQuickTrade and handleHotButtonTrade
  const calculateSubmitPrice = (price, side, isHotButton?) => {
    const offsetValue = isHotButton ? cardState?.hotButtonIncrement : cardState?.slippage;

    let submitPrice = 0;
    if (marketSettings?.showSlippage && offsetValue > 0) {
      const safePrice = Big(price);
      const safePip = Big(pip);
      submitPrice =
        side === OrderSide.Buy
          ? safePrice.add(safePip?.times(offsetValue))
          : safePrice.minus(safePip?.times(offsetValue));

      submitPrice = Number(submitPrice.toString());
    } else {
      submitPrice = price;
    }
    return submitPrice;
  };

  const handleQuickTrade = (price, side, quantity?) => {
    const optionalQuickTradeData: any = {};
    if (orderRibbonSettings.useIceberg?.length > 0) {
      optionalQuickTradeData.displaySize = orderRibbonSettings.icebergQuantity;
    }
    if (ordersSettings.defaultAggressiveOrderStrategy)
      optionalQuickTradeData.strategy = ordersSettings.defaultAggressiveOrderStrategy;

    const selectedLifespan = () => {
      if (
        orderRibbonSettings.showOrderRibbon &&
        orderRibbonSettings.selectedDefaultOrderLifespan &&
        orderRibbonSettings.selectedDefaultOrderLifespan.length > 0
      ) {
        return orderRibbonSettings.selectedDefaultOrderLifespan[0];
      }
      if (ordersSettings && ordersSettings.defaultAggressiveOrderLifespan) {
        return ordersSettings.defaultAggressiveOrderLifespan;
      }
      return OrderLifespan.Day;
    };

    let finalPrice: FinalPrice = {
      price: price.toString(),
      slippage: calculateSlippageToPip(cardState.slippage, pip).toString(),
    };
    if (!settings.ordersSettings.priceSlippageSeparated) {
      finalPrice = {
        price: calculateSubmitPrice(price, side).toString(),
      };
    }

    submitNewOrder(
      {
        side,
        instrumentId: symbol,
        currency: cardState.currencySelected,
        settleDate: currencyPair.settlementDate,
        fixingDate: currencyPair.fixingDate,
        quantity: quantity || cardState.quantity,
        product: product && product.toString(),
        type: orderRibbonSettings.selectedDefaultOrderType || null,
        traderName: settings.activeTrader,
        lifespan: selectedLifespan(),
        ...optionalQuickTradeData,
        // HERE
        // price: calculateSubmitPrice(price, side),
        // price: price,
        // slippage: calculateSlippageToPip(cardState.slippage, pip),
        ...finalPrice,
      },
      { takerName: settings.activeTaker }
    );
  };

  const handleHotButtonTrade = (price, side, quantity?) => {
    submitTradeHelper(price, side, quantity, true);
  };

  const handleFlywheelTrade = (price, side) => {
    submitTradeHelper(price, side, null, false);

    setFlywheelPriceBuy(0);
    setFlywheelPriceSell(0);
  };

  const submitTradeHelper = (price, side, quantity, isHotButton) => {
    const selectedLifespan = () => {
      if (
        orderRibbonSettings.showOrderRibbon &&
        orderRibbonSettings.selectedDefaultOrderLifespan &&
        orderRibbonSettings.selectedDefaultOrderLifespan.length > 0
      ) {
        return orderRibbonSettings.selectedDefaultOrderLifespan[0];
      }
      if (ordersSettings && ordersSettings.defaultAggressiveOrderLifespan) {
        return ordersSettings.defaultAggressiveOrderLifespan;
      }
      return OrderLifespan.Day;
    };

    const selectedStrategy = () => {
      if (ordersSettings?.defaultAggressiveOrderStrategy) {
        return ordersSettings.defaultAggressiveOrderStrategy;
      }
    };

    // Hot buttons and flywheel don't apply the slippage (story 53244 in Shortcut)
    const finalPrice: FinalPrice = {
      price: price.toString(),
    };

    if (isHotButton) {
      finalPrice.price = calculateSubmitPrice(price, side, isHotButton).toString();
    }

    // Place order
    submitNewOrder(
      {
        side,
        instrumentId: symbol,
        currency: cardState.currencySelected,
        settleDate: currencyPair.settlementDate,
        fixingDate: currencyPair.fixingDate,
        quantity: quantity || cardState.quantity,
        product: product && product.toString(),
        type: OrderType.Limit,
        traderName: settings.activeTrader,
        lifespan: selectedLifespan(),
        strategy: selectedStrategy(),
        maximumFloor: 0,
        // HERE
        // price: calculateSubmitPrice(price, side),
        // price: price,
        // slippage: calculateSlippageToPip(cardState.slippage, pip),
        ...finalPrice,
      },
      { takerName: settings.activeTaker }
    );
  };

  const saveSettings = (value: any, field: string) => {
    const { currencySelected, quantity, slippage, flywheelIncrement, hotButtonIncrement } = cardState;
    const newSettings = { currencySelected, quantity, slippage, flywheelIncrement, hotButtonIncrement };
    newSettings[field] = value;
    saveCurrencySettings(newSettings, symbol);
  };

  const selectedDefaultQuantity = getDefaultOrderQuantityFromIndex(
    orderRibbonSettings.selectedDefaultQuantityIndex,
    orderRibbonSettings.orderSizes
  );

  const handleIncrementDecrement = (direction, incrementAmount) => {
    const slippage = Big(cardState.slippage || 0);
    const plusValue = Number(slippage.add(incrementAmount).toString());
    const minusValue = Number(slippage.minus(incrementAmount).toString());
    let value = cardState.slippage;
    if (direction === "increment") {
      if (plusValue < 1000) {
        value = plusValue;
      }
    } else if (minusValue > 0) {
      value = minusValue;
    } else {
      value = 0;
    }
    saveSettings(value, "slippage");
  };

  const incrementsFieldHandler = (event, incrementName) => {
    const selectedText = window.getSelection();
    if (
      !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(event.key) &&
      !(!cardState[incrementName].toString().includes(".") && event.key === ".")
    ) {
      // cap increment length to 3 digits but allow highlighted text to be replaced
      if (
        ((cardState[incrementName].toString().includes(".") && cardState[incrementName].toString().length === 4) ||
          (!cardState[incrementName].toString().includes(".") && cardState[incrementName].toString().length === 3)) &&
        !selectedText?.toString()
      ) {
        event.preventDefault();
      } else {
        positiveNumberEnforcer(event);
      }
    }
  };

  const onUpdateSymbol = newSymbol => {
    currencyPairsToggled({
      selectedCurrencyPairIds: [newSymbol],
      selectedSymbolsInsertPosition: cardIndex,
      removedSymbol: symbol,
    });
    setIsEditingCurrencyPair(false);
  };

  // Flywheel for BUY button (visibility)
  const flywheelElementBuy = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(flywheelElementBuy, () => setFlywheelPriceBuy(0));

  const [flywheelPriceBuy, setFlywheelPriceBuy] = useState<number>(0);

  // Flywheel for SELL button (visibility)
  const flywheelElementSell = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(flywheelElementSell, () => setFlywheelPriceSell(0));

  const [flywheelPriceSell, setFlywheelPriceSell] = useState<number>(0);

  // Highlight this card only while its auction panel is open.
  const cardRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (activeCard === cardRef && cardState?.order == null) {
      setActiveCard(null);
      setActiveCardSide("");
    }
  }, [cardState]);

  return (
    <>
      <Modal className="cardModal" closable={false} visible={cardSettingsVisible} footer={null}>
        <IconClose className="close" onClick={() => setCardSettingsVisible(false)}></IconClose>

        <h2 className="title">Card Settings</h2>

        <span className="subtitle">{ccyPairIdStripper(symbol)}</span>

        <ul className="settings">
          <li className="settingItem">
            <label className="settingName">Flywheel increment</label>
            <InputNumber
              className="settingInput"
              data-testid="currency_flywheelIncrement_input"
              type="text"
              size="small"
              value={cardState.flywheelIncrement}
              onKeyDown={event => {
                incrementsFieldHandler(event, "flywheelIncrement");
              }}
              onBlur={event => {
                event.target.value === "" && setCardState({ ...cardState, flywheelIncrement: 0 });
              }}
              // onFocus={event => event.target.select()}
              onChange={value => setCardState({ ...cardState, flywheelIncrement: value })}
            />
          </li>

          {cantorAlgoEnabled && (
            <li className="settingItem">
              <label className="settingName">Hot button increment</label>
              <InputNumber
                className="settingInput"
                data-testid="currency_hotButtonIncrement_input"
                type="text"
                size="small"
                value={cardState.hotButtonIncrement}
                onKeyDown={event => {
                  incrementsFieldHandler(event, "hotButtonIncrement");
                }}
                onBlur={event => {
                  event.target.value === "" && setCardState({ ...cardState, hotButtonIncrement: 0 });
                }}
                onChange={value => setCardState({ ...cardState, hotButtonIncrement: value })}
              />
            </li>
          )}
        </ul>

        <div className="actions">
          <Button onClick={() => setCardSettingsVisible(false)} className="cancelSetting">
            Cancel
          </Button>
          <Button onClick={() => saveCardSettings(["flywheelIncrement", "hotButtonIncrement"])} className="saveSetting">
            Save
          </Button>
        </div>
      </Modal>

      <div
        ref={cardRef}
        className={clsx(
          "card",
          !isPortal && "roundedBorder",
          activeCard === cardRef && activeCardSide && `card--${activeCardSide}`
        )}
      >
        {isEditingCurrencyPair && (
          <div className={clsx("noDrag", "selectorWrapper")}>
            <div className={"currencyPairSelector"}>
              <CurrencyPairSelector
                isVisible={true}
                onOk={(symbols, selectedSymbols) => {
                  const newPair = selectedSymbols && selectedSymbols.length > 0 ? selectedSymbols[0] : null;
                  onUpdateSymbol(newPair);
                }}
                onCancel={() => setIsEditingCurrencyPair(false)}
              />
            </div>
          </div>
        )}
        <div className="currencyMeta">
          <CardHeader
            actions={actions}
            isPortal={isPortal}
            onClose={closeModal}
            symbol={currencyPair.symbol || symbol}
            settlementDate={currencyPair.settlementDate}
            fixingDate={currencyPair.fixingDate}
            order={orderState.order}
            cardState={cardState}
            saveSettings={saveSettings}
            onClickCurrencyButton={() => setIsEditingCurrencyPair(true)}
          />
          {marketSettings.showPriceTrendAndSpread && (
            <PriceInfo
              bidPriceTrend={bidPriceTrend}
              askPriceTrend={askPriceTrend}
              priceData={topOfBook}
              pip={pip}
              spreadFormat={marketSettings.spreadValue}
              currencySelected={cardState.currencySelected}
              symbol={symbol}
            />
          )}
          <PriceRow
            readOnly={readOnly}
            bidPriceNum={topOfBook && topOfBook.bidPriceNum}
            bidPriceObj={topOfBook && topOfBook.bidPriceObj}
            askPriceNum={topOfBook && topOfBook.askPriceNum}
            askPriceObj={topOfBook && topOfBook.askPriceObj}
            handleQuickTrade={handleQuickTrade}
            clickType={marketSettings.clickType}
            isCrypto={topOfBook.isCrypto}
            quantity={cardState.quantity}
          />

          <div className={clsx("noDrag", "actions")}>
            <div
              onContextMenu={event => {
                event.preventDefault();
                !readOnly && setFlywheelPriceBuy(topOfBook?.askPriceNum);
              }}
            >
              <Button
                data-testid="currency_bid_button"
                className="bid"
                size="small"
                disabled={readOnly || !currencyPair.bestBidPrice}
                type="ghost"
                onClick={() => {
                  const cardInfo = {
                    ...cardState,
                    side: OrderSide.Buy,
                    type: OrderType.Limit,
                    price: currencyPair.bestBidPrice,
                  };
                  closeModal();
                  setTimeout(() => {
                    setAuctionCard({ ...cardInfo, instrumentId: symbol });
                    setActiveCard(cardRef);
                    setActiveCardSide("buy");
                  }, 0);
                }}
              >
                {OrderSideLabel.Buy}
              </Button>
            </div>

            <div ref={flywheelElementBuy}>
              {!!flywheelPriceBuy && (
                <FlywheelMenu
                  basePrice={flywheelPriceBuy}
                  orderSide={OrderSide.Buy}
                  origin={OrderSide.Buy} // Sets the position to the left side.
                  quantity={cardState.quantity}
                  actionOrder={handleFlywheelTrade}
                  instrumentId={symbol}
                  pip={pip}
                  increment={cardState.flywheelIncrement}
                  clickType={marketSettings.clickType}
                  testid={"bid_flywheel"}
                ></FlywheelMenu>
              )}
            </div>

            <div className="tooltipWrapper">
              <Input
                data-testid="currency_size_input"
                type="text"
                className="quantityInput"
                size="small"
                value={addCommas(cardState.quantity)}
                disabled={
                  orderRibbonSettings &&
                  orderRibbonSettings.showOrderRibbon &&
                  isNaN(orderRibbonSettings.selectedDefaultQuantityIndex) === false &&
                  orderRibbonSettings.orderSizes.includes(selectedDefaultQuantity)
                }
                onKeyDown={(e: any) => priceFormatValidator(e, e.target.value)}
                onChange={(e: any) => {
                  const newQuantity = removeCommasAndAddMultiplierShortcuts(e.target.value);
                  saveSettings(newQuantity, "quantity");
                }}
                onFocus={event => event.target.select()}
                onBlur={event => {
                  // Do not allow decimals for non-cryptos, among other things
                  let quantity = formatSizeBasedOnSettings(
                    event.target.value,
                    orderRibbonSettings,
                    currencySettingsBySymbol,
                    marketSettings.notional,
                    symbol
                  );

                  saveSettings(quantity, "quantity");
                }}
              />
              <div className="tooltip">
                <div className="tooltipTextContainer">Order Size</div>
              </div>
            </div>

            <div
              onContextMenu={event => {
                event.preventDefault();
                !readOnly && setFlywheelPriceSell(topOfBook?.bidPriceNum);
              }}
            >
              <Button
                data-testid="currency_offer_button"
                size="small"
                type="ghost"
                disabled={readOnly || !currencyPair.bestAskPrice}
                className="offer"
                onClick={() => {
                  const cardInfo = {
                    ...cardState,
                    side: OrderSide.Sell,
                    type: OrderType.Limit,
                    price: currencyPair.bestAskPrice,
                  };
                  closeModal();
                  setTimeout(() => {
                    setAuctionCard({ ...cardInfo, instrumentId: symbol });
                    setActiveCard(cardRef);
                    setActiveCardSide("sell");
                  }, 0);
                }}
              >
                {OrderSideLabel.Sell}
              </Button>
            </div>

            <div ref={flywheelElementSell}>
              {!!flywheelPriceSell && (
                <FlywheelMenu
                  basePrice={flywheelPriceSell}
                  orderSide={OrderSide.Sell}
                  origin={OrderSide.Sell} // Sets the position to the right side.
                  quantity={cardState.quantity}
                  actionOrder={handleFlywheelTrade}
                  instrumentId={symbol}
                  pip={pip}
                  clickType={marketSettings.clickType}
                  increment={cardState.flywheelIncrement}
                  testid={"offer_flywheel"}
                ></FlywheelMenu>
              )}
            </div>
          </div>

          {marketSettings.showSlippage && (
            <div className="slippage">
              <Button
                data-testid="currency_slippage_currency_select"
                className="noDrag slippageButton"
                size="small"
                onClick={() => setShowCurrencySlippageDropdown(!showCurrencySlippageDropdown)}
              >
                <span className="selectedCurrency">{cardState.currencySelected}</span>
                {Icon({ type: "caret-down" })}
              </Button>
              {showCurrencySlippageDropdown && (
                <div className="dropDownSection" ref={currencySlippageSelectRef}>
                  <div className="dropdownContent slippageDropdown noDrag" tabIndex={0}>
                    <Menu className="cardOverflow">
                      <Menu.Item>
                        <Radio.Group
                          onChange={event => {
                            saveSettings(event.target.value, "currencySelected");
                            setShowCurrencySlippageDropdown(!showCurrencySlippageDropdown);
                          }}
                          value={cardState.currencySelected}
                        >
                          {currencies &&
                            currencies.map((option, i) => (
                              <Radio
                                key={`${option}.${i}`}
                                value={option}
                                disabled={product === ProductType.Ndf}
                                // making radio buttons look like select
                                className={clsx(
                                  "ant-select-dropdown-menu-item",
                                  cardState.currencySelected === option && "ant-select-dropdown-menu-item-selected",
                                  product === ProductType.Ndf && "ant-radio-disabled-option"
                                )}
                              >
                                {option}
                              </Radio>
                            ))}
                        </Radio.Group>
                      </Menu.Item>
                    </Menu>
                  </div>
                </div>
              )}

              <div className="tooltipWrapper tooltipWrapper--wide noDrag">
                <InputNumber
                  data-testid="currency_slippage_input"
                  type="text"
                  size="small"
                  step={0}
                  value={cardState.slippage}
                  onFocus={event => {
                    if (cardState.slippage === 0) {
                      // highlight text if 0 so it gets replaced
                      event.target.select();
                    }
                  }}
                  onKeyDown={event => incrementsFieldHandler(event, "slippage")}
                  onChange={value => setCardState({ ...cardState, slippage: value })}
                  onBlur={() => saveCardSettings(["slippage"])}
                />
                <div className="tooltip">
                  <div className="tooltipTextContainer">Slippage</div>
                </div>
              </div>

              <Button
                data-testid="currency_slippage_decrease_button"
                size="small"
                className={clsx("noDrag", "slippageIncrementer")}
                onClick={() => handleIncrementDecrement("decrement", settings.marketSettings.slippageLeftIncrement)}
                onContextMenu={event => {
                  event.preventDefault();
                  handleIncrementDecrement("decrement", settings.marketSettings.slippageRightIncrement);
                }}
              >
                <RemoveIcon />
              </Button>
              <Button
                data-testid="currency_slippage_increase_button"
                size="small"
                className={clsx("noDrag", "slippageIncrementer")}
                onClick={() => handleIncrementDecrement("increment", settings.marketSettings.slippageLeftIncrement)}
                onContextMenu={event => {
                  event.preventDefault();
                  handleIncrementDecrement("increment", settings.marketSettings.slippageRightIncrement);
                }}
              >
                <AddIcon />
              </Button>
            </div>
          )}
        </div>{" "}
        {/* .currencyMeta */}
        {cantorAlgoEnabled && (
          <HotButtonRow
            readOnly={readOnly}
            bidPriceNum={topOfBook && topOfBook.bidPriceNum}
            bidPriceObj={topOfBook && topOfBook.bidPriceObj}
            askPriceNum={topOfBook && topOfBook.askPriceNum}
            askPriceObj={topOfBook && topOfBook.askPriceObj}
            handleHotButtonTrade={handleHotButtonTrade}
            clickType={marketSettings.clickType}
            // HEREX
            // cardOffset={cardState.slippage}
            cardOffset={cardState.hotButtonIncrement}
            quantity={cardState.quantity}
          />
        )}
        <PriceTable
          book={priceTableBook}
          clickType={marketSettings.clickType}
          depth={marketSettings.depthDisplay}
          depthTrading={marketSettings.enableDepthOfBookTrading && !readOnly}
          displaySpreadOnDepthOfBook={marketSettings.displaySpreadOnDepthOfBook}
          handleQuickTrade={handleQuickTrade}
          pip={pip}
          spreadFormat={marketSettings.spreadValue}
        />
      </div>
    </>
  );
}

export default connect(
  (state, ownProps: any) => {
    const readOnly = getOrdersReadOnly(state);
    const isScreenLocked = getIsScreenLocked(state);
    return {
      symbol: ownProps.symbolId,
      settings: getSettings(state),
      // ordersSettings: getOrdersSettings(state),
      currencyPair: currencyPairByIdSelector(state, ownProps.symbolId),
      currencySettingsBySymbol: getCurrencySettingsBySymbol(state, ownProps.symbolId) || {},
      pip: pipForSymbol(state, ownProps.symbolId),
      readOnly: readOnly || isScreenLocked,
      cantorAlgoEnabled: getCantorAlgoEnabled(state),
    };
  },
  {
    dockCard,
    setAuctionCard,
    setCurrentPrice,
    cancelOrder: cancelOrderAction,
    submitNewOrder,
    saveCurrencySettings,
    currencyPairRemoved,
    currencyPairsToggled,
  }
)(CurrencyCard);

const CardHeader = ({
  actions,
  cardState,
  fixingDate,
  saveSettings,
  settlementDate,
  symbol,
  onClickCurrencyButton,
}: any) => {
  const [showOverflowDropdown, setShowOverflowDropdown] = useState<boolean>(false);
  const cardOverflowDropdownRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(cardOverflowDropdownRef, () => setShowOverflowDropdown(false), symbol);
  const product = isNDFCurrency(symbol) ? ProductType.Ndf : ProductType.Spot;

  return (
    <div className="cardHeader">
      <div className="currency" data-testid="currency_title_button">
        <Button
          className={clsx("noDrag", "currencyButton")}
          size="default"
          type="default"
          onClick={onClickCurrencyButton}
        >
          <span className="currencyCode">{strippedCurrencyLabel(symbol)}</span>
        </Button>
        <span className={"currencyInstrumentLabel"}>{displayInstrumentLabel(symbol)}</span>
      </div>
      <div className="date">
        <span data-testid="currency_fixing_date">
          {fixingDate
            ? fixingDate !== 0
              ? moment(fixingDate)
                  .utc()
                  .format("DDMMM")
                  .toUpperCase() + "/"
              : ""
            : ""}
        </span>
        <span data-testid="currency_settlement_date">
          {/* {console.log(settlementDate)} */}
          {settlementDate
            ? moment(settlementDate)
                .utc()
                .format("DDMMM")
                .toUpperCase()
            : ""}
        </span>
      </div>
      {actions.length === 0 ? (
        <></>
      ) : (
        // `dropdownWrapper` is needed for `useWindowClick` to work.
        <div className="dropdownWrapper">
          <Button
            data-testid="currency_overflow_button"
            className="noDrag cardOverflowButton"
            size="small"
            onClick={() => setShowOverflowDropdown(!showOverflowDropdown)}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.8">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.5 7C13.5 7.82843 12.8284 8.5 12 8.5C11.1716 8.5 10.5 7.82843 10.5 7C10.5 6.17157 11.1716 5.5 12 5.5C12.8284 5.5 13.5 6.17157 13.5 7ZM13.5 12C13.5 12.8284 12.8284 13.5 12 13.5C11.1716 13.5 10.5 12.8284 10.5 12C10.5 11.1716 11.1716 10.5 12 10.5C12.8284 10.5 13.5 11.1716 13.5 12ZM12 18.5C12.8284 18.5 13.5 17.8284 13.5 17C13.5 16.1716 12.8284 15.5 12 15.5C11.1716 15.5 10.5 16.1716 10.5 17C10.5 17.8284 11.1716 18.5 12 18.5Z"
                  fill="currentColor"
                />
              </g>
            </svg>
          </Button>
          {showOverflowDropdown && (
            <div className="dropDownSection" ref={cardOverflowDropdownRef}>
              <div className="dropdownContent noDrag" tabIndex={0}>
                <Menu className="cardOverflow">
                  <Menu.Item>
                    <Radio.Group
                      onChange={event => {
                        saveSettings(event.target.value, "currencySelected");
                        setShowOverflowDropdown(!showOverflowDropdown);
                      }}
                      value={cardState.currencySelected}
                    >
                      {ndfInstrumentStripper(symbol)
                        .split("/")
                        .map((option, i) => {
                          return (
                            <Radio
                              key={`${option}.${i}`}
                              value={option}
                              disabled={product === ProductType.Ndf}
                              // making radio buttons look like select
                              className={clsx(
                                "ant-select-dropdown-menu-item",
                                cardState.currencySelected === option && "ant-select-dropdown-menu-item-selected",
                                product === ProductType.Ndf && "ant-radio-disabled-option"
                              )}
                            >
                              {option}
                            </Radio>
                          );
                        })}
                    </Radio.Group>
                  </Menu.Item>
                  <Menu.Divider />
                  {actions.map((action, index) => {
                    return (
                      <Menu.Item
                        key={`${action.displayValue}.${index}`}
                        onClick={() => {
                          action.action();
                          setShowOverflowDropdown(!showOverflowDropdown);
                        }}
                      >
                        {action.displayValue}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// Limits the length of a given value to avoid overrunning allocated screen space.
const shortenValue = (value: string, maxLength: number = 8) => {
  if (value.length <= maxLength) {
    return value;
  }

  const valueInt = parseInt(value, 10);
  const valueFloat = parseFloat(value);
  let maximumFractionDigits = Math.max(0, maxLength - 1 - valueInt.toString().length);
  if (valueFloat < 0 && maximumFractionDigits >= 1) {
    maximumFractionDigits -= 1;
  }
  const newValue = valueFloat.toLocaleString(appLocale, {
    minimumFractionDigits: 0,
    maximumFractionDigits,
  });
  return newValue === "-0" ? "0" : newValue;
};

const PriceInfo = ({ bidPriceTrend, askPriceTrend, priceData, pip, spreadFormat, currencySelected, symbol }) => {
  const baseCurrency = symbol.split("/")[0];

  const bidPrice = priceData.bidPriceNum || 0;
  const askPrice = priceData.askPriceNum || 0;
  const safeAskPrice = Big(askPrice);
  let spreadValue = safeAskPrice.minus(bidPrice).toString();
  const showSpread = bidPrice && askPrice && !isNaN(parseFloat(spreadValue));
  if (showSpread) {
    if (spreadFormat === SpreadValue.Pip) {
      spreadValue = spreadToPipCalculator(spreadValue, pip);
    }
    spreadValue = shortenValue(spreadValue);
  }

  return (
    <div className="priceInfo">
      <div className="side">
        <div className="indicatorContainer left">
          <PriceDirectionIndicator trend={bidPriceTrend} data-testid="currency_trending_bid_arrow" />
        </div>
        <span className="leftLabel">
          {currencySelected === baseCurrency ? "SELL " : "BUY "}
          {currencySelected}
        </span>
      </div>

      <div className="spread" data-testid="currency_spread">
        {showSpread ? spreadValue : <>&mdash;</>}
      </div>

      <div className="side">
        <span className="rightLabel">
          {currencySelected === baseCurrency ? "BUY " : "SELL "}
          {currencySelected}
        </span>
        <div className="indicatorContainer right">
          <PriceDirectionIndicator trend={askPriceTrend} data-testid="currency_trending_offer_arrow" />
        </div>
      </div>
    </div>
  );
};

const PriceRow = ({
  askPriceObj,
  askPriceNum,
  bidPriceObj,
  bidPriceNum,
  handleQuickTrade,
  clickType,
  readOnly,
  isCrypto,
  quantity,
}) => {
  // check
  return (
    <div className="priceRow noDrag">
      <PriceButton
        testid={"currency_top_bid_button"}
        isTradingEnabled={!readOnly}
        priceObj={bidPriceObj}
        clickType={clickType}
        onQuickTrade={() => handleQuickTrade(bidPriceNum, OrderSide.Sell)}
        size="lg"
        isCrypto={isCrypto}
        quantity={quantity}
      />
      <PriceButton
        testid={"currency_top_offer_button"}
        isTradingEnabled={!readOnly}
        priceObj={askPriceObj}
        clickType={clickType}
        onQuickTrade={() => handleQuickTrade(askPriceNum, OrderSide.Buy)}
        size="lg"
        isCrypto={isCrypto}
        quantity={quantity}
      />
    </div>
  );
};

const HotButtonRow = ({
  askPriceObj,
  askPriceNum,
  bidPriceObj,
  bidPriceNum,
  handleHotButtonTrade,
  clickType,
  readOnly,
  cardOffset,
  quantity,
}) => {
  return (
    <div className="hotButtonRow noDrag">
      <HotButton
        side={OrderSide.Buy}
        isTradingEnabled={!readOnly}
        priceObj={bidPriceObj}
        onClickHotButton={() => handleHotButtonTrade(bidPriceNum, OrderSide.Buy)}
        clickType={clickType}
        testid={"bid_hot_button"}
        offset={cardOffset}
        quantity={quantity}
      />
      <HotButton
        side={OrderSide.Sell}
        isTradingEnabled={!readOnly}
        priceObj={askPriceObj}
        onClickHotButton={() => handleHotButtonTrade(askPriceNum, OrderSide.Sell)}
        clickType={clickType}
        testid={"offer_hot_button"}
        offset={cardOffset}
        quantity={quantity}
      />
    </div>
  );
};

const PriceDirectionIndicator = ({ trend }) =>
  trend === true ? (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.21025 0.399294C6.61513 -0.133098 7.38487 -0.133098 7.78975 0.399294L13.7765 8.27146C14.3062 8.96807 13.8348 10 12.9867 10H1.01328C0.16523 10 -0.306247 8.96807 0.223523 8.27146L6.21025 0.399294Z"
        fill="#1EE3CF"
      />
    </svg>
  ) : trend === false ? (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.78975 9.60071C7.38487 10.1331 6.61513 10.1331 6.21025 9.60071L0.223524 1.72854C-0.306246 1.03193 0.16523 -1.20947e-06 1.01328 -1.13534e-06L12.9867 -8.85834e-08C13.8348 -1.44448e-08 14.3062 1.03193 13.7765 1.72854L7.78975 9.60071Z"
        fill="#FF6768"
      />
    </svg>
  ) : null;

const PriceTable = ({
  book,
  depth,
  depthTrading,
  clickType,
  handleQuickTrade,
  displaySpreadOnDepthOfBook,
  spreadFormat,
  pip,
}) => (
  <div className="priceTable">
    {/* <tbody> */}
    {depth > 0 &&
      book.map((row, i) => (
        <PriceTableRow
          key={i}
          rowId={i}
          row={row}
          clickType={clickType}
          handleQuickTrade={handleQuickTrade}
          depthTrading={depthTrading}
          displaySpreadOnDepthOfBook={displaySpreadOnDepthOfBook}
          pip={pip}
          spreadFormat={spreadFormat}
        />
      ))}
    {/* </tbody> */}
  </div>
);

const PriceTableRow = ({
  rowId,
  row,
  clickType,
  handleQuickTrade,
  depthTrading,
  displaySpreadOnDepthOfBook,
  spreadFormat,
  pip,
}) => {
  const bidPrice = row.bidPriceNum || 0;
  const askPrice = row.askPriceNum || 0;
  const safeAskPrice = Big(askPrice);
  let spreadValue = safeAskPrice.minus(bidPrice).toString();
  const showSpread = bidPrice && askPrice && !isNaN(parseFloat(spreadValue));
  if (showSpread) {
    if (spreadFormat === SpreadValue.Pip) {
      spreadValue = spreadToPipCalculator(spreadValue, pip);
    }
    spreadValue = shortenValue(spreadValue);
  }

  return (
    <div className={"priceTableRow"} data-testid={`currency_depth_row_${rowId}`}>
      {/* {displaySpreadOnDepthOfBook && (
        <div className={"priceTableRowSpreadValue"} data-testid={`currency_depth_spread_${rowId}`}>
          {showSpread ? spreadValue : <>&mdash;</>}
        </div>
      )} */}
      <div className={"rowData"}>
        <div className="volumeCol left">
          <div className={"rowWrapper"}>
            <SizeButton
              testid={`currency_depth_bid_size_button${rowId}`}
              depthTradingEnabled={depthTrading}
              priceNum={row.bidPriceNum}
              quantity={row.bidSize}
              size={"sm"}
              clickType={clickType}
              onQuickTrade={() => handleQuickTrade(row.bidPriceNum, OrderSide.Sell, row.bidSize)}
            />
          </div>
        </div>

        <div className={clsx("priceContainer left", depthTrading && "noDrag")}>
          <div className={"rowWrapper"}>
            {row && row.bidPriceObj ? (
              <PriceButton
                testid={`currency_depth_bid_price_button${rowId}`}
                isTradingEnabled={depthTrading}
                priceObj={row.bidPriceObj}
                clickType={clickType}
                onQuickTrade={() => handleQuickTrade(row.bidPriceNum, OrderSide.Sell, row.bidSize)}
                isCrypto={row.isCrypto}
              />
            ) : (
              <span className={clsx("numberSm")}>&mdash;</span>
            )}
          </div>
        </div>

        {displaySpreadOnDepthOfBook && (
          <div className={"priceTableRowSpreadValue"} data-testid={`currency_depth_spread_${rowId}`}>
            {showSpread ? spreadValue : <>&mdash;</>}
          </div>
        )}

        <div className={clsx("priceContainer right", depthTrading && "noDrag")}>
          <div className={"rowWrapper"}>
            {row && row.askPriceObj ? (
              <PriceButton
                testid={`currency_depth_offer_price_button${rowId}`}
                isTradingEnabled={depthTrading}
                priceObj={row.askPriceObj}
                clickType={clickType}
                onQuickTrade={() => handleQuickTrade(row.askPriceNum, OrderSide.Buy, row.askSize)}
                isCrypto={row.isCrypto}
              />
            ) : (
              <span className={clsx("numberSm")}>&mdash;</span>
            )}
          </div>
        </div>
        <div className="volumeCol right">
          <div className={"rowWrapper"}>
            <SizeButton
              testid={`currency_depth_offer_size_button${rowId}`}
              depthTradingEnabled={depthTrading}
              priceNum={row.askPriceNum}
              quantity={row.askSize}
              size={"sm"}
              clickType={clickType}
              onQuickTrade={() => handleQuickTrade(row.askPriceNum, OrderSide.Buy, row.askSize)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

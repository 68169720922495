import React from "react";
import clsx from "clsx";

import styles from "./SelectionList.module.scss";

type SelectionListProps = {
  list: any;
  onSelect: any;
  onDeselect?: any;
  style: any;
};
export const SelectionList = ({ list, onSelect, onDeselect, style }: SelectionListProps) => {
  return (
    <ul className={styles.list} style={style}>
      {list.length > 0 ? (
        list.map((item, i) => (
          <li
            key={`${item.index}.${i}`}
            className={clsx(styles.currencyPairItem)}
            onClick={() =>
              item.isSelected ? (onDeselect ? onDeselect(item) : null) : onSelect ? onSelect(item) : null
            }
          >
            <span className={clsx(styles.bullet, !item.isSelected && styles.itemUnchecked)}>•</span>
            <span>{item.value}</span>
          </li>
        ))
      ) : (
        <div className={styles.noMatchDiv}>We couldn’t find a match for this currency.</div>
      )}
    </ul>
  );
};

import React from "react";
import { Button, Icon, Radio, Select } from "antd";
import Big from "big.js";
import clsx from "clsx";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  addCommas,
  formatSizeBasedOnSettings,
  isValidTimestamp,
  ndfInstrumentStripper,
  positiveNumberEnforcer,
  priceFormatValidator,
  removeCommasAndAddMultiplierShortcuts,
} from "../../../lib/formatUtil";
import {
  allowOnlyNumbers,
  calculateSlippageToPip,
  convertToNumber,
  getPriceStringWithPrecision,
  isNDFCurrency,
  minValueZero,
  moveCursorToEnd,
  percentValue,
  useWindowEnter,
  zeroToNinetyNine,
} from "../../../lib/util";
import { refreshAuctionCardPrice, setAuctionCard } from "../../../state/currency/currencyActions";
import { getAuctionCardDetailByIdSelector } from "../../../state/currency/currencyState";
import { pipForSymbol } from "../../../state/currency/symbols/symbolsState";
import {
  cancelOrderAction,
  orderByIdSelector,
  submitNewOrder,
  submitReplaceOrder,
} from "../../../state/orders/ordersState";
import {
  ClosedOrderStatus,
  OrderLifespan,
  OrderModel,
  OrderSide,
  OrderStrategy,
  OrderType,
  PriceType,
  ProductType,
  ReplaceOrderModel,
} from "../../../state/orders/types";
import { setAuctionCardStyle } from "../../../state/settings/settingsActions";
import {
  getCurrencySettingsBySymbol,
  getDefaultOrderQuantitySettings,
  getSettings,
} from "../../../state/settings/settingsState";
import Order from "../../orders/Order";
import AuctionCardHeader from "./AuctionCardHeader";
import styles from "./AuctionOrderForm.module.scss";

import { algoOrderFields, commonFields, FieldType, lastFields, OrderFieldsEnum, pegFields } from "./fields";
import {
  basicUrgencyOptions,
  orderSideOptions,
  startTimeOptions,
  startTimeOptionsBfix,
  urgencyOptions,
} from "./selectOptions";
import { CommonStrategyFields, StrategyMapper } from "./strategies";
import { maxTextAreaChars, nextCalendarDateText, startTimeEODRecycle } from "../../../lib/constants";
import InputTextArea from "./common/InputTextArea/InputTextArea";
import PriceAdjusterButtons from "./common/PriceAdjusterButtons";
// import { trade } from "@lume/lume-gatekeeper/dist/providers";

type AuctionCardProps = {
  cancelOrderAction: (orderId: string) => void;
  currencySelected: any;
  currencySettingsBySymbol: any;
  editing: boolean;
  instrumentId: string;
  instrumentPipValue: number;
  marketDataDown: boolean;
  order: any;
  price: number;
  quantity: any;
  refreshAuctionCardPrice: (auctionOrderState) => void;
  selectedDefaultQuantity: any;
  setAuctionCard: any;
  settings: any;
  side: any;
  slippage: number;
  submitNewOrder: (orders: OrderModel, localOrderData: any, includeDates: boolean) => void;
  submitReplaceOrder: (order: ReplaceOrderModel) => void;
  type: string;
  activeCard: any;
  setActiveCard: any;
  activeCardSide: string;
  setActiveCardSide: any;
  currencyPair: any;
};

type AuctionCardState = {
  currency: string;
  endTime: string | null;
  isNextCalendarDateEndTime: boolean;
  instrumentId: string;
  lifespan: string;
  lowerBandPrice: number | null;
  lowerBandTargetPercentVolume: number | null;
  maxParticipationRate: number | null;
  maximumFloor: number | null;
  minimumExecutedQuantity: number | null;
  pegFloorFloatingPoints: number | null;
  pegFloorPrice: number | null;
  pegFloorType: number | null;
  pegOffset: number | null;
  pegReferencePriceType: string | null;
  price: string; // using string to allow for price to be formatted on load
  marketPrice: string;
  stopLossPrice: string;
  priceType: number;
  slippage: string;
  product: string;
  quantity: number;
  randomizationPercent: number | null;
  sliceInterval: number | null;
  sliceSize: number | null;
  side: string;
  startTime: string;
  isNextCalendarDateStartTime: boolean;
  // stopLossPrice: number | null;
  strategy: string | null;
  strategyConfig: string | null;
  targetParticipationRate: number | null;
  type: string;
  upperBandPrice: number | null;
  upperBandTargetPercentVolume: number | null;
  urgency: number;
  wouldPrice: string | null;
  wouldSizePercent: number | null;
};

function AuctionOrderForm({
  cancelOrderAction,
  currencySelected,
  currencySettingsBySymbol,
  editing,
  instrumentId,
  instrumentPipValue,
  marketDataDown,
  order,
  price,
  quantity,
  refreshAuctionCardPrice,
  selectedDefaultQuantity,
  setAuctionCard,
  settings,
  side,
  slippage,
  submitNewOrder,
  submitReplaceOrder,
  setActiveCardSide,
  currencyPair,
}: AuctionCardProps) {
  const { marketSettings, orderRibbonSettings, ordersSettings } = settings;
  const enterKeyNotifyObject = useWindowEnter();
  // const productValue = instrumentId ? (isNDFCurrency(instrumentId) ? ProductType.Ndf : ProductType.Spot) : "";

  const quarterMillion = 250000;
  const oneMillion = 1000000;

  const [orderType, setOrderType] = useState(order ? order?.type : ordersSettings?.defaultPassiveOrderType);

  const initialAuctionOrderStateTime = {
    startTime: moment()
      .tz("America/New_York")
      .format("YYYY/MM/DD"),
    startTimeTime: "",
    endTime: moment() // need an API for this
      .tz("America/New_York")
      .endOf("day")
      .format("YYYY/MM/DD"),
    endTimeTime: "",
    isNextCalendarDateStartTime: false,
    isNextCalendarDateEndTime: false,
  };

  const [auctionOrderStateTime, setAuctionOrderStateTime] = useState<any>({
    [OrderType.Market]: initialAuctionOrderStateTime,
    [OrderType.Stop]: initialAuctionOrderStateTime,
    [OrderType.Limit]: initialAuctionOrderStateTime,
    [OrderType.StopLimit]: initialAuctionOrderStateTime,
    [OrderType.Oco]: initialAuctionOrderStateTime,
  });

  const initialAuctionOrderState = {
    currency: currencySelected,
    endTime: "",
    instrumentId,
    lifespan: OrderLifespan.Day,
    lowerBandPrice: null,
    lowerBandTargetPercentVolume: null,
    marketPrice: order ? "" : getPriceStringWithPrecision(price, instrumentId, true),
    maxParticipationRate: null,
    maximumFloor: 0,
    minimumExecutedQuantity: null,
    pegFloorFloatingPoints: null,
    pegFloorPrice: null,
    pegFloorType: null,
    pegOffset: null,
    pegReferencePriceType: null,
    // Third argument is noCommas. In this case we don't want to add commas to the price's integer part
    price: order ? "" : getPriceStringWithPrecision(price, instrumentId, true),
    priceType: PriceType.Limit,
    slippage: "0",
    product: instrumentId && isNDFCurrency(instrumentId) ? ProductType.Ndf : ProductType.Spot,
    quantity: selectedDefaultQuantity ? parseFloat(selectedDefaultQuantity) * 1000000 : order ? "" : quantity,
    randomizationPercent: null,
    side,
    sliceInterval: null,
    sliceSize: null,
    startTime: "",
    stopLossPrice: order ? "" : getPriceStringWithPrecision(price, instrumentId, true),
    strategy: null,
    strategyConfig: order ? order.strategyConfig : "",
    targetParticipationRate: null,
    // type: order ? order.type : type,
    upperBandPrice: null,
    upperBandTargetPercentVolume: null,
    urgency: 0,
    wouldPrice: null,
    wouldSizePercent: null,
  };

  // HERE: instead of "any" it should be "object of AuctionCardState"
  const [auctionOrderState, setAuctionOrderState] = useState<any>({
    [OrderType.Market]: initialAuctionOrderState,
    [OrderType.Stop]: initialAuctionOrderState,
    [OrderType.Limit]: initialAuctionOrderState,
    [OrderType.StopLimit]: initialAuctionOrderState,
    [OrderType.Oco]: initialAuctionOrderState,
  });

  const [isSubmitExecuting, setIsSubmitExecuting] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [lifespanOptions, setLifespanOptions] = useState<any>({
    [OrderType.Market]: [],
    [OrderType.Stop]: [],
    [OrderType.Limit]: [],
    [OrderType.StopLimit]: [],
    [OrderType.Oco]: [],
  });
  const [isPegFloorFloatingPointsDisabled, setIsPegFloorFloatingPointsDisabled] = useState<any>({
    [OrderType.Market]: true,
    [OrderType.Stop]: true,
    [OrderType.Limit]: true,
    [OrderType.StopLimit]: true,
    [OrderType.Oco]: true,
  });

  const currencies = instrumentId ? ndfInstrumentStripper(instrumentId).split("/") : [];
  const currencyObject = currencies.map(currency => {
    return {
      label: currency,
      value: currency,
    };
  });

  const setContentToMaxChars = text => {
    setAuctionOrderState(prevState => ({
      ...prevState,
      [orderType]: {
        ...prevState[orderType],
        strategyConfig: text.slice(0, maxTextAreaChars),
      },
    }));
  };

  const auctionOrderStateStrategy = auctionOrderState[orderType]?.strategy;
  const auctionOrderStateUrgency = auctionOrderState[orderType]?.urgency;
  const auctionOrderStateStartTime = auctionOrderStateTime[orderType]?.startTime;
  const auctionOrderStateStartTimeTime = auctionOrderStateTime[orderType]?.startTimeTime;

  const useInitializeFormEffect = (dependencies, orderTypeVal) => {
    useEffect(() => {
      // console.log("USE EFFECT 1");

      // We moved this here, before the return, so the lifespan options are populated also the first time is rendered (when no strategy is selected)
      // Set order lifespan based on strategy.
      const lifeSpanOptionsList = [OrderStrategy.Sor, OrderStrategy.Iceberg].includes(
        auctionOrderStateStrategy as OrderStrategy
      )
        ? [
            {
              label: "Day",
              value: OrderLifespan.Day,
            },

            {
              label: "IOC",
              value: OrderLifespan.IOC,
            },
          ]
        : [
            {
              label: "Day",
              value: OrderLifespan.Day,
            },
          ];

      setLifespanOptions(prevState => ({
        ...prevState,
        [orderTypeVal]: lifeSpanOptionsList,
      }));

      const lifeSpanOptionsListValues = lifeSpanOptionsList.map(lifespanOption => lifespanOption.value);

      let lifespan = order
        ? order.lifespan?.toLowerCase()
        : orderRibbonSettings?.selectedDefaultOrderLifespan?.length > 0
        ? orderRibbonSettings?.selectedDefaultOrderLifespan?.[0]
        : ordersSettings.defaultPassiveOrderLifespan || auctionOrderState[orderTypeVal]?.lifespan;

      lifespan = lifeSpanOptionsListValues?.includes(lifespan) ? lifespan : OrderLifespan.Day;

      // If there is no strategy in the state: it's the first time we open the form or we select "Select..."
      if (!auctionOrderStateStrategy) {
        return;
      }

      const strategyIsIceberg = auctionOrderStateStrategy === OrderStrategy.Iceberg;
      const strategyIsBfix = auctionOrderStateStrategy === OrderStrategy.Bfix;
      const strategyIsFixing = auctionOrderStateStrategy === OrderStrategy.Fixing;

      // Update the order's Type, Urgency and Lifespan fields
      const changes = {
        // HERE: Iceberg only has or had the "limit" option
        // [OrderFieldsEnum.type]: strategyIsIceberg
        //   ? orderTypeVal.Limit
        //   : order
        //   ? order.type
        //   : strategyIsBfix || strategyIsFixing
        //   ? orderTypeVal.Market // Default to Market for BFIX and WMFIX.
        //   : ordersSettings.defaultPassiveOrderType,
        [OrderFieldsEnum.urgency]: !auctionOrderStateUrgency
          ? [OrderStrategy.Twap, OrderStrategy.Vwap, OrderStrategy.Bfix, OrderStrategy.Fixing].includes(
              auctionOrderStateStrategy as OrderStrategy
            )
            ? 2
            : 3
          : auctionOrderStateUrgency,
        [OrderFieldsEnum.lifespan]: lifespan,
      };

      setAuctionOrderState(prevState => ({
        ...prevState,
        [orderTypeVal]: {
          ...prevState[orderTypeVal],
          ...changes,
        },
      }));

      // If the strategy is BFIX or WMFIX, we are using a dropdown for the start time and have to
      // default to the next available time (equal to or after the "current" time). Or if we're just
      // editing an order, then we need to leave the start time alone (not bump it forward).
      if (strategyIsBfix || strategyIsFixing) {
        const currentTime = auctionOrderStateStartTimeTime
          ? moment(`${auctionOrderStateStartTimeTime}`, "HH:mm:ss", true)
          : moment();
        const timeOptions = strategyIsBfix ? startTimeOptionsBfix : startTimeOptions;
        const timeFound = timeOptions.find(option => {
          const optionTime = moment(option.value, "HH:mm:ss");
          return currentTime.isSameOrBefore(optionTime);
        });

        setAuctionOrderStateTime(prevState => ({
          ...prevState,
          [orderTypeVal]: {
            ...prevState[orderTypeVal],
            startTimeTime: timeFound?.value ?? "",
          },
        }));

        checkNextCalendarDateStartTime(OrderFieldsEnum.startTimeTime, timeFound?.value);
      }
    }, dependencies);
  };

  useInitializeFormEffect(
    [
      auctionOrderStateTime[OrderType.Market]?.startTime,
      auctionOrderState[OrderType.Market]?.strategy,
      auctionOrderState[OrderType.Market]?.quantity,
      auctionOrderState[OrderType.Market]?.urgency,
      order,
      ordersSettings.defaultPassiveOrderType,
      ordersSettings.defaultPassiveOrderLifespan,
      orderRibbonSettings,
    ],
    OrderType.Market
  );
  useInitializeFormEffect(
    [
      auctionOrderStateTime[OrderType.Stop]?.startTime,
      auctionOrderState[OrderType.Stop]?.strategy,
      auctionOrderState[OrderType.Stop]?.quantity,
      auctionOrderState[OrderType.Stop]?.urgency,
      order,
      ordersSettings.defaultPassiveOrderType,
      ordersSettings.defaultPassiveOrderLifespan,
      orderRibbonSettings,
    ],
    OrderType.Stop
  );
  useInitializeFormEffect(
    [
      auctionOrderStateTime[OrderType.Limit]?.startTime,
      auctionOrderState[OrderType.Limit]?.strategy,
      auctionOrderState[OrderType.Limit]?.quantity,
      auctionOrderState[OrderType.Limit]?.urgency,
      order,
      ordersSettings.defaultPassiveOrderType,
      ordersSettings.defaultPassiveOrderLifespan,
      orderRibbonSettings,
    ],
    OrderType.Limit
  );
  useInitializeFormEffect(
    [
      auctionOrderStateTime[OrderType.StopLimit]?.startTime,
      auctionOrderState[OrderType.StopLimit]?.strategy,
      auctionOrderState[OrderType.StopLimit]?.quantity,
      auctionOrderState[OrderType.StopLimit]?.urgency,
      order,
      ordersSettings.defaultPassiveOrderType,
      ordersSettings.defaultPassiveOrderLifespan,
      orderRibbonSettings,
    ],
    OrderType.StopLimit
  );
  useInitializeFormEffect(
    [
      auctionOrderStateTime[OrderType.Oco]?.startTime,
      auctionOrderState[OrderType.Oco]?.strategy,
      auctionOrderState[OrderType.Oco]?.quantity,
      auctionOrderState[OrderType.Oco]?.urgency,
      order,
      ordersSettings.defaultPassiveOrderType,
      ordersSettings.defaultPassiveOrderLifespan,
      orderRibbonSettings,
    ],
    OrderType.Oco
  );
  const useResetDispQtySorEffect = (dependencies, orderTypeVal) => {
    useEffect(() => {
      // console.log("USE EFFECT 2");

      // If we just changed the strategy to Smart Sweep, reset the display quantity to zero.
      if (auctionOrderStateStrategy === OrderStrategy.Sor) {
        const changes = {
          [OrderFieldsEnum.maximumFloor]: 0,
        };

        setAuctionOrderState(prevState => ({
          ...prevState,
          [orderTypeVal]: {
            ...prevState[orderTypeVal],
            ...changes,
          },
        }));
      }
      // }, [auctionOrderState[orderType]?.strategy]);
    }, dependencies);
  };
  useResetDispQtySorEffect([auctionOrderState[OrderType.Market]?.strategy], OrderType.Market);
  useResetDispQtySorEffect([auctionOrderState[OrderType.Stop]?.strategy], OrderType.Stop);
  useResetDispQtySorEffect([auctionOrderState[OrderType.Limit]?.strategy], OrderType.Limit);
  useResetDispQtySorEffect([auctionOrderState[OrderType.StopLimit]?.strategy], OrderType.StopLimit);
  useResetDispQtySorEffect([auctionOrderState[OrderType.Oco]?.strategy], OrderType.Oco);

  const useSetDisplayQuantityEffect = (dependencies, orderTypeVal) => {
    useEffect(() => {
      // console.log("USE EFFECT 3");

      // Adjust the auction order state if the size is defined and non-null. (We need `!=` here.)
      // If the strategy is not Smart Sweep, link display quantity to size.
      if (auctionOrderState[orderTypeVal]?.quantity != null && auctionOrderStateStrategy !== OrderStrategy.Sor) {
        const changes: any = {};

        // If the strategy is ICEBERG, the minimum will be 1/4 million instead of 1 million.
        const minDefaultDisplayQuantity =
          auctionOrderStateStrategy === OrderStrategy.Iceberg ? quarterMillion : oneMillion;

        // If size >= 1M, set the display quantity to a default minimum value.
        changes[OrderFieldsEnum.maximumFloor] =
          auctionOrderState[orderTypeVal]?.quantity >= oneMillion
            ? minDefaultDisplayQuantity
            : auctionOrderState[orderTypeVal]?.quantity;

        setAuctionOrderState(prevState => ({
          ...prevState,
          [orderTypeVal]: {
            ...prevState[orderTypeVal],
            ...changes,
          },
        }));
      }
      // }, [auctionOrderState[orderType]?.strategy, auctionOrderState[orderType]?.quantity]);
    }, dependencies);
  };

  useSetDisplayQuantityEffect(
    [auctionOrderState[OrderType.Market]?.strategy, auctionOrderState[OrderType.Market]?.quantity],
    OrderType.Market
  );
  useSetDisplayQuantityEffect(
    [auctionOrderState[OrderType.Stop]?.strategy, auctionOrderState[OrderType.Stop]?.quantity],
    OrderType.Stop
  );
  useSetDisplayQuantityEffect(
    [auctionOrderState[OrderType.Limit]?.strategy, auctionOrderState[OrderType.Limit]?.quantity],
    OrderType.Limit
  );
  useSetDisplayQuantityEffect(
    [auctionOrderState[OrderType.StopLimit]?.strategy, auctionOrderState[OrderType.StopLimit]?.quantity],
    OrderType.StopLimit
  );
  useSetDisplayQuantityEffect(
    [auctionOrderState[OrderType.Oco]?.strategy, auctionOrderState[OrderType.Oco]?.quantity],
    OrderType.Oco
  );

  const useRibbonSettingsMiscEffect = (dependencies, orderTypeVal) => {
    useEffect(() => {
      // console.log("USE EFFECT 4");

      const changes = {};

      // HERE - Price and slippage can be sent separately or calculated depending on the user settings
      if (price) {
        if (!settings.ordersSettings.priceSlippageSeparated) {
          // this is checking for slippage, should send this on card I think
          let submitPrice;

          if (marketSettings.showSlippage && slippage > 0) {
            const safePrice = Big(price);
            const safePip = Big(instrumentPipValue);

            submitPrice =
              side === OrderSide.Buy
                ? safePrice.add(safePip.times(slippage))
                : safePrice.minus(safePip.times(slippage));
            submitPrice = Number(submitPrice.toString());
          } else {
            submitPrice = price;
          }
          changes[OrderFieldsEnum.price] = getPriceStringWithPrecision(submitPrice, instrumentId, true);
        } else {
          changes[OrderFieldsEnum.price] = getPriceStringWithPrecision(price, instrumentId, true);
          changes[OrderFieldsEnum.slippage] = calculateSlippageToPip(slippage, instrumentPipValue).toString();
        }
      }

      if (orderRibbonSettings.showOrderRibbon) {
        changes[OrderFieldsEnum.strategy] =
          orderRibbonSettings.useIceberg?.length > 0 && orderTypeVal !== OrderType.Market
            ? OrderStrategy.Iceberg
            : auctionOrderState[orderTypeVal]?.strategy;
      }
      if ("pegFloorType" in auctionOrderState[orderTypeVal]) {
        if (
          auctionOrderState[orderTypeVal]?.pegFloorType === 2 ||
          (auctionOrderState[orderTypeVal]?.pegFloorType === 1 &&
            !auctionOrderState[orderTypeVal]?.pegFloorPrice &&
            auctionOrderState[orderTypeVal]?.strategy === OrderStrategy.Peg)
        ) {
          setIsPegFloorFloatingPointsDisabled(prevState => ({
            ...prevState,
            [orderTypeVal]: false,
          }));
        } else {
          // setIsPegFloorFloatingPointsDisabled(true);
          setIsPegFloorFloatingPointsDisabled(prevState => ({
            ...prevState,
            [orderTypeVal]: true,
          }));
          changes[OrderFieldsEnum.pegFloorFloatingPoints] = null;
        }
      }
      if (
        [OrderStrategy.Twap.toString(), OrderStrategy.Vwap.toString()].includes(
          auctionOrderState[orderTypeVal]?.strategy!
        )
      ) {
        changes[OrderFieldsEnum.urgency] = 2;
      }
      if (price || orderRibbonSettings.showOrderRibbon) {
        setAuctionOrderState(prevState => ({
          ...prevState,
          [orderTypeVal]: {
            ...prevState[orderTypeVal],
            ...changes,
          },
        }));
      }
    }, dependencies);
  };

  useRibbonSettingsMiscEffect(
    [
      price,
      orderRibbonSettings.showOrderRibbon,
      orderRibbonSettings.selectedDefaultOrderType,
      ordersSettings.defaultPassiveOrderType,
      auctionOrderState[OrderType.Market]?.pegFloorType,
      auctionOrderState[OrderType.Market]?.pegFloorPrice,
    ],
    OrderType.Market
  );

  useRibbonSettingsMiscEffect(
    [
      price,
      orderRibbonSettings.showOrderRibbon,
      orderRibbonSettings.selectedDefaultOrderType,
      auctionOrderState[OrderType.Stop]?.pegFloorType,
      auctionOrderState[OrderType.Stop]?.pegFloorPrice,
    ],
    OrderType.Stop
  );

  useRibbonSettingsMiscEffect(
    [
      price,
      orderRibbonSettings.showOrderRibbon,
      orderRibbonSettings.selectedDefaultOrderType,
      auctionOrderState[OrderType.Limit]?.pegFloorType,
      auctionOrderState[OrderType.Limit]?.pegFloorPrice,
    ],
    OrderType.Limit
  );

  useRibbonSettingsMiscEffect(
    [
      price,
      orderRibbonSettings.showOrderRibbon,
      orderRibbonSettings.selectedDefaultOrderType,
      auctionOrderState[OrderType.StopLimit]?.pegFloorType,
      auctionOrderState[OrderType.StopLimit]?.pegFloorPrice,
    ],
    OrderType.StopLimit
  );

  useRibbonSettingsMiscEffect(
    [
      price,
      orderRibbonSettings.showOrderRibbon,
      orderRibbonSettings.selectedDefaultOrderType,
      auctionOrderState[OrderType.Oco]?.pegFloorType,
      auctionOrderState[OrderType.Oco]?.pegFloorPrice,
    ],
    OrderType.Oco
  );

  const useDisableSubmitButtonEffect = dependencies => {
    useEffect(() => {
      // console.log("USE EFFECT 5");

      // const currentTime = moment("17:04:00", "HH:mm:ss", true);
      const currentTime = moment(new Date(), "HH:mm:ss", true);
      const startTime = moment(auctionOrderStateTime[orderType]?.startTimeTime, "HH:mm:ss", true);

      // HERE: to decide: we don't need market price, it should be 0?
      const disabled =
        ((auctionOrderState[orderType]?.price == null ||
          auctionOrderState[orderType]?.price === "" ||
          parseFloat(auctionOrderState[orderType]?.price) === 0) &&
          orderType === OrderType.Limit) ||
        ((auctionOrderState[orderType]?.stopLossPrice == null ||
          auctionOrderState[orderType]?.stopLossPrice === "" ||
          parseFloat(auctionOrderState[orderType]?.stopLossPrice) === 0) &&
          [OrderType.Stop, OrderType.StopLimit].includes(orderType)) ||
        auctionOrderState[orderType]?.quantity === null ||
        auctionOrderState[orderType]?.quantity === "" ||
        auctionOrderState[orderType]?.quantity == "0" ||
        auctionOrderState[orderType]?.strategy === null ||
        marketDataDown ||
        (order && !editing) ||
        (order && Object.values(ClosedOrderStatus).includes(order.status)) ||
        (auctionOrderState[orderType]?.strategy === OrderStrategy.Pov &&
          !Boolean(auctionOrderState[orderType]?.targetParticipationRate)) ||
        (auctionOrderStateTime[orderType]?.startTimeTime &&
          !isValidTimestamp(auctionOrderStateTime[orderType]?.startTimeTime)) ||
        (auctionOrderStateTime[orderType]?.endTimeTime &&
          !isValidTimestamp(auctionOrderStateTime[orderType]?.endTimeTime)) ||
        (currentTime.isBefore(moment(startTimeEODRecycle, "HH:mm:ss", true)) && startTime.isBefore(currentTime)) ||
        (auctionOrderState[orderType]?.side === OrderSide.Buy &&
          !handleIsFieldDisabled(OrderFieldsEnum.wouldPrice) &&
          auctionOrderState[orderType]?.wouldPrice !== null &&
          parseFloat(auctionOrderState[orderType]?.wouldPrice) > parseFloat(auctionOrderState[orderType]?.price)) ||
        (auctionOrderState[orderType]?.side === OrderSide.Sell &&
          !handleIsFieldDisabled(OrderFieldsEnum.wouldPrice) &&
          auctionOrderState[orderType]?.wouldPrice !== null &&
          parseFloat(auctionOrderState[orderType]?.wouldPrice) < parseFloat(auctionOrderState[orderType]?.price));

      setIsSubmitDisabled(disabled);
    }, dependencies);
  };

  useDisableSubmitButtonEffect([
    auctionOrderState[OrderType.Market],
    auctionOrderStateTime[OrderType.Market],
    order,
    editing,
    orderType,
  ]);

  useDisableSubmitButtonEffect([auctionOrderState[OrderType.Stop], auctionOrderStateTime[OrderType.Stop]]);
  useDisableSubmitButtonEffect([auctionOrderState[OrderType.Limit], auctionOrderStateTime[OrderType.Limit]]);
  useDisableSubmitButtonEffect([auctionOrderState[OrderType.StopLimit], auctionOrderStateTime[OrderType.StopLimit]]);
  useDisableSubmitButtonEffect([auctionOrderState[OrderType.Oco], auctionOrderStateTime[OrderType.Oco]]);

  const useEditOrderFieldsEffect = (dependencies, orderTypeVal) => {
    useEffect(() => {
      // console.log("USE EFFECT 6");
      // console.log(orderTypeVal);
      // console.log(auctionOrderState);

      if (order) {
        // HERE: IMPORTANT - Vertex is sending me back lifespan in uppercase "DAY" instead of "day"
        const auctionOrderStateTimeState = {};
        if (auctionOrderState[orderTypeVal]?.price === "") {
          const orderUpdated = { ...order };

          // HERE: IMPORTANT If we don't have a "market price" or "stop price" we populate the fields with "price" so it's easier for the user to edit an order
          orderUpdated.marketPrice = !order.marketPrice ? price : order.marketPrice;
          orderUpdated.stopLossPrice = !order.stopLossPrice ? price : order.stopLossPrice;

          // only update once so users can edit price/size
          setAuctionOrderState(prevState => ({
            ...prevState,
            [orderTypeVal]: {
              ...prevState[orderTypeVal],
              ...orderUpdated,
            },
          }));
        }

        auctionOrderStateTimeState[OrderFieldsEnum.startTime] = order.startTime
          ? moment(order.startTime).format("YYYY/MM/DD")
          : "";
        auctionOrderStateTimeState[OrderFieldsEnum.startTimeTime] = order.startTime
          ? moment(order.startTime).format("HH:mm:ss")
          : "";

        auctionOrderStateTimeState[OrderFieldsEnum.endTime] = order.endTime
          ? moment(order.endTime).format("YYYY/MM/DD")
          : "";
        auctionOrderStateTimeState[OrderFieldsEnum.endTimeTime] = order.endTime
          ? moment(order.endTime).format("HH:mm:ss")
          : "";

        setAuctionOrderStateTime(prevState => ({
          ...prevState,
          [orderTypeVal]: {
            ...prevState[orderTypeVal],
            ...auctionOrderStateTimeState,
          },
        }));

        checkNextCalendarDateStartTime(OrderFieldsEnum.startTimeTime, moment(order.startTime).format("HH:mm:ss"));
        checkNextCalendarDateEndTime(OrderFieldsEnum.endTimeTime, moment(order.endTime).format("HH:mm:ss"));
      }
    }, dependencies);
  };

  useEditOrderFieldsEffect([order, orderType], OrderType.Market);
  useEditOrderFieldsEffect([order, orderType], OrderType.Stop);
  useEditOrderFieldsEffect([order, orderType], OrderType.Limit);
  useEditOrderFieldsEffect([order, orderType], OrderType.StopLimit);
  useEditOrderFieldsEffect([order, orderType], OrderType.Oco);

  useEffect(() => {
    // console.log("USE EFFECT 7");
    // console.log(auctionOrderState);

    // handles when you hit the Enter button to submit an order
    if (enterKeyNotifyObject.notify) {
      if (order && editing) {
        handleReplaceOrder();
      } else {
        handleSubmitOrder();
      }
      enterKeyNotifyObject.setNotify(false);
    }
  }, [enterKeyNotifyObject.notify]);

  const handleIsFieldDisabled = fieldName => {
    let disabled = false;

    const allowedFields = [OrderFieldsEnum.quantity];

    if (orderType === OrderType.Limit) {
      allowedFields.push(OrderFieldsEnum.price);
    }

    const startFields = [
      OrderFieldsEnum.lifespan,
      OrderFieldsEnum.side,
      OrderFieldsEnum.type,
      OrderFieldsEnum.quantity,
      OrderFieldsEnum.strategy,
      OrderFieldsEnum.price,
      OrderFieldsEnum.marketPrice,
      OrderFieldsEnum.stopLossPrice,
    ];

    if (
      (order && !editing) ||
      // (fieldName !== OrderFieldsEnum.strategy && auctionOrderState[orderType]?.strategy === null) ||
      (!startFields.includes(fieldName) && auctionOrderState[orderType]?.strategy === null) ||
      Boolean(order && Object.values(ClosedOrderStatus).includes(order.status)) ||
      (!CommonStrategyFields.includes(fieldName) &&
        auctionOrderState[orderType]?.strategy &&
        !StrategyMapper[auctionOrderState[orderType]?.strategy].includes(fieldName)) ||
      (fieldName === OrderFieldsEnum.priceType &&
        auctionOrderState[orderType]?.strategy === OrderStrategy.Sor &&
        auctionOrderState[orderType]?.lifespan !== OrderLifespan.IOC) ||
      (fieldName === OrderFieldsEnum.price && [OrderType.Market, OrderType.Stop].includes(orderType)) ||
      (fieldName === OrderFieldsEnum.marketPrice && orderType !== OrderType.Market) ||
      (fieldName === OrderFieldsEnum.stopLossPrice &&
        [OrderType.Market, OrderType.Limit, OrderType.Oco].includes(orderType))
      // HERE: is this a premise?
      // (fieldName === OrderFieldsEnum.priceType && orderType === OrderType.Market)
    ) {
      disabled = true;
    }

    return disabled;
  };

  const closeModal = () => {
    setAuctionCard(null);
  };

  const checkNextCalendarDateStartTime = (fieldName, fieldValue) => {
    setAuctionOrderStateTime(prevState => ({
      ...prevState,
      [orderType]: {
        ...prevState[orderType],
        isNextCalendarDateStartTime: false,
      },
    }));

    checkNextCalendarDateHelper(fieldName, fieldValue);
  };

  const checkNextCalendarDateEndTime = (fieldName, fieldValue) => {
    setAuctionOrderStateTime(prevState => ({
      ...prevState,
      [orderType]: {
        ...prevState[orderType],
        isNextCalendarDateEndTime: false,
      },
    }));

    checkNextCalendarDateHelper(fieldName, fieldValue);
  };

  const checkNextCalendarDateHelper = (fieldName, fieldValue) => {
    if (isValidTimestamp(fieldValue)) {
      // const currentTime = moment("17:04:00", "HH:mm:ss", true);
      const currentTime = moment(new Date(), "HH:mm:ss", true);
      const fieldTime = moment(fieldValue, "HH:mm:ss", true);

      if (
        currentTime.isSameOrAfter(moment(startTimeEODRecycle, "HH:mm:ss", true)) &&
        currentTime.isSameOrBefore(moment("23:59:00", "HH:mm:ss", true))
      ) {
        if (fieldTime.isBefore(currentTime) && fieldTime.isBefore(moment(startTimeEODRecycle, "HH:mm:ss", true))) {
          if (fieldName === OrderFieldsEnum.startTimeTime) {
            setAuctionOrderStateTime(prevState => ({
              ...prevState,
              [orderType]: {
                ...prevState[orderType],
                isNextCalendarDateStartTime: true,
              },
            }));
          } else {
            setAuctionOrderStateTime(prevState => ({
              ...prevState,
              [orderType]: {
                ...prevState[orderType],
                isNextCalendarDateEndTime: true,
              },
            }));
          }
        }
      }
    }
  };

  const handleSubmitOrder = () => {
    if (editing && order && !Object.values(ClosedOrderStatus).includes(order.status)) {
      // don't send a cancel order if the order already completed
      cancelOrderAction(order.id);
    }
    const newOrder = {};

    const startTimeTime = moment(`${auctionOrderStateTime[orderType]?.startTimeTime}`, "HH:mm:ss", true);
    const endTimeTime = moment(`${auctionOrderStateTime[orderType]?.endTimeTime}`, "HH:mm:ss", true);

    // If the strategy is BFIX or WMFIX, the time to send has to be 5 or 2.5 minutes (respectively)
    // before the time entered by the user. Note: `subtract` mutates `startTimeTime`.
    if (auctionOrderState[orderType]?.strategy === OrderStrategy.Bfix) {
      startTimeTime.subtract(5, "minute");
    } else if (auctionOrderState[orderType]?.strategy === OrderStrategy.Fixing) {
      startTimeTime.subtract(2.5, "minute");
    }

    if (auctionOrderStateTime[orderType]?.isNextCalendarDateStartTime) {
      startTimeTime.add(1, "d");
    }

    if (auctionOrderStateTime[orderType]?.isNextCalendarDateEndTime) {
      endTimeTime.add(1, "d");
    }

    const startTimeValue = startTimeTime.valueOf();
    const endTimeValue = endTimeTime.valueOf();

    // HERE: Here we are creating the order to submit with all the fields in the state. This is why is sending marketPrice and stopLossPrice
    for (const key in auctionOrderState[orderType]) {
      if (auctionOrderState[orderType]?.hasOwnProperty(key) && !handleIsFieldDisabled(key)) {
        newOrder[key] = auctionOrderState[orderType][key];
      }
    }

    newOrder[OrderFieldsEnum.startTime] = startTimeValue ? startTimeValue : null;
    newOrder[OrderFieldsEnum.endTime] = endTimeValue ? endTimeValue : null;
    newOrder[OrderFieldsEnum.instrumentId] = auctionOrderState[orderType]?.instrumentId;
    newOrder[OrderFieldsEnum.product] = auctionOrderState[orderType]?.product;
    // HERE
    newOrder[OrderFieldsEnum.price] = orderType === OrderType.Market ? 0 : auctionOrderState[orderType]?.price;
    // auctionOrderState[orderType]?.type === OrderType.Market ? 0 : auctionOrderState[orderType]?.price;
    settings.ordersSettings.priceSlippageSeparated &&
      (newOrder[OrderFieldsEnum.slippage] = auctionOrderState[orderType]?.slippage);
    newOrder[OrderFieldsEnum.lifespan] = auctionOrderState[orderType]?.lifespan;
    // moving to redux side
    // newOrder[OrderFieldsEnum.fixingDate] = auctionOrderState[orderType]?.fixingDate;
    // newOrder[OrderFieldsEnum.settleDate] = auctionOrderState[orderType]?.settleDate;
    newOrder[OrderFieldsEnum.traderName] = settings.activeTrader;

    // HERE
    // newOrder[OrderFieldsEnum.type] = auctionOrderState[orderType]?.type;
    newOrder[OrderFieldsEnum.type] = orderType;

    const orderToSubmit = newOrder as OrderModel;
    submitNewOrder(orderToSubmit, { takerName: settings.activeTaker }, true);
  };

  const handleReplaceOrder = () => {
    const newOrder = {};

    // If order was entered with empty times we have to add a date
    const startTime = auctionOrderStateTime[orderType]?.startTime
      ? auctionOrderStateTime[orderType]?.startTime
      : moment().format("YYYY/MM/DD");
    const startTimeTime = moment(
      `${startTime} ${auctionOrderStateTime[orderType]?.startTimeTime}`,
      "YYYY/MM/DD HH:mm:ss",
      true
    );
    // If order was entered with empty times we have to add a date
    const endTime = auctionOrderStateTime[orderType]?.endTime
      ? auctionOrderStateTime[orderType]?.endTime
      : moment().format("YYYY/MM/DD");
    const endTimeTime = moment(
      `${endTime} ${auctionOrderStateTime[orderType]?.endTimeTime}`,
      "YYYY/MM/DD HH:mm:ss",
      true
    );

    // If the strategy is BFIX or WMFIX, the time to send has to be 5 or 2.5 minutes (respectively)
    // before the time entered by the user. Note: `subtract` mutates `startTimeTime`.
    if (auctionOrderState[orderType]?.strategy === OrderStrategy.Bfix) {
      startTimeTime.subtract(5, "minute");
    } else if (auctionOrderState[orderType]?.strategy === OrderStrategy.Fixing) {
      startTimeTime.subtract(2.5, "minute");
    }

    if (auctionOrderStateTime[orderType]?.isNextCalendarDateStartTime) {
      startTimeTime.add(1, "d");
    }

    if (auctionOrderStateTime[orderType]?.isNextCalendarDateEndTime) {
      endTimeTime.add(1, "d");
    }

    const startTimeValue = startTimeTime.valueOf();
    const endTimeValue = endTimeTime.valueOf();

    for (const key in auctionOrderState[orderType]) {
      if (auctionOrderState[orderType]?.hasOwnProperty(key) && !handleIsFieldDisabled(key)) {
        newOrder[key] = auctionOrderState[orderType][key];
      }
    }
    newOrder[OrderFieldsEnum.startTime] = startTimeValue ? startTimeValue : null;
    newOrder[OrderFieldsEnum.endTime] = endTimeValue ? endTimeValue : null;
    newOrder[OrderFieldsEnum.instrumentId] = auctionOrderState[orderType]?.instrumentId;
    newOrder[OrderFieldsEnum.product] = auctionOrderState[orderType]?.product;
    newOrder[OrderFieldsEnum.price] = orderType === OrderType.Market ? 0 : auctionOrderState[orderType]?.price;
    // auctionOrderState[orderType]?.type === OrderType.Market ? 0 : auctionOrderState[orderType]?.price;
    // HERE
    // newOrder[OrderFieldsEnum.slippage] = calculateSlippageToPip(slippage, instrumentPipValue);
    newOrder[OrderFieldsEnum.lifespan] = auctionOrderState[orderType]?.lifespan;
    // moving to redux side
    // newOrder[OrderFieldsEnum.fixingDate] = auctionOrderState[orderType]?.fixingDate;
    // newOrder[OrderFieldsEnum.settleDate] = auctionOrderState[orderType]?.settleDate;
    newOrder[OrderFieldsEnum.traderName] = settings.activeTrader;

    // HERE
    // newOrder[OrderFieldsEnum.type] = auctionOrderState[orderType]?.type;
    newOrder[OrderFieldsEnum.type] = orderType;

    // console.log("REPLACE");
    // console.log(newOrder[OrderFieldsEnum.type]);

    newOrder["id"] = order.id;

    const orderToSubmit = newOrder;

    submitReplaceOrder(orderToSubmit as ReplaceOrderModel);
  };

  // *********  field functions, may want to move out of file ***************************
  const mKeyHandler = (event, field) => {
    if (!["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(event.key)) {
      if (event.key !== "m") {
        positiveNumberEnforcer(event);
      } else {
        event.preventDefault();
        const changes = {
          [field.dataFieldName]: auctionOrderState[orderType][field.dataFieldName] * 1000000,
        };

        setAuctionOrderState(prevState => ({
          ...prevState,
          [orderType]: {
            ...prevState[orderType],
            ...changes,
          },
        }));
      }
    }
  };

  const sizeFieldHandler = (event, field): void => {
    priceFormatValidator(event, auctionOrderState[orderType][field.dataFieldName]);
  };

  // TODO refactor into sizeFieldHandler - should probably be same function
  const priceFieldHandler = (event, field) => {
    if (
      !["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(event.key) &&
      !(
        auctionOrderState[orderType][field.dataFieldName] !== "" &&
        auctionOrderState[orderType][field.dataFieldName] &&
        !Array.from(String(auctionOrderState[orderType][field.dataFieldName])).includes(".") &&
        event.key === "."
      )
    ) {
      positiveNumberEnforcer(event);
    }
  };

  const timeStampKeyDownHandler = (event, field) => {
    // leaving this here because it's cool
    // `${auctionOrderStateTime[field.dataFieldName]}${event.key}`.match(
    //   /^(([0-1]|$)([0-9]|$)|(2|$)([0-3]|$))(:|$)([0-5]|$)([0-9]|$)(:|$)([0-5]|$)([0-9]|$)$/g
    // )

    // You're only allowed to input numbers or any of the event keys listed.
    if (
      !(
        ["Backspace", "Tab", "ArrowLeft", "ArrowRight"].includes(event.key) ||
        (event.key !== ":" && event.key.match(/^[0-9]$/g))
      )
    ) {
      event.preventDefault();
    }
  };

  // onchange event handler, adds colons if necessary
  const timeStampColonHandler = (event, field) => {
    if (
      event.target.value &&
      event.target.value.length > auctionOrderStateTime[orderType][field.dataFieldName].length &&
      (auctionOrderStateTime[orderType][field.dataFieldName].match(/^\d$/g) ||
        auctionOrderStateTime[orderType][field.dataFieldName].match(/^\d\d:\d$/g))
    ) {
      return `${event.target.value}:`;
    }

    return event.target.value.length <= 8 ? event.target.value : auctionOrderStateTime[orderType][field.dataFieldName];
  };

  const removeCommasAndAddMultiplierShortcutsHandler = (event: any): string =>
    removeCommasAndAddMultiplierShortcuts(event.target?.value || "0");

  const getSizeBasedOnSettingsHelper = (value: any) => {
    return formatSizeBasedOnSettings(
      value,
      orderRibbonSettings,
      currencySettingsBySymbol,
      marketSettings,
      instrumentId
    );
  };

  const formatSizeBasedOnSettingsHandler = (value: any) => {
    const quantity = getSizeBasedOnSettingsHelper(value);

    setAuctionOrderState(prevState => ({
      ...prevState,
      [orderType]: {
        ...prevState[orderType],
        quantity,
      },
    }));
  };

  const formatDisplayQtyBasedOnSettingsHandler = (value: any) => {
    const maximumFloor = getSizeBasedOnSettingsHelper(value);

    setAuctionOrderState(prevState => ({
      ...prevState,
      [orderType]: {
        ...prevState[orderType],
        maximumFloor,
      },
    }));
  };

  const getUrgencyOptionsValues = strategy => {
    if ([OrderStrategy.Twap, OrderStrategy.Vwap, OrderStrategy.Bfix, OrderStrategy.Fixing].includes(strategy)) {
      return basicUrgencyOptions;
    }
    if (strategy === OrderStrategy.Pov) {
      return urgencyOptions.slice(0, 5);
    }
    if (strategy === OrderStrategy.ISeek) {
      return urgencyOptions.slice(1, 4);
    }
    return urgencyOptions;
  };

  const getPricePrecision = () => {
    const priceVal = parseFloat(auctionOrderState[orderType]?.price);
    // tslint:disable-next-line: ban-comma-operator // maybe get rid of altogether, TS prevents this issue
    const priceWithPrecision = getPriceStringWithPrecision(priceVal, instrumentId, true);
    if (auctionOrderState[orderType]?.price !== priceWithPrecision) {
      setAuctionCard({
        ...auctionOrderState[orderType],
        price: priceWithPrecision,
      });
    }
  };

  const showAsterisk = required => {
    const strategy: string | null = auctionOrderState[orderType]?.strategy;
    return required.includes(strategy);
  };

  const handleIncrementDecrement = (direction, incrementAmount, fieldName) => {
    // const priceBig = Big(auctionOrderState[orderType]?.price || 0);

    const priceBig = Big(auctionOrderState[orderType][fieldName] || 0);
    const plusValue = Number(priceBig.add(incrementAmount).toString());
    const minusValue = Number(priceBig.minus(incrementAmount).toString());
    const priceValue = direction === "increment" ? plusValue : minusValue > 0 ? minusValue : 0;

    setAuctionOrderState(prevState => ({
      ...prevState,
      [orderType]: {
        ...prevState[orderType],
        // price: priceValue.toString(),
        [fieldName]: priceValue.toString(),
      },
    }));
  };

  // *********  end field functions ***************************

  // when you add functions in fields.ts you need to map the string name of the function to the actual function. this is all shorthand since your
  // string and function name should be the same
  const functionMapper = {
    addCommas,
    allowOnlyNumbers,
    convertToNumber,
    formatSizeBasedOnSettingsHandler,
    formatDisplayQtyBasedOnSettingsHandler,
    getPricePrecision,
    mKeyHandler,
    minValueZero,
    moveCursorToEnd,
    percentValue,
    priceFieldHandler,
    removeCommasAndAddMultiplierShortcutsHandler,
    sizeFieldHandler,
    timeStampColonHandler,
    timeStampKeyDownHandler,
    zeroToNinetyNine,
  };

  const setStateFunctionMapper = {
    // auctionOrderState: state => setAuctionOrderState(state),
    auctionOrderState: state => {
      setAuctionOrderState(prevState => ({
        ...prevState,
        [orderType]: {
          ...prevState[orderType],
          ...state,
        },
      }));
    },
    auctionOrderStateTime: state => {
      setAuctionOrderStateTime(prevState => ({
        ...prevState,
        [orderType]: {
          ...prevState[orderType],
          ...state,
        },
      }));
    },
  };

  const isBuyAction = auctionOrderState[orderType]?.side === orderSideOptions[0].value;

  const refreshPrice = (fieldName, currencyPair) => {
    refreshAuctionCardPrice({
      orderState: { ...auctionOrderState[orderType], editing: true },
      fieldName: fieldName,
    });

    // HERE: For stopLossPrice -> to make sure the auctionOrderState is being updated
    setAuctionOrderState(prevState => ({
      ...prevState,
      [orderType]: {
        ...prevState[orderType],
        [fieldName]:
          auctionOrderState[orderType].side === OrderSide.Buy
            ? currencyPair.bestBidPrice.toString()
            : currencyPair.bestAskPrice.toString(),
      },
    }));
  };

  // Everything here should be generic enough that you can define a field of type select or input and include functions for any event type.
  // This function gets executed each time anything in this component changes
  const getFormElement = fieldObject => {
    const field = { ...fieldObject };

    const stateHolderObj = [
      OrderFieldsEnum.startTimeTime,
      OrderFieldsEnum.startTime,
      OrderFieldsEnum.endTimeTime,
      OrderFieldsEnum.endTime,
    ].includes(field.dataFieldName)
      ? { auctionOrderStateTime }
      : { auctionOrderState };

    const isRadio = [OrderFieldsEnum.type].includes(field.dataFieldName);

    const isNarrow = !isRadio && ![OrderFieldsEnum.strategy].includes(field.dataFieldName);

    const isFieldDisabled = handleIsFieldDisabled(field.dataFieldName);

    let stateName: string = Object.keys(stateHolderObj)?.length > 0 ? Object.keys(stateHolderObj)[0] : "";
    let stateObj: any = stateHolderObj["auctionOrderState"]
      ? stateHolderObj["auctionOrderState"][orderType]
      : stateHolderObj["auctionOrderStateTime"][orderType];

    const getOptions = currentFieldObject => {
      const fieldIsCurrency = currentFieldObject.dataFieldName === OrderFieldsEnum.currency;
      const fieldIsUrgency = currentFieldObject.dataFieldName === OrderFieldsEnum.urgency;
      const fieldIsType = currentFieldObject.dataFieldName === OrderFieldsEnum.type;
      const fieldIsLifespan = currentFieldObject.dataFieldName === OrderFieldsEnum.lifespan;
      const fieldIsStartTime = currentFieldObject.dataFieldName === OrderFieldsEnum.startTimeTime;
      const strategyIsIceberg = auctionOrderState[orderType]?.strategy === OrderStrategy.Iceberg;
      const strategyIsBfix = auctionOrderState[orderType]?.strategy === OrderStrategy.Bfix;

      const options = fieldIsCurrency
        ? currencyObject
        : fieldIsUrgency
        ? getUrgencyOptionsValues(auctionOrderState[orderType]?.strategy)
        : fieldIsType && strategyIsIceberg
        ? [
            {
              label: "Limit",
              value: OrderType.Limit,
            },
          ]
        : fieldIsLifespan
        ? lifespanOptions[orderType]
        : fieldIsStartTime && strategyIsBfix
        ? currentFieldObject.valuesBfix
        : currentFieldObject.values;

      return options;
    };

    // We want a select dropdown for the start time if the strategy is BFIX or WMFIX. Note: We are
    // changing the input type, so this should be refactored and added on object creation.
    if (
      field.dataFieldName === OrderFieldsEnum.startTimeTime &&
      (auctionOrderState[orderType]?.strategy === OrderStrategy.Bfix ||
        auctionOrderState[orderType]?.strategy === OrderStrategy.Fixing)
    ) {
      field.type = FieldType.select;
    }

    if (field.type === FieldType.select) {
      return order && editing && field.dataFieldName === OrderFieldsEnum.type ? (
        // return order &&
        //   editing &&
        //   [OrderFieldsEnum.price, OrderFieldsEnum.stopLossPrice].includes(field.dataFieldName) ? (
        // priceAdjusterButtons()
        <div></div>
      ) : (
        <div
          className={clsx(styles.inputBlock, isFieldDisabled && styles.inputBlockDisabled)}
          key={field.dataFieldName}
        >
          <label>
            {field.label}
            <span>
              {field.dataFieldName === OrderFieldsEnum.pegFloorFloatingPoints &&
              !isPegFloorFloatingPointsDisabled[orderType]
                ? showAsterisk(field.required) && "*"
                : showAsterisk(field.required)
                ? "*"
                : ""}
            </span>
          </label>
          <div
            className={clsx(
              styles.inputSection,
              isNarrow && styles.inputSectionNarrow,
              isFieldDisabled && styles.inputDisabled
            )}
          >
            {field.dataFieldName === OrderFieldsEnum.side ? (
              <Radio.Group
                data-testid={`auction_${field.dataFieldName}`}
                className={clsx(`${isBuyAction ? styles.buy : styles.sell} ${styles.radioToggle}`)}
                disabled={isFieldDisabled || order}
                value={isFieldDisabled && !order ? "" : stateObj[field.dataFieldName]}
                onChange={event => {
                  setStateFunctionMapper[stateName]({
                    ...stateObj,
                    [field.dataFieldName]: event.target.value,
                  });

                  // Update the color of the highlighted card when "Buy/Sell" is changed.
                  if (field.dataFieldName === "side") {
                    setActiveCardSide(event.target.value);
                  }
                }}
              >
                {getOptions(field).map((option, i) => (
                  <Radio
                    key={`${option.value}.${i}`}
                    value={option.value}
                    className={clsx(styles.radioToggle, order && styles.radioDisabled)}
                  >
                    {option.label}
                  </Radio>
                ))}
              </Radio.Group>
            ) : isRadio ? (
              <Radio.Group
                data-testid={`auction_${field.dataFieldName}`}
                disabled={isFieldDisabled}
                value={isFieldDisabled && !order ? "" : stateObj[field.dataFieldName]}
                onChange={event => {
                  setStateFunctionMapper[stateName]({
                    ...stateObj,
                    [field.dataFieldName]: event.target.value,
                  });
                }}
              >
                {getOptions(field).map((option, i) => (
                  <Radio key={`${option.value}.${i}`} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
              </Radio.Group>
            ) : (
              <Select
                data-testid={`auction_${field.dataFieldName}`}
                size="small"
                className={clsx(
                  field.dataFieldName === OrderFieldsEnum.side ? (isBuyAction ? styles.buy : styles.sell) : ""
                )}
                disabled={isFieldDisabled || (editing && field.dataFieldName === OrderFieldsEnum.side)}
                dropdownMatchSelectWidth={false}
                value={isFieldDisabled && !order ? "" : stateObj[field.dataFieldName]}
                onSelect={(value: string) => {
                  setStateFunctionMapper[stateName]({
                    ...stateObj,
                    [field.dataFieldName]: value,
                  });

                  if (field.dataFieldName === OrderFieldsEnum.startTimeTime) {
                    checkNextCalendarDateStartTime(OrderFieldsEnum.startTimeTime, value);
                  } else if (field.dataFieldName === OrderFieldsEnum.endTimeTime) {
                    checkNextCalendarDateEndTime(OrderFieldsEnum.endTimeTime, value);
                  }
                }}
                suffixIcon={Icon({ type: "caret-down" })}
              >
                {getOptions(field)
                  .filter(
                    item =>
                      // If orderType is Market it's not possible to trade Iceberg
                      ((orderType === OrderType.Market && item.value !== OrderStrategy.Iceberg) ||
                        orderType !== OrderType.Market) &&
                      item
                  )
                  .map((option, i) => (
                    <Select.Option key={`${option.value}.${i}`} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
              </Select>
            )}

            {field.dataFieldName === OrderFieldsEnum.startTimeTime &&
              auctionOrderStateTime[orderType]?.isNextCalendarDateStartTime && (
                <div className={clsx(styles.timeInfo, styles.timeInfoSelect)}>{nextCalendarDateText}</div>
              )}
            {field.dataFieldName === OrderFieldsEnum.endTimeTime &&
              auctionOrderStateTime[orderType]?.isNextCalendarDateEndTime && (
                <div className={clsx(styles.timeInfo, styles.timeInfoSelect)}>{nextCalendarDateText}</div>
              )}
          </div>
        </div>
      );
    }

    if (field.type === FieldType.input) {
      const isDisabled =
        field.dataFieldName === OrderFieldsEnum.pegFloorFloatingPoints
          ? isPegFloorFloatingPointsDisabled[orderType]
          : isFieldDisabled;
      return (
        <>
          <div className={clsx(styles.inputBlock, isDisabled && styles.inputBlockDisabled)} key={field.dataFieldName}>
            <label>
              {field.label}
              <span>
                {field.dataFieldName === OrderFieldsEnum.pegFloorFloatingPoints &&
                !isPegFloorFloatingPointsDisabled[orderType]
                  ? showAsterisk(field.required) && "*"
                  : showAsterisk(field.required)
                  ? "*"
                  : ""}
              </span>
            </label>
            <div
              className={clsx(
                styles.inputSection,
                isNarrow && styles.inputSectionNarrow,
                isDisabled && styles.inputDisabled
              )}
            >
              <InputTextArea
                containerClass={
                  field.dataFieldName !== OrderFieldsEnum.strategyConfig
                    ? `ant-input-number ant-input-number-sm ${
                        isDisabled ? "ant-input-number-disabled" : ""
                      }, ${field.dataFieldName === "marketPrice" && styles.inputBlockedContainer}`
                    : isDisabled
                    ? "ant-input-number-disabled"
                    : ""
                }
                dataTestId={`auction_${field.dataFieldName}`}
                className="ant-input-number-input  ant-input-number-sm"
                inputType={field.inputType}
                fieldType={
                  field.dataFieldName !== OrderFieldsEnum.strategyConfig ? FieldType.input : FieldType.textarea
                }
                disabled={isDisabled}
                autoFocus={(field.dataFieldName === "price" || field.dataFieldName === "stopLossPrice") && !order}
                value={
                  (isDisabled || [undefined, null].includes(stateObj[field.dataFieldName])
                    ? ""
                    : field.valueFormat
                    ? functionMapper[field.valueFormat](stateObj[field.dataFieldName])
                    : stateObj[field.dataFieldName]) || ""
                }
                placeholder={
                  // using placeholder so order values appear when input is disabled
                  !order
                    ? field.placeholder
                    : field.valueFormat
                    ? functionMapper[field.valueFormat](stateObj[field.dataFieldName])
                    : stateObj[field.dataFieldName]
                }
                onKeyDown={e => field.onKeyDownFunction && functionMapper[field.onKeyDownFunction](e, field)}
                onKeyUp={e => field.onKeyUpFunction && functionMapper[field.onKeyUpFunction](e, field)}
                onChange={(e: any) => {
                  const value = e.target.value
                    ? field.onChangeFunction
                      ? functionMapper[field.onChangeFunction](e, field)
                      : e.target.value
                    : "";

                  setStateFunctionMapper[stateName]({
                    ...stateObj,
                    [field.dataFieldName]: value,
                  });

                  if (field.dataFieldName === OrderFieldsEnum.strategyConfig) {
                    setContentToMaxChars(value);
                  }

                  if (field.dataFieldName === OrderFieldsEnum.startTimeTime) {
                    checkNextCalendarDateStartTime(OrderFieldsEnum.startTimeTime, value);
                  } else if (field.dataFieldName === OrderFieldsEnum.endTimeTime) {
                    checkNextCalendarDateEndTime(OrderFieldsEnum.endTimeTime, value);
                  }
                }}
                onBlur={e => field.onBlurFunction && functionMapper[field.onBlurFunction](e.target.value)}
                onFocus={e => field.onFocusFunction && functionMapper[field.onFocusFunction](e)}
              />
              {field.dataFieldName === OrderFieldsEnum.startTimeTime &&
                auctionOrderStateTime[orderType]?.isNextCalendarDateStartTime && (
                  <div className={clsx(styles.timeInfo, styles.timeInfoInput)}>{nextCalendarDateText}</div>
                )}
              {field.dataFieldName === OrderFieldsEnum.endTimeTime &&
                auctionOrderStateTime[orderType]?.isNextCalendarDateEndTime && (
                  <div className={clsx(styles.timeInfo, styles.timeInfoInput)}>{nextCalendarDateText}</div>
                )}
            </div>
          </div>

          {order &&
          editing &&
          (field.dataFieldName === OrderFieldsEnum.price || field.dataFieldName === OrderFieldsEnum.stopLossPrice) &&
          !isDisabled ? (
            <PriceAdjusterButtons
              field={field}
              handleIncrementDecrement={handleIncrementDecrement}
              instrumentId={instrumentId}
              instrumentPipValue={instrumentPipValue}
              refreshPrice={refreshPrice}
            />
          ) : null}
        </>
      );
    }

    return <></>;
  };

  // for the time being, the style has these two sections in the same flex row wrap.

  const commonAlgoFields = [...commonFields, ...algoOrderFields];

  const commonAndAlgoFieldsFinal =
    auctionOrderState[orderType]?.strategy !== OrderStrategy.Peg
      ? [...commonAlgoFields, ...lastFields]
      : [...commonAlgoFields];

  const pegFieldsFinal = [...pegFields, ...lastFields];

  return (
    <div className={clsx(styles.auctionOrderForm, isBuyAction ? styles.buyBorder : styles.sellBorder)}>
      <div className={styles.tabs}>
        {/* HERE TODO: change divs by Buttons and remove animation from Buttons  */}
        <div
          className={clsx(styles.tab, orderType === OrderType.Market && styles.activeTab)}
          onClick={() => setOrderType(OrderType.Market)}
          key={OrderType.Market}
        >
          Market
        </div>
        {/* <div
          className={clsx(styles.tab, orderType === OrderType.Stop && styles.activeTab)}
          onClick={() => setOrderType(OrderType.Stop)}
          key={OrderType.Stop}
        >
          Stop
        </div> */}
        <div
          className={clsx(styles.tab, orderType === OrderType.Limit && styles.activeTab)}
          onClick={() => setOrderType(OrderType.Limit)}
          key={OrderType.Limit}
        >
          Limit
        </div>
        {/* <div
          className={clsx(styles.tab, orderType === OrderType.StopLimit && styles.activeTab)}
          onClick={() => setOrderType(OrderType.StopLimit)}
          key={OrderType.StopLimit}
        >
          Stop Limit
        </div> */}
        {/* <div
          className={clsx(styles.tab, orderType === OrderType.Oco && styles.activeTab)}
          onClick={() => setOrderType(OrderType.Oco)}
          key={OrderType.Oco}
        >
          OCO
        </div> */}
      </div>

      <AuctionCardHeader closeModal={closeModal} instrumentId={auctionOrderState[orderType]?.instrumentId} />

      <section className={styles.orderMain}>{commonAndAlgoFieldsFinal.map(field => getFormElement(field))}</section>

      {auctionOrderState[orderType]?.strategy === OrderStrategy.Peg && (
        <div className={styles.enhancedOrderSettings}>{pegFieldsFinal.map(field => getFormElement(field))}</div>
      )}

      {(!order || editing) && (
        <div className={styles.auctionActions}>
          <Button
            data-testid="auction_cancel_button"
            size="small"
            className={styles.cancelButton}
            type="ghost"
            onClick={() => closeModal()}
          >
            Cancel
          </Button>

          <Button
            data-testid="auction_submit_button"
            type="primary"
            size="small"
            className={
              isSubmitDisabled || isSubmitExecuting
                ? ""
                : clsx(styles.submitButton, isBuyAction ? styles.buySubmitButton : styles.sellSubmitButton)
            }
            disabled={isSubmitDisabled || isSubmitExecuting}
            onClick={() => {
              setIsSubmitExecuting(true);
              if (order && editing) {
                handleReplaceOrder();
              } else {
                handleSubmitOrder();
              }
            }}
          >
            Submit
          </Button>
        </div>
      )}

      {order && (
        <div className={styles.orderStatus}>
          <Order orderData={order} isModalOrder={true} />
        </div>
      )}
    </div>
  );
}

export default connect(
  (state, ownProps: any) => ({
    auctionCardDetail: getAuctionCardDetailByIdSelector(state, ownProps.instrumentId),
    instrumentPipValue: pipForSymbol(state, ownProps.instrumentId),
    order: orderByIdSelector(state, ownProps.id),
    selectedDefaultQuantity: getDefaultOrderQuantitySettings(state),
    settings: getSettings(state),
    currencySettingsBySymbol: getCurrencySettingsBySymbol(state, ownProps.instrumentId) || {},
  }),
  {
    cancelOrderAction,
    refreshAuctionCardPrice,
    setAuctionCard,
    setAuctionCardStyle,
    submitNewOrder,
    submitReplaceOrder,
  }
)(AuctionOrderForm);

import React from "react";
import { Button, Dropdown, Menu } from "antd";
import { connect } from "react-redux";
import Order from "./Order";
import styles from "./OrdersPanel.module.scss";
import "./OrdersPanel.scss";
import {
  cancelAllOrders,
  liveOrdersSelector,
  removeClosedOrdersFromActiveOrders,
} from "../../state/orders/ordersState";
import { getOrdersSettings, getOrdersReadOnly } from "../../state/settings/settingsState";
import { ReactComponent as IconClear } from "../../assets/images/ClearIcon-20.svg";
import { ReactComponent as IconStop } from "../../assets/images/StopIcon-20.svg";
import backgroundImage from "../../assets/images/OrderList.svg";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import OrderContainer from "./OrderContainer";

function OrdersPanel({ orders, cancelAllOrders, orderSettings, readOnly, removeClosedOrdersFromActiveOrders }) {
  const cancelAll = () => {
    cancelAllOrders();
  };

  const renderHeaderMenu = (
    <Menu>
      {/* <Menu.Item key="0" onClick={() => console.log("Clear")}>
        Clear complete
      </Menu.Item>
      <Menu.Item key="1" onClick={() => console.log("Pause")}>
        Pause all
      </Menu.Item> */}
      <Menu.Item
        key="2"
        onClick={() => cancelAll()}
        disabled={readOnly}
        className={readOnly && styles.dropdownItemDisabled}
      >
        Cancel all
      </Menu.Item>
    </Menu>
  );

  const confirmCancelationMenu = cancelAction => {
    return (
      <Menu style={{ padding: 0 }} className={styles.cancelConfirmMenu}>
        <Menu.Item key="0">
          <div className={styles.confirmCancelOptions}>
            <Button type="default" size="small">
              Leave as-is
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                cancelAction();
              }}
            >
              Confirm cancel
            </Button>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  return orders && orders.length > 0 ? (
    <div className={styles.ordersPanel}>
      <header className={styles.header}>
        <div className={styles.headerContent}></div>

        <div className={styles.ordersActions}>
          <Button
            size={"small"}
            onClick={() => removeClosedOrdersFromActiveOrders(null)}
            className={styles.ordersAction}
          >
            <IconClear className={styles.ordersActionIcon} />
            Clear All
          </Button>

          <Dropdown
            placement="bottomRight"
            overlay={confirmCancelationMenu(cancelAll)}
            trigger={orderSettings.confirmCancelation ? ["click"] : []}
            disabled={readOnly}
          >
            <Button
              size={"small"}
              onClick={() => !orderSettings.confirmCancelation && cancelAll()}
              className={styles.ordersAction}
            >
              <IconStop className={styles.ordersActionIcon} />
              Cancel All
            </Button>
          </Dropdown>
        </div>

        <div className={styles.headerAside}>
          <Dropdown placement="bottomRight" overlay={renderHeaderMenu} trigger={["click"]}>
            <Button size="default" icon="more" />
          </Dropdown>
        </div>
      </header>
      <Orders orders={orders} />
    </div>
  ) : (
    <div className={styles.ordersPanel}>
      <img width="128px" height="80px" alt="building" src={backgroundImage} className={styles.backgroundImage} />
      <h4 className={styles.noOrdersTitle}>No orders working.</h4>
    </div>
  );
}

export default connect(
  (state: any) => ({
    orders: liveOrdersSelector(state),
    orderSettings: getOrdersSettings(state),
    readOnly: getOrdersReadOnly(state),
  }),
  { cancelAllOrders, removeClosedOrdersFromActiveOrders }
)(OrdersPanel);

type OrdersProps = {
  orders: any[];
};

function Orders({ orders }: any) {
  return (
    <div className={styles.ordersList}>
      <TransitionGroup>
        {orders.map((order: any, i: number) => (
          <CSSTransition enter={true} exit={true} key={order.correlation} timeout={500} classNames={"order-animate"}>
            <div className={"order-animate"}>
              <Order orderData={order} isModalOrder={false} />
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
}

import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import clsx from "clsx";
import OrderProgressBar from "./OrderProgressBar";
import OrderStatusLabel from "../../shared/OrderStatusLabel";
import styles from "./OrdersPanel.module.scss";
import { capitalizeFirstLetter, ndfInstrumentStripper } from "../../lib/formatUtil";
import {
  cancelOrderAction,
  removeClosedOrdersFromActiveOrders,
  // ON_POST_PROCESS_UPDATE_ORDERS_FAILURE,
  submitReplaceOrder,
} from "../../state/orders/ordersState";
import {
  ClosedOrderStatus,
  OrderLifespan,
  OrderStatus,
  OrderStrategy,
  OrderType,
  ReplaceOrderModel,
} from "../../state/orders/types";
import { setAuctionCard } from "../../state/currency/currencyActions";
import { pipForSymbol } from "../../state/currency/symbols/symbolsState";
import {
  getOrdersSettings,
  getStatusOverrides,
  getMarketSettings,
  getOrdersReadOnly,
  getCantorAlgoEnabled,
} from "../../state/settings/settingsState";
import { orderSideOptions, orderStrategyOptions, orderTypeOptions } from "../currency/AuctionOrderForm/selectOptions";
import { ReactComponent as IconCancel } from "../../assets/images/CancelIcon-20.svg";
import { ReactComponent as IconEdit } from "../../assets/images/ActionEdit-20.svg";
import { ReactComponent as IconCaretUpDown } from "../../assets/images/CaretUpDown-20.svg";
import { ReactComponent as IconClear } from "../../assets/images/ClearIcon-20.svg";

import { getPriceStringWithPrecision, useOnClickOutside } from "../../lib/util";
import { FlywheelMenu } from "../currency/Flywheel/FlywheelMenu";
import { OrderFieldsEnum } from "../currency/AuctionOrderForm/fields";
import OrderDetail from "./OrderDetail";

type OrderProps = {
  orderData: any;
  orderStatusOverrides: object;
  isModalOrder: boolean;
  cancelOrder: (id: string) => void;
  showAuctionCard: (id: string) => void;
  orderSettings: any;
  replaceOrder: (orderInfo: ReplaceOrderModel) => void;
  pip: any;
  marketSettings: any;
  readOnly: boolean;
  removeClosedOrdersFromActiveOrders: any;
  cantorAlgoEnabled: boolean;
};

function Order({
  orderData,
  orderStatusOverrides,
  isModalOrder,
  cancelOrder,
  showAuctionCard,
  orderSettings,
  replaceOrder,
  pip,
  marketSettings,
  readOnly,
  removeClosedOrdersFromActiveOrders,
  cantorAlgoEnabled,
}: OrderProps) {
  const isOpenFin = window.fin !== undefined;
  const isOrderStatusClosedLeg1 = Object.values(ClosedOrderStatus).includes(orderData.status);
  const isOrderStatusClosedLeg2 = Object.values(ClosedOrderStatus).includes(orderData.statusLeg2);
  const isOrderStatusClosed =
    orderData.type !== OrderType.Oco ? isOrderStatusClosedLeg1 : isOrderStatusClosedLeg1 && isOrderStatusClosedLeg2;
  const isInFlightLeg1 = [OrderStatus.Pending, OrderStatus.Replacing, OrderStatus.Canceling].includes(orderData.status);
  const isInFlightLeg2 = [OrderStatus.Pending, OrderStatus.Replacing, OrderStatus.Canceling].includes(
    orderData.statusLeg2
  );
  const isInFlight = orderData.type! == OrderType.Oco ? isInFlightLeg1 : isInFlightLeg1 && isInFlightLeg2;

  const lifespan = typeof orderData.lifespan === "string" ? [orderData.lifespan] : orderData.lifespan ?? [];
  const isUnCancelableStrategy = lifespan.includes(OrderLifespan.FOK) || lifespan.includes(OrderLifespan.IOC);

  // {isOrderStatusClosed || isUnCancelableStrategy || isInFlight || readOnly ? (

  const strategyTooltipText = <span>Cannot cancel FOK or IOC</span>;
  const inFlightTooltipText = <span>Cannot cancel in-flight orders</span>;
  const completeTooltipText = <span>Cannot cancel completed orders</span>;

  const getTooltipText = () => {
    return (
      (isOrderStatusClosed && completeTooltipText) ||
      (isInFlight && inFlightTooltipText) ||
      (isUnCancelableStrategy && strategyTooltipText) ||
      ""
    );
  };

  const addTooltipIfNeeded = thatThingYouSentMe => {
    if (isOrderStatusClosed || isInFlight || isUnCancelableStrategy) {
      return (
        <Tooltip placement="left" title={getTooltipText}>
          {thatThingYouSentMe}
        </Tooltip>
      );
    }
    return thatThingYouSentMe;
  };

  const cancelOrders = () => {
    cancelOrder(orderData.id);
  };

  const showOrderDetails =
    !isOpenFin && !isModalOrder && (isOrderStatusClosedLeg1 || isUnCancelableStrategy || isInFlightLeg1);
  const showOrderDetailsLeg2 =
    !isOpenFin && !isModalOrder && (isOrderStatusClosedLeg2 || isUnCancelableStrategy || isInFlightLeg2);

  const confirmCancelationMenu = cancelAction => {
    return (
      <Menu style={{ padding: 0 }} className={styles.cancelConfirmMenu}>
        <Menu.Item key="0">
          <div className={styles.confirmCancelOptions}>
            <Button type="default" size="small">
              Leave as-is
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                cancelAction();
              }}
            >
              Confirm cancel
            </Button>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const renderMenu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => cancelOrder(orderData.id)}
        disabled={isOrderStatusClosed || isUnCancelableStrategy || isInFlight || readOnly}
        className={clsx(readOnly && styles.dropdownItemDisabled)}
      >
        {addTooltipIfNeeded("Cancel this order")}
      </Menu.Item>
      {isOpenFin && (
        <Menu.Item key="2" onClick={() => console.log("Undock")}>
          Undock
        </Menu.Item>
      )}
      {!isOpenFin && !isModalOrder && (
        <Menu.Item
          key="2"
          onClick={() => {
            showAuctionCard(orderData);
          }}
        >
          See details
        </Menu.Item>
      )}
    </Menu>
  );
  const strategy = orderStrategyOptions.find(obj => obj.value === orderData.strategy);
  const orderType = orderTypeOptions.find(obj => obj.value === orderData.type);
  const sideOption = orderSideOptions.find(obj => obj.value === orderData.side);
  const sideOptionLeg2 = orderSideOptions.find(obj => obj.value === orderData.sideLeg2);

  // Flywheel visibility
  const flywheelElement = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(flywheelElement, () => setFlywheelPrice(0));

  const [flywheelPrice, setFlywheelPrice] = useState<number>(0);

  const handleReplaceOrder = newPrice => {
    const newOrder = {
      ...orderData,
    };

    newOrder[OrderFieldsEnum.price] = newPrice;

    replaceOrder(newOrder as ReplaceOrderModel);

    // Reset price and hide Flywheel
    setFlywheelPrice(0);
  };

  return (
    <div className={clsx(styles.order, !isModalOrder && styles.orderBorder)}>
      {!isModalOrder && (
        <div className={styles.orderTitle}>
          <span className={styles.titleText}>
            {ndfInstrumentStripper(orderData.instrumentId)} {orderData.product && orderData.product.toUpperCase()}{" "}
            {orderType ? orderType.label : ""}
            {/* {strategy && strategy.value !== OrderStrategy.Common ? ` ${strategy.label}` : ""} : */}
          </span>

          <div className={styles.orderActions}>
            {cantorAlgoEnabled && (
              <Button
                size="small"
                className={styles.editButton}
                disabled={isOrderStatusClosed || isInFlight || readOnly}
                onClick={event => {
                  event.preventDefault();
                  setFlywheelPrice(orderData.price);
                }}
              >
                <IconCaretUpDown className={styles.orderActionIcon} />
              </Button>
            )}

            <div ref={flywheelElement}>
              {!!flywheelPrice && (
                <FlywheelMenu
                  basePrice={flywheelPrice}
                  orderSide={sideOption?.value ? sideOption.value : ""}
                  origin="orderPanelReplace"
                  quantity={orderData.quantity}
                  actionOrder={handleReplaceOrder}
                  instrumentId={orderData.instrumentId}
                  pip={pip}
                  clickType={marketSettings.clickType}
                  increment=""
                  testid={"replace_flywheel"}
                ></FlywheelMenu>
              )}
            </div>

            {isOrderStatusClosed || isUnCancelableStrategy || isInFlight || readOnly ? (
              addTooltipIfNeeded(
                <Button size="small" icon="stop" disabled={true} className={styles.antButtonDisabled} />
              )
            ) : (
              <Dropdown
                placement="bottomRight"
                overlay={confirmCancelationMenu(cancelOrders)}
                trigger={orderSettings.confirmCancelation ? ["click"] : []}
              >
                <div
                  className={styles.cancelButton}
                  onClick={() => !orderSettings.confirmCancelation && cancelOrder(orderData.id)}
                >
                  <IconCancel className={styles.orderActionIcon} />
                </div>
              </Dropdown>
            )}

            {cantorAlgoEnabled && (
              <Button
                size="small"
                className={styles.editButton}
                // HEREZ: Can I edit only one of them or the 2 have to be editable?
                disabled={isOrderStatusClosed || isInFlight || readOnly}
                onClick={() => showAuctionCard({ ...orderData, editing: true })}
              >
                <IconEdit className={styles.orderActionIcon} />
              </Button>
            )}

            <Button
              size="small"
              className={styles.editButton}
              disabled={!isOrderStatusClosed || isInFlight || readOnly}
              onClick={() => removeClosedOrdersFromActiveOrders(orderData?.id)}
            >
              <IconClear className={styles.orderActionIcon} />
            </Button>

            <Dropdown
              placement="bottomRight"
              overlay={renderMenu}
              trigger={["click"]}
              // HEREZ: TEMP: disabled temporarily to test
              // disabled={isOrderStatusClosed || isUnCancelableStrategy || isInFlight}
            >
              <Button size="small" icon="more" className={styles.orderActionIcon} />
            </Dropdown>
          </div>
        </div>
      )}

      {/* HEREZ
      <div>{isOrderStatusClosed ? "Overall status is closed" : "Overall status is NOT closed"}</div>
      <div>{isInFlight ? "It's overall inflight" : "It's not overall inflight"}</div> */}

      <OrderDetail
        orderData={orderData}
        isModalOrder={isModalOrder}
        typeName={orderData?.type === OrderType.Oco ? OrderFieldsEnum.orderTypeLeg1 : OrderFieldsEnum.type}
        priceName={OrderFieldsEnum.price}
        stopLossPriceName={OrderFieldsEnum.stopLossPrice}
        sideOption={sideOption}
        quantityFilledName={OrderFieldsEnum.quantityFilled}
        statusName={OrderFieldsEnum.status}
        showOrderDetails={showOrderDetails}
        showAuctionCard={showAuctionCard}
        orderStatusOverrides={orderStatusOverrides}
        isOrderStatusClosed={isOrderStatusClosed}
        isUnCancelableStrategy={isUnCancelableStrategy}
        isInFlight={isInFlight}
        readOnly={readOnly}
        cancelOrder={cancelOrder}
        renderMenu={renderMenu}
        disableActions={false}
      />

      {orderData.type === OrderType.Oco && (
        <OrderDetail
          orderData={orderData}
          isModalOrder={isModalOrder}
          typeName={OrderFieldsEnum.orderTypeLeg2}
          priceName={OrderFieldsEnum.priceLeg2}
          stopLossPriceName={OrderFieldsEnum.stopLossPriceLeg2}
          sideOption={sideOptionLeg2}
          quantityFilledName={OrderFieldsEnum.quantityFilledLeg2}
          statusName={OrderFieldsEnum.statusLeg2}
          showOrderDetails={showOrderDetailsLeg2}
          showAuctionCard={showAuctionCard}
          orderStatusOverrides={orderStatusOverrides}
          isOrderStatusClosed={isOrderStatusClosed}
          isUnCancelableStrategy={isUnCancelableStrategy}
          isInFlight={isInFlight}
          readOnly={readOnly}
          cancelOrder={cancelOrder}
          renderMenu={renderMenu}
          disableActions={true}
        />
      )}
    </div>
  );
}

export default connect(
  (state: any, ownProps: any) => ({
    orderSettings: getOrdersSettings(state),
    orderStatusOverrides: getStatusOverrides(state),
    pip: pipForSymbol(state, ownProps.orderData.instrumentId),
    marketSettings: getMarketSettings(state),
    readOnly: getOrdersReadOnly(state),
    cantorAlgoEnabled: getCantorAlgoEnabled(state),
  }),
  {
    cancelOrder: cancelOrderAction,
    showAuctionCard: setAuctionCard,
    replaceOrder: submitReplaceOrder,
    removeClosedOrdersFromActiveOrders: removeClosedOrdersFromActiveOrders,
  }
)(Order);

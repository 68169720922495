import { getDefaultOrderQuantityFromIndex, CUSTOM_SIZE_UNIT_OPTIONS } from "../state/settings/settingsState";
import { isCryptoCurrency, isNDFCurrency } from "./util";
import { appLocale } from "./constants";

export const toMShorthand = (value: string): string => {
  const shorthandValue = toPriceShorthand(value);

  // If the rounded value is 0, but true value is > 0, show the value as 0.25M.
  // TODO: Why is this necessary? This function could just be replaced with `toPriceShorthand`.
  if (shorthandValue === "0" && parseInt(value, 10) > 0) {
    return "0.25M";
  }

  return shorthandValue;
};

// Converts a supplied size value to an abbreviated form, rounded as needed.
export const toPriceShorthand = (value: string): string => {
  // Values have to be above zero.
  const valueFloat: number = parseFloat(value);
  if (!valueFloat || valueFloat < 0) {
    return "";
  }

  // Determine the scale of the supplied value.
  let scale = 1;
  let scaleMarker = "";
  if (valueFloat >= 1000000000) {
    scale = 1000000000;
    scaleMarker = CUSTOM_SIZE_UNIT_OPTIONS.B;
  } else if (valueFloat >= 1000000) {
    scale = 1000000;
    scaleMarker = CUSTOM_SIZE_UNIT_OPTIONS.M;
  } else if (valueFloat >= 1000) {
    scale = 1000;
    scaleMarker = CUSTOM_SIZE_UNIT_OPTIONS.K;
  }

  // Scale large numbers down, and round them to the nearest 0.25.
  if (scale > 1) {
    const valueScaledDown = valueFloat / scale;
    const valueRounded = roundToNearestDecimal(valueScaledDown, 0.25);
    return `${valueRounded}${scaleMarker}`;
  }

  // For smaller numbers, round to a maximum of five digits, regardless of decimal placement.
  const valueInt = Math.min(parseInt(value, 10) || 0, 999);
  const precision = 5 - valueInt.toString().length;
  return valueFloat.toFixed(precision).replace(/\.?0+$/g, "");
};

// Rounds a number to the nearest decimal interval you provide.
// Example: Args (8.73, 0.1) returns 8.7; args (8.73, 0.25) returns 8.75.
export const roundToNearestDecimal = (num: number, interval: number): number => {
  // Only round when given a fractional value between 0 and 1.
  if (interval <= 0 || interval >= 1) {
    return num;
  }

  // Round the number to the specified level of precision.
  const intervalPrecision = interval.toString().split(".")[1].length;
  const numRounded = parseFloat((Math.round(num / interval) * interval).toFixed(intervalPrecision));

  return numRounded;
};

// Rounds a given number to the nearest non-zero million.
export const roundToNearestMillion = (num: number): number => {
  const MILLION = 1000000;
  if (num && num < MILLION) {
    return MILLION;
  }
  return num ? Math.round(num / MILLION) * MILLION : MILLION;
};

export enum SettingsType {
  marketSettings = "marketSettings",
  ribbonSettings = "ribbonSettings",
  currencySettings = "currencySettings",
  ordersSettings = "ordersSettings",
}

export const getNotionalPriorityBasedOnSettings = (
  orderRibbonSettings,
  currencySettingsBySymbol,
  marketSettingsNotionalValue,
  blankFieldIsSupported?
): string => {
  let type = SettingsType.marketSettings;
  if (
    orderRibbonSettings &&
    orderRibbonSettings.showOrderRibbon &&
    orderRibbonSettings.selectedDefaultQuantityIndex &&
    orderRibbonSettings.selectedDefaultQuantityIndex.length > 0 &&
    isNaN(orderRibbonSettings.selectedDefaultQuantityIndex) === false
  ) {
    type = SettingsType.ribbonSettings;
  } else if (
    currencySettingsBySymbol &&
    (currencySettingsBySymbol.quantity ||
      currencySettingsBySymbol.quantity === 0 ||
      (blankFieldIsSupported && currencySettingsBySymbol.quantity === ""))
  ) {
    type = SettingsType.currencySettings;
  } else if (marketSettingsNotionalValue && (marketSettingsNotionalValue || marketSettingsNotionalValue === 0)) {
    type = SettingsType.marketSettings;
  }
  return type;
};

const DEFAULT_SELECTED_QUANTITY = 1000000;

export const getQuantityBasedOnSettings = (
  orderRibbonSettings: any,
  currencySettingsBySymbol: any,
  marketSettingsNotionalValue: number,
  emptyStringValueIsSupported: boolean
): number => {
  let quantity = DEFAULT_SELECTED_QUANTITY;
  const notionalPriority = getNotionalPriorityBasedOnSettings(
    orderRibbonSettings,
    currencySettingsBySymbol,
    marketSettingsNotionalValue,
    emptyStringValueIsSupported || false
  );
  if (notionalPriority === SettingsType.ribbonSettings) {
    const selectedDefaultQuantity = getDefaultOrderQuantityFromIndex(
      orderRibbonSettings.selectedDefaultQuantityIndex,
      orderRibbonSettings.orderSizes
    );
    quantity = selectedDefaultQuantity * 1000000;
  } else if (notionalPriority === SettingsType.currencySettings) {
    quantity = currencySettingsBySymbol.quantity;
  } else if (notionalPriority === SettingsType.marketSettings) {
    quantity = marketSettingsNotionalValue;
  }

  return quantity;
};

export const formatSizeBasedOnSettings = (
  value,
  orderRibbonSettings,
  currencySettingsBySymbol,
  marketSettingsNotionalValue: number,
  symbol
): number => {
  const quantityString: string = value.replace(/,/g, "");
  let quantity: number = Number(quantityString);

  if (quantityString === "") {
    quantity = getQuantityBasedOnSettings(
      orderRibbonSettings,
      currencySettingsBySymbol,
      marketSettingsNotionalValue,
      false
    );
  } else if (isNaN(quantity)) {
    // Invalid quanities should become 0
    quantity = 0;
  } else {
    if (!isCryptoCurrency(symbol)) {
      quantity = Math.round(quantity);
    }

    // Enforce a cap on valid quantities.
    // quantity = Math.min(quantity, 999999999);
    quantity = Math.min(quantity, 999999999999999);
  }

  return quantity;
};

// integerFormatter adds commas for formatting. Won't work for floating point with 4+ decimal places
// (i.e. "1000" becomes "1,000", "10.654321" becomes "10.654,321" which is bad formatting)
export const integerFormatter = (value): string => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// Prevents the user from typing anything other than positive integers, but not from copy/pasting
// in bad characters.
export const positiveNumberEnforcer = event => {
  const modifierKey = event.metaKey || event.ctrlKey || event.altKey || event.shiftKey;
  if ((isSingleNonNumericCharacter(event.key) || modifierKey) && event.key !== "Backspace") {
    event.preventDefault();
  }
};

export const positiveNumberMaxLengthEnforcer = (event, maxLength) => {
  positiveNumberEnforcer(event);

  const modifierKey = event.metaKey || event.ctrlKey || event.altKey || event.shiftKey;
  if (!isSingleNonNumericCharacter(event.key) && !modifierKey) {
    const newNumberLength = event.target.value.length + 1;
    if (newNumberLength > maxLength) {
      event.preventDefault();
    }
  }
};

// Like `positiveNumberEnforcer` but allows decimals.
export const positiveValueEnforcer = event => {
  const modifierKey = event.metaKey || event.ctrlKey || event.altKey || event.shiftKey;
  if (
    (isSingleNonNumericCharacter(event.key) || modifierKey) &&
    event.key !== "Backspace" &&
    !(event.key === "." && !event.target.value.includes("."))
  ) {
    event.preventDefault();
  }
};

// Like `positiveNumberMaxLengthEnforcer` but allows decimals.
export const positiveValueMaxLengthEnforcer = (event, maxLength = 3, maxPrecision = 4) => {
  positiveValueEnforcer(event);

  const modifierKey = event.metaKey || event.ctrlKey || event.altKey || event.shiftKey;
  const { value, selectionStart } = event.target;
  if (!isSingleNonNumericCharacter(event.key) && !modifierKey && value.length) {
    const valueParts = value.split(".");
    let integerLength = valueParts?.[0]?.length ?? 0;
    let decimalLength = valueParts?.[1]?.length ?? 0;
    if (selectionStart <= integerLength) {
      integerLength += 1;
    } else {
      decimalLength += 1;
    }
    if (integerLength > maxLength || decimalLength > maxPrecision) {
      event.preventDefault();
    }
  }
};

// Regex looks for a single character. Looking for more would result in non-alphabet keys being blocked (e.g. Backspace, Enter)
export const isSingleNonNumericCharacter = (value): boolean => {
  return value.match(/[0-9]$/) === null;
};

export const isValidTimestamp = (timestamp): boolean => {
  return timestamp.match(/^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/) !== null;
};

const formatUtil = () => {
  console.log("Deconstruct the function you want.");
};

export const displayInstrumentLabel = (value: string): string => {
  const valueWithoutId: string = ccyPairIdStripper(value);
  const valueWithoutIdSplittedByTenor: string[] = valueWithoutId?.split("-");

  const forwardTenor = valueWithoutIdSplittedByTenor?.length > 1 ? valueWithoutIdSplittedByTenor[1] : "";

  if (isNDFCurrency(valueWithoutId)) {
    return forwardTenor ? `:NDF ${forwardTenor}` : ":NDF";
  }

  return forwardTenor ? `:${forwardTenor}` : "";
};

export const ccyPairIdStripper = symbol => {
  const symbolWithoutId = symbol?.split("_")[0];
  return symbolWithoutId;
};

export const ndfInstrumentStripper = (symbol: string): string => {
  const symbolWithoutId = ccyPairIdStripper(symbol);
  const symbolWithoutIdSplittedByTenor = symbolWithoutId?.split("-");

  return symbolWithoutIdSplittedByTenor.length > 0 ? symbolWithoutIdSplittedByTenor[0] : "";
};

export const strippedCurrencyLabel = (symbol: string): string => {
  const symbolWithoutId = ccyPairIdStripper(symbol);
  const stripped: string[] = symbolWithoutId ? ndfInstrumentStripper(symbolWithoutId)?.split("/") : [""];

  if (stripped?.length > 1) {
    return `${stripped[0]}${stripped[1]}`;
  }

  return "";
};

// temporary override
export const priceFormatValidator = (event, value): void => {
  priceFormatValidatorNoShortcutKeys(event, value, true);
};

// Use the keydown event to prevent invalid input but allow special characters.
export const priceFormatValidatorNoShortcutKeys = (
  event: any,
  value: any,
  includeShorthandKeys: boolean = false
): void => {
  const keyboardKeys: string[] = ["Backspace", "Tab", "ArrowLeft", "ArrowRight"];
  if (includeShorthandKeys) {
    keyboardKeys.push("k", "m");
  }
  if (
    !keyboardKeys.includes(event.key) &&
    !(event.key === "a" && (event.metaKey || event.ctrlKey)) && // Allow select all.
    !(value && !value.toString().includes(".") && event.key === ".")
  ) {
    positiveNumberEnforcer(event);
  }
};

export const removeCommasAndAddMultiplierShortcuts = (value: any): string => {
  let newValue = value.toString().replace(/,/g, "");

  // Replace a 'k' or 'm' with '000' or '000000' respectively.
  const key = newValue.slice(-1);
  if (key === "k" || key === "m") {
    newValue = newValue.slice(0, -1);

    // If the user has 0 or empty in card size, default to 1 then multiply.
    const multiplier = key === "k" ? 1000 : 1000000;
    newValue = ((newValue === "" || newValue === "0" ? 1 : parseFloat(newValue)) * multiplier).toString();

  }

  // Limit decimal precision to four places.
  const valueParts = newValue.split(".", 2);
  if (valueParts.length > 1) {
    valueParts[1] = valueParts[1].slice(0, 4);
    newValue = valueParts.join(".");
  }

  return newValue;
};

export const addCommas = (value: any): string => {
  const isUserInput = typeof value === "string";
  if (!isUserInput && !value && value !== 0) {
    return "";
  }

  let valueParts;
  if (isUserInput) {
    // Split the value at the decimal point.
    valueParts = value.split(".", 2);
  } else {
    // Deal with this number rounded to four decimal places (since float numbers are inexact).
    const valueScaledUp = Math.round(parseFloat(value) * 10000)
      .toString()
      .padStart(5, "0");
    valueParts = [valueScaledUp.slice(0, -4), valueScaledUp.slice(-4)];
  }

  // Add a comma every three digits from the right.
  valueParts[0] = valueParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  let newValue = valueParts.join(".");

  // Get rid of any trailing zeros if this is saved numerical data.
  if (!isUserInput) {
    newValue = newValue.replace(/\.?0+$/g, "");
  }

  return newValue;
};

export const capitalizeFirstLetter = (pString: string): string => {
  if (typeof pString !== "string") {
    return pString;
  } else {
    let newString = "";
    if (pString && pString.length > 0) {
      newString = pString.charAt(0).toUpperCase() + (pString.length > 1 ? pString.slice(1) : "");
    }

    return newString;
  }
};

export default formatUtil;

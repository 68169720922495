import { ColDef } from "@ag-grid-community/core";
import { AgGridReact as AgGridReactType } from "@ag-grid-community/react/lib/agGridReact";
import { Button, Icon } from "antd";

import { getAllOrdersSelector, getSelectedRowsOrderData } from "../../state/orders/ordersState";
import clsx from "clsx";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";

import isToday from "date-fns/isToday";
import { orderStatusMap } from "../../lib/formatters";
import { appLocale } from "../../lib/constants";
import Blotter from "../../shared/blotter/Blotter";
import OrderStatusLabel from "../../shared/OrderStatusLabel";
import { updateSelectedOrderHistoryRows } from "../../state/app/appState";

import {
  pauseOrdersAction,
  resumeOrdersAction,
  cancelOrdersAction,
} from "../../state/orders/pauseResumeOrders/pauseResumeOrdersActions";

import {
  getIsEverySelectedOrderCancelable,
  getIsEverySelectedOrderPausable,
  getIsEverySelectedOrderResumable,
  getSelectedOrders,
} from "../../state/orders/pauseResumeOrders/pauseResumeOrdersState";

import { getOrdersIdsByCancelableOrders, getOrdersIdsByStatuses, getPriceStringWithPrecision } from "../../lib/util";
import { capitalizeFirstLetter } from "../../lib/formatUtil";
import {
  ClosedOrderStatus,
  OrderAction,
  OrderSide,
  OrderStatus,
  OrderStrategy,
  OrderType,
  OrderTypeLabel,
  ProductType,
} from "../../state/orders/types";
import {
  DisplayOrdersByTimeType,
  getBlotterSettings,
  getCantorAlgoEnabled,
  getOrdersReadOnly,
  getStatusOverrides,
} from "../../state/settings/settingsState";
import {
  basicUrgencyOptions,
  orderLifespanOptions,
  pegFloorTypeOptions,
  pegReferencePriceTypeOptions,
  priceTypeOptions,
  urgencyOptions,
} from "../currency/AuctionOrderForm/selectOptions";
import ActionsBlotterRenderer from "./renderers/ActionsBlotterRenderer";
import CheckboxCellRenderer from "./renderers/CheckboxCellRenderer";
import SelectAllCheckboxCellRenderer from "./renderers/SelectAllCheckboxCellRenderer";
import style from "./AnalyticsPanel.module.scss";

const dashSymbol = "\u2014";

const accordionCloseIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // fillRule="evenodd"
      // clipRule="evenodd"
      d="M3.29289 5.29289C3.68342 4.90237 4.31658 4.90237 4.70711 5.29289L8 8.58579L11.2929 5.29289C11.6834 4.90237 12.3166 4.90237 12.7071 5.29289C13.0976 5.68342 13.0976 6.31658 12.7071 6.70711L8 11.4142L3.29289 6.70711C2.90237 6.31658 2.90237 5.68342 3.29289 5.29289Z"
      fill="inherit"
    />
  </svg>
);

const accordionOpenIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // fillRule="evenodd"
      // clipRule="evenodd"
      d="M5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929L8.58579 8L5.29289 4.70711C4.90237 4.31658 4.90237 3.68342 5.29289 3.29289C5.68342 2.90237 6.31658 2.90237 6.70711 3.29289L11.4142 8L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071Z"
      fill="inherit"
    />
  </svg>
);

const blotterPartialFillColumnDefs: ColDef[] = [
  {
    headerName: "Trade date",
    field: "tradeDate",
    colId: "tradeDate",
    width: 102,
    minWidth: 64,
    cellRendererFramework: ({ value }) => {
      return value ? (
        moment(value)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD")
      ) : (
        <>&mdash;</>
      );
    },
  },
  {
    headerName: "Trade time",
    field: "tradeDate",
    colId: "tradeTime",
    width: 130,
    minWidth: 64,
    cellRendererFramework: ({ value }) => {
      return value ? (
        moment(value)
          .tz(moment.tz.guess())
          .format("HH:mm:ss.SSS z")
      ) : (
        <>&mdash;</>
      );
    },
  },
  {
    headerName: "Size",
    field: "quantity",
    colId: "quantity",
    valueFormatter: ({ value }) =>
      value ? value.toLocaleString(appLocale, { maximumFractionDigits: 4 }) : <>&mdash;</>,
    width: 96,
    minWidth: 64,
  },
  {
    headerName: "Price",
    field: "price",
    colId: "price",
    cellRendererFramework: ({ data, value }) => {
      if (data && value) {
        return getPriceStringWithPrecision(value, data.instrumentId);
      }
      return <>&mdash;</>;
    },
    width: 102,
    minWidth: 64,
  },
  {
    headerName: "ExecId",
    field: "execId",
    colId: "execId",
    width: 178,
    minWidth: 64,
  },
  {
    headerName: "Counter party",
    field: "counterParty",
    colId: "counterParty",
    cellRendererFramework: ({ value }) => {
      return value?.length > 0 ? value : <>&mdash;</>;
    },
    width: 154,
    minWidth: 64,
  },
];

function OrderHistory({
  cantorAlgoEnabled,
  displayOrdersByTime,
  id,
  orders,
  saveSettings,
  setDataLoaded,
  tabType,
  tableSettings,
  updateSelectedOrderHistoryRows,
  pauseOrders,
  resumeOrders,
  cancelOrders,
  selectedOrders,
  isEverySelectedOrderPausable,
  isEverySelectedOrderResumable,
  isEverySelectedOrderCancelable,
  readOnly,
}) {
  const [orderHistoryTableSettings, setOrderHistoryTableSettings] = useState(tableSettings);

  const gridRef = useRef<AgGridReactType>(null);

  const setTableSettings = ({ columns, filters, sorts }) => {
    tableSettings = { columns, filters, sorts };
    saveSettings(id, tableSettings);
  };

  const cellRenderer = (data, value) => {
    if (data) {
      if (data.type === OrderType.Oco) {
        return (
          <>
            <div>{value ? value : dashSymbol}</div>
            <div>{value ? value : dashSymbol}</div>
          </>
        );
      } else {
        return value ? value : dashSymbol;
      }
    }

    return <>&mdash;</>;
  };

  useEffect(() => {
    setOrderHistoryTableSettings(tableSettings);
  }, [tableSettings]);

  useEffect(() => {
    if (gridRef.current !== null) {
      gridRef.current.api?.deselectAll();
      const selectedRows = gridRef.current.api?.getSelectedRows();
      updateSelectedOrderHistoryRows(selectedRows);
    }
  }, [displayOrdersByTime]);

  // placing this inside the component so updateSelectedOrderHistoryRows works
  const defaultColDef = {
    onCellClicked: params => {
      if (updateSelectedOrderHistoryRows) {
        const selectedRows = params.api.getSelectedRows();
        updateSelectedOrderHistoryRows(selectedRows);
      }
    },
  };

  const optionalAlgoColumnDefs: ColDef[] = [
    {
      headerName: "Start Time",
      field: "startTime",
      colId: "startTime",
      filter: "agDateColumnFilter",
      width: 150,
      minWidth: 64,
      cellRendererFramework: ({ value }) => {
        return value ? (
          moment(value)
            .tz(moment.tz.guess())
            .format("HH:mm:ss.SSS z")
        ) : (
          <>&mdash;</>
        );
      },
    },
    {
      headerName: "End Time",
      field: "endTime",
      colId: "endTime",
      filter: "agDateColumnFilter",
      width: 150,
      minWidth: 64,
      cellRendererFramework: ({ value }) => {
        return value ? (
          moment(value)
            .tz(moment.tz.guess())
            .format("HH:mm:ss.SSS z")
        ) : (
          <>&mdash;</>
        );
      },
    },
    // TODO - when Vertex updates to include this info in orders, uncomment to display
    // you may need to re-run the db script to clear user's columns settings if columns appear in the wrong order

    // {
    //   headerName: "Raw Price",
    //   field: "rawPrice",
    //   colId: "rawPrice",
    //   filter: "agNumberColumnFilter",
    //   cellRendererFramework: ({ data, value }) => {
    //     if (data && value !== undefined) {
    //       if (data.type === OrderType.Market) {
    //         return "0";
    //       }
    //       return getPriceStringWithPrecision(value, data.instrumentId);
    //     }
    //     return <>&mdash;</>;
    //   },
    //   width: 102,
    //   minWidth: 64,
    // },
    // {
    //   headerName: "All-in Price",
    //   field: "allInPrice",
    //   colId: "allInPrice",
    //   cellRendererFramework: ({ data, value }) => {
    //     if (data && value !== undefined) {
    //       if (data.type === OrderType.Market) {
    //         return "0";
    //       }
    //       return getPriceStringWithPrecision(value, data.instrumentId);
    //     }
    //     return <>&mdash;</>;
    //   },
    //   width: 102,
    //   minWidth: 64,
    // },
    // {
    //   headerName: "Execution Date",
    //   field: "executionDate",
    //   colId: "executionDate",
    //   filter: "agDateColumnFilter",
    //   width: 150,
    //   minWidth: 64,
    //   cellRendererFramework: ({ value }) => {
    //     return value ? (
    //       moment(value)
    //         .tz(moment.tz.guess())
    //         .format("YYYY-MM-DD")
    //     ) : (
    //       <>&mdash;</>
    //     );
    //   },
    // },
    {
      headerName: "Min Quantity",
      field: "minimumExecutedQuantity",
      colId: "minimumExecutedQuantity",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) =>
        value ? value.toLocaleString(appLocale, { maximumFractionDigits: 4 }) : <>&mdash;</>,
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Price Type",
      field: "priceType",
      colId: "priceType",
      filterParams: {
        valueFormatter: params => capitalizeFirstLetter(params.value),
      },
      cellRendererFramework: ({ value }) => {
        let ptObj = priceTypeOptions.find(val => val.value.toString() === value);
        return value ? ptObj ? ptObj.label : value : <>&mdash;</>;
      },
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Upper band price",
      field: "upperBandPrice",
      colId: "upperBandPrice",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Upper band % vol",
      field: "upperBandTargetPercentVolume",
      colId: "upperBandTargetPercentVolume",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Lower band price",
      field: "lowerBandPrice",
      colId: "lowerBandPrice",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Lower band % vol",
      field: "lowerBandTargetPercentVolume",
      colId: "lowerBandTargetPercentVolume",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Disp qty",
      field: "maximumFloor",
      colId: "maximumFloor",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) =>
        value ? value.toLocaleString(appLocale, { maximumFractionDigits: 4 }) : <>&mdash;</>,
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Before fix %",
      field: "allocationBeforeFix",
      colId: "allocationBeforeFix",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Would price",
      field: "wouldPrice",
      colId: "wouldPrice",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Would size %",
      field: "wouldSizePercent",
      colId: "wouldSizePercent",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Max part rate %",
      field: "maxParticipationRate",
      colId: "maxParticipationRate",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Target part rate",
      field: "targetParticipationRate",
      colId: "targetParticipationRate",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Urgency",
      field: "urgency",
      colId: "urgency",
      cellRendererFramework: ({ data, value }) => {
        let options = [OrderStrategy.Twap, OrderStrategy.Vwap, OrderStrategy.Bfix, OrderStrategy.Fixing].includes(
          data.strategy
        )
          ? basicUrgencyOptions
          : urgencyOptions;
        let uObj = options.find(val => val.value === value);
        return value ? uObj ? uObj.label : value : <>&mdash;</>;
      },
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Slice size",
      field: "sliceSize",
      colId: "sliceSize",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) =>
        value ? value.toLocaleString(appLocale, { maximumFractionDigits: 4 }) : <>&mdash;</>,
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Slice interval",
      field: "sliceInterval",
      colId: "sliceInterval",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Randomization %",
      field: "randomizationPercent",
      colId: "randomizationPercent",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Strategy config",
      field: "strategyConfig",
      colId: "strategyConfig",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Peg ref price type",
      field: "pegReferencePriceType",
      colId: "pegReferencePriceType",
      cellRendererFramework: ({ value }) => {
        let prptoObj = pegReferencePriceTypeOptions.find(val => val.value === value);
        return value ? prptoObj ? prptoObj.label : value : <>&mdash;</>;
      },
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Peg offset",
      field: "pegOffset",
      colId: "pegOffset",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Peg floor type",
      field: "pegFloorType",
      colId: "pegFloorType",
      cellRendererFramework: ({ value }) => {
        let pftoObj = pegFloorTypeOptions.find(val => val.value === value);
        return value ? pftoObj ? pftoObj.label : value : <>&mdash;</>;
      },
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Peg floor price",
      field: "pegFloorPrice",
      colId: "pegFloorPrice",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Peg floor float pts",
      field: "pegFloorFloatingPoints",
      colId: "pegFloorFloatingPoints",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ value }) => (value ? value : <>&mdash;</>),
      width: 110,
      minWidth: 64,
    },
  ];

  const pauseSelectedOrders = () => {
    // pauseResumeHelper
    // console.log(selectedOrders);

    // Filter orders by selected equal to true and pausables
    const selectedOrdersFilteredArr = getOrdersIdsByStatuses(selectedOrders, OrderAction.Pause, readOnly);

    // pauseResumeHelper
    // console.log("selectedOrdersFilteredArr");
    // console.log(selectedOrdersFilteredArr);

    if (selectedOrdersFilteredArr.length > 0) {
      pauseOrders(selectedOrdersFilteredArr);
    }
  };

  const resumeSelectedOrders = () => {
    // pauseResumeHelper
    // console.log(selectedOrders);

    // Filter orders by selected equal to true and paused
    const selectedOrdersFilteredArr = getOrdersIdsByStatuses(selectedOrders, OrderAction.Resume, readOnly);

    // pauseResumeHelper
    // console.log("selectedOrdersFilteredArr");
    // console.log(selectedOrdersFilteredArr);

    if (selectedOrdersFilteredArr.length > 0) {
      resumeOrders(selectedOrdersFilteredArr);
    }
  };

  const cancelSelectedOrders = () => {
    // pauseResumeHelper
    // console.log("cancelSelectedOrders");
    // console.log(selectedOrders);

    // Filter orders by selected equal to true and cancelables
    const selectedOrdersFilteredArr = getOrdersIdsByCancelableOrders(selectedOrders, readOnly);

    // pauseResumeHelper
    // console.log("selectedOrdersFilteredArr");
    // console.log(selectedOrdersFilteredArr);

    if (selectedOrdersFilteredArr.length > 0) {
      cancelOrders(selectedOrdersFilteredArr);
    }
  };

  const blotterColumnDefs: ColDef[] = [
    {
      headerName: "Select Orders",
      field: "checkboxCol",
      colId: "checkboxCol",
      headerComponent: "selectAllCheckboxCellRenderer",
      cellRenderer: "checkboxCellRenderer",
      minWidth: 38,
      maxWidth: 38,
      pinned: "left",
      // minWidth: 64,
      // headerComponentFramework: () => {
      //   return (
      //     <Checkbox
      //       checked={selectedOrders["selectAll"]}
      //       onChange={e => {
      //         console.log(selectedOrders);
      //         toggleVisibleOrders(!selectedOrders["selectAll"]);
      //       }}
      //     />
      //   );
      // },
      // headerCellRenderer: "selectAllCheckboxCellRenderer",
      // headerCheckboxSelection: true,
      // checkboxSelection: true,se
      // onRowSelected: "(event) => handleChange(event)",
      // onRowClicked={(e) => console.log("row clicked", e.rowIndex)},
      // filter: "agNumberColumnFilter",
    },
    {
      colId: "",
      headerName: " ",
      maxWidth: 32,
      minWidth: 32,
      lockPosition: true,
      menuTabs: [],
      sortable: false,
      resizable: false,
      lockVisible: true,
      onCellClicked: params => {
        // don't toggle the row selection
        params.node.setSelected(!params.node.isSelected());
      },
      // toolPanelClass adds styling to the checkbox row for this column in the tool panel
      toolPanelClass: ["hideColumnFromColumnFilter"],
      cellRendererFramework: params => {
        return (
          <span
            className={"masterDetailCell"}
            onClick={() => {
              params.node.expanded ? params.node.setExpanded(false) : params.node.setExpanded(true);
              // ag grid doesn't see this as a data change so need to force it to refresh
              params.api.refreshCells({ rowNodes: [params.node], force: true });
            }}
          >
            {params.node.expanded ? accordionCloseIcon : accordionOpenIcon}
          </span>
        );
      },
    },
    {
      headerName: "Creation Date",
      field: "createdDate",
      colId: "creationDate",
      filter: "agDateColumnFilter",
      width: 150,
      minWidth: 64,
      cellRendererFramework: ({ data, value }) => {
        if (data) {
          const creationDate = value
            ? moment(value)
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD")
            : dashSymbol;

          if (data.type === OrderType.Oco) {
            return (
              <>
                <div>{creationDate}</div>
                <div>{creationDate}</div>
              </>
            );
          } else {
            return <>{creationDate}</>;
          }
        }

        return <>&mdash;</>;
      },
    },
    {
      headerName: "Creation Time",
      field: "createdDate",
      colId: "creationTime",
      filter: "agDateColumnFilter",
      width: 152,
      minWidth: 64,
      cellRendererFramework: ({ data, value }) => {
        if (data) {
          const creationTime = value
            ? moment(value)
                .tz(moment.tz.guess())
                .format("HH:mm:ss.SSS z")
            : dashSymbol;

          if (data.type === OrderType.Oco) {
            return (
              <>
                <div>{creationTime}</div>
                <div>{creationTime}</div>
              </>
            );
          } else {
            return <>{creationTime}</>;
          }
        }

        return <>&mdash;</>;
      },
    },
    {
      headerName: "Instrument",
      field: "instrumentId",
      colId: "instrumentId",
      cellRendererFramework: ({ data, value }) => {
        if (data && value) {
          // Remove possible trailing numbers attached to the instrument ID.
          const cleanValue = value ? value.split("_")?.[0] ?? "" : "";

          if (data.type === OrderType.Oco) {
            return data.currency !== cleanValue.split("/")[0] ? (
              <>
                <div className={style.instrumentBox}>
                  {cleanValue}{" "}
                  <div className={style.dotBox}>
                    <span className={style.currencyDot} />
                  </div>
                </div>
                <div className={style.instrumentBox}>
                  {cleanValue}{" "}
                  <div className={style.dotBox}>
                    <span className={style.currencyDot} />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>{cleanValue}</div>
                <div>{cleanValue}</div>
              </>
            );
          } else {
            return data.currency !== cleanValue.split("/")[0] ? (
              <div className={style.instrumentBox}>
                {cleanValue}{" "}
                <div className={style.dotBox}>
                  <span className={style.currencyDot} />
                </div>
              </div>
            ) : (
              cleanValue
            );
          }
        }

        if (data?.type === OrderType.Oco) {
          return (
            <>
              <div>&mdash;</div>
              <div>&mdash;</div>
            </>
          );
        }

        return <>&mdash;</>;
      },
      width: 142,
      minWidth: 64,
    },
    {
      headerName: "Status",
      field: "status",
      colId: "status",
      filterParams: {
        valueFormatter: params => orderStatusMap[params.value],
      },
      cellRendererFramework: ({ node, value }) => {
        const updatedData = node.data;
        return OrderStatusRenderer({ updatedData, value });
      },
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Actions",
      field: "blotterActions",
      colId: "blotterActions",
      cellRenderer: "actionsBlotterRenderer",
      filter: false,
      minWidth: 140,
      maxWidth: 140,
      pinned: "right",
      suppressMenu: true,
    },
    {
      headerName: "Product",
      field: "product",
      colId: "product",
      cellRendererFramework: ({ data, value }) => {
        if (data) {
          const product = ProductType.Ndf === value ? "NDF" : ProductType.Spot === value ? "Spot" : dashSymbol;

          if (data?.type === OrderType.Oco) {
            return (
              <>
                <div>{product}</div>
                <div>{product}</div>
              </>
            );
          } else {
            return product;
          }
        }

        return <>&mdash;</>;
      },
      filterParams: {
        valueFormatter: params =>
          ProductType.Ndf === params.value ? "NDF" : ProductType.Spot === params.value ? "Spot" : "",
      },
      width: 118,
      minWidth: 64,
    },
    {
      headerName: "Side",
      field: "side",
      colId: "side",
      filterParams: {
        valueFormatter: params => capitalizeFirstLetter(params.value),
      },
      cellRendererFramework: ({ data, value }) => {
        if (data && value) {
          if (data.type === OrderType.Oco) {
            return (
              <>
                <div className={clsx(OrderSide.Buy === value ? style.buy : style.sell)}>
                  {OrderSide.Buy === value ? "Buy" : "Sell"}
                </div>
                <div className={clsx(OrderSide.Buy === data?.sideLeg2 ? style.buy : style.sell)}>
                  {OrderSide.Buy === data?.sideLeg2 ? "Buy" : "Sell"}
                </div>
              </>
            );
          } else {
            return (
              <div className={clsx(OrderSide.Buy === value ? style.buy : style.sell)}>
                {OrderSide.Buy === value ? "Buy" : "Sell"}
              </div>
            );
          }
        }

        if (data?.type === OrderType.Oco) {
          return (
            <>
              <div>&mdash;</div>
              <div>&mdash;</div>
            </>
          );
        }

        return <>&mdash;</>;
      },
      width: 98,
      minWidth: 64,
    },
    {
      headerName: "Filled",
      field: "quantityFilled",
      colId: "quantityFilled",
      cellRendererFramework: ({ data, value }) => {
        if (data) {
          const quantityFilled = value ? value.toLocaleString(appLocale, { maximumFractionDigits: 4 }) : dashSymbol;
          const quantityFilledLeg2 = data.quantityFilledLeg2
            ? data.quantityFilledLeg2.toLocaleString(appLocale, { maximumFractionDigits: 4 })
            : dashSymbol;

          if (data.type === OrderType.Oco) {
            return (
              <>
                <div>{quantityFilled}</div>
                <div>{quantityFilledLeg2}</div>
              </>
            );
          } else {
            return <>{quantityFilled}</>;
          }
        }

        return <>&mdash;</>;
      },
      filter: "agNumberColumnFilter",
      width: 104,
      minWidth: 64,
    },
    {
      headerName: "Size",
      field: "quantity",
      colId: "quantity",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ data, value }) => {
        // HEREZ: TODO: change undefined to appLocale
        const size = value ? value.toLocaleString(appLocale, { maximumFractionDigits: 4 }) : dashSymbol;

        if (data?.type === OrderType.Oco) {
          return (
            <>
              <div>{size}</div>
              <div>{size}</div>
            </>
          );
        } else {
          return size;
        }
      },
      width: 96,
      minWidth: 64,
    },
    {
      headerName: "Amount CCY",
      field: "currency",
      colId: "currency",
      cellRendererFramework: ({ data, value }) => cellRenderer(data, value),
      width: 146,
      minWidth: 64,
    },
    {
      headerName: "Price",
      field: "price",
      colId: "price",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ data, value }) => {
        if (data && value) {
          let price = getPriceStringWithPrecision(value, data.instrumentId);
          let priceLeg2 = getPriceStringWithPrecision(data.priceLeg2, data.instrumentId);

          if (data.type === OrderType.Oco) {
            if (data.orderTypeLeg1 === OrderType.Stop) {
              price = dashSymbol;
            }

            if (data.orderTypeLeg2 === OrderType.Stop) {
              priceLeg2 = dashSymbol;
            }

            return (
              <>
                <div>{price}</div>
                <div>{priceLeg2}</div>
              </>
            );
          } else {
            if (data.type === OrderType.Market) {
              return <>0</>;
            }
            return <>{price}</>;
          }
        }

        if (data?.type === OrderType.Oco) {
          return (
            <>
              <div>&mdash;</div>
              <div>&mdash;</div>
            </>
          );
        }

        return <>&mdash;</>;
      },
      width: 102,
      minWidth: 64,
    },
    {
      headerName: "Stop loss price",
      field: "stopLossPrice",
      colId: "stopLossPrice",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ data, value }) => {
        if (data && value) {
          let stopLossPrice = getPriceStringWithPrecision(value, data.instrumentId);
          let stopLossPriceLeg2 = getPriceStringWithPrecision(data.stopLossPriceLeg2, data.instrumentId);

          if (data.type === OrderType.Oco) {
            if (data.orderTypeLeg1 === OrderType.Limit) {
              stopLossPrice = dashSymbol;
            }

            if (data.orderTypeLeg2 === OrderType.Limit) {
              stopLossPriceLeg2 = dashSymbol;
            }

            return (
              <>
                <div>{stopLossPrice}</div>
                <div>{stopLossPriceLeg2}</div>
              </>
            );
          } else {
            return <>{value}</>;
          }
        }

        if (data?.type === OrderType.Oco) {
          return (
            <>
              <div>&mdash;</div>
              <div>&mdash;</div>
            </>
          );
        }

        return <>&mdash;</>;
      },
      width: 110,
      minWidth: 64,
    },
    {
      headerName: "Avg price",
      field: "averagePrice",
      colId: "averagePrice",
      filter: "agNumberColumnFilter",
      cellRendererFramework: ({ data, value }) => {
        if (data) {
          const avgPrice = value ? getPriceStringWithPrecision(value, data.instrumentId) : dashSymbol;

          if (data.type === OrderType.Oco) {
            return (
              <>
                <div>{avgPrice}</div>
                <div>{avgPrice}</div>
              </>
            );
          } else {
            return avgPrice;
          }
        }

        return <>&mdash;</>;
      },
      width: 126,
      minWidth: 64,
    },
    {
      headerName: "Slippage",
      field: "slippage",
      colId: "slippage",
      cellRendererFramework: ({ data, value }) => cellRenderer(data, value),
      width: 102,
      minWidth: 64,
    },
    {
      headerName: "Notional",
      field: "notional",
      colId: "notional",
      cellRendererFramework: ({ data, value }) => {
        if (data && value) {
          // Calculate a notional value based on the precise values shown in the Blotter.
          let bestValue = value;
          if (data.quantityFilled && data.averagePrice && data.instrumentId) {
            const filledSize = parseFloat(
              data.quantityFilled.toLocaleString(appLocale, { maximumFractionDigits: 4 }).replace(/,/g, "")
            );
            const avgPrice = parseFloat(
              getPriceStringWithPrecision(data.averagePrice, data.instrumentId).replace(/,/g, "")
            );
            if (filledSize && avgPrice) {
              const [firstCcy] = data.instrumentId.split(/(_|\/)/);
              bestValue = data.currency === firstCcy ? filledSize * avgPrice : filledSize / avgPrice;
            }
          }

          const bestValueFormatted =
            bestValue < 1
              ? (Math.floor(bestValue * 10000) / 10000).toLocaleString(appLocale, { maximumFractionDigits: 4 })
              : Math.floor(bestValue).toLocaleString(appLocale, { maximumFractionDigits: 0 });

          // Update the `data` object so this calculated value will be available for export.
          data.notional = bestValueFormatted;

          if (data.type === OrderType.Oco) {
            return (
              <>
                <div>{bestValueFormatted}</div>
                <div>{bestValueFormatted}</div>
              </>
            );
          } else {
            return bestValueFormatted;
          }
        }

        if (data?.type === OrderType.Oco) {
          return (
            <>
              <div>&mdash;</div>
              <div>&mdash;</div>
            </>
          );
        }

        return <>&mdash;</>;
      },
      filter: "agNumberColumnFilter",
      width: 120,
      minWidth: 64,
    },
    {
      headerName: "Fixing Date",
      field: "fixingDate",
      colId: "fixingDate",
      filter: "agDateColumnFilter",
      width: 138,
      minWidth: 64,
      cellRendererFramework: ({ data, value }) => {
        if (data) {
          const fixingDate = value
            ? moment(value)
                .utc()
                .format("YYYY-MM-DD")
            : dashSymbol;

          if (data.type === OrderType.Oco) {
            return (
              <>
                <div>{fixingDate}</div>
                <div>{fixingDate}</div>
              </>
            );
          } else {
            return fixingDate;
          }
        }

        return <>&mdash;</>;
      },
    },
    {
      headerName: "Settle Date",
      field: "settleDate",
      colId: "settleDate",
      filter: "agDateColumnFilter",
      width: 136,
      minWidth: 64,
      cellRendererFramework: ({ data, value }) => {
        if (data) {
          const settleDate = value
            ? moment(value)
                .utc()
                .format("YYYY-MM-DD")
            : dashSymbol;

          if (data.type === OrderType.Oco) {
            return (
              <>
                <div>{settleDate}</div>
                <div>{settleDate}</div>
              </>
            );
          } else {
            return settleDate;
          }
        }

        return <>&mdash;</>;
      },
    },
    {
      headerName: "Type",
      field: "type",
      colId: "type",
      filterParams: {
        valueFormatter: params => capitalizeFirstLetter(params.value),
      },
      // valueFormatter: ({ value }) => capitalizeFirstLetter(value),
      cellRendererFramework: ({ data, value }) => {
        if (data && value) {
          if (data.type === OrderType.Oco) {
            return (
              <>
                <div>Either {OrderTypeLabel[data.orderTypeLeg1]}</div>
                <div>Or {OrderTypeLabel[data.orderTypeLeg2]}</div>
              </>
            );
          } else {
            return OrderTypeLabel[value];
          }
        }

        if (data?.type === OrderType.Oco) {
          return (
            <>
              <div>&mdash;</div>
              <div>&mdash;</div>
            </>
          );
        }

        return <>&mdash;</>;
      },
      width: 120,
      minWidth: 64,
    },
    // HEREZ TODO: Found bug: A classic user can't place a trade with Strategy "Iceberg"
    {
      headerName: "Strategy",
      field: "strategy",
      colId: "strategy",
      filterParams: {
        valueFormatter: params => capitalizeFirstLetter(params.value),
      },
      cellRendererFramework: ({ data, value }) => {
        if (data) {
          if (data.type === OrderType.Oco) {
            return (
              <>
                <div style={{ textTransform: "capitalize" }}>{value ? value : dashSymbol}</div>
                <div style={{ textTransform: "capitalize" }}>{value ? value : dashSymbol}</div>
              </>
            );
          } else {
            return <div style={{ textTransform: "capitalize" }}>{value ? value : dashSymbol}</div>;
          }
        }

        return <>&mdash;</>;
      },
      width: 100,
      minWidth: 64,
    },
    {
      headerName: "OrderID",
      field: "id",
      colId: "id",
      cellRendererFramework: ({ data, value }) => cellRenderer(data, value),
      width: 192,
      minWidth: 64,
    },
    {
      headerName: "Session",
      field: "takerName",
      colId: "takerName",
      filter: "agTextColumnFilter",
      cellRendererFramework: ({ data, value }) => cellRenderer(data, value),
      minWidth: 64,
    },
    {
      headerName: "On behalf of",
      field: "traderName",
      colId: "traderName",
      filter: "agTextColumnFilter",
      cellRendererFramework: ({ data, value }) => cellRenderer(data, value),
      minWidth: 64,
    },
    {
      headerName: "Origin",
      field: "userName",
      colId: "userName",
      filter: "agTextColumnFilter",
      cellRendererFramework: ({ data, value }) => cellRenderer(data, value),
      minWidth: 64,
    },
    {
      headerName: "Message",
      field: "rejectReasonText",
      colId: "rejectReasonText",
      filter: "agTextColumnFilter",
      cellRendererFramework: ({ data, value }) => {
        if (data) {
          if (data.type === OrderType.Oco) {
            return (
              <>
                <div>{value ? value : dashSymbol}</div>
                <div>{data.rejectReasonTextLeg2 ? data.rejectReasonTextLeg2 : dashSymbol}</div>
              </>
            );
          } else {
            return value ? value : dashSymbol;
          }
        }

        return <>&mdash;</>;
      },
      minWidth: 264,
    },
    {
      headerName: "Counter party",
      field: "fills",
      colId: "fills",
      filter: true,
      cellRendererFramework: ({ data, value }) => cellRenderer(data, value),
      valueGetter: params => {
        return params.data.fills.length > 0 ? params.data.fills[0].counterParty : undefined;
      },
      width: 154,
      minWidth: 64,
    },
    {
      headerName: "Lifespan",
      field: "lifespan",
      colId: "lifespan",
      filterParams: {
        valueFormatter: params => {
          const returnValue = orderLifespanOptions.find(option => option.value === params.value.toLowerCase());
          return returnValue ? returnValue.label : <>&mdash;</>;
        },
      },
      cellRendererFramework: ({ data, value }) => {
        if (data && value) {
          const returnValue = orderLifespanOptions.find(option => option.value === value.toLowerCase());

          if (returnValue) {
            if (data.type === OrderType.Oco) {
              return (
                <>
                  <div>{returnValue.label}</div>
                  <div>{returnValue.label}</div>
                </>
              );
            } else {
              return returnValue.label;
            }
          }
        }

        if (data?.type === OrderType.Oco) {
          return (
            <>
              <div>&mdash;</div>
              <div>&mdash;</div>
            </>
          );
        }

        return <>&mdash;</>;
      },
      minWidth: 64,
    },
  ];

  const allColumnDefs = cantorAlgoEnabled ? [...blotterColumnDefs, ...optionalAlgoColumnDefs] : [...blotterColumnDefs];

  return (
    <>
      {orders.length > 0 && (
        <div className={style.actionsContainer}>
          {cantorAlgoEnabled && (
            <Button onClick={pauseSelectedOrders} className={style.action} disabled={!isEverySelectedOrderPausable}>
              <Icon type="pause-circle" />
              Pause selected
            </Button>
          )}

          {cantorAlgoEnabled && (
            <Button onClick={resumeSelectedOrders} className={style.action} disabled={!isEverySelectedOrderResumable}>
              <Icon type="play-circle" />
              Resume selected
            </Button>
          )}

          <Button onClick={cancelSelectedOrders} className={style.action} disabled={!isEverySelectedOrderCancelable}>
            <Icon type="close" />
            {/* <Icon type="close-circle" /> */}
            Cancel selected
          </Button>
        </div>
      )}

      <Blotter
        optionalRef={gridRef}
        columnDefs={allColumnDefs}
        defaultColDefs={defaultColDef}
        partialFillColumnDefs={blotterPartialFillColumnDefs}
        rowData={orders}
        setBlotterTableSettings={setTableSettings}
        blotterTableSettings={orderHistoryTableSettings}
        setDataLoaded={setDataLoaded}
        rowSelectionEnabled={true}
        tabType={tabType}
        customFrameworkComponents={{
          checkboxCellRenderer: CheckboxCellRenderer,
          selectAllCheckboxCellRenderer: SelectAllCheckboxCellRenderer,
          actionsBlotterRenderer: ActionsBlotterRenderer,
        }}
      />
    </>
  );
}

export default connect(
  (state: any, props: any) => {
    const settings = getBlotterSettings(state);
    const { displayOrdersByTime } = settings;
    const orderStatusOverrides = getStatusOverrides(state);
    let orders = getAllOrdersSelector(state);
    if (displayOrdersByTime) {
      orders = orders.filter(order =>
        displayOrdersByTime === DisplayOrdersByTimeType.today ? isToday(order.createdDate) : order
      );
    }

    return {
      cantorAlgoEnabled: getCantorAlgoEnabled(state),
      displayOrdersByTime,
      orders,
      orderStatusOverrides,
      orderHistorySelectedRows: getSelectedRowsOrderData(state),
      selectedOrders: getSelectedOrders(state),
      isEverySelectedOrderPausable: getIsEverySelectedOrderPausable(state),
      isEverySelectedOrderResumable: getIsEverySelectedOrderResumable(state),
      isEverySelectedOrderCancelable: getIsEverySelectedOrderCancelable(state),
      readOnly: getOrdersReadOnly(state),
    };
  },
  {
    updateSelectedOrderHistoryRows, //
    pauseOrders: pauseOrdersAction,
    resumeOrders: resumeOrdersAction,
    cancelOrders: cancelOrdersAction,
  }
)(OrderHistory);

const OrderStatusRenderer = ({ updatedData, value }) => {
  const statusOverrides = useSelector(getStatusOverrides);

  if (updatedData?.type === OrderType.Oco) {
    return (
      <>
        <OrderStatusLabel type="text" status={value} labelOverride={statusOverrides && statusOverrides[value]} />
        <OrderStatusLabel
          type="text"
          status={updatedData.statusLeg2}
          labelOverride={statusOverrides && statusOverrides[updatedData.statusLeg2]}
        />
      </>
    );
  } else {
    return <OrderStatusLabel type="text" status={value} labelOverride={statusOverrides && statusOverrides[value]} />;
  }
};

import { ofType } from "redux-observable";
import { merge, of } from "rxjs";
import { catchError, delay, mapTo, mergeMap, retryWhen } from "rxjs/operators";

import { connect } from "./webSocketService";
import { store } from "../configureStore";
import {
  APP_LOAD_FAIL,
  APP_LOAD_START,
  APP_LOAD_SUCCESS,
  CONNECTED,
  CONNECTION_FAILED,
  DISCONNECTED,
  MAX_RETRY_EXCEEDED,
  OPEN_CONNECTION,
  openConnection,
  RECONNECT,
} from "./connectionState";
import { retryStrategy } from "./retryStrategy";

export const openConnectionEpic = action$ =>
  action$.pipe(
    ofType(OPEN_CONNECTION),
    mergeMap((action: any) =>
      merge(
        of(action.isInitialLoad ? { type: APP_LOAD_START } : { type: RECONNECT }),
        connect().pipe(
          retryWhen(
            retryStrategy({
              maxRetryAttempts: 1,
              scalingDuration: 100,
              dispatch: store.dispatch,
            })
          ),
          catchError(err => {
            return of(MAX_RETRY_EXCEEDED);
          }),
          mergeMap(msgObservable => {
            if (msgObservable === MAX_RETRY_EXCEEDED) {
              return merge(
                of(action.isInitialLoad ? { type: APP_LOAD_FAIL } : { type: RECONNECT }),
                of({ type: CONNECTION_FAILED })
              );
            }
            return merge(of({ type: APP_LOAD_SUCCESS }), of({ type: CONNECTED, messages$: msgObservable }));
          })
        )
      )
    )
  );

export const disconnectedEpic = action$ =>
  action$.pipe(ofType(DISCONNECTED), delay(3000), mapTo(openConnection(false)));



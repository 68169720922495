import React, { useState, useEffect } from "react";
import { Input, Button, Menu, Icon, DatePicker } from "antd";
import "antd/dist/antd.css";

import { SymbolsTenorsType } from "./symbolsList.types";

import styles from "./SymbolTenorItem.module.scss";
import "./brokenDateCalendar.scss";
import clsx from "clsx";

const tenorsList = [
  { tenor: "Spot", value: "" },
  { tenor: "Broken Date", value: "brokenDate" },
  { tenor: "1W", value: "1W" },
  { tenor: "2W", value: "2W" },
  { tenor: "3W", value: "3W" },
  { tenor: "1M", value: "1M" },
  { tenor: "2M", value: "2M" },
  { tenor: "3M", value: "3M" },
  { tenor: "4M", value: "4M" },
  { tenor: "5M", value: "5M" },
  { tenor: "6M", value: "6M" },
  { tenor: "7M", value: "7M" },
  { tenor: "8M", value: "8M" },
  { tenor: "9M", value: "9M" },
  { tenor: "10M", value: "10M" },
  { tenor: "11M", value: "11M" },
  { tenor: "1Y", value: "1Y" },
  { tenor: "BMF1", value: "BMF1" },
  { tenor: "IMM1", value: "IMM1" },
  { tenor: "IMM2", value: "IMM2" },
  { tenor: "SN", value: "SN" },
];

const dateFormat = "MM/DD/YYYY";

type SymbolTenorItemProps = {
  allSymbols: any;
  selectedSymbols: any;
  config: any;
  elementValues: any;
  globalList: any;
  setGlobalList: any;
};

export function SymbolTenorItem({
  allSymbols,
  config,
  elementValues,
  globalList,
  setGlobalList,
}: //   deleteAction,
SymbolTenorItemProps) {
  const { listHeight1, listHeight2, filterPlaceholder } = config || {};
  const style1 = { height: listHeight1 || "100%" };
  const style2 = { height: listHeight2 || "100%" };

  const symbols = allSymbols
    //   We are no longer (for now) filtering the ccy pairs we already have in the currency grid
    // .filter(symbol => selectedSymbols && !selectedSymbols.includes(symbol))
    .map(symbol => ({
      symbol: symbol,
    }));

  const computeList = () =>
    symbols.map((item, i) => ({
      ...item,
      index: i,
      value: item.symbol,
      isSelected: item.isSelected || false,
    }));

  const [list, setList] = useState<any>(computeList());
  const [showMenuSymbols, setShowMenuSymbols] = useState<boolean>(false);
  const [showMenuTenors, setShowMenuTenors] = useState<boolean>(false);
  const [showBrokenDateMenu, setShowBrokenDateMenu] = useState<boolean>(false);

  useEffect(() => {
    computeList();
  }, [symbols]);

  const [filter, setFilter] = useState<String>("");
  const filteredList = list.filter(item =>
    item.value
      .replace("/", "")
      .toLowerCase()
      .includes(filter.replace("/", "").toLowerCase())
  );

  const toggleMenuSymbols = value => {
    // Hide symbols menu
    setShowMenuSymbols(value);
    // Reset symbols filter
    setFilter("");
  };

  const handleSelect = (item, index, menuItemType) => {
    let updatedItem: SymbolsTenorsType = {
      index: 0,
      symbol: "Select",
      symbolValue: "",
      tenor: "Spot",
      tenorValue: "",
      brokenDateValue: "",
    };

    // Update item in the list to the new value
    updatedItem = {
      ...globalList[index], //
      // We update the label in the dropdown
      [menuItemType]: item[menuItemType],
      // We update the value (in the broken date case we have to update this and the calendar value, in handleDateChange)
      [`${menuItemType}Value`]: item.value,
    };

    // If we change the tenor (to 1W, 1M... etc) we want to reset the brokenValue so we don't have old selected values and we don't use them
    if (menuItemType === "tenor") {
      updatedItem.brokenDateValue = "";
    }

    // Insert the updatedItem in the list
    const newList = [
      ...globalList.slice(0, index), //
      updatedItem,
      ...globalList.slice(index + 1),
    ];

    setGlobalList(newList);

    // If the tenor is brokenDate we show the calendar, if the tenor has another value we hide the calendar
    if (menuItemType === "tenor") {
      if (item.value === "brokenDate") {
        setShowBrokenDateMenu(true);
      } else {
        setShowBrokenDateMenu(false);
      }
    }

    // Closing the selected dropdown on click.
    if (menuItemType === "symbol") {
      toggleMenuSymbols(false);
    } else if (menuItemType === "tenor") {
      setShowMenuTenors(false);
    }
  };

  const handleDateChange = (date, dateString, index) => {
    // Update item in the list to the new value
    const updatedItem = {
      ...globalList[index], //
      // In the brokenDate case we don't need to update the label in the dropdown, only the value
      brokenDateValue: dateString,
    };

    const newList = [
      ...globalList.slice(0, index), //
      updatedItem,
      ...globalList.slice(index + 1),
    ];

    setGlobalList(newList);
  };

  return (
    <div className={styles.modalRow} key={elementValues.index}>
      <div className={styles.symbolsContainer}>
        <Button
          className={clsx("noDrag", styles.buttonAsDropdown, styles.currenyPair)}
          size="small"
          onClick={() => toggleMenuSymbols(!showMenuSymbols)}
        >
          <span className="selectedCurrency">{elementValues.symbol}</span>
          {Icon({ type: "caret-down" })}
        </Button>

        {showMenuSymbols && (
          <>
            <div className={styles.filter}>
              <Input onChange={e => setFilter(e.target.value)} size="large" placeholder={filterPlaceholder || ""} />
            </div>

            <Menu className={clsx(styles.list, styles.dropdownContent)} style={style1}>
              {filteredList.length > 0 ? (
                filteredList.map((item, i) => (
                  <Menu.Item
                    key={`${item.index}.${i}`}
                    className={clsx(styles.currencyPairItem)}
                    onClick={() => handleSelect(item, elementValues.index, "symbol")}
                  >
                    <span>{item.value}</span>
                  </Menu.Item>
                ))
              ) : (
                <li className={styles.noMatchDiv}>No match found</li>
              )}
            </Menu>
          </>
        )}
      </div>

      <div className={styles.tenorsContainer}>
        <Button
          className={clsx("noDrag", styles.buttonAsDropdown, styles.tenor)}
          size="small"
          onClick={() => setShowMenuTenors(!showMenuTenors)}
        >
          <span className="selectedCurrency">{elementValues.tenor}</span>
          {Icon({ type: "caret-down" })}
        </Button>

        {showMenuTenors && (
          <Menu className={clsx(styles.list, styles.dropdownContent)} style={style2}>
            {tenorsList.map((item, i) => (
              <Menu.Item
                key={`${item}.${i}`}
                className={clsx(styles.currencyPairItem)}
                onClick={() => handleSelect(item, elementValues.index, "tenor")}
              >
                <span>{item.tenor}</span>
              </Menu.Item>
            ))}
          </Menu>
        )}
      </div>

      {showBrokenDateMenu && (
        <div className="brokenDateSelect">
          <DatePicker
            onChange={(date, dateString) => handleDateChange(date, dateString, elementValues.index)} //
            format={dateFormat}
            allowClear={false}
            dropdownClassName="datePickerCalendar"
            placeholder="MM/DD/YYYY"
          />
        </div>
      )}

      {/* <Button onClick={() => deleteAction(elementValues.index)}>Delete row</Button> */}
    </div>
  );
}

import React from "react";
import { connect } from "react-redux";
import { Button } from "antd";

import styles from "./ActionsBlotterRenderer.module.scss";
import { ReactComponent as IconPlay } from "../../../assets/images/PlayIcon-24.svg";
import { ReactComponent as IconPause } from "../../../assets/images/PauseIcon-24.svg";
import { ReactComponent as IconCancel } from "../../../assets/images/CancelIcon-20.svg";

import { getSelectedOrders } from "../../../state/orders/pauseResumeOrders/pauseResumeOrdersState";
import {
  cancelOrdersAction,
  pauseOrdersAction,
  resumeOrdersAction,
} from "../../../state/orders/pauseResumeOrders/pauseResumeOrdersActions";
import { getCantorAlgoEnabled, getOrdersReadOnly } from "../../../state/settings/settingsState";
import { isOrderCancelable, isOrderPausable, isOrderResumable } from "../../../lib/util";
import clsx from "clsx";

export const ActionsBlotterRenderer = ({
  data,
  selectedOrders,
  pauseOrders,
  resumeOrders,
  cancelOrders,
  readOnly,
  cantorAlgoEnabled,
}) => {
  const pauseOrderDirectly = () => {
    // pauseResumeHelper
    // console.log("pauseOrderDirectly");
    pauseOrders([data.id]);
  };

  const resumeOrderDirectly = () => {
    // pauseResumeHelper
    // console.log("resumeOrderDirectly");
    resumeOrders([data.id]);
  };

  const cancelOrderDirectly = () => {
    // pauseResumeHelper
    // console.log("cancelOrderDirectly");
    // console.log(data.id);

    cancelOrders([data.id]);
  };

  return (
    <>
      {cantorAlgoEnabled && (
        <>
          {isOrderPausable(selectedOrders[data.id]?.status, selectedOrders[data.id]?.lifespan, readOnly) ? (
            <Button className={styles.actionBtn} onClick={pauseOrderDirectly}>
              <IconPause className={styles.actionIcon} />
            </Button>
          ) : isOrderResumable(selectedOrders[data.id]?.status, readOnly) ? (
            <Button className={styles.actionBtn} onClick={resumeOrderDirectly}>
              <IconPlay className={styles.actionIcon} />
            </Button>
          ) : (
            <Button className={styles.actionBtn} disabled>
              <IconPause className={styles.actionIcon} />
            </Button>
          )}
        </>
      )}

      {isOrderCancelable(selectedOrders[data.id]?.status, selectedOrders[data.id]?.lifespan, readOnly) ? (
        <Button className={clsx(styles.actionBtn, styles.cancelBtn)} onClick={cancelOrderDirectly}>
          <IconCancel className={clsx(styles.actionIcon, styles.cancelIcon)} />
        </Button>
      ) : (
        <Button className={clsx(styles.actionBtn, styles.cancelBtn)} disabled>
          <IconCancel className={clsx(styles.actionIcon, styles.cancelIcon)} />
        </Button>
      )}
    </>
  );
};

export default connect(
  (state: any, props: any) => ({
    selectedOrders: getSelectedOrders(state),
    readOnly: getOrdersReadOnly(state),
    cantorAlgoEnabled: getCantorAlgoEnabled(state),
  }),
  {
    pauseOrders: pauseOrdersAction,
    resumeOrders: resumeOrdersAction,
    cancelOrders: cancelOrdersAction,
  }
)(ActionsBlotterRenderer);

export type CurrencyPair = {
  id: string;
  symbol: string;
};

export type CurrencyGroup = {
  id: string;
  displayName: string;
  symbols: string[];
};

export type AuctionCardDetail = {
  size: number;
  type: string;
  selectedCurrency: string;
  side: string;
  price: number;
  stopLossPrice: number;
  instrumentId: string;
};

// constants

export const SET_CURRENT_PRICE = "currency/SET_CURRENT_PRICE";
export const SHOW_AUCTION_CARD = "currency/SHOW_AUCTION_CARD";
export const SHOW_AUCTION_CARD_OPENFIN = "currency/SHOW_AUCTION_CARD_OPENFIN";
export const REFRESH_AUCTION_PRICE = "currency/REFRESH_AUCTION_PRICE";

export const UNDOCK_CARD = "UNDOCK_CARD";
export const CARD_UNDOCKED = "CARD_UNDOCKED";

export const CURRENCY_PAIRS_SELECTED = "CURRENCY_PAIRS_SELECTED";
export const CURRENCY_PAIR_REMOVED = "CURRENCY_PAIR_REMOVED";
export const INITIAL_DATA = "INITIAL_DATA";
export const SYMBOLS_LOADED = "SYMBOLS_LOADED";
export const CURRENCY_DATA_UPDATE = "CURRENCY_DATA_UPDATE";
export const DELETE_CURRENCY_DATA = "DELETE_CURRENCY_DATA";

export const DOCK_CARD = "DOCK_CARD";
export const CLOSE_UNDOCKED_CARDS = "CLOSE_UNDOCKED_CARDS";

// actions
export const dockCard = symbol => ({
  type: DOCK_CARD,
  payload: symbol,
});

export const currencyPairsSelected = (selectedCurrencyPairIds: string[]) => ({
  type: CURRENCY_PAIRS_SELECTED,
  payload: {
    selectedCurrencyPairIds,
  },
});

export const selectedTakerChanged = () => ({
  type: DELETE_CURRENCY_DATA,
});

export const currencyPairRemoved = (symbol: string) => ({
  type: CURRENCY_PAIR_REMOVED,
  payload: {
    symbol,
  },
});

export const symbolsLoaded = symbols => ({
  type: SYMBOLS_LOADED,
  payload: symbols,
});

export const currencyDataUpdate = data => ({
  type: CURRENCY_DATA_UPDATE,
  payload: data,
});

export const undockCard = (symbol: string) => ({
  type: UNDOCK_CARD,
  payload: {
    symbol,
  },
});

export const closeUndockedCards = () => ({
  type: CLOSE_UNDOCKED_CARDS,
});

export const setAuctionCard = (data: any | null) => {
  // Remove the outline from any highlighted cards.
  document.querySelectorAll(".card--buy, .card--sell").forEach(el => {
    el.classList.remove("card--buy", "card--sell");
  });

  return {
    type: SHOW_AUCTION_CARD,
    payload: data,
  };
};

export const setCurrentPrice = price => {
  return {
    type: SET_CURRENT_PRICE,
    payload: price,
  };
};

export const refreshAuctionCardPrice = (data: any) => {
  return {
    type: REFRESH_AUCTION_PRICE,
    payload: data,
  };
};

import React, { useCallback, useEffect, useState } from "react";
import { Menu } from "antd";
import Big from "big.js";
import { decimalToPriceObjectConverterBySymbol, isNDFCurrency } from "../../../lib/util";

import styles from "./FlywheelMenu.module.scss";
import clsx from "clsx";
import { OrderSide } from "../../../state/orders/types";

import FlywheelItem from "./FlywheelItem";
import { ndfInstrumentStripper } from "../../../lib/formatUtil";

type FlywheelMenu = {
  basePrice: number;
  orderSide: string;
  origin: string;
  quantity: string;
  actionOrder: (i, o, q) => void;
  instrumentId: any;
  pip: any;
  clickType: string;
  increment: any;
  // currencySettingsBySymbol: any;
  testid?: string;
};

export const FlywheelMenu = ({
  basePrice,
  orderSide,
  origin,
  quantity,
  actionOrder,
  instrumentId,
  pip,
  clickType,
  increment,
  // currencySettingsBySymbol,
  testid,
}: FlywheelMenu) => {
  const [menuItems, setMenuItems] = useState<any>([]);
  const [offset, setOffset] = useState<number>(0);

  const shiftItems = useCallback(
    (step: number) => {
      setOffset(offset + step);
    },
    [offset]
  );

  const getGeneratedMenu = useCallback(() => {
    if (isNaN(pip)) {
      return <></>;
    }

    // HERE
    // const ITEM_COUNT = 11;
    const ITEM_COUNT = 9;

    const currentPrice = basePrice;
    const currentPriceBig: Big = new Big(currentPrice);

    // let step: number = isNDFCurrency(instrumentId) ? pip : pip / 10;
    let step: number = pip / 10;
    if (increment) {
      step = Big(pip)
        .times(increment)
        .toNumber();
    }

    const listPrices: any[] = [];
    const startAt = Math.round(-(ITEM_COUNT - 1) / 2) + offset;
    let longestLength: number = 0;
    for (let i = startAt; i < startAt + ITEM_COUNT; i++) {
      const price: number = currentPriceBig.plus(step * i).toNumber();
      const priceObj: object = decimalToPriceObjectConverterBySymbol(price, instrumentId);

      listPrices.push({
        price: price,
        priceObj: priceObj,
      });

      // listPrices.push(price);

      // Save in the longest price length to apply the styles to the last digit.
      const priceString: string = price.toString();
      if (priceString.length > longestLength) {
        longestLength = priceString.length;
      }
    }

    const listItems = listPrices
      // .sort((a, b) => b - a)
      .sort((a, b) => b.price - a.price)
      .map(item => (
        <FlywheelItem
          key={item.price}
          instrumentId={instrumentId}
          clickType={clickType}
          actionOrder={actionOrder}
          // item={item}
          item={item.price}
          itemObj={item.priceObj}
          orderSide={orderSide}
          quantity={quantity}
          longestLength={longestLength}
        ></FlywheelItem>
      ));

    const titleSide = `${orderSide === OrderSide.Buy ? "BUY" : "SELL"} ${ndfInstrumentStripper(instrumentId)}`;
    const titleItem = (
      <Menu.Item
        key="title-item"
        className={clsx(
          "flywheelMenuItem",
          styles.flywheelMenuItem,
          styles.flywheelMenuItemTitle,
          orderSide === OrderSide.Buy ? styles.buyColor : styles.sellColor
        )}
      >
        {titleSide}
      </Menu.Item>
    );

    const upArrowItem = (
      <Menu.Item
        key="up-arrow"
        className={clsx("flywheelMenuItem", styles.flywheelMenuItem, styles.flywheelMenuItemArrow)}
        onClick={() => shiftItems(1)}
      >
        ▲
      </Menu.Item>
    );

    const downArrowItem = (
      <Menu.Item
        key="down-arrow"
        className={clsx("flywheelMenuItem", styles.flywheelMenuItem, styles.flywheelMenuItemArrow)}
        onClick={() => shiftItems(-1)}
      >
        ▼
      </Menu.Item>
    );

    return [titleItem, upArrowItem, ...listItems, downArrowItem];
    // return [upArrowItem, ...listItems, downArrowItem];
  }, [
    // actionOrder,
    basePrice,
    clickType,
    instrumentId,
    offset,
    orderSide,
    pip,
    quantity,
    shiftItems,
  ]);

  useEffect(() => {
    setMenuItems(getGeneratedMenu());
  }, [getGeneratedMenu]);

  return (
    <Menu
      className={clsx(
        "flywheelMenu",
        styles.flywheelMenu,
        origin === OrderSide.Buy
          ? styles.flywheelCardBuyPosition
          : origin === OrderSide.Sell
          ? styles.flywheelCardSellPosition
          : styles.flywheelOrderPosition
      )}
      data-testid={testid}
    >
      {menuItems}
    </Menu>
  );
};

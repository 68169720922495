// must match API spec
export enum OrderStatus {
  Canceling = "canceling",
  Filled = "filled",
  PartialCancel = "partialCancel",
  PartialFill = "partialFill",
  Pending = "pending",
  Rejected = "rejected",
  Replacing = "replacing",
  UnsolCancel = "unsolCancel",
  UnsolPartial = "unsolPartial",
  UserCancel = "userCancel",
  Working = "working",
  Paused = "paused",
}

export enum OpenOrderStatus {
  Canceling = "canceling",
  PartialFill = "partialFill",
  Pending = "pending",
  Replacing = "replacing",
  Working = "working",
}

export enum ClosedOrderStatus {
  Filled = "filled",
  PartialCancel = "partialCancel",
  Rejected = "rejected",
  UnsolCancel = "unsolCancel",
  UnsolPartial = "unsolPartial",
  UserCancel = "userCancel",
}

export enum CancelledClosedOrderStatus {
  PartialCancel = "partialCancel",
  Rejected = "rejected",
  UnsolCancel = "unsolCancel",
  UnsolPartial = "unsolPartial",
  UserCancel = "userCancel",
}

export enum FilledClosedOrderStatus {
  Filled = "filled",
}

export enum OrderLifespan {
  Day = "day",
  FAK = "fak",
  FOK = "fok",
  IOC = "ioc",
}

export enum OrderSide {
  Buy = "buy",
  Sell = "sell",
}

export enum OrderSideLabel {
  Buy = "Bid",
  Sell = "Offer",
}

export enum OrderType {
  Market = "market",
  Stop = "stop",
  Limit = "limit",
  StopLimit = "stopLimit",
  Oco = "oco",
}

export enum OrderTypeLabel {
  market = "Market",
  stop = "Stop",
  limit = "Limit",
  stopLimit = "Stop Limit",
  oco = "OCO",
}

export const orderTypeDisplay = [
  {
    name: "Limit",
    value: OrderType.Limit,
  },
  {
    name: "Market",
    value: OrderType.Market,
  },
];

export enum OrderStrategy {
  Bfix = "bfix",
  Close = "close",
  Common = "common",
  Custom = "custom",
  Fixing = "fixing",
  ISeek = "seek",
  Iceberg = "iceberg",
  Peg = "peg",
  Pov = "pov",
  Sor = "sor",
  Twap = "twap",
  Vwap = "vwap",
}

export enum OrderAction {
  Cancel = "cancel",
  Pause = "pause",
  Resume = "resume",
}

export enum ProductType {
  Ndf = "ndf",
  Spot = "spot",
}

export enum PriceType {
  Limit = 0,
  Average = 99,
}

// everything that should be in an order sent by the UI
// need to include optional fields that come from backend
export interface OrderModel {
  allocationAfterFix?: number | null;
  allocationBeforeFix?: number | null;
  correlation?: string;
  currency: string;
  displaySize?: string;
  endTime?: number | null;
  fixingDate: number | null;
  id?: string;
  instrumentId: string;
  lifespan: string;
  lowerBandPrice?: number | null;
  lowerBandTargetPercentVolume?: number | null;
  maxParticipationRate?: number | null;
  maximumFloor?: number | null;
  minParticipationRate?: number | null;
  minimumExecutedQuantity?: number | null;
  orderTypeLeg1?: OrderType;
  orderTypeLeg2?: OrderType;
  price: string;
  priceLeg2?: string;
  priceType?: string | null;
  product: string;
  quantity: string;
  randomizationPercent?: number | null;
  settleDate: number | null;
  side: string;
  sideLeg2?: string;
  sliceInterval?: number | null;
  sliceSize?: number | null;
  startTime?: number | null;
  stopLossPrice?: number | null;
  stopLossPriceLeg2?: number | null;
  strategy: OrderStrategy;
  strategyConfig: string;
  takerName?: string;
  targetParticipationRate?: number | null;
  traderName: string;
  type: OrderType;
  upperBandPrice?: number | null;
  upperBandTargetPercentVolume?: number | null;
  urgency?: number | null;
  wouldPrice?: number | null;
  wouldSizePercent?: number | null;
}

export interface ReplaceOrderModelClassic {
  id: string;
  price?: string;
  priceLeg2?: string;
  quantity: string;
  stopLossPrice?: string;
  stopLossPriceLeg2?: number | null;
  type: string;
}

export interface ReplaceOrderModel extends OrderModel {
  id: string;
}

import React from "react";
import { Checkbox } from "antd";
import { connect } from "react-redux";

import styles from "./CheckboxCellRenderer.module.scss";

import {
  deselectOrdersAction,
  selectOrderAction,
} from "../../../state/orders/pauseResumeOrders/pauseResumeOrdersActions";

import { getSelectedOrders } from "../../../state/orders/pauseResumeOrders/pauseResumeOrdersState";
import { getOrdersReadOnly } from "../../../state/settings/settingsState";

export const CheckboxCellRenderer = ({ data, selectOrder, deselectOrders, selectedOrders, readOnly }) => {
  const selectOrderPauseResumeList = (e, orderId) => {
    if (e.target.checked === true) {
      // pauseResumeHelper
      // console.log(`Select ${orderId} to store`);
      selectOrder(orderId, readOnly);
    } else {
      // pauseResumeHelper
      // console.log(`Deselect ${orderId} from store`);
      deselectOrders([orderId], readOnly);
    }
  };

  const isChecked = () => {
    if (selectedOrders) {
      return selectedOrders[data.id]?.selected ? true : false;
    }

    return false;
  };

  return (
    <Checkbox
      checked={isChecked()}
      onChange={e => selectOrderPauseResumeList(e, data.id)}
      className={styles.selectOrderCheckbox}
    />
  );
};

export default connect(
  (state: any, props: any) => ({
    selectedOrders: getSelectedOrders(state),
    readOnly: getOrdersReadOnly(state),
  }),
  {
    selectOrder: selectOrderAction, //
    deselectOrders: deselectOrdersAction,
  }
)(CheckboxCellRenderer);

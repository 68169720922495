import { ofType } from "redux-observable";
import { from, map, mergeMap, of } from "rxjs";
import { staticAssetServerURL } from "../../../lib/constants";

export const LOAD_SYMBOLS_PIPS = "LOAD_SYMBOLS_PIPS";
export const SYMBOLS_PIPS_LOADED = "SYMBOLS_PIPS_LOADED";


export const loadSymbolsPipsFromAssetServer = () => ({
    type: LOAD_SYMBOLS_PIPS,
});

export const symbolsPipsLoaded = ( data: any ) => ({
    type: SYMBOLS_PIPS_LOADED,
    payload: data
});

export const loadSymbolsPipsFromAssetServerEpic = action$ => {
    return action$.pipe(
      ofType(LOAD_SYMBOLS_PIPS),
      mergeMap(() =>
      from(fetch(`${staticAssetServerURL}/symbolsPips.json`).then(response => {
        return response.json();
      }).catch((error) => {
        console.error(`Warning: issue accessing the asset server URL for '${staticAssetServerURL}/symbolsPips.json', loading the local file instead.`);
      })).pipe(
          map((data) => (symbolsPipsLoaded(data))),
        ),
    ),
  );
};
import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { Menu, Button, Dropdown, Input } from "antd";
import clsx from "clsx";
import uuid from "uuid";

import styles from "./PageBar.module.scss";

type TabProps = {
  page: any;
  onSelectTab: any;
  onRenamePage: any;
  onDeletePage: any;
  isActive: boolean;
  size: any;
  isRoot?: boolean;
  isDynamic: boolean; // isDynamic global, overridden by page.isDynamic config
  isLastTab: boolean;
};
const Tab = ({ page, onSelectTab, onRenamePage, onDeletePage, isActive, size, isDynamic, isLastTab }: TabProps) => {
  const tabSize = (size = size === "small" ? "small" : size === "medium" ? "medium" : "large");
  const [isRenaming, setIsRenaming] = useState(false);
  const inputRef = useRef<Input>(null);
  const isTabDynamic = page.isDynamic ? page.isDynamic : isDynamic ? isDynamic : false;
  const handleRenameComplete = (e, page) => {
    setIsRenaming(false);
    const { value } = e.target;
    if (value) {
      onRenamePage({ ...page, name: value });
    }
  };

  const handleDeletePage = page => {
    setIsRenaming(false);
    onDeletePage(page);
  };

  const handleRenameStart = () => {
    setIsRenaming(true);
  };

  useEffect(() => {
    if (isRenaming) {
      inputRef?.current?.focus();
    }
  }, [isRenaming]);

  const dropdownMenu = () => (
    <Menu>
      <Menu.Item key="0" onClick={handleRenameStart}>
        Rename tab
      </Menu.Item>
      {!isLastTab && (
        <Menu.Item key="1" onClick={e => handleDeletePage(page)}>
          Delete tab
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div
      className={clsx(
        styles.tab,
        !isTabDynamic && styles.standardTab,
        styles[tabSize],
        !isRenaming && isActive && styles.activeTab
      )}
    >
      {isRenaming ? (
        <div className={styles.pageInputWrap}>
          <Input
            size="small"
            ref={inputRef}
            defaultValue={page.name}
            onBlur={e => handleRenameComplete(e, page)}
            onPressEnter={e => handleRenameComplete(e, page)}
            placeholder="Type tab name..."
          />
        </div>
      ) : (
        <span className={clsx(styles.tabLabel, styles[tabSize])} onClick={onSelectTab}>
          {page.name}
        </span>
      )}

      {isTabDynamic && (
        <Dropdown className="ant-dropdown-link" overlay={dropdownMenu} trigger={["click"]}>
          <Button size="large" icon="more" />
        </Dropdown>
      )}
    </div>
  );
};

type PageBarProps = {
  onSetCurrentPage: any;
  onDeleteTab?: any;
  onRenameTab?: any;
  onAddTab?: any;
  tabs: any;
  size: any;
  isDynamic: any; // isDynamic flag sets plus button and overrides initialPages[i].isDynamic config
  selectedTabId?: number;
  isMainHeader?: any;
  onPageBarOverflow?: (isIt) => void;
};
export const PageBar = ({
  onSetCurrentPage,
  tabs,
  onDeleteTab,
  onRenameTab,
  onAddTab,
  size,
  isDynamic,
  selectedTabId,
  isMainHeader,
  onPageBarOverflow,
}: PageBarProps) => {
  selectedTabId = selectedTabId || 0;
  const pageBarSize = size === "small" ? "small" : size === "medium" ? "medium" : "large";
  // customize the relationship between the pagebar size and its internal components
  const tabSize = pageBarSize;
  const buttonSize: any = pageBarSize === "small" || pageBarSize === "medium" ? "default" : "large";

  isMainHeader = isMainHeader || false;

  const handleAddPage = () => {
    const newTab = { id: uuid(), name: `Tab ${tabs.length + 1}` };
    onAddTab(newTab);
    handleSelectTab(newTab)
  };

  const handleDeletePage = selectedPage => {
    onDeleteTab(selectedPage);
  };

  const handleRenamePage = selectedPage => {
    onRenameTab(selectedPage);
  };

  const [tabsWrapperWidth, setTabsWrapperWidth] = useState(0); // visible width of tabs
  const [tabsWidth, setTabsWidth] = useState(0); // visible + non-visible width of tabs
  const [scrollAmount, setScrollAmount] = useState(0);
  const [leftArrowDisabled, setLeftArrowDisabled] = useState(false);
  const [rightArrowDisabled, setRightArrowDisabled] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);

  const tabsFixedWidthEl = useRef<HTMLDivElement>(null);
  const tabsEl = useRef<HTMLDivElement>(null);

  const SCROLL_INCREMENT = 3 * 100; // 100px = assumed average tab size

  useLayoutEffect(() => {
    updateWidths();
    window.addEventListener("resize", updateWidths);
    // when the component dies, cleanup the hook
    return () => window.removeEventListener("resize", updateWidths);
  }, []);

  useEffect(() => {
    updateWidths();
  }, [tabs]);

  useEffect(() => {
    const buttonWidth = isDynamic ? 40 : 0;
    const overflowed = tabsWrapperWidth + buttonWidth < tabsWidth;

    setIsOverflow(overflowed);
    onPageBarOverflow && onPageBarOverflow(overflowed);
  }, [tabsWrapperWidth, tabsWidth]);

  const updateWidths = () => {
    if (!tabsEl.current) {
      return null;
    }
    const tabsWidthCalculated = tabsEl.current ? tabsEl.current.scrollWidth : 0;
    const tabsWrapperWidthCalculated = tabsFixedWidthEl.current ? tabsFixedWidthEl.current.offsetWidth : 0;

    setTabsWrapperWidth(tabsWrapperWidthCalculated);
    setTabsWidth(tabsWidthCalculated); // visible + non-visible width
    setScroll(scrollAmount); // recalculate/reset scroll amount based on screen resize
  };

  const handleSelectTab = page => {
    if (selectedTabId !== page.id) {
      onSetCurrentPage(page);
    }
  };

  const handleScrollLeft = () => {
    const nextScroll = scrollAmount - SCROLL_INCREMENT;
    setScroll(nextScroll);
  };
  const handleScrollRight = () => {
    const nextScroll = scrollAmount + SCROLL_INCREMENT;
    setScroll(nextScroll);
  };

  const setScroll = nextScroll => {
    // limits bounds of scroll amount to bounds of container
    const maxScroll = tabsWidth - tabsWrapperWidth;
    const newScroll = nextScroll < 0 ? 0 : nextScroll > maxScroll ? maxScroll : nextScroll;
    setScrollAmount(newScroll);
    setLeftArrowDisabled(newScroll === 0);
    setRightArrowDisabled(newScroll === maxScroll);
    if (tabsEl.current) {
      tabsEl.current.style.right = `${newScroll}px`;
    }
  };

  return (
    <div className={styles.pageBar}>
      {!leftArrowDisabled && <div className={styles.leftShadow} />}
      <div className={styles.tabsFixedWidth} ref={tabsFixedWidthEl}>
        <div className={clsx(styles.tabs, isMainHeader && styles.tabsMainHeader)} ref={tabsEl}>
          {tabs.map((page, i) => (
            <Tab
              key={`${page.name}.${i}`}
              page={page}
              onSelectTab={() => handleSelectTab(page)}
              isActive={selectedTabId === page.id}
              onRenamePage={handleRenamePage}
              onDeletePage={handleDeletePage}
              size={tabSize}
              isDynamic={isDynamic}
              isLastTab={tabs.length === 1}
            />
          ))}
        </div>
      </div>
      {isDynamic && (
        <div className={clsx(styles.pageBarActions, isDynamic && styles.pageBarActionsDynamic)}>
          {isOverflow && (
            <React.Fragment>
              {!rightArrowDisabled && <div className={styles.rightShadow} />}
              <Button disabled={leftArrowDisabled} size={buttonSize} icon="left" onClick={handleScrollLeft} />
              <Button disabled={rightArrowDisabled} size={buttonSize} icon="right" onClick={handleScrollRight} />
            </React.Fragment>
          )}
          {isDynamic && <Button size={buttonSize} icon="plus" onClick={handleAddPage} />}
        </div>
      )}
    </div>
  );
};

export default PageBar;

import React from "react";

import styles from "./HotButton.module.scss";
import { OrderSide, OrderSideLabel } from "../../../state/orders/types";
import { Button } from "antd";
import { ClickType } from "../../../state/settings/settingsState";

type HotButton = {
  side: string;
  isTradingEnabled?: boolean;
  onClickHotButton: () => void;
  priceObj: any;
  clickType: string;
  testid?: string;
  offset: number;
  quantity: any;
};

export const HotButton = ({
  side,
  isTradingEnabled,
  priceObj,
  onClickHotButton,
  clickType,
  testid,
  offset,
  quantity,
}: HotButton) => {
  // If "trading is enabled" for the user, if the parameter "readOnly" in the DB is false
  const isDepthTradingEnabled = isTradingEnabled === undefined ? true : isTradingEnabled;

  // const dataTestValue = testid ? { "data-testid": testid } : {};
  const operator = side === OrderSide.Buy ? "+" : "-";
  const tradeIsPossible =
    isDepthTradingEnabled && offset && priceObj && quantity !== null && quantity !== "" && quantity != 0;
  const buttonLabel = side === OrderSide.Buy ? OrderSideLabel.Buy : OrderSideLabel.Sell;

  return (
    <Button
      data-testid={testid}
      size="small"
      className={`hotButton ${styles.hotButton}`}
      onClick={() => {
        if (clickType === ClickType.Single) {
          if (priceObj) {
            onClickHotButton();
          } else {
            return null;
          }
        }
      }}
      onDoubleClick={() => {
        if (clickType === ClickType.Double) {
          if (priceObj) {
            onClickHotButton();
          } else {
            return null;
          }
        }
      }}
      disabled={!tradeIsPossible}
    >
      <span className={styles.buttonLabel}>{buttonLabel}</span>
      <span className={styles.buttonValue}>{tradeIsPossible ? `${operator}${offset}` : 0}</span>
    </Button>
  );
};

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ColDef } from "@ag-grid-community/core";
import moment from "moment-timezone";
import clsx from "clsx";

import { allOrdersSelector } from "../../state/orders/ordersState";
import Blotter from "../../shared/blotter/Blotter";
import { ClosedOrderStatus, OrderSide } from "../../state/orders/types";
import style from "./AnalyticsPanel.module.scss";
import { getPriceStringWithPrecision } from "../../lib/util";
import { capitalizeFirstLetter } from "../../lib/formatUtil";

const tickerColumnDefs: ColDef[] = [
  {
    headerName: "Instrument",
    field: "instrumentId",
    colId: "instrumentId",
    width: 125,
    minWidth: 125,
  },
  {
    headerName: "Side",
    field: "side",
    colId: "side",
    filterParams: {
      valueFormatter: params => capitalizeFirstLetter(params.value),
    },
    cellRendererFramework: ({ value }) => (
      <div className={clsx(OrderSide.Buy === value ? style.buy : style.sell)}>
        {OrderSide.Buy === value ? "Buy" : "Sell"}
      </div>
    ),
    width: 98,
    minWidth: 98,
  },
  {
    headerName: "Rate",
    field: "price",
    colId: "price",
    filter: "agNumberColumnFilter",
    cellRendererFramework: ({ data, value }) => {
      if (value && data) {
        return getPriceStringWithPrecision(value, data.instrumentId);
      } else {
        return "";
      }
    },
    width: 90,
    minWidth: 90,
  },
  {
    headerName: "Timestamp",
    field: "tradeDate",
    colId: "tradeDate",
    width: 220,
    minWidth: 220,
    filter: "agDateColumnFilter",
    cellRendererFramework: ({ value }) => {
      return value ? (
        moment(value)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD HH:mm:ss.SSS z")
      ) : (
        <>&mdash;</>
      );
    },
  },
];

function Ticker({ id, saveSettings, orders, tableSettings, setDataLoaded, tabType }) {
  const [tickerTableSettings, setTickerTableSettings] = useState<any>(tableSettings);

  const setTableSettings = ({ columns, filters, sorts }) => {
    tableSettings = { columns, filters, sorts };
    saveSettings(id, tableSettings);
  };

  useEffect(() => {
    setTickerTableSettings(tableSettings);
  }, [tableSettings]);

  const modifiedOrders = orders.map(order => {
    order.fills = order.fills.map(fill => {
      fill.instrumentId = order.instrumentId;
      fill.side = order.side;
      return fill;
    });
    return order;
  });

  const fillsArray = modifiedOrders.reduce((fills, current) => fills.concat(current.fills), []);

  return (
    <Blotter
      columnDefs={tickerColumnDefs}
      rowData={fillsArray}
      setBlotterTableSettings={setTableSettings}
      blotterTableSettings={tickerTableSettings}
      setDataLoaded={setDataLoaded}
      tabType={tabType}
    />
  );
}

export default connect(
  (state: any) => {
    const allOrders = allOrdersSelector(state);
    const orders = allOrders.filter(order => Object.values(ClosedOrderStatus).includes(order.status) && order.fills.length > 0);
    return {
      orders
    }
  })(Ticker);

import React, { useState } from "react";
import { FieldType } from "../../fields";
import TextArea from "antd/lib/input/TextArea";

import styles from "./InputTextArea.module.scss";
import { maxTextAreaChars } from "../../../../../lib/constants";
import { OrderType } from "../../../../../state/orders/types";



const InputTextArea = ({ containerClass, dataTestId, className, inputType, fieldType, disabled, autoFocus, value, placeholder, onKeyDown, onKeyUp, onChange, onBlur, onFocus }) => {

    return (

        <>
            {fieldType === FieldType.input
                ? 
                    <div className={containerClass}>
                        <input
                            data-testid={dataTestId}
                            className={className}
                            type={inputType}
                            disabled={disabled}
                            autoFocus={autoFocus}
                            value={value}
                            placeholder={placeholder}
                            onKeyDown={onKeyDown}
                            onKeyUp={onKeyUp}
                            onChange={onChange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                        />
                    </div>
                : 
                    <div className={containerClass}>
                        <TextArea
                            data-testid={dataTestId}
                            className={styles.textAreaField}
                            disabled={disabled}
                            autoFocus={autoFocus}
                            value={value}
                            placeholder={placeholder}
                            onKeyUp={onKeyUp}
                            onChange={onChange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            rows={2}
                        >
                        </TextArea>
                    </div>
            }
        </>

    );


}

export default InputTextArea;
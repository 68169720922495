import { ofType } from "redux-observable";
import { map, distinctUntilChanged } from "rxjs/operators";
import { handleSettingsLoaded } from "../connection/messagesState";
import { sendMessage } from "../connection/webSocketService";
import { ACTIVE_TAKER_CHANGED, LOAD_PROFILE } from "./settingsActions";

export const saveSettingsToProfileEpic = (action$, state$) => {
  return state$.pipe(
    // uncomment this after Vertex fixes loading MD on start
    // see clubhouse ticket # 24249 for why this feature exists
    // filter((state: any) => state.messages.settingsLoaded),
    map((state: any) => {
      return state.settings;
    }),
    distinctUntilChanged(),
    // skip(1), // ignore first load of settings
    map(settings => {
      const settingsMessage = {
        type: "spot",
        profile: settings,
      };

      sendMessage("profile", settingsMessage);
      return { type: "PROFILE_SAVE_SENT" };
    })
  );
};

export const clearMarketDataOnTakerChangeEpic = action$ => {
  return action$.pipe(
    ofType(ACTIVE_TAKER_CHANGED),
    map((action: any) => {
      return { type: "DELETE_CURRENCY_DATA" };
    })
  );
};

export const loadSettingsEpic = action$ => {
  return action$.pipe(
    ofType(LOAD_PROFILE),
    map((action: any) => {
      return handleSettingsLoaded();
    })
  );
};

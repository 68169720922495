import React, { useState } from "react";
import { Menu, Modal, Button, Dropdown } from "antd";
import { connect } from "react-redux";
import clsx from "clsx";
import CurrencyPairsModal from "./CurrencyPairsModal";
import PageBar from "./PageBar";
import { allCurrencyGroupsSelector, selectedCurrencyGroupIdSelector } from "../state/settings/settingsState";
import { setIsConnected } from "../state/connection/connectionState";

import styles from "./Header.module.scss";
import { allSymbolsSelector } from "../state/currency/symbols/symbolsState";
import ConnectionIndicator from "./connection/ConnectionIndicator";
import { closeUndockedCards, currencyPairsSelected } from "../state/currency/currencyActions";
import { getIsScreenLocked } from "../state/app/appState";
import {
  onCurrencyGroupSelect,
  onCurrencyGroupAdd,
  onCurrencyGroupRename,
  onCurrencyGroupDelete,
} from "../state/settings/settingsActions";

type HeaderProps = {
  closeUndockedCards: () => void;
  currencyGroups: any;
  logoUrl: any;
  symbols: any;
  onCurrencyGroupSelect: any;
  onCurrencyGroupRename: any;
  onCurrencyGroupDelete: any;
  onCurrencyGroupAdd: any;
  currencyPairsSelected: any;
  openDrawer: any;
  selectedCurrencyGroupId: any;
  setIsConnected: any;
  onToggleLock?: any;
  isScreenLocked: boolean;
  logout: any;
  contactInfo: any;
};

function Header({
  closeUndockedCards,
  currencyGroups,
  logoUrl,
  onCurrencyGroupSelect,
  onCurrencyGroupRename,
  onCurrencyGroupDelete,
  onCurrencyGroupAdd,
  openDrawer,
  symbols,
  currencyPairsSelected,
  selectedCurrencyGroupId,
  setIsConnected,
  onToggleLock,
  isScreenLocked,
  logout,
  contactInfo,
}: HeaderProps) {
  window.addEventListener("online", setIsConnected.bind(null, true));
  window.addEventListener("offline", setIsConnected.bind(null, false));

  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState<any>(null);
  const [isContactSupportModalOpen, setIsContactSupportModalOpen] = useState(false);
  const [, setIsPageBarOverflowed] = useState(false);

  const handleConfirmCurrencyPairs = (symbols, selectedSymbols) => {
    currencyPairsSelected(selectedSymbols);
    setIsCurrencyModalOpen(false);
  };

  const handleSignOut = () => {
    closeUndockedCards();
    logout();
  };

  const handleRenameTab = tab => {
    onCurrencyGroupRename(tab.id, tab.name);
  };

  const handleSelectedTabChange = tab => {
    onCurrencyGroupSelect(tab.id);
  };

  const handleDeleteTab = tab => {
    onCurrencyGroupDelete(tab.id);
  };

  const handleAddTab = tab => {
    onCurrencyGroupAdd(tab.id, tab.name);
  };

  const pages = currencyGroups.map((group, index) => ({
    id: group.id,
    name: group.displayName,
    isSelected: group.isSelected,
  }));

  const handleContactSupport = () => {
    setIsContactSupportModalOpen(true);
  };

  const handleCloseContactSupport = () => {
    setIsContactSupportModalOpen(false);
  };

  const handlePageBarOverflow = isIt => {
    setIsPageBarOverflowed(isIt);
  };

  const mainMenu = () => (
    <Menu>
      <Menu.Item key="0" onClick={handleContactSupport}>
        Contact Support
      </Menu.Item>
      <Menu.Item key="1" onClick={() => openDrawer(true)}>
        Settings
      </Menu.Item>

      <Menu.Item key="2" onClick={handleSignOut}>
        Sign out
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <img src={logoUrl} className={styles.logoImage} alt={""} />
      </div>
      <div className={styles.headerPageBar}>
        <PageBar
          tabs={pages}
          onSetCurrentPage={handleSelectedTabChange}
          onDeleteTab={handleDeleteTab}
          onRenameTab={handleRenameTab}
          onAddTab={handleAddTab}
          size={"large"}
          isDynamic={true}
          selectedTabId={selectedCurrencyGroupId}
          isMainHeader={true}
          onPageBarOverflow={handlePageBarOverflow}
        />
      </div>

      <div className={styles.headerActions}>
        {/* <div className={clsx(styles.tagline, isPageBarOverflowed && styles.taglineHidden)}>Powered by Lucera</div> */}
        <div className={clsx(styles.actionCell, styles.addPairButton)}>
          <Button size="large" className={styles.button} onClick={() => setIsCurrencyModalOpen(true)}>
            Add new pair
          </Button>
        </div>
        <ConnectionIndicator />
        {/* <div className={styles.actionCell}>
            <Button size="large" icon="lock" onClick={handleClickLockScreen} />
          </div> */}
        <div className={styles.actionCell}>
          <Button
            className={isScreenLocked ? styles.lockActive : styles.lockInactive}
            size="large"
            icon="lock"
            onClick={onToggleLock}
          />
        </div>
        <div className={styles.actionCell}>
          <Dropdown overlay={mainMenu} trigger={["click"]}>
            <Button size="large" icon="user" />
          </Dropdown>
        </div>
      </div>

      <CurrencyPairsModal
        isVisible={isCurrencyModalOpen}
        onOk={handleConfirmCurrencyPairs}
        onCancel={() => setIsCurrencyModalOpen(false)}
      />

      <Modal
        visible={isContactSupportModalOpen}
        closable={false}
        footer={null}
        style={{ padding: 0, borderRadius: "11px" }}
        bodyStyle={{ padding: 0, borderRadius: "11px", width: "336px", height: "264px" }}
        width={"336px"}
      >
        <div className={styles.contactModal}>
          <div className={styles.title}>Contact Support</div>
          <div className={styles.smallTitle}>Phone</div>
          <div className={styles.titleValue}>{contactInfo?.phone ? contactInfo.phone : "+1 (855)299-2800 Ext 0"}</div>
          <div className={styles.smallTitle}>Email</div>
          <div className={styles.titleValue}>{contactInfo?.email ? contactInfo.email : "lumefx@lucera.com"}</div>
          <div className={styles.closeButton}>
            <Button type="default" size="small" onClick={handleCloseContactSupport}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default connect(
  (state: any, ownProps: any) => ({
    symbols: allSymbolsSelector(state),
    currencyGroups: allCurrencyGroupsSelector(state),
    selectedCurrencyGroupId: selectedCurrencyGroupIdSelector(state),
    logoUrl: ownProps.logoUrl,
    isScreenLocked: getIsScreenLocked(state),
  }),
  {
    closeUndockedCards,
    onCurrencyGroupSelect,
    onCurrencyGroupAdd,
    onCurrencyGroupRename,
    onCurrencyGroupDelete,
    currencyPairsSelected,
    setIsConnected,
  }
)(Header);

import { Button } from "antd";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Sortable from "sortablejs";
import backgroundImage from "../../assets/images/Spot.svg";
import { currencyPairsSelected, undockCard } from "../../state/currency/currencyActions";
import { getAuctionCardDetailSelector } from "../../state/currency/currencyState";
import { onCurrencyGroupMove } from "../../state/settings/settingsActions";
import {
  CardSize,
  getAuctionCardStyle,
  getMarketSettings,
  getOrdersSettings,
  selectedCurrencyGroupSelector,
} from "../../state/settings/settingsState";
import CurrencyPairsModal from "../CurrencyPairsModal";
import CurrencyCard from "./Card";
import "./cards.scss";

const isOpenFin = window.fin !== undefined;

const CurrencyGrid = ({
  currencyGroup,
  currencyPairsSelected,
  orderSettings,
  marketSettings,
  undockCard,
  onCurrencyGroupMove,
  activeCard,
  setActiveCard,
  activeCardSide,
  setActiveCardSide,
}: any) => {
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState<any>(null);
  const [, setAuctionCardModalContent] = useState<any>();
  const symbols = currencyGroup && currencyGroup.symbols;
  const currencyCardsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = currencyCardsRef;
    Sortable.create(
      current,
      {
        chosenClass: "dragZone",
        dragClass: "dragZone",
        ghostClass: "ghostZone",
        filter: ".noDrag",
        preventOnFilter: false,
        animation: 250,
        swapThreshold: 1,
        invertSwap: true,
        onUpdate(event) {
          onCurrencyGroupMove(event.newIndex, event.oldIndex);
        },
      },
      []
    );
  }, [onCurrencyGroupMove]);

  const handleConfirmCurrencyPairs = (symbols, selectedSymbols) => {
    currencyPairsSelected(selectedSymbols);
    setIsCurrencyModalOpen(false);
  };

  const currencyCardsSizeClass =
    marketSettings?.cardSize === CardSize.Medium
      ? "currencyCardsMediumSize"
      : marketSettings?.cardSize === CardSize.Large
      ? "currencyCardsLargeSize"
      : "";

  return (
    <div className="currencyGrid">
      <div
        ref={currencyCardsRef}
        className={clsx("currencyCards", !orderSettings.showOrders && "currencyCardsMaxWidth", currencyCardsSizeClass)}
      >
        {symbols && symbols.length > 0 ? (
          symbols.map((symbol, i) => (
            <div className="cardDragContainer" key={`${symbol}.${i}`}>
              <CurrencyCard
                key={symbol + i}
                cardIndex={i}
                symbolId={symbol}
                undockCard={undockCard.bind(null, symbol)}
                isOpenFin={isOpenFin}
                setAuctionCardModalContent={setAuctionCardModalContent}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                activeCardSide={activeCardSide}
                setActiveCardSide={setActiveCardSide}
              />
            </div>
          ))
        ) : (
          <div className="noCard">
            <img width="128px" height="80px" alt="building" src={backgroundImage} className={"background-image"} />
            <div className="noCardTitle">No currency pairs added yet.</div>
            <Button className="noCardBtn" size="large" onClick={() => setIsCurrencyModalOpen(true)}>
              Add new pair
            </Button>
          </div>
        )}
        <CurrencyPairsModal
          isVisible={isCurrencyModalOpen}
          onOk={handleConfirmCurrencyPairs}
          onCancel={() => setIsCurrencyModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default connect(
  (state: any) => ({
    currencyGroup: selectedCurrencyGroupSelector(state),
    auctionCardDetail: getAuctionCardDetailSelector(state),
    auctionCardStyle: getAuctionCardStyle(state),
    orderSettings: getOrdersSettings(state),
    marketSettings: getMarketSettings(state),
  }),
  { undockCard, currencyPairsSelected, onCurrencyGroupMove }
)(CurrencyGrid);

import { OrderStatus } from "../state/orders/types";

export const SYMBOL_PRICE_FORMAT = {
  "ADA/EUR": {
    precision: 5,
    significantEnd: 1,
    significantLength: 2,
  },
  "ADA/USDC": {
    precision: 5,
    significantEnd: 1,
    significantLength: 2,
  },
  "ADA/USDT": {
    precision: 5,
    significantEnd: 1,
    significantLength: 2,
  },
  "ADA/USD": {
    precision: 5,
    significantEnd: 1,
    significantLength: 2,
  },
  "AUD/JPY": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "BCH/EUR": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "BCH/JPY": {
    precision: 0,
    significantEnd: 0,
    significantLength: 2,
  },
  "BCH/USD": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "BCH/USDC": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "BCH/USDT": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "BNB/EUR": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "BNB/USDC": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "BNB/USDT": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "BNB/USD": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "BTC/EUR": {
    precision: 2,
    significantEnd: 0,
    significantLength: 3,
  },
  "BTC/JPY": {
    precision: 0,
    significantEnd: 0,
    significantLength: 2,
  },
  "BTC/USD": {
    precision: 2,
    significantEnd: 0,
    significantLength: 3,
  },
  "BTC/USDT": {
    precision: 2,
    significantEnd: 0,
    significantLength: 3,
  },
  "BTC/USDC": {
    precision: 2,
    significantEnd: 0,
    significantLength: 3,
  },
  "CAD/JPY": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "CAD/NOK": {
    precision: 1,
    significantEnd: 1,
    significantLength: 2,
  },
  "CHF/HUF": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "CHF/JPY": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "CHF/RUB": {
    precision: 4,
    significantEnd: 1,
    significantLength: 2,
  },
  "CNH/RUB": {
    precision: 4,
    significantEnd: 1,
    significantLength: 2,
  },
  "DKK/JPY": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "DOT/EUR": {
    precision: 4,
    significantEnd: 1,
    significantLength: 2,
  },
  "DOT/USDC": {
    precision: 4,
    significantEnd: 1,
    significantLength: 2,
  },
  "DOT/USDT": {
    precision: 4,
    significantEnd: 1,
    significantLength: 2,
  },
  "DOT/USD": {
    precision: 4,
    significantEnd: 1,
    significantLength: 2,
  },
  "ETH/BTC": {
    precision: 6,
    significantEnd: 0,
    significantLength: 2,
  },
  "ETH/EUR": {
    precision: 2,
    significantEnd: 0,
    significantLength: 3,
  },
  "ETH/JPY": {
    precision: 0,
    significantEnd: 0,
    significantLength: 2,
  },
  "ETH/USD": {
    precision: 2,
    significantEnd: 0,
    significantLength: 3,
  },
  "ETH/USDT": {
    precision: 2,
    significantEnd: 0,
    significantLength: 3,
  },
  "ETH/USDC": {
    precision: 2,
    significantEnd: 0,
    significantLength: 3,
  },
  "EUR/CZK": {
    precision: 5,
    significantEnd: 2,
    significantLength: 2,
  },
  "EUR/HUF": {
    precision: 5,
    significantEnd: 3,
    significantLength: 2,
  },
  "EUR/JPY": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "EUR/USD": {
    precision: 5,
    significantEnd: 1,
    significantLength: 2,
  },
  "GBP/CZK": {
    precision: 4,
    significantEnd: 1,
    significantLength: 2,
  },
  "GBP/HUF": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "GBP/JPY": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "HKD/JPY": {
    precision: 5,
    significantEnd: 1,
    significantLength: 1,
  },
  "LTC/JPY": {
    precision: 0,
    significantEnd: 0,
    significantLength: 2,
  },
  "LTC/EUR": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "LTC/USD": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "LTC/USDC": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "LTC/USDT": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "MXN/JPY": {
    precision: 4,
    significantEnd: 1,
    significantLength: 2,
  },
  "NOK/JPY": {
    precision: 4,
    significantEnd: 1,
    significantLength: 2,
  },
  "NZD/JPY": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "SEK/JPY": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "SGD/JPY": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "SOL/EUR": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "SOL/USDC": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "SOL/USDT": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "SOL/USD": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "TRY/JPY": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "USD/BRL": {
    precision: 5,
    significantEnd: 1,
    significantLength: 2,
  },
  "USD/BRL-1M": {
    precision: 5,
    significantEnd: 1,
    significantLength: 2,
  },
  "USD/BRL-BMF1": {
    precision: 5,
    significantEnd: 1,
    significantLength: 2,
  },
  "USD/CLP": {
    precision: 4,
    significantEnd: 2,
    significantLength: 2,
  },
  "USD/CLP-1M": {
    precision: 4,
    significantEnd: 2,
    significantLength: 2,
  },
  "USD/CNY": {
    precision: 4,
    significantEnd: 0,
    significantLength: 2,
  },
  "USD/CNY-1M": {
    precision: 4,
    significantEnd: 0,
    significantLength: 2,
  },
  "USD/COP": {
    precision: 2,
    significantEnd: 0,
    significantLength: 2,
  },
  "USD/COP-1M": {
    precision: 2,
    significantEnd: 0,
    significantLength: 2,
  },
  "USD/CZK": {
    precision: 4,
    significantEnd: 1,
    significantLength: 2,
  },
  "USD/HUF": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "USD/IDR": {
    precision: 2,
    significantEnd: 2,
    significantLength: 2,
  },
  "USD/IDR-1M": {
    precision: 2,
    significantEnd: 2,
    significantLength: 2,
  },
  "USD/INR": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "USD/INR-1M": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "USD/JPY": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "USD/KRW": {
    precision: 2,
    significantEnd: 0,
    significantLength: 2,
  },
  "USD/KRW-1M": {
    precision: 2,
    significantEnd: 0,
    significantLength: 2,
  },
  "USD/KRW-1W": {
    precision: 2,
    significantEnd: 0,
    significantLength: 2,
  },
  "USD/PEN": {
    precision: 4,
    significantEnd: 0,
    significantLength: 2,
  },
  "USD/PEN-1M": {
    precision: 4,
    significantEnd: 0,
    significantLength: 2,
  },
  "USD/PHP": {
    precision: 4,
    significantEnd: 2,
    significantLength: 2,
  },
  "USD/PHP-1M": {
    precision: 4,
    significantEnd: 2,
    significantLength: 2,
  },
  "USD/THB": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "USD/TWD": {
    precision: 4,
    significantEnd: 1,
    significantLength: 2,
  },
  "USD/TWD-1M": {
    precision: 4,
    significantEnd: 1,
    significantLength: 2,
  },
  "USDT/EUR": {
    precision: 5,
    significantEnd: 0,
    significantLength: 2,
  },
  "USDT/USD": {
    precision: 5,
    significantEnd: 0,
    significantLength: 2,
  },
  "USDC/EUR": {
    precision: 5,
    significantEnd: 0,
    significantLength: 2,
  },
  "USDC/USDT": {
    precision: 5,
    significantEnd: 0,
    significantLength: 2,
  },
  "USDC/USD": {
    precision: 5,
    significantEnd: 0,
    significantLength: 2,
  },
  "XAG/AUD": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAG/CAD": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAG/CHF": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAG/EUR": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAG/GBP": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAG/JPY": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAG/NZD": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAG/SGD": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAG/USD": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAG/USDm": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAG/USM": {
    precision: 4,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAU/AUD": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAU/CAD": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAU/CHF": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAU/EUR": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAU/GBP": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAU/JPY": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAU/NZD": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAU/SGD": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAU/TRY": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAU/USD": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAU/USDm": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XAU/USM": {
    precision: 3,
    significantEnd: 1,
    significantLength: 2,
  },
  "XPD/EUR": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XPD/USD": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XPT/EUR": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XPT/USD": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
  "XRP/EUR": {
    precision: 5,
    significantEnd: 1,
    significantLength: 2,
  },
  "XRP/JPY": {
    precision: 0,
    significantEnd: 0,
    significantLength: 2,
  },
  "XRP/USD": {
    precision: 5,
    significantEnd: 1,
    significantLength: 2,
  },
  "XRP/USDC": {
    precision: 5,
    significantEnd: 1,
    significantLength: 2,
  },
  "XRP/USDT": {
    precision: 5,
    significantEnd: 1,
    significantLength: 2,
  },
  "XPL/USD": {
    precision: 3,
    significantEnd: 0,
    significantLength: 2,
  },
  "ZAR/JPY": {
    precision: 2,
    significantEnd: 1,
    significantLength: 2,
  },
};

// if market data ends up providing this then we don't need use this list
// If we want to add the NDF label we have to add the whole ccy pair to this list
export const NDFCurrencies = [
  "USD/CNY-1M",
  "USD/IDR-1M",
  "USD/INR-1M",
  "USD/KRW-1M",
  "USD/KRW-1W",
  "USD/MYR-1M",
  "USD/PHP-1M",
  "USD/TWD-1M",
  "USD/PEN-1M",
  "USD/COP-1M",
  "USD/CLP-1M",
  "USD/BRL-1M",
  "USD/BRL-BMF1",
];

export const cryptoCurrencies = [
  "ADA/EUR",
  "ADA/USD",
  "ADA/USDC",
  "ADA/USDT",
  "BCH/EUR",
  "BCH/JPY",
  "BCH/USD",
  "BCH/USDC",
  "BCH/USDT",
  "BNB/EUR",
  "BNB/USD",
  "BNB/USDC",
  "BNB/USDT",
  "BTC/CHF",
  "BTC/EUR",
  "BTC/GBP",
  "BTC/JPY",
  "BTC/USD",
  "BTC/USDC",
  "BTC/USDT",
  "DOT/EUR",
  "DOT/USD",
  "DOT/USDC",
  "DOT/USDT",
  "ETH/BTC",
  "ETH/CHF",
  "ETH/EUR",
  "ETH/GBP",
  "ETH/JPY",
  "ETH/USD",
  "ETH/USDC",
  "ETH/USDT",
  "LTC/EUR",
  "LTC/JPY",
  "LTC/USD",
  "LTC/USDC",
  "LTC/USDT",
  "SOL/EUR",
  "SOL/USD",
  "SOL/USDC",
  "SOL/USDT",
  "USDC/CHF",
  "USDC/EUR",
  "USDC/USD",
  "USDC/USDT",
  "USDT/CHF",
  "USDT/EUR",
  "USDT/USD",
  "USDT/USD",
  "USDT/USDC",
  "XPL/USD",
  "XRP/EUR",
  "XRP/JPY",
  "XRP/USD",
  "XRP/USDC",
  "XRP/USDT",
];

// const newCryptos = [
//   "ADA/EUR",
//   "ADA/USDC",
//   "ADA/USDT",
//   "ADA/USD",
//   "BCH/EUR",
//   "BCH/USDC",
//   "BCH/USDT",
//   "BCH/USD",
//   "BNB/EUR",
//   "BNB/USDC",
//   "BNB/USDT",
//   "BNB/USD",
//   "BTC/EUR",
//   "BTC/USDC",
//   "BTC/USDT",
//   "BTC/USD",
//   "ETH/EUR",
//   "ETH/USDC",
//   "ETH/USDT",
//   "ETH/USD",
//   "LTC/EUR",
//   "LTC/USDC",
//   "LTC/USDT",
//   "LTC/USD",
//   "SOL/EUR",
//   "SOL/USDC",
//   "SOL/USDT",
//   "SOL/USD",
//   "DOT/EUR",
//   "DOT/USDC",
//   "DOT/USDT",
//   "DOT/USD",
//   "XRP/EUR",
//   "XRP/USDC",
//   "XRP/USDT",
//   "XRP/USD",
//   "USDC/EUR",
//   "USDC/USDT",
//   "USDC/USD",
//   "USDT/EUR",
//   "USDT/USDC",
//   "USDT/USD"
// ];

export const staticAssetServerURL = "https://ny4-static-assets.lucera.com";

export const minRandomCard = 1;
export const maxRandomCard = 1000000;

// export const pausableStatuses = [OrderStatus.Working, OrderStatus.PartialFill];
export const resumableStatuses = [OrderStatus.Paused];

export const startTimeEODRecycle = "17:00:00";
// export const endTimeEODRecycle = "17:15:00";

export const nextCalendarDateText = "for next calendar date";
export const appLocale = "en-US";

export const maxTextAreaChars = 64;

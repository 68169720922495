import { isToday } from "date-fns";
import { DisplayOrdersByTimeType } from "../../settings/settingsState";

import { orderOrders } from "../ordersReducer";
import { UPDATE_ORDER } from "../ordersState";
import { isOrderCancelable, isOrderPausable, isOrderResumable } from "../../../lib/util";


export const INIT_ALL_ORDERS_BASIC_INFO = "INIT_ALL_ORDERS_BASIC_INFO";
export const SET_ORDERS_VISIBILITY_AFTER_FILTERING = "SET_ORDERS_VISIBILITY_AFTER_FILTERING";
export const SET_NEW_VISIBLE_ORDER = "SET_NEW_VISIBLE_ORDER";
export const TOGGLE_VISIBLE_ORDERS = "TOGGLE_VISIBLE_ORDERS";
export const UPDATE_ORDERS_VISIBILITY = "UPDATE_ORDERS_VISIBILITY";
export const SET_CHECK_ORDERS_VISIBILITY = "SET_CHECK_ORDERS_VISIBILITY";
export const CHECK_ORDERS_VISIBILITY_AND_TOGGLE_VISIBLE_ORDERS = "CHECK_ORDERS_VISIBILITY_AND_TOGGLE_VISIBLE_ORDERS";
export const SELECT_ORDER = "SELECT_ORDER";
export const DESELECT_ORDERS = "DESELECT_ORDERS";
export const PAUSE_ORDERS = "PAUSE_ORDERS";
export const RESUME_ORDERS = "RESUME_ORDERS";
export const CANCEL_ORDERS = "CANCEL_ORDERS";


const initialState = {
    selectedOrders: {},
    checkOrdersVisibility: false,
    isEverySelectedOrderPausable: false,
    isEverySelectedOrderResumable: false,
    isEverySelectedOrderCancelable: false
};


// Reducer 
const pauseResumeOrdersReducer = (state = initialState, action: any) => {

    switch (action.type) {

        case INIT_ALL_ORDERS_BASIC_INFO: {

            // pauseResumeHelper
            // console.log("INIT_ALL_ORDERS_BASIC_INFO");

            const ordersArr = orderOrders(action.payload.orders);
            const displayOrdersByTime = action.payload.displayOrdersByTime;
            const ordersBasicInfoObj = {};

            // pauseResumeHelper
            // console.log(displayOrdersByTime);

            // "Select all" checkbox 
            ordersBasicInfoObj["selectAll"] = false;

            ordersArr.forEach((order) =>{

                // If the user didn't select "today" all orders would be visible 
                let isVisible = true;

                // If the user set up the orders to only see today's orders then today's orders should be the only ones set to "visible=true", the rest should be set to false
                if(displayOrdersByTime === DisplayOrdersByTimeType.today){

                    isVisible = isToday(order?.createdDate) ? true : false;

                } 

                ordersBasicInfoObj[order?.id] = {correlation: order?.correlation, status: order?.status, lifespan: order?.lifespan?.toLowerCase(), selected: false, visible: isVisible};

            } );

            // pauseResumeHelper
            // console.log(ordersBasicInfoObj);

            return {...state.selectedOrders, selectedOrders: ordersBasicInfoObj};

        }
    
        case SELECT_ORDER: {

            // pauseResumeHelper
            // console.log("SELECT ORDER REDUCER");

            const id = action.payload.orderId;
            const readOnly = action.payload.readOnly;
            // pauseResumeHelper
            // console.log(state.selectedOrders[id]);

            let isPausable = true;
            let isResumable = true;
            let isCancelable = true;
            for (const key in state.selectedOrders){

                const item = state.selectedOrders[key];
                if(item?.visible){
                    // If at least one has a non-pausable status then isPausable should be false."key == id" condition bc this order is being selected, not yet selected
                    if ((item?.selected && !isOrderPausable(item?.status, item?.lifespan, readOnly)) || (key === id && !isOrderPausable(item?.status, item?.lifespan, readOnly)) ){
                        isPausable = false;
                    }

                    if ((item?.selected && !isOrderResumable(item?.status, readOnly)) || (key === id && !isOrderResumable(item?.status, readOnly)) ){
                        isResumable = false;
                    }

                    if((item?.selected && !isOrderCancelable(item?.status, item?.lifespan, readOnly))  || (key === id && !isOrderCancelable(item?.status, item?.lifespan, readOnly))){
                        isCancelable = false;
                    }
                     
                }
            }

            return {...state, selectedOrders: {...state.selectedOrders, [id]: { status: state.selectedOrders[id].status, selected: true, visible: state.selectedOrders[id].visible } }, isEverySelectedOrderPausable: isPausable, isEverySelectedOrderResumable: isResumable, isEverySelectedOrderCancelable: isCancelable};

        }

        case DESELECT_ORDERS: {

            // pauseResumeHelper
            // console.log("DESELECT ORDER REDUCER");

            const newSelectedOrders = state.selectedOrders;
            newSelectedOrders["selectAll"] = false;
            const orderIds = action.payload.orderIds;
            const readOnly = action.payload.readOnly;

            let isPausable = true;
            let isResumable = true;
            let isCancelable = true;
            let noOrdersSelected = true;

            orderIds.forEach((id) => {

                for (const key in state.selectedOrders){

                    const item = state.selectedOrders[key];

                    // We are not taking into account the order that triggered this because we are deselecting it
                    if(item.visible && item?.selected && key !== id){

                        if(!isOrderPausable(item?.status, item?.lifespan, readOnly)){
                            isPausable = false;
                        }

                        if(!isOrderResumable(item?.status, readOnly)){
                            isResumable = false;
                        }

                        if(!isOrderCancelable(item?.status, item?.lifespan, readOnly)){
                            isCancelable = false;
                        }

                        // If no orders are selected 
                        noOrdersSelected = false;
                    }

                }

                newSelectedOrders[id] = { status: state.selectedOrders[id].status, selected: false, visible: state.selectedOrders[id].visible };

            });


            // If no orders are selected Pause and Resume buttons should be disabled 
            if (noOrdersSelected){
                isPausable = false;
                isResumable = false;
                isCancelable = false;
            }

            // return {...state, selectedOrders: {...state.selectedOrders, [id]: { status: state.selectedOrders[id].status, selected: false, visible: state.selectedOrders[id].visible }, selectAll: false}, isEverySelectedOrderPausable: isPausable, isEverySelectedOrderResumable: isResumable, isEverySelectedOrderCancelable: isCancelable };
            return {...state, selectedOrders: {...newSelectedOrders}, isEverySelectedOrderPausable: isPausable, isEverySelectedOrderResumable: isResumable, isEverySelectedOrderCancelable: isCancelable };

        } 

        // case SET_NEW_VISIBLE_ORDER: {
        
        //     console.log("SET_NEW_VISIBLE_ORDER");

        //     const order = action.payload;
        //     const newSelectedOrder = {};
            

        //     newSelectedOrder[order?.id] = {correlation: order?.correlation, status: order?.status, lifespan: order?.lifespan?.toLowerCase(), selected: false, visible: true, isNewOrder: true};

        //     console.log(newSelectedOrder);

        //     const testOrders = {...state.selectedOrders, [order?.id]: {correlation: order?.correlation, status: order?.status, lifespan: order?.lifespan?.toLowerCase(), selected: false, visible: true, isNewOrder: true}};

        //     console.log(testOrders);
            
        //     HEREZ
        //     return {...state.selectedOrders, selectedOrders: testOrders};
            
        // }

        case SET_ORDERS_VISIBILITY_AFTER_FILTERING: {

            // pauseResumeHelper
            // console.log("SET ORDERS VISIBILITY");


            // console.log(gridRef);
            // gridRef.current.api?.

            // After filtering set "visible" to true the orders that are visible in screen (filtered). The rest of the orders have to be be setted to false. 
            // Also deselect all the selected previous orders. Kind of a reset
            const ordersIds = action.payload.orderIds; 
            const readOnly = action.payload.readOnly;

            // pauseResumeHelper
            // console.log(ordersIds);

            const selectedNewOrders = {};
            let selectAll = state.selectedOrders["selectAll"];

            for (const key in state.selectedOrders){

                const item = state.selectedOrders[key];

                // if(item.isNewOrder){
                //     console.log("It's new order");
                //     console.log(item);
                // }

                // The ag grid filter function sometimes uses the order id and sometimes the correlation as node id
                // let isVisible = ordersIds.includes(key) || ordersIds.includes(item.correlation || item.isNewOrder) ? true : false;
                let isVisible = ordersIds.includes(key) || ordersIds.includes(item.correlation) ? true : false;
                selectedNewOrders[key] = {...item, visible: isVisible, selected: isVisible ? item?.selected : false, isNewOrder: false};

            }

            // We need to check the orders after the changes, that's why we iterate over "selectedNewOrders" here 
            let isPausable = true;
            let isResumable = true;
            let isCancelable = true;
            let noOrdersSelected = true;
            for (const key in selectedNewOrders){

                const newItem = selectedNewOrders[key];

                if(newItem?.visible) {
                
                    if(newItem?.selected){

                        if(!isOrderPausable(newItem?.status, newItem?.lifespan, readOnly)){
                            isPausable = false;
                        }

                        if(!isOrderResumable(newItem?.status, readOnly)){
                            isResumable = false;
                        }

                        if(!isOrderCancelable(newItem?.status, newItem?.lifespan, readOnly)){
                            isCancelable = false;
                        }

                    noOrdersSelected = false;

                     // If there is one order not selected then deselect "Select all"
                    } else {
                        
                        selectAll = false;

                    }

                }

            }

            // Reset selectAll checkbox 
            selectedNewOrders["selectAll"] = selectAll;

            if(noOrdersSelected){
                isPausable = false;
                isResumable = false;
                isCancelable = false;
            }

            // pauseResumeHelper
            // console.log(selectedNewOrders);

            return {...state, selectedOrders: selectedNewOrders, isEverySelectedOrderPausable: isPausable, isEverySelectedOrderResumable: isResumable, isEverySelectedOrderCancelable: isCancelable};

        }

        case TOGGLE_VISIBLE_ORDERS: {

            // pauseResumeHelper
            // console.log("TOGGLE VISIBLE ORDERS");

            const isSelectAll = action.payload.isSelectAll;
            const readOnly = action.payload.readOnly;
            
            const selectedNewOrders = {};
            let isPausable = isSelectAll;
            let isResumable = isSelectAll;
            let isCancelable = isSelectAll;

            for (const key in state.selectedOrders){

                const item = state.selectedOrders[key];

                // Select or deselect ONLY the VISIBLE orders, if the orders are NOT VISIBLE deselect them
                let isSelect = item.visible ? isSelectAll : false;
                
                selectedNewOrders[key] = {...item, selected: isSelect};

                if(item?.visible && isSelectAll){
                    // If we are selecting the orders and they are not pausable 
                    if(!isOrderPausable(item?.status, item?.lifespan, readOnly)){
                        isPausable = false;
                    }

                    if(!isOrderResumable(item?.status, readOnly)){
                        isResumable = false;
                    }

                    if(!isOrderCancelable(item?.status, item?.lifespan, readOnly)){
                        isCancelable = false;
                    }
                }

            }

            selectedNewOrders["selectAll"] = isSelectAll;
            // pauseResumeHelper
            // console.log(selectedNewOrders);

            return {...state, selectedOrders: selectedNewOrders, isEverySelectedOrderPausable: isPausable, isEverySelectedOrderResumable: isResumable, isEverySelectedOrderCancelable: isCancelable};
        }

        case SET_CHECK_ORDERS_VISIBILITY: {

            // pauseResumeHelper
            // console.log("SET_CHECK_ORDERS_VISIBILITY");

            return {...state, checkOrdersVisibility: action.payload};

        }

        case UPDATE_ORDER: {

            // pauseResumeHelper
            // console.log("UPDATE ORDER");

            const order = action.payload.data;
            const readOnly = action.payload.readOnly;

            // Selection: if the order already exists then leave "selected" and "visible" as it was. If it's a new order (when we submit a new order) initialize it to the default value, false 
            const orderSelected = state.selectedOrders[order?.id] ? state.selectedOrders[order?.id].selected : false;
        
            // Visibility: if it's a new order the default value will be set visible to false
            const orderVisible = state.selectedOrders[order?.id] ? state.selectedOrders[order?.id].visible : false;

            // If it's a new order then set the checkOrdersVisibility flag to true so the filter function (ag grid) is triggered. The filter checks if the new order should be visible (if it's included in the filters). And deselect "selectAll"
            // if(!state.selectedOrders[order?.id]){
            //     console.log("NEW ORDER");
            //     console.log(order?.id);
            //     // HEREZ
            //     return {...state, selectedOrders: {...state.selectedOrders, [order?.id]: {correlation: order?.correlation, status: order?.status, selected: orderSelected, visible: orderVisible }, selectAll: false }, checkOrdersVisibility: true};
            // }

            let isPausable = true;
            let isResumable = true;
            let isCancelable = true;
            let noOrdersSelected = true;
            for (const key in state.selectedOrders){

                const item = state.selectedOrders[key];

                if(item?.visible && item?.selected){

                    if(!isOrderPausable(item?.status, item?.lifespan, readOnly)){
                        isPausable = false;
                    }

                    if(!isOrderResumable(item?.status, readOnly)){
                        isResumable = false;
                    }


                    if(!isOrderCancelable(item?.status, item?.lifespan, readOnly)){
                        isCancelable = false;
                    }

                    noOrdersSelected = false;
                    
                }
            }

            // pauseResumeHelper
            // console.log("noOrdersSelected");
            // console.log(noOrdersSelected);

            if(noOrdersSelected){
                isPausable = false;
                isResumable = false;
                isCancelable = false;
            }
            
            // pauseResumeHelper
            // console.log("-----------------------------------");


            return {...state, selectedOrders: {...state.selectedOrders, [order?.id]: {correlation: order?.correlation, status: order?.status,  lifespan: order?.lifespan?.toLowerCase(), selected: orderSelected, visible: orderVisible } }, isEverySelectedOrderPausable: isPausable, isEverySelectedOrderResumable: isResumable, isEverySelectedOrderCancelable: isCancelable, checkOrdersVisibility: true};

        }

        default:
            return state;
    
    }

  }

export default pauseResumeOrdersReducer;


// Selectors 
export const getSelectedOrders = (state: any) => state.pauseResumeOrders.selectedOrders;
export const getCheckOrdersVisibility = (state: any) => state.pauseResumeOrders.checkOrdersVisibility;
export const getIsEverySelectedOrderPausable = (state: any) => state.pauseResumeOrders.isEverySelectedOrderPausable;
export const getIsEverySelectedOrderResumable = (state: any) => state.pauseResumeOrders.isEverySelectedOrderResumable;
export const getIsEverySelectedOrderCancelable = (state: any) => state.pauseResumeOrders.isEverySelectedOrderCancelable;






import React from "react";
import ReactDOM from "react-dom";
import copyStyles from "../../openfin/copyStyles";
import { setAuctionCard } from "../../state/currency/currencyActions";

import CurrencyCard from "./Card";
import { getCardPanelHeight } from "../../lib/util";
import { CardSize } from "../../state/settings/settingsState";
import { ccyPairIdStripper } from "../../lib/formatUtil";

type WindowProps = {
  symbol: string;
  depthDisplay: any;
  displaySpreadOnDepthOfBook: boolean;
  showSlippage: boolean;
  showPriceTrendAndSpread: boolean;
  cardSize: string;
};

const isOpenFin = window.fin !== undefined;

function Window({
  symbol,
  depthDisplay,
  displaySpreadOnDepthOfBook,
  showSlippage,
  showPriceTrendAndSpread,
  cardSize,
}: WindowProps) {
  const height = getCardPanelHeight(
    depthDisplay,
    displaySpreadOnDepthOfBook,
    showSlippage,
    showPriceTrendAndSpread,
    cardSize
  );

  if (!window["externalPanels"]) window["externalPanels"] = {};
  // 228 = 216 (card width) + 12 (scrollbar width)
  // const windowWidth = cardSize === CardSize.Medium ? 282 : cardSize === CardSize.Large ? 336 : 228;
  const windowWidth = cardSize === CardSize.Medium ? 361 : cardSize === CardSize.Large ? 404 : 283;
  window["externalPanels"][symbol] = window.open("", "", `width=${windowWidth},height=${height},left=20,top=20`);
  const element = document.createElement("div");

  const currencyCardsSizeClass =
    cardSize === CardSize.Medium
      ? "currencyCardsMediumSize"
      : cardSize === CardSize.Large
      ? "currencyCardsLargeSize"
      : "";

  window["externalPanels"][symbol].document.body.appendChild(element);
  copyStyles(document, window["externalPanels"][symbol].document);
  window["externalPanels"][symbol].document.title = ccyPairIdStripper(symbol);
  return ReactDOM.createPortal(
    <div className={currencyCardsSizeClass}>
      <CurrencyCard
        undockCard={null}
        key={symbol}
        symbolId={symbol}
        onTenderClick={setAuctionCard}
        isOpenFin={isOpenFin}
        isModal={false}
        isPortal={true}
      />
    </div>,
    element
  );
}

export default Window;

import React, { useState, useEffect } from "react";
import { Input, Button, Menu, Icon } from "antd";

import { SelectionList } from "./SelectionList";

import styles from "../CurrencyPairsModal.module.scss";
import { getRandomInt } from "../../lib/util";
import { minRandomCard, maxRandomCard } from "../../lib/constants";
import { SymbolsListProps } from "./symbolsList.types";

export function SymbolsListBasic({ allSymbols, selectedSymbols, onOk, onCancel, config }: SymbolsListProps) {
  //STATE-----------------
  const { listHeight, title, footerButtonSize, footerActionLabel, filterPlaceholder, allowMultiSelect } = config || {};
  const symbols = allSymbols
    // We remove the filtering that was being applied before
    // .filter(symbol => selectedSymbols && !selectedSymbols.includes(symbol))
    .map(symbol => ({
      symbol: symbol,
    }));

  const computeList = () =>
    symbols.map((item, i) => ({
      ...item,
      index: i,
      value: item.symbol,
      isSelected: item.isSelected || false,
    }));

  const [list, setList] = useState<any>(computeList());

  useEffect(() => {
    computeList();
  }, [symbols]);

  const [filter, setFilter] = useState<String>("");
  const filteredList = list.filter(item =>
    item.value
      .replace("/", "")
      .toLowerCase()
      .includes(filter.replace("/", "").toLowerCase())
  );

  //ACTIONS--------------
  const handleSelect = clickedItem => {
    const newList = list.map(item => {
      return item.index === clickedItem.index
        ? { ...item, isSelected: !item.isSelected }
        : allowMultiSelect
        ? item
        : { ...item, isSelected: false };
    });
    setList(newList);
  };

  const clearList = () => {
    const newList = list.map(item => {
      return { ...item, isSelected: false };
    });
    setList(newList);
  };

  const handleOk = () => {
    let selectedSymbols = list.filter(item => item.isSelected).map(item => item.symbol);

    if (selectedSymbols.length <= 0 && filter.length > 0) {
      //no selected items and filter is not blank and enter key clicked---then submit first match
      const matchedCurrencyPair = list.find(currencyPair => {
        const value = currencyPair.value.replace("/", "").toLowerCase() || "";
        const isMatch = value.startsWith(filter.replace("/", "").toLowerCase()) || false;
        return isMatch;
      });
      if (matchedCurrencyPair) selectedSymbols = [matchedCurrencyPair.symbol];
    }
    clearList();

    const selectedSymbolsWithId = selectedSymbols.map(value => {
      const id = getRandomInt(minRandomCard, maxRandomCard);
      return `${value}_${id}`;
    });

    console.log(selectedSymbolsWithId);

    if (selectedSymbolsWithId.length > 0 && selectedSymbolsWithId[0]) {
      onOk(symbols, selectedSymbolsWithId);
    }
  };

  const handleCancel = () => {
    clearList();
    onCancel();
  };

  const handleKeypress = e => {
    if (e && e.key === "Enter") {
      e.preventDefault();
      handleOk();
    }
  };

  return (
    <React.Fragment>
      <div className={styles.header}>
        {title && <h2 className={styles.headerItem}>{title}</h2>}
        <Input
          onChange={e => setFilter(e.target.value)}
          className={styles.headerItem}
          size="large"
          placeholder={filterPlaceholder || ""}
          autoFocus={true}
          onKeyDown={(e: any) => {
            handleKeypress(e);
          }}
        />
      </div>

      <SelectionList
        style={{ height: listHeight || "100%" }}
        list={filteredList}
        onSelect={item => handleSelect(item)}
        onDeselect={item => handleSelect(item)}
      />

      <div className={styles.footer}>
        <Button className={styles.footerElement} onClick={handleCancel} size={footerButtonSize || "large"}>
          Cancel
        </Button>
        <Button
          className={styles.footerElement}
          size={footerButtonSize || "large"}
          type="primary"
          disabled={list.findIndex(item => item.isSelected) < 0}
          onClick={handleOk}
        >
          {footerActionLabel}
        </Button>
      </div>
    </React.Fragment>
  );
}

import React from "react";
import { Checkbox } from "antd";
import { connect } from "react-redux";

import styles from "./SelectAllCheckboxCellRenderer.module.scss";
import {
  checkOrdersVisibilityAndToggleVisibleOrdersAction,
  toggleVisibleOrdersAction,
} from "../../../state/orders/pauseResumeOrders/pauseResumeOrdersActions";
import { getSelectedOrders } from "../../../state/orders/pauseResumeOrders/pauseResumeOrdersState";

export const SelectAllCheckboxCellRenderer = ({ selectedOrders, checkOrdersVisibilityAndToggleVisibleOrders }) => {
  return (
    <Checkbox
      checked={selectedOrders["selectAll"]}
      onChange={e => {
        checkOrdersVisibilityAndToggleVisibleOrders(true, !selectedOrders["selectAll"]);
      }}
      className={styles.selectAllCheckbox}
    />
  );
};

export default connect(
  (state: any, props: any) => ({
    selectedOrders: getSelectedOrders(state),
  }),
  {
    toggleVisibleOrders: toggleVisibleOrdersAction,
    checkOrdersVisibilityAndToggleVisibleOrders: checkOrdersVisibilityAndToggleVisibleOrdersAction,
  }
)(SelectAllCheckboxCellRenderer);

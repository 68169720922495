import React from "react";
import { Button, Icon, Radio, Select } from "antd";
import Big from "big.js";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  addCommas,
  displayInstrumentLabel,
  formatSizeBasedOnSettings,
  ndfInstrumentStripper,
  positiveNumberEnforcer,
  priceFormatValidator,
  removeCommasAndAddMultiplierShortcuts,
  strippedCurrencyLabel,
} from "../../../lib/formatUtil";
import { calculateSlippageToPip, decimalToPriceObjectConverterBySymbol, isNDFCurrency } from "../../../lib/util";
import { setAuctionCard } from "../../../state/currency/currencyActions";
import {
  cancelOrderAction,
  orderByCorrelationSelector,
  submitNewOrder,
  submitReplaceOrderClassic,
} from "../../../state/orders/ordersState";
import {
  ClosedOrderStatus,
  OrderLifespan,
  OrderModel,
  OrderSide,
  OrderStrategy,
  OrderType,
  ProductType,
  ReplaceOrderModelClassic,
} from "../../../state/orders/types";
import {
  getCurrencySettingsBySymbol,
  getDefaultOrderQuantitySettings,
  getSettings,
} from "../../../state/settings/settingsState";
import Order from "../../orders/Order";
import styles from "./AuctionOrderFormClassic.module.scss";

import clsx from "clsx";
import { ReactComponent as CloseIcon } from "../../../assets/images/CloseIcon-16.svg";
import { ReactComponent as PriceRefresh } from "../../../assets/images/PriceRefresh-24.svg";
import { getPriceStringWithPrecision, useWindowEnter } from "../../../lib/util";
import { currencyPairByIdSelector } from "../../../state/currency/currencyState";
import { pipForSymbol } from "../../../state/currency/symbols/symbolsState";
import { setAuctionCardStyle } from "../../../state/settings/settingsActions";
import { orderTypeOptions } from "../../currency/AuctionOrderForm/selectOptions";
import { FinalPrice } from "../card.types";
import { OrderFieldsEnum } from "./fields";
import { orderLifespanOptions, orderSideOptions } from "./selectOptions";
import LimitMarketPrice from "./common/LimitMarketPrice";
import StopPrice from "./common/StopPrice";
import PriceOco from "./common/PriceOco";
import Side from "./common/Side";
import DateDropdown from "./common/DateDropdown/DateDropdown";

type AuctionCardClassicState = {
  instrumentId: string;
  lifespan: string;
  price: string;
  slippage: string;
  type: string;
  side: string;
  quantity: string;
  strategy: string;
  displaySize: string;
  currency: string;
  product: string;
  settleDate: string | null;
  fixingDate: string | null;
};

type AuctionCardProps = {
  cancelOrderAction: (orderId: string) => void;
  currencyPair: any;
  currencySelected: string;
  currencySettingsBySymbol: any;
  editing: boolean;
  instrumentId: string;
  instrumentPipValue: number;
  marketDataDown: boolean;
  order: any | undefined;
  price: number | undefined;
  type: string;
  quantity: number;
  selectedCurrency: any;
  selectedDefaultQuantity: string;
  setAuctionCard: (value: object | null) => void;
  setAuctionCardStyle: (style: string) => void;
  settings: any;
  side: OrderSide;
  sideLeg2: OrderSide;
  slippage: number;
  submitNewOrder: (orders: OrderModel, localOrderData: any) => void;
  submitReplaceOrderClassic: (order: ReplaceOrderModelClassic) => void;
  setActiveCardSide: any;
};

function AuctionOrderFormClassic({
  cancelOrderAction,
  currencyPair,
  currencySelected,
  currencySettingsBySymbol,
  editing,
  instrumentId,
  instrumentPipValue,
  marketDataDown,
  order,
  price,
  type,
  quantity,
  selectedDefaultQuantity,
  setAuctionCard,
  settings,
  side,
  sideLeg2,
  slippage,
  submitNewOrder,
  submitReplaceOrderClassic,
  setActiveCardSide,
}: AuctionCardProps) {
  const { marketSettings, orderRibbonSettings, ordersSettings } = settings;
  const orderStrategyOptions = [
    {
      label: "Common",
      value: OrderStrategy.Common,
    },
    // {
    //   label: "Iceberg",
    //   value: OrderStrategy.Iceberg,
    // },
  ];

  const [isSubmitExecuting, setIsSubmitExecuting] = useState<any>({
    [OrderType.Market]: false,
    [OrderType.Stop]: false,
    [OrderType.Limit]: false,
    [OrderType.StopLimit]: false,
    [OrderType.Oco]: false,
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<any>({
    [OrderType.Market]: false,
    [OrderType.Stop]: false,
    [OrderType.Limit]: false,
    [OrderType.StopLimit]: false,
    [OrderType.Oco]: false,
  });
  const [isGenericFieldDisabled, setIsGenericFieldDisabled] = useState<any>({
    [OrderType.Market]: false,
    [OrderType.Stop]: false,
    [OrderType.Limit]: false,
    [OrderType.StopLimit]: false,
    [OrderType.Oco]: false,
  });
  const [isPriceFieldDisabled, setIsPriceFieldDisabled] = useState<any>({
    [OrderType.Market]: false,
    [OrderType.Stop]: false,
    [OrderType.Limit]: false,
    [OrderType.StopLimit]: false,
    [OrderType.Oco]: false,
  });
  const [isQuantityFieldDisabled, setIsQuantityFieldDisabled] = useState<any>({
    [OrderType.Market]: false,
    [OrderType.Stop]: false,
    [OrderType.Limit]: false,
    [OrderType.StopLimit]: false,
    [OrderType.Oco]: false,
  });

  const [orderType, setOrderType] = useState(order ? order?.type : ordersSettings?.defaultPassiveOrderType);

  const initialAuctionOrderState = {
    instrumentId: instrumentId || order.instrumentId || "",
    lifespan: ordersSettings.defaultPassiveOrderLifespan || OrderLifespan.Day,
    price: price ? price.toString() : order?.price ? order.price.toString() : "",
    marketPrice: price ? price.toString() : order?.price ? order.price.toString() : "",
    stopLossPrice: order?.stopLossPrice
      ? order.stopLossPrice.toString()
      : price
      ? price.toString()
      : order?.price
      ? order.price.toString()
      : "",
    slippage: "0",
    type,
    side: side || order?.side || "",
    quantity: order?.quantity
      ? order.quantity
      : selectedDefaultQuantity
      ? parseFloat(selectedDefaultQuantity) * 1000000
      : quantity?.toString() || "",
    strategy: order && order.displaySize ? OrderStrategy.Iceberg : OrderStrategy.Common,
    displaySize: quantity?.toString() || order?.displaySize?.toString() || "",
    currency: currencySelected ? currencySelected : order ? order.currency : "",
    product:
      currencyPair && currencyPair.symbol && isNDFCurrency(currencyPair.symbol) ? ProductType.Ndf : ProductType.Spot,
    settleDate: currencyPair?.settlementDate || null,
    fixingDate: currencyPair?.fixingDate || null,
  };

  // HEREZ
  // const { price, ...rest} = initialAuctionOrderState;

  const initialAuctionOrderStateOco = {
    ...initialAuctionOrderState,
    priceLeg2: order?.priceLeg2
      ? order.priceLeg2.toString()
      : price
      ? price.toString()
      : order?.price
      ? order.price.toString()
      : "",
    stopLossPriceLeg2: order?.stopLossPriceLeg2
      ? order.stopLossPriceLeg2.toString()
      : price
      ? price.toString()
      : order?.price
      ? order.price.toString()
      : "",
    sideLeg2: order?.sideLeg2 || side || "",
    // valueDate: "",
    // valueDateLeg2: "",
    // ordersSettings?.defaultPassiveOrderType
    orderTypeLeg1: order ? order?.orderTypeLeg1 : OrderType.Limit,
    orderTypeLeg2: order ? order?.orderTypeLeg2 : OrderType.Limit,
  };

  // HERE: instead of "any" it should be "object of AuctionCardClassicState"
  const [auctionOrderState, setAuctionOrderState] = useState<any>({
    [OrderType.Market]: initialAuctionOrderState,
    [OrderType.Stop]: initialAuctionOrderState,
    [OrderType.Limit]: initialAuctionOrderState,
    [OrderType.StopLimit]: initialAuctionOrderState,
    [OrderType.Oco]: initialAuctionOrderStateOco,
  });

  useEffect(() => {
    let disabled = false;

    if (orderType !== OrderType.Oco) {
      disabled =
        ([OrderType.Limit, OrderType.StopLimit].includes(orderType) && !auctionOrderState[orderType]?.price) ||
        ([OrderType.Limit, OrderType.StopLimit].includes(orderType) && auctionOrderState[orderType]?.price === "0") ||
        ([OrderType.Limit, OrderType.StopLimit].includes(orderType) &&
          auctionOrderState[orderType]?.price.toString().endsWith(".")) ||
        ([OrderType.Stop, OrderType.StopLimit].includes(orderType) && !auctionOrderState[orderType]?.stopLossPrice) ||
        ([OrderType.Stop, OrderType.StopLimit].includes(orderType) &&
          auctionOrderState[orderType]?.stopLossPrice === "0") ||
        ([OrderType.Stop, OrderType.StopLimit].includes(orderType) &&
          auctionOrderState[orderType]?.stopLossPrice.toString().endsWith(".")) ||
        auctionOrderState[orderType]?.quantity === null ||
        auctionOrderState[orderType]?.quantity === "" ||
        auctionOrderState[orderType]?.quantity === "0" ||
        marketDataDown ||
        (order && !editing) ||
        (order && Object.values(ClosedOrderStatus).includes(order.status));
    } else {
      disabled =
        ([OrderType.Limit, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg1) &&
          !auctionOrderState[orderType]?.price) ||
        ([OrderType.Limit, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg1) &&
          auctionOrderState[orderType]?.price === "0") ||
        ([OrderType.Limit, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg1) &&
          auctionOrderState[orderType]?.price.toString().endsWith(".")) ||
        ([OrderType.Limit, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg2) &&
          !auctionOrderState[orderType]?.priceLeg2) ||
        ([OrderType.Limit, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg2) &&
          auctionOrderState[orderType]?.priceLeg2 === "0") ||
        ([OrderType.Limit, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg2) &&
          auctionOrderState[orderType]?.priceLeg2.toString().endsWith(".")) ||
        ([OrderType.Stop, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg1) &&
          !auctionOrderState[orderType]?.stopLossPrice) ||
        ([OrderType.Stop, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg1) &&
          auctionOrderState[orderType]?.stopLossPrice === "0") ||
        ([OrderType.Stop, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg1) &&
          auctionOrderState[orderType]?.stopLossPrice.toString().endsWith(".")) ||
        ([OrderType.Stop, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg2) &&
          !auctionOrderState[orderType]?.stopLossPriceLeg2) ||
        ([OrderType.Stop, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg2) &&
          auctionOrderState[orderType]?.stopLossPriceLeg2 === "0") ||
        ([OrderType.Stop, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg2) &&
          auctionOrderState[orderType]?.stopLossPriceLeg2.toString().endsWith("."));
    }

    setIsSubmitDisabled(prevState => ({
      ...prevState,
      [orderType]: disabled,
    }));

    /*
      Field disabled scenario requirements:

      1. first open - new order - order does not exist > everything enabled
      2. view order details - order exists - editing is undefined/false > everything disabled
      3. edit order - editing = true / order exists > all fields disabled except price/quantity
      4. edit order - state becomes closed > price/quantity get disabled
    */

    if (order) {
      // setIsGenericFieldDisabled(true);
      setIsGenericFieldDisabled(prevState => ({
        ...prevState,
        [orderType]: true,
      }));
      if (editing && Object.values(ClosedOrderStatus).includes(order.status)) {
        setIsPriceFieldDisabled(prevState => ({
          ...prevState,
          [orderType]: true,
        }));

        setIsQuantityFieldDisabled(prevState => ({
          ...prevState,
          [orderType]: true,
        }));
      } else if (!editing) {
        // if not editing - scenario 2
        setIsPriceFieldDisabled(prevState => ({
          ...prevState,
          [orderType]: true,
        }));

        setIsQuantityFieldDisabled(prevState => ({
          ...prevState,
          [orderType]: true,
        }));
      }
    }
  }, [
    order,
    auctionOrderState[orderType]?.price,
    auctionOrderState[orderType]?.stopLossPrice,
    auctionOrderState[OrderType.Oco]?.priceLeg2,
    auctionOrderState[OrderType.Oco]?.stopLossPriceLeg2,
    auctionOrderState[orderType]?.orderTypeLeg1,
    auctionOrderState[orderType]?.orderTypeLeg2,
    auctionOrderState[orderType]?.quantity,
    marketDataDown,
    editing,
    orderType,
  ]);

  useEffect(() => {
    // console.log("USE EFFECT 2");

    if (orderType === OrderType.Market) {
      setIsPriceFieldDisabled(prevState => ({
        ...prevState,
        [orderType]: true,
      }));
    }
  }, [orderType]);

  const useCalculatePriceInitRibbonEffect = (dependencies, orderTypeVal) => {
    useEffect(() => {
      // console.log("USE EFFECT 3");

      const changes = {};

      // HERE - Price and slippage can be sent separately or calculated depending on the user settings
      if (price) {
        if (!settings.ordersSettings.priceSlippageSeparated) {
          let submitPrice: number;
          if (marketSettings.showSlippage && slippage > 0) {
            const safePrice = Big(price);
            const safePipMultipliedBySlippage = Big(instrumentPipValue)?.times(slippage);
            submitPrice = Number(
              (side === OrderSide.Buy
                ? safePrice.add(safePipMultipliedBySlippage)
                : safePrice.minus(safePipMultipliedBySlippage)
              ).toString()
            );
          } else {
            submitPrice = price;
          }
          changes[OrderFieldsEnum.price] = submitPrice.toString();
        } else {
          changes[OrderFieldsEnum.price] = price.toString();
          changes[OrderFieldsEnum.slippage] = calculateSlippageToPip(slippage, instrumentPipValue).toString();
        }
      }

      // HEREZ - Add slippage to priceLeg2
      // if (priceLeg2) {
      //   if (!settings.ordersSettings.priceSlippageSeparated) {
      //     let submitPrice: number;
      //     if (marketSettings.showSlippage && slippage > 0) {
      //       const safePrice = Big(priceLeg2);
      //       const safePipMultipliedBySlippage = Big(instrumentPipValue)?.times(slippage);
      //       submitPrice = Number(
      //         (side === OrderSide.Buy
      //           ? safePrice.add(safePipMultipliedBySlippage)
      //           : safePrice.minus(safePipMultipliedBySlippage)
      //         ).toString()
      //       );
      //     } else {
      //       submitPrice = priceLeg2;
      //     }
      //     changes[OrderFieldsEnum.priceLeg2] = submitPrice.toString();
      //   } else {
      //     changes[OrderFieldsEnum.priceLeg2] = priceLeg2.toString();
      //     changes[OrderFieldsEnum.slippage] = calculateSlippageToPip(slippage, instrumentPipValue).toString();
      //   }
      // }

      if (!order && orderRibbonSettings.showOrderRibbon && orderRibbonSettings.useIceberg?.length > 0) {
        changes[OrderFieldsEnum.displaySize] = orderRibbonSettings.icebergQuantity;
        changes[OrderFieldsEnum.strategy] = OrderStrategy.Iceberg;
      }

      if (Object.keys(changes).length) {
        setAuctionOrderState(prevState => ({
          ...prevState,
          [orderTypeVal]: { ...prevState[orderTypeVal], ...changes },
        }));
      }
    }, dependencies);
  };
  useCalculatePriceInitRibbonEffect(
    [order, orderRibbonSettings.icebergQuantity, orderRibbonSettings.showOrderRibbon, orderRibbonSettings.useIceberg],
    OrderType.Market
  );
  useCalculatePriceInitRibbonEffect(
    [order, orderRibbonSettings.icebergQuantity, orderRibbonSettings.showOrderRibbon, orderRibbonSettings.useIceberg],
    OrderType.Stop
  );
  useCalculatePriceInitRibbonEffect(
    [order, orderRibbonSettings.icebergQuantity, orderRibbonSettings.showOrderRibbon, orderRibbonSettings.useIceberg],
    OrderType.Limit
  );
  useCalculatePriceInitRibbonEffect(
    [order, orderRibbonSettings.icebergQuantity, orderRibbonSettings.showOrderRibbon, orderRibbonSettings.useIceberg],
    OrderType.StopLimit
  );
  useCalculatePriceInitRibbonEffect(
    [order, orderRibbonSettings.icebergQuantity, orderRibbonSettings.showOrderRibbon, orderRibbonSettings.useIceberg],
    OrderType.Oco
  );

  const useSetLifespanEffect = (dependencies, orderTypeVal) => {
    useEffect(() => {
      // console.log("USE EFFECT 4");

      // HERE: IMPORTANT "toLowerCase" is temp, vertex has to send "day" instead of "DAY"
      const lifespan = order
        ? order.lifespan?.toLowerCase()
        : orderRibbonSettings?.selectedDefaultOrderLifespan?.length > 0
        ? orderRibbonSettings?.selectedDefaultOrderLifespan?.[0]
        : ordersSettings.defaultPassiveOrderLifespan || auctionOrderState[orderTypeVal]?.lifespan;

      setAuctionOrderState(prevState => ({
        ...prevState,
        [orderTypeVal]: { ...prevState[orderTypeVal], lifespan: lifespan },
      }));
    }, dependencies);
  };

  useSetLifespanEffect(
    [orderRibbonSettings, ordersSettings.defaultPassiveOrderType, ordersSettings.defaultPassiveOrderLifespan, order],
    OrderType.Market
  );
  useSetLifespanEffect(
    [orderRibbonSettings, ordersSettings.defaultPassiveOrderType, ordersSettings.defaultPassiveOrderLifespan, order],
    OrderType.Stop
  );
  useSetLifespanEffect(
    [orderRibbonSettings, ordersSettings.defaultPassiveOrderType, ordersSettings.defaultPassiveOrderLifespan, order],
    OrderType.Limit
  );
  useSetLifespanEffect(
    [orderRibbonSettings, ordersSettings.defaultPassiveOrderType, ordersSettings.defaultPassiveOrderLifespan, order],
    OrderType.StopLimit
  );
  useSetLifespanEffect(
    [orderRibbonSettings, ordersSettings.defaultPassiveOrderType, ordersSettings.defaultPassiveOrderLifespan, order],
    OrderType.Oco
  );

  useEffect(() => {}, [
    orderRibbonSettings,
    ordersSettings.defaultPassiveOrderType,
    ordersSettings.defaultPassiveOrderLifespan,
    order,
  ]);

  const closeModal = () => {
    setAuctionCard(null);
  };

  const enterKeyNotifyObject = useWindowEnter();

  useEffect(() => {
    // console.log("USE EFFECT 5");

    if (enterKeyNotifyObject.notify) {
      if (order && editing) {
        handleReplaceOrder();
      } else {
        handleSubmitOrder();
      }
      enterKeyNotifyObject.setNotify(false);
    }
  }, [enterKeyNotifyObject.notify]);

  const handleSubmitOrder = () => {
    const optionalData: any = {};
    if (auctionOrderState[orderType]?.strategy === OrderStrategy.Iceberg) {
      optionalData.displaySize = auctionOrderState[orderType]?.displaySize;
    }

    if (editing && order && !Object.values(ClosedOrderStatus).includes(order.status)) {
      // don't send a cancel order if the order already completed
      cancelOrderAction(order.id);
    }

    //HERE: We send the slippage and the price separately or the slippage included in the price depending on the user's settings
    let priceSlippage: FinalPrice = {
      price: orderType === OrderType.Market ? 0 : auctionOrderState[orderType]?.price,
    };
    if (settings.ordersSettings.priceSlippageSeparated) {
      priceSlippage = { ...priceSlippage, slippage: auctionOrderState[orderType]?.slippage };
    }

    // // HERE: If we want to send the correspondent prices for orderType instead of the 2 of them
    // let priceToSend = {};
    // if (orderType === OrderType.Stop) {
    //   priceToSend = { stopLossPrice: auctionOrderState[orderType]?.stopLossPrice };
    // } else if (orderType === OrderType.StopLimit) {
    //   priceToSend = { ...priceSlippage, stopLossPrice: auctionOrderState[orderType]?.stopLossPrice };
    // } else {
    //   priceToSend = { ...priceSlippage };
    // }

    let ocoData: any = {};

    if (orderType === OrderType.Oco) {
      ocoData = {
        priceLeg2: auctionOrderState[orderType]?.priceLeg2,
        stopLossPriceLeg2: auctionOrderState[orderType]?.stopLossPriceLeg2,
        sideLeg2: auctionOrderState[orderType]?.sideLeg2,
        // valueDate: auctionOrderState[orderType]?.valueDate,
        // valueDateLeg2: auctionOrderState[orderType]?.valueDateLeg2,
        orderTypeLeg1: auctionOrderState[orderType]?.orderTypeLeg1,
        orderTypeLeg2: auctionOrderState[orderType]?.orderTypeLeg2,
      };
    }

    submitNewOrder(
      {
        ...optionalData,
        ...priceSlippage,
        ...ocoData,
        stopLossPrice: auctionOrderState[orderType]?.stopLossPrice,
        currency: auctionOrderState[orderType]?.currency,
        fixingDate: currencyPair?.fixingDate || null,
        instrumentId: auctionOrderState[orderType]?.instrumentId,
        lifespan: auctionOrderState[orderType]?.lifespan,
        // HERE: slippage: calculateSlippageToPip(slippage, instrumentPipValue),
        // price: auctionOrderState[orderType]?.type === OrderType.Market ? 0 : auctionOrderState[orderType]?.price,
        // slippage: auctionOrderState[orderType]?.slippage,
        product: auctionOrderState[orderType]?.product && auctionOrderState[orderType]?.product.toString(),
        quantity: auctionOrderState[orderType]?.quantity,
        settleDate: currencyPair?.settleDate || null,
        side: auctionOrderState[orderType]?.side,
        strategy: OrderStrategy.Common, // this stays as common, bc iceberg is not strategy
        traderName: settings.activeTrader,
        type: orderType,
      },
      { takerName: settings.activeTaker }
    );
  };

  const handleReplaceOrder = () => {
    // // HERE: If we want to send the correspondent prices for orderType instead of the 2 of them
    // let priceToSend = {};
    // if (orderType === OrderType.Stop) {
    //   priceToSend = { stopLossPrice: auctionOrderState[orderType]?.stopLossPrice };
    // } else if (orderType === OrderType.StopLimit) {
    //   priceToSend = {
    //     price: auctionOrderState[orderType]?.price,
    //     stopLossPrice: auctionOrderState[orderType]?.stopLossPrice,
    //   };
    // } else {
    //   priceToSend = orderType === OrderType.Market ? { price: 0 } : { price: auctionOrderState[orderType]?.price };
    // }

    let ocoData: any = {};

    if (orderType === OrderType.Oco) {
      ocoData = {
        priceLeg2: auctionOrderState[orderType]?.priceLeg2,
        stopLossPriceLeg2: auctionOrderState[orderType]?.stopLossPriceLeg2,
      };
    }

    submitReplaceOrderClassic({
      ...ocoData,
      id: order.id,
      quantity: auctionOrderState[orderType]?.quantity,
      price: orderType === OrderType.Market ? 0 : auctionOrderState[orderType]?.price,
      stopLossPrice: auctionOrderState[orderType]?.stopLossPrice,
      type: orderType,
    });
  };

  const refreshPrice = fieldName => {
    setAuctionOrderState(prevState => ({
      ...prevState,
      [orderType]: {
        ...prevState[orderType],
        [fieldName]:
          auctionOrderState[orderType]?.side === OrderSide.Buy
            ? currencyPair.bestBidPrice.toString()
            : currencyPair.bestAskPrice.toString(),
      },
    }));
  };

  const currencies = auctionOrderState[orderType]?.instrumentId
    ? ndfInstrumentStripper(auctionOrderState[orderType]?.instrumentId).split("/")
    : [];

  const isBuyAction = auctionOrderState[orderType]?.side === orderSideOptions[0]?.value;
  const isBuyActionLeg2 = auctionOrderState[orderType]?.sideLeg2 === orderSideOptions[0]?.value;

  const handleIncrementDecrement = (direction, incrementAmount, fieldName) => {
    const slippageBig = Big(auctionOrderState[orderType]?.[fieldName] || 0);
    const plusValue = Number(slippageBig.add(incrementAmount).toString());
    const minusValue = Number(slippageBig.minus(incrementAmount).toString());
    const priceValue = direction === "increment" ? plusValue : minusValue > 0 ? minusValue : 0;

    // setAuctionOrderState(prevState => ({ ...prevState, price: priceValue.toString() }));

    setAuctionOrderState(prevState => ({
      ...prevState,
      [orderType]: { ...prevState[orderType], [fieldName]: priceValue.toString() },
    }));
  };

  return (
    <div className={clsx(styles.auctionOrderForm, isBuyAction ? styles.buyBorder : styles.sellBorder)}>
      <div className={styles.tabs}>
        {/* HERE TODO: change divs by Buttons and remove animation from Buttons  */}
        <div
          className={clsx(styles.tab, orderType === OrderType.Market && styles.activeTab)}
          onClick={() => setOrderType(OrderType.Market)}
        >
          Market
        </div>
        <div
          className={clsx(styles.tab, orderType === OrderType.Stop && styles.activeTab)}
          onClick={() => setOrderType(OrderType.Stop)}
        >
          Stop
        </div>
        <div
          className={clsx(styles.tab, orderType === OrderType.Limit && styles.activeTab)}
          onClick={() => setOrderType(OrderType.Limit)}
        >
          Limit
        </div>
        <div
          className={clsx(styles.tab, orderType === OrderType.StopLimit && styles.activeTab)}
          onClick={() => setOrderType(OrderType.StopLimit)}
        >
          Stop Limit
        </div>
        {/* <div
          className={clsx(styles.tab, orderType === OrderType.Oco && styles.activeTab)}
          onClick={() => setOrderType(OrderType.Oco)}
        >
          OCO
        </div> */}
      </div>

      <AuctionCardClassicHeader
        bestAskPrice={currencyPair && currencyPair.bestAskPrice ? currencyPair.bestAskPrice : null}
        bestBidPrice={currencyPair && currencyPair.bestBidPrice ? currencyPair.bestBidPrice : null}
        closeModal={closeModal}
        fixingDate={auctionOrderState[orderType]?.fixingDate}
        settleDate={auctionOrderState[orderType]?.settleDate}
        symbol={auctionOrderState[orderType]?.instrumentId}
      />

      {/* HEREZ  */}
      <section className={styles.orderMain}>
        {orderType !== OrderType.Oco && (
          <>
            <LimitMarketPrice
              isPriceFieldDisabledByOrderType={isPriceFieldDisabled[orderType]}
              orderType={orderType}
              order={order}
              auctionOrderStatePriceByOrderType={auctionOrderState[orderType]?.[OrderFieldsEnum.price]}
              setAuctionOrderState={setAuctionOrderState}
              handleIncrementDecrement={handleIncrementDecrement}
              instrumentId={instrumentId}
              instrumentPipValue={instrumentPipValue}
              currencyPair={currencyPair}
              refreshPrice={refreshPrice}
            />

            <StopPrice
              isPriceFieldDisabledByOrderType={isPriceFieldDisabled[orderType]}
              orderType={orderType}
              order={order}
              auctionOrderStatePriceByOrderType={auctionOrderState[orderType]?.[OrderFieldsEnum.stopLossPrice]}
              setAuctionOrderState={setAuctionOrderState}
              handleIncrementDecrement={handleIncrementDecrement}
              instrumentId={instrumentId}
              instrumentPipValue={instrumentPipValue}
              currencyPair={currencyPair}
              refreshPrice={refreshPrice}
            />
          </>
        )}

        <div className={styles.inputBlock}>
          <label>Strategy</label>
          <div className={clsx(styles.inputSection, isGenericFieldDisabled[orderType] && styles.inputDisabled)}>
            <Radio.Group
              data-testid="auction_strategy"
              value={auctionOrderState[orderType]?.strategy}
              disabled={isGenericFieldDisabled[orderType]}
              onChange={event => {
                // setAuctionOrderState(prevState => ({ ...prevState, strategy: event.target.value }));
                setAuctionOrderState(prevState => ({
                  ...prevState,
                  [orderType]: { ...prevState[orderType], strategy: event.target.value },
                }));
              }}
            >
              {orderStrategyOptions.map((option, i) => (
                <Radio key={`${option.value}.${i}`} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>

        <div className={styles.inputBlock}>
          <label>Lifespan</label>
          <div className={clsx(styles.inputSection, isGenericFieldDisabled[orderType] && styles.inputDisabled)}>
            <Radio.Group
              data-testid="auction_lifespan"
              value={auctionOrderState[orderType]?.lifespan}
              disabled={isGenericFieldDisabled[orderType]}
              onChange={event => {
                // setAuctionOrderState(prevState => ({ ...prevState, lifespan: event.target.value }));

                setAuctionOrderState(prevState => ({
                  ...prevState,
                  [orderType]: { ...prevState[orderType], lifespan: event.target.value },
                }));
              }}
            >
              {orderLifespanOptions.map((option, i) => (
                <Radio key={`${option.value}.${i}`} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>

        {orderType !== OrderType.Oco && (
          <Side
            sideName={OrderFieldsEnum.side}
            isGenericFieldDisabledByOrderType={isGenericFieldDisabled[orderType]}
            isBuyAction={isBuyAction}
            auctionOrderStateSideByOrderType={auctionOrderState[orderType]?.[OrderFieldsEnum.side]}
            setAuctionOrderState={setAuctionOrderState}
            orderType={orderType}
            setActiveCardSide={setActiveCardSide}
            order={order}
          />
        )}

        <div className={styles.inputBlock}>
          <label>Size</label>
          <div className={clsx(styles.inputSection, isQuantityFieldDisabled[orderType] && styles.inputDisabled)}>
            <div className="ant-input-number ant-input-number-sm ">
              <input
                data-testid="auction_quantity"
                className="ant-input-number-input"
                disabled={isQuantityFieldDisabled[orderType]}
                type="text"
                value={addCommas(auctionOrderState[orderType]?.quantity)}
                onKeyDown={(e: any) => priceFormatValidator(e, e.target.value)}
                onChange={(e: any) => {
                  const quantity = removeCommasAndAddMultiplierShortcuts(e.target.value);
                  // setAuctionOrderState(prevState => ({ ...prevState, quantity }));

                  setAuctionOrderState(prevState => ({
                    ...prevState,
                    [orderType]: { ...prevState[orderType], quantity },
                  }));
                }}
                onBlur={e => {
                  const quantityVal = formatSizeBasedOnSettings(
                    e.target.value,
                    orderRibbonSettings,
                    currencySettingsBySymbol,
                    marketSettings,
                    instrumentId
                  );

                  setAuctionOrderState(prevState => ({
                    ...prevState,
                    [orderType]: { ...prevState[orderType], quantity: quantityVal.toString() },
                  }));
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.inputBlock}>
          <label>Ccy</label>
          <div className={clsx(styles.inputSection, isGenericFieldDisabled[orderType] && styles.inputDisabled)}>
            <Radio.Group
              data-testid="auction_currency"
              value={auctionOrderState[orderType]?.currency}
              disabled={isGenericFieldDisabled[orderType]}
              onChange={event => {
                // setAuctionOrderState(prevState => ({ ...prevState, currency: event.target.value }));

                setAuctionOrderState(prevState => ({
                  ...prevState,
                  [orderType]: { ...prevState[orderType], currency: event.target.value },
                }));
              }}
            >
              {currencies &&
                currencies.map((option, i) => (
                  <Radio
                    key={`${option}.${i}`}
                    value={option}
                    disabled={auctionOrderState[orderType] && auctionOrderState[orderType]?.product === ProductType.Ndf}
                  >
                    {option}
                  </Radio>
                ))}
            </Radio.Group>
          </div>
        </div>

        {auctionOrderState[orderType]?.strategy === OrderStrategy.Iceberg && (
          <div className={styles.inputBlock}>
            <label>Max show</label>
            <div className={clsx(styles.inputSection, isGenericFieldDisabled[orderType] && styles.inputDisabled)}>
              <div className="ant-input-number ant-input-number-sm ">
                <input
                  className="ant-input-number-input"
                  data-testid="auction_quantity_display"
                  type="text"
                  value={
                    auctionOrderState[orderType]?.displaySize > auctionOrderState[orderType]?.quantity
                      ? addCommas(auctionOrderState[orderType]?.quantity)
                      : addCommas(auctionOrderState[orderType]?.displaySize)
                  }
                  disabled={isGenericFieldDisabled[orderType]}
                  onKeyDown={event => {
                    if (event.key !== "m") {
                      positiveNumberEnforcer(event);
                    } else {
                      event.preventDefault();
                      const value = parseFloat(auctionOrderState[orderType]?.displaySize) * 1000000;
                      // setAuctionOrderState(prevState => ({ ...prevState, displaySize: value.toString() }));

                      setAuctionOrderState(prevState => ({
                        ...prevState,
                        [orderType]: { ...prevState[orderType], displaySize: value.toString() },
                      }));
                    }
                  }}
                  onChange={(e: any) => {
                    const value = Math.max(0, parseInt(e.target.value.replace(/,/g, ""), 10) || 0);
                    // setAuctionOrderState(prevState => ({ ...prevState, displaySize: value.toString() }));

                    setAuctionOrderState(prevState => ({
                      ...prevState,
                      [orderType]: { ...prevState[orderType], displaySize: value.toString() },
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {orderType === OrderType.Oco && (
          <>
            <div>
              <h3 className={styles.title}>Either</h3>

              <div className={styles.containerTwoCol}>
                <div className={clsx(styles.inputBlock, styles.inputBlockTwoCol)}>
                  <label>Order Type</label>
                  <Select
                    size="small"
                    disabled={isGenericFieldDisabled[orderType]}
                    className={isGenericFieldDisabled[orderType] ? styles.selectDisabled : ""}
                    value={auctionOrderState[orderType]?.orderTypeLeg1}
                    onSelect={value => {
                      setAuctionOrderState(prevState => ({
                        ...prevState,
                        [orderType]: { ...prevState[orderType], orderTypeLeg1: value },
                      }));
                    }}
                    suffixIcon={Icon({ type: "caret-down" })}
                  >
                    <Select.Option key={OrderType.Stop} value={OrderType.Stop}>
                      Stop
                    </Select.Option>
                    <Select.Option key={OrderType.Limit} value={OrderType.Limit}>
                      Limit
                    </Select.Option>
                    <Select.Option key={OrderType.StopLimit} value={OrderType.StopLimit}>
                      Stop Limit
                    </Select.Option>
                  </Select>
                </div>

                <Side
                  sideName={OrderFieldsEnum.side}
                  isGenericFieldDisabledByOrderType={isGenericFieldDisabled[orderType]}
                  isBuyAction={isBuyAction}
                  auctionOrderStateSideByOrderType={auctionOrderState[orderType]?.[OrderFieldsEnum.side]}
                  setAuctionOrderState={setAuctionOrderState}
                  orderType={orderType}
                  setActiveCardSide={setActiveCardSide}
                  order={order}
                />

                {[OrderType.Limit, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg1) && (
                  <PriceOco
                    priceName={OrderFieldsEnum.price}
                    priceType={OrderFieldsEnum.price}
                    isPriceFieldDisabledByOrderType={isPriceFieldDisabled[orderType]}
                    order={order}
                    auctionOrderStatePriceByOrderType={auctionOrderState[orderType]?.[OrderFieldsEnum.price]}
                    setAuctionOrderState={setAuctionOrderState}
                    handleIncrementDecrement={handleIncrementDecrement}
                    instrumentId={instrumentId}
                    instrumentPipValue={instrumentPipValue}
                    currencyPair={currencyPair}
                    refreshPrice={refreshPrice}
                  />
                )}

                {[OrderType.Stop, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg1) && (
                  <PriceOco
                    priceName={OrderFieldsEnum.stopLossPrice}
                    priceType={OrderFieldsEnum.stopLossPrice}
                    isPriceFieldDisabledByOrderType={isPriceFieldDisabled[orderType]}
                    order={order}
                    auctionOrderStatePriceByOrderType={auctionOrderState[orderType]?.[OrderFieldsEnum.stopLossPrice]}
                    setAuctionOrderState={setAuctionOrderState}
                    handleIncrementDecrement={handleIncrementDecrement}
                    instrumentId={instrumentId}
                    instrumentPipValue={instrumentPipValue}
                    currencyPair={currencyPair}
                    refreshPrice={refreshPrice}
                  />
                )}

                {/* <div className={styles.inputBlock}>
                <label>Value Date</label>
                <DateDropdown
                  valueDateName={OrderFieldsEnum.valueDate}
                  setAuctionOrderState={setAuctionOrderState}
                  auctionOrderState={auctionOrderState}
                />
              </div> */}
              </div>
            </div>

            <div>
              <h3 className={styles.title}>Or</h3>
              <div className={styles.containerTwoCol}>
                <div className={clsx(styles.inputBlock, styles.inputBlockTwoCol)}>
                  <label>Order Type</label>
                  <Select
                    // data-testid={`auction_${field.dataFieldName}`}
                    size="small"
                    // className={clsx(
                    //   field.dataFieldName === OrderFieldsEnum.side ? (isBuyAction ? styles.buy : styles.sell) : ""
                    // )}
                    // disabled={isFieldDisabled || (editing && field.dataFieldName === OrderFieldsEnum.side)}
                    // dropdownMatchSelectWidth={false}
                    // value={isFieldDisabled && !order ? "" : stateObj[field.dataFieldName]}
                    // onSelect={(value: string) => {
                    //   setStateFunctionMapper[stateName]({
                    //     ...stateObj,
                    //     [field.dataFieldName]: value,
                    //   });
                    // }}
                    disabled={isGenericFieldDisabled[orderType]}
                    className={isGenericFieldDisabled[orderType] ? styles.selectDisabled : ""}
                    value={auctionOrderState[orderType]?.orderTypeLeg2}
                    onSelect={value => {
                      setAuctionOrderState(prevState => ({
                        ...prevState,
                        [orderType]: { ...prevState[orderType], orderTypeLeg2: value },
                      }));
                    }}
                    suffixIcon={Icon({ type: "caret-down" })}
                  >
                    <Select.Option key={OrderType.Stop} value={OrderType.Stop}>
                      Stop
                    </Select.Option>
                    <Select.Option key={OrderType.Limit} value={OrderType.Limit}>
                      Limit
                    </Select.Option>
                    <Select.Option key={OrderType.StopLimit} value={OrderType.StopLimit}>
                      Stop Limit
                    </Select.Option>
                  </Select>
                </div>

                <Side
                  sideName={OrderFieldsEnum.sideLeg2}
                  isGenericFieldDisabledByOrderType={isGenericFieldDisabled[orderType]}
                  isBuyAction={isBuyActionLeg2}
                  auctionOrderStateSideByOrderType={auctionOrderState[orderType]?.[OrderFieldsEnum.sideLeg2]}
                  setAuctionOrderState={setAuctionOrderState}
                  orderType={orderType}
                  setActiveCardSide={setActiveCardSide}
                  order={order}
                />

                {[OrderType.Limit, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg2) && (
                  <PriceOco
                    priceName={OrderFieldsEnum.priceLeg2}
                    priceType={OrderFieldsEnum.price}
                    isPriceFieldDisabledByOrderType={isPriceFieldDisabled[orderType]}
                    order={order}
                    auctionOrderStatePriceByOrderType={auctionOrderState[orderType]?.[OrderFieldsEnum.priceLeg2]}
                    setAuctionOrderState={setAuctionOrderState}
                    handleIncrementDecrement={handleIncrementDecrement}
                    instrumentId={instrumentId}
                    instrumentPipValue={instrumentPipValue}
                    currencyPair={currencyPair}
                    refreshPrice={refreshPrice}
                  />
                )}

                {[OrderType.Stop, OrderType.StopLimit].includes(auctionOrderState[orderType]?.orderTypeLeg2) && (
                  <PriceOco
                    priceName={OrderFieldsEnum.stopLossPriceLeg2}
                    priceType={OrderFieldsEnum.stopLossPrice}
                    isPriceFieldDisabledByOrderType={isPriceFieldDisabled[orderType]}
                    order={order}
                    auctionOrderStatePriceByOrderType={
                      auctionOrderState[orderType]?.[OrderFieldsEnum.stopLossPriceLeg2]
                    }
                    setAuctionOrderState={setAuctionOrderState}
                    handleIncrementDecrement={handleIncrementDecrement}
                    instrumentId={instrumentId}
                    instrumentPipValue={instrumentPipValue}
                    currencyPair={currencyPair}
                    refreshPrice={refreshPrice}
                  />
                )}
              </div>

              {/* HEREZ  */}
              {/* <div className={styles.inputBlock}>
                <label>Value Date</label>
                <DateDropdown
                  valueDateName={OrderFieldsEnum.valueDateLeg2}
                  setAuctionOrderState={setAuctionOrderState}
                  auctionOrderState={auctionOrderState}
                />
              </div> */}
            </div>
          </>
        )}

        {(!order || editing) && auctionOrderState[orderType] && (
          <div className={styles.auctionActions}>
            <Button
              data-testid="auction_cancel_button"
              size="small"
              className={styles.cancelButton}
              type="ghost"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>

            <Button
              data-testid="auction_submit_button"
              type="primary"
              className={
                isSubmitDisabled[orderType] || isSubmitExecuting[orderType]
                  ? ""
                  : clsx(styles.submitButton, isBuyAction ? styles.buySubmitButton : styles.sellSubmitButton)
              }
              size="small"
              disabled={isSubmitDisabled[orderType] || isSubmitExecuting[orderType]}
              onClick={() => {
                setIsSubmitExecuting(true);
                if (order && editing) {
                  handleReplaceOrder();
                } else {
                  handleSubmitOrder();
                }
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </section>

      {order && (
        <div className={clsx(styles.orderStatus, order.type === OrderType.Oco ? styles.orderStatusHigh : "")}>
          <Order orderData={order} isModalOrder={true} />
        </div>
      )}
    </div>
  );
}

export default connect(
  (state, ownProps: any) => ({
    currencyPair: currencyPairByIdSelector(state, ownProps.instrumentId),
    instrumentPipValue: pipForSymbol(state, ownProps.instrumentId),
    order: orderByCorrelationSelector(state, ownProps.correlation), // can't use id because for pending orders we don't have the id that vertex generates
    selectedDefaultQuantity: getDefaultOrderQuantitySettings(state),
    settings: getSettings(state),
    currencySettingsBySymbol: getCurrencySettingsBySymbol(state, ownProps.instrumentId) || {},
  }),
  {
    cancelOrderAction,
    setAuctionCard,
    setAuctionCardStyle,
    submitNewOrder,
    submitReplaceOrderClassic,
  }
)(AuctionOrderFormClassic);

const AuctionCardClassicHeader = ({ bestAskPrice, bestBidPrice, closeModal, fixingDate, settleDate, symbol }) => {
  const bestAskPriceObj = decimalToPriceObjectConverterBySymbol(bestAskPrice, symbol);
  const bestBidPriceObj = decimalToPriceObjectConverterBySymbol(bestBidPrice, symbol);

  return (
    <>
      <div className={styles.closeIcon} onClick={() => closeModal()} data-testid="auction_close_button">
        <CloseIcon />
      </div>

      <div className={styles.cardHeader}>
        <div className={styles.titleRow}>
          <div className={styles.currency} data-testid="auction_symbol">
            <span className={styles.currencyCode}>{strippedCurrencyLabel(symbol)}</span>
            {displayInstrumentLabel(symbol)}
          </div>
          <div className={styles.date}>
            <span data-testid="auction_fixing_date">
              {fixingDate &&
                `${moment(fixingDate)
                  .utc()
                  .format("DDMMM")
                  .toUpperCase()}/`}
            </span>
            <span data-testid="auction_settlement_date">
              {settleDate &&
                moment(settleDate)
                  .utc()
                  .format("DDMMM")
                  .toUpperCase()}
            </span>
          </div>
        </div>
        <div className={styles.priceRow}>
          <div data-testid="auction_buy_price">
            B{" "}
            {bestAskPriceObj ? (
              <>
                <span>{bestAskPriceObj.basePrice}</span>
                {bestAskPriceObj.varBig && (
                  <>
                    <span className={styles.bigNum}>{bestAskPriceObj.varBig}</span>
                    <span>{bestAskPriceObj.varSm}</span>
                  </>
                )}
              </>
            ) : (
              <>&mdash;</>
            )}
          </div>
          <div data-testid="auction_sell_price">
            S{" "}
            {bestBidPriceObj ? (
              <>
                <span>{bestBidPriceObj.basePrice}</span>
                {bestBidPriceObj.varBig && (
                  <>
                    <span className={styles.bigNum}>{bestBidPriceObj.varBig}</span>
                    <span>{bestBidPriceObj.varSm}</span>
                  </>
                )}
              </>
            ) : (
              <>&mdash;</>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

import { OrderLifespan, OrderSide, OrderStrategy, OrderType, PriceType } from "../../../state/orders/types";

export const orderLifespanOptions = [
  {
    label: "Day",
    value: OrderLifespan.Day,
  },
  {
    label: "IOC",
    value: OrderLifespan.IOC,
  },
  {
    label: "FOK",
    value: OrderLifespan.FOK,
  },
];

export const orderSideOptions = [
  {
    label: "Buy",
    value: OrderSide.Buy,
  },
  {
    label: "Sell",
    value: OrderSide.Sell,
  },
];

export const orderTypeOptions = [
  {
    label: "Market",
    value: OrderType.Market,
  },{
    label: "Stop",
    value: OrderType.Stop,
  },
  {
    label: "Limit",
    value: OrderType.Limit,
  },
  {
    label: "Stop Limit",
    value: OrderType.StopLimit,
  },
  {
    label: "OCO",
    value: OrderType.Oco,
  },
];

export const orderTypeOptionsToDirectlyTrade = [
  {
    label: "Market",
    value: OrderType.Market,
  },
  {
    label: "Limit",
    value: OrderType.Limit,
  }
];


export const orderStrategyOptions = [
  {
    label: "Select...",
    value: null,
  },
  {
    label: "BFIX",
    value: OrderStrategy.Bfix,
  },
  {
    label: "WMFIX",
    value: OrderStrategy.Fixing,
  },
  {
    label: "ICEBERG",
    value: OrderStrategy.Iceberg,
  },
  {
    label: "PEG",
    value: OrderStrategy.Peg,
  },
  {
    label: "POV",
    value: OrderStrategy.Pov,
  },
  {
    label: "iSEEK",
    value: OrderStrategy.ISeek,
  },
  {
    label: "Smart Sweep",
    value: OrderStrategy.Sor,
  },
  {
    label: "TWAP",
    value: OrderStrategy.Twap,
  },
  {
    label: "VWAP",
    value: OrderStrategy.Vwap,
  },
];

export const priceTypeOptions = [
  {
    label: "Limit",
    value: PriceType.Limit,
  },
  {
    label: "Average",
    value: PriceType.Average,
  },
];

export const urgencyOptions = [
  {
    label: "S. Passive",
    value: 1,
  },
  {
    label: "Passive",
    value: 2,
  },
  {
    label: "Normal",
    value: 3,
  },
  {
    label: "Aggressive",
    value: 4,
  },
  {
    label: "S. Aggressive",
    value: 5,
  },
];

export const basicUrgencyOptions = [
  {
    label: "Passive",
    value: 1,
  },
  {
    label: "Normal",
    value: 2,
  },
  {
    label: "Aggressive",
    value: 3,
  },
];

export const pegReferencePriceTypeOptions = [
  {
    label: "",
    value: null,
  },
  {
    label: "Near",
    value: "R",
  },
  {
    label: "Mid",
    value: "M",
  },
  {
    label: "Far",
    value: "P",
  },
];

export const pegFloorTypeOptions = [
  {
    label: "",
    value: null,
  },
  {
    label: "No Floor",
    value: 0,
  },
  {
    label: "Fixed",
    value: 1,
  },
  {
    label: "Floating",
    value: 2,
  },
];

export const startTimeOptions = [
  {
    label: "12:00 AM",
    value: "00:00:00",
  },
  {
    label: "12:30 AM",
    value: "00:30:00",
  },
  {
    label: "1:00 AM",
    value: "01:00:00",
  },
  {
    label: "1:30 AM",
    value: "01:30:00",
  },
  {
    label: "2:00 AM",
    value: "02:00:00",
  },
  {
    label: "2:30 AM",
    value: "02:30:00",
  },
  {
    label: "3:00 AM",
    value: "03:00:00",
  },
  {
    label: "3:30 AM",
    value: "03:30:00",
  },
  {
    label: "4:00 AM",
    value: "04:00:00",
  },
  {
    label: "4:30 AM",
    value: "04:30:00",
  },
  {
    label: "5:00 AM",
    value: "05:00:00",
  },
  {
    label: "5:30 AM",
    value: "05:30:00",
  },
  {
    label: "6:00 AM",
    value: "06:00:00",
  },
  {
    label: "6:30 AM",
    value: "06:30:00",
  },
  {
    label: "7:00 AM",
    value: "07:00:00",
  },
  {
    label: "7:30 AM",
    value: "07:30:00",
  },
  {
    label: "8:00 AM",
    value: "08:00:00",
  },
  {
    label: "8:30 AM",
    value: "08:30:00",
  },
  {
    label: "9:00 AM",
    value: "09:00:00",
  },
  {
    label: "9:30 AM",
    value: "09:30:00",
  },
  {
    label: "10:00 AM",
    value: "10:00:00",
  },
  {
    label: "10:30 AM",
    value: "10:30:00",
  },
  {
    label: "11:00 AM",
    value: "11:00:00",
  },
  {
    label: "11:30 AM",
    value: "11:30:00",
  },
  {
    label: "12:00 PM",
    value: "12:00:00",
  },
  {
    label: "12:30 PM",
    value: "12:30:00",
  },
  {
    label: "1:00 PM",
    value: "13:00:00",
  },
  {
    label: "1:30 PM",
    value: "13:30:00",
  },
  {
    label: "2:00 PM",
    value: "14:00:00",
  },
  {
    label: "2:30 PM",
    value: "14:30:00",
  },
  {
    label: "3:00 PM",
    value: "15:00:00",
  },
  {
    label: "3:30 PM",
    value: "15:30:00",
  },
  {
    label: "4:00 PM",
    value: "16:00:00",
  },
  {
    label: "4:30 PM",
    value: "16:30:00",
  },
  {
    label: "6:00 PM",
    value: "18:00:00",
  },
  {
    label: "6:30 PM",
    value: "18:30:00",
  },
  {
    label: "7:00 PM",
    value: "19:00:00",
  },
  {
    label: "7:30 PM",
    value: "19:30:00",
  },
  {
    label: "8:00 PM",
    value: "20:00:00",
  },
  {
    label: "8:30 PM",
    value: "20:30:00",
  },
  {
    label: "9:00 PM",
    value: "21:00:00",
  },
  {
    label: "9:30 PM",
    value: "21:30:00",
  },
  {
    label: "10:00 PM",
    value: "22:00:00",
  },
  {
    label: "10:30 PM",
    value: "22:30:00",
  },
  {
    label: "11:00 PM",
    value: "23:00:00",
  },
  {
    label: "11:30 PM",
    value: "23:30:00",
  },
];

export const startTimeOptionsBfix = [
  {
    label: "12:00 AM",
    value: "00:00:00",
  },
  {
    label: "12:30 AM",
    value: "00:30:00",
  },
  {
    label: "1:00 AM",
    value: "01:00:00",
  },
  {
    label: "1:30 AM",
    value: "01:30:00",
  },
  {
    label: "2:00 AM",
    value: "02:00:00",
  },
  {
    label: "2:30 AM",
    value: "02:30:00",
  },
  {
    label: "3:00 AM",
    value: "03:00:00",
  },
  {
    label: "3:30 AM",
    value: "03:30:00",
  },
  {
    label: "4:00 AM",
    value: "04:00:00",
  },
  {
    label: "4:30 AM",
    value: "04:30:00",
  },
  {
    label: "5:00 AM",
    value: "05:00:00",
  },
  {
    label: "5:30 AM",
    value: "05:30:00",
  },
  {
    label: "6:00 AM",
    value: "06:00:00",
  },
  {
    label: "6:30 AM",
    value: "06:30:00",
  },
  {
    label: "7:00 AM",
    value: "07:00:00",
  },
  {
    label: "7:30 AM",
    value: "07:30:00",
  },
  {
    label: "8:00 AM",
    value: "08:00:00",
  },
  {
    label: "8:30 AM",
    value: "08:30:00",
  },
  {
    label: "9:00 AM",
    value: "09:00:00",
  },
  {
    label: "9:30 AM",
    value: "09:30:00",
  },
  {
    label: "10:00 AM",
    value: "10:00:00",
  },
  {
    label: "10:30 AM",
    value: "10:30:00",
  },
  {
    label: "11:00 AM",
    value: "11:00:00",
  },
  {
    label: "11:30 AM",
    value: "11:30:00",
  },
  {
    label: "12:00 PM",
    value: "12:00:00",
  },
  {
    label: "12:30 PM",
    value: "12:30:00",
  },
  {
    label: "1:00 PM",
    value: "13:00:00",
  },
  {
    label: "1:30 PM",
    value: "13:30:00",
  },
  {
    label: "2:00 PM",
    value: "14:00:00",
  },
  {
    label: "2:30 PM",
    value: "14:30:00",
  },
  {
    label: "3:00 PM",
    value: "15:00:00",
  },
  {
    label: "3:30 PM",
    value: "15:30:00",
  },
  {
    label: "4:00 PM",
    value: "16:00:00",
  },
  {
    label: "4:30 PM",
    value: "16:30:00",
  },
  {
    label: "5:00 PM",
    value: "17:00:00",
  },
  {
    label: "6:00 PM",
    value: "18:00:00",
  },
  {
    label: "6:30 PM",
    value: "18:30:00",
  },
  {
    label: "7:00 PM",
    value: "19:00:00",
  },
  {
    label: "7:30 PM",
    value: "19:30:00",
  },
  {
    label: "8:00 PM",
    value: "20:00:00",
  },
  {
    label: "8:30 PM",
    value: "20:30:00",
  },
  {
    label: "9:00 PM",
    value: "21:00:00",
  },
  {
    label: "9:30 PM",
    value: "21:30:00",
  },
  {
    label: "10:00 PM",
    value: "22:00:00",
  },
  {
    label: "10:30 PM",
    value: "22:30:00",
  },
  {
    label: "11:00 PM",
    value: "23:00:00",
  },
  {
    label: "11:30 PM",
    value: "23:30:00",
  },
];

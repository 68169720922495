import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import styles from "./OrderRibbon.module.scss";

import {
  CUSTOM_SIZE_UNIT_OPTIONS,
  getOrderRibbonSettings,
  getAvailableTakers,
  getActiveTaker,
  getActiveTrader,
  getTraders,
  getOrdersSettings,
  getCantorAlgoEnabled,
} from "../../state/settings/settingsState";
import { orderTypeOptionsToDirectlyTrade } from "../currency/AuctionOrderForm/selectOptions";
// components
import { RadioGroup, RadioButton } from "../../shared/radio";
import Dropdown from "../../shared/Dropdown";
import { OrderLifespan } from "../../state/orders/types";
import { Input, Select, Icon, Menu, Radio } from "antd";
import { positiveNumberEnforcer } from "../../lib/formatUtil";
import { useOnClickOutside } from "../../lib/util";
import { appLocale } from "../../lib/constants";

import {
  orderQuantitySelected,
  orderLifespanSelected,
  orderTypeSelected,
  resetAllCardSizes,
  setIcebergSelected,
  setIcebergQuantitySelected,
  setActiveTaker,
  setActiveTrader,
} from "../../state/settings/settingsActions";

type ContextMenuState = {
  show: boolean;
  xPos: number;
  yPos: number;
};

const OrderRibbon = ({
  activeTaker,
  activeTrader,
  availableTakers,
  orderLifespanSelected,
  orderQuantitySelected,
  orderRibbonSettings,
  orderTypeSelected,
  ordersSettings,
  resetAllCardSizes,
  setActiveTaker,
  setActiveTrader,
  setIcebergQuantitySelected,
  setIcebergSelected,
  traders,
  cantorAlgoEnabled,
}) => {
  const [contextMenuPositions, setContextMenuPostions] = useState<ContextMenuState>({
    show: false,
    xPos: 0,
    yPos: 0,
  });

  const [orderLifespanOptions, setOrderLifespanOptions] = useState<any>([]);

  useEffect(() => {
    const orderLifespanBasicOptions = [OrderLifespan.Day, OrderLifespan.IOC];

    cantorAlgoEnabled
      ? setOrderLifespanOptions(orderLifespanBasicOptions)
      : setOrderLifespanOptions([...orderLifespanBasicOptions, OrderLifespan.FOK]);
  }, [cantorAlgoEnabled]);

  const saveIcebergQuantity = event => {
    // Get a valid number from the field.
    const { value } = event.target;
    const icebergQuantity = parseFloat(value.replace(/,/g, "")) || 0;

    setIcebergQuantitySelected(icebergQuantity);
  };

  const handleMKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "m") {
      positiveNumberEnforcer(event);
    } else {
      event.preventDefault();
      setIcebergQuantitySelected(orderRibbonSettings.icebergQuantity * 1000000);
    }
  };

  const oboButtons = () => {
    const nonNoneButtons = orderRibbonSettings.onBehalfOfButtons.filter(btnObj => {
      return btnObj.value !== "none";
    });

    return nonNoneButtons.length === 0 ? (
      <></>
    ) : (
      <RadioGroup
        allowNone={nonNoneButtons.findIndex(nNB => nNB.value === activeTrader) < 0}
        selected={[activeTrader]}
        onChange={value => {
          setActiveTrader(value[0]);
        }}
      >
        {orderRibbonSettings?.onBehalfOfButtons &&
          nonNoneButtons.map((oboObj, i) => (
            <div key={`${i}`} className={clsx(styles.actionCell, styles.actionCellMd)}>
              <RadioButton block={true} name={oboObj.value} size={"sm"}>
                {oboObj.value.length > 12 ? oboObj.value.slice(0, 12) + "..." : oboObj.value}
              </RadioButton>
            </div>
          ))}
      </RadioGroup>
    );
  };

  const closeContextMenu = () => setContextMenuPostions({ ...contextMenuPositions, show: false });

  const handleOnContextMenu = event => {
    event.preventDefault();
    setContextMenuPostions({ ...contextMenuPositions, show: true, xPos: event.pageX, yPos: event.pageY });
  };

  const handleOrderLifespanSelected = event => {
    closeContextMenu();
    const { value } = event.target;
    const lifespanValue = orderRibbonSettings.selectedDefaultOrderLifespan?.[0] === value ? [] : [value];
    orderLifespanSelected(lifespanValue);
  };

  const handleResetAllSizes = () => {
    closeContextMenu();
    if (orderRibbonSettings.selectedDefaultQuantityIndex) {
      orderQuantitySelected(null);
    }
    resetAllCardSizes();
  };

  return (
    <>
      {contextMenuPositions.show && (
        <OrderRibbonContextMenu
          contextMenuPositions={contextMenuPositions}
          toggle={closeContextMenu}
          orderRibbonSettings={orderRibbonSettings}
          saveChangeFunction={handleOrderLifespanSelected}
          resetFunction={handleResetAllSizes}
          cantorAlgoEnabled={cantorAlgoEnabled}
        />
      )}
      <div className={styles.orderRibbon} onContextMenu={handleOnContextMenu}>
        <div className={clsx(styles.radioGroup, styles.orderSize)}>
          <RadioGroup
            allowNone={true}
            selected={orderRibbonSettings.selectedDefaultQuantityIndex}
            onChange={index => {
              orderQuantitySelected(index || null);
            }}
          >
            {orderRibbonSettings?.customSizes &&
              orderRibbonSettings.customSizes.map((customSize, i) => (
                <div key={`${i}`} className={clsx(styles.actionCell, styles.actionCellMd)}>
                  <RadioButton block={true} name={i} size={"sm"}>
                    {customSize.multiplier === CUSTOM_SIZE_UNIT_OPTIONS.U
                      ? customSize.customSize
                      : `${customSize.customSize}${customSize.multiplier}`}
                  </RadioButton>
                </div>
              ))}
          </RadioGroup>
        </div>
        <div className={clsx(styles.radioGroup, styles.orderStrategy)}>
          <RadioGroup
            allowNone={true}
            selected={orderRibbonSettings?.selectedDefaultOrderLifespan}
            onChange={value => orderLifespanSelected(value ? value : [])}
          >
            {orderRibbonSettings &&
              orderLifespanOptions.map((orderLifespan, i) => (
                <div
                  key={`${orderLifespan}.${i}`}
                  className={clsx(styles.actionCell, styles.actionCellMd, styles.icebergButton)}
                >
                  <RadioButton block={true} name={orderLifespan} size={"sm"}>
                    {orderLifespan.toUpperCase()}
                  </RadioButton>
                </div>
              ))}
          </RadioGroup>
          {ordersSettings?.marketOrdersEnabled && (
            <Select
              size="small"
              className={styles.orderTypeSelect}
              dropdownMatchSelectWidth={false}
              value={orderRibbonSettings.selectedDefaultOrderType}
              onSelect={(value: string) => {
                orderTypeSelected(value || null);
              }}
              suffixIcon={Icon({ type: "caret-down" })}
            >
              {orderTypeOptionsToDirectlyTrade.map((option, i) => (
                <Select.Option key={`${option.value}.${i}`} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>

        {cantorAlgoEnabled && !orderRibbonSettings?.hideIceberg && (
          <div className={clsx(styles.radioGroup, styles.oddsAndEnds)}>
            <RadioGroup
              allowNone={true}
              selected={orderRibbonSettings?.useIceberg}
              onChange={value => setIcebergSelected(value)}
            >
              {orderRibbonSettings &&
                ["iceberg"].map((orderStrategy, i) => (
                  <div key={`${orderStrategy}.${i}`} className={clsx(styles.actionCell)}>
                    <RadioButton block={true} name={orderStrategy} size={"sm"}>
                      <span className={styles.icebergButton}>{orderStrategy}</span>
                    </RadioButton>
                  </div>
                ))}
            </RadioGroup>
            <Input
              size="small"
              disabled={!orderRibbonSettings?.useIceberg || orderRibbonSettings?.useIceberg.length === 0}
              value={
                orderRibbonSettings?.icebergQuantity &&
                orderRibbonSettings.icebergQuantity.toLocaleString(appLocale, { maximumFractionDigits: 4 })
              }
              className={styles.icebergInput}
              onKeyDown={handleMKey}
              onChange={saveIcebergQuantity}
            />
          </div>
        )}

        <div className={clsx(styles.radioGroup, styles.onBehalfSection)}>
          <Dropdown onChange={value => setActiveTaker(value)} options={availableTakers} value={activeTaker} />
          <Select
            size="small"
            disabled={traders && traders.length <= 0}
            className={styles.tradersSelect}
            dropdownMatchSelectWidth={false}
            value={activeTrader}
            onSelect={(value: string) => {
              setActiveTrader(value);
            }}
            suffixIcon={Icon({ type: "caret-down" })}
          >
            {traders.map((option, i) => (
              <Select.Option key={`${option}.${i}`} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
          {orderRibbonSettings.showOnBehalfOfButtons && oboButtons()}
        </div>
        <div className={clsx(styles.radioGroup, styles.theRest)}></div>
      </div>
    </>
  );
};

export default connect(
  (state: any) => {
    return {
      orderRibbonSettings: getOrderRibbonSettings(state),
      ordersSettings: getOrdersSettings(state),
      availableTakers: getAvailableTakers(state) || [],
      traders: getTraders(state) || [],
      activeTaker: getActiveTaker(state),
      activeTrader: getActiveTrader(state),
      cantorAlgoEnabled: getCantorAlgoEnabled(state),
    };
  },
  {
    orderQuantitySelected,
    orderLifespanSelected,
    orderTypeSelected,
    resetAllCardSizes,
    setIcebergSelected,
    setIcebergQuantitySelected,
    setActiveTaker,
    setActiveTrader,
  }
)(OrderRibbon);

const OrderRibbonContextMenu = ({
  contextMenuPositions,
  orderRibbonSettings,
  resetFunction,
  saveChangeFunction,
  toggle,
  cantorAlgoEnabled,
}) => {
  const elRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(elRef, () => toggle());

  const [orderLifespanOptions, setOrderLifespanOptions] = useState<any>([]);

  useEffect(() => {
    const orderLifespanBasicOptions = [
      {
        label: "Day",
        value: OrderLifespan.Day,
      },
      {
        label: "Immediate or cancel (IOC)",
        value: OrderLifespan.IOC,
      },
    ];

    const fokOption = {
      label: "Fill or Kill (FOK)",
      value: OrderLifespan.FOK,
    };

    cantorAlgoEnabled
      ? setOrderLifespanOptions(orderLifespanBasicOptions)
      : setOrderLifespanOptions([...orderLifespanBasicOptions, fokOption]);
  }, [cantorAlgoEnabled]);

  // adding dropdownContent and cardOverflow from cards.scss to achieve the look of a radio group as a custom select
  return (
    <div
      ref={elRef}
      style={{ position: "absolute", left: contextMenuPositions.xPos, top: contextMenuPositions.yPos }}
      className={clsx(styles.orderRibbonContextMenu, "orderRibbonContextMenu", "dropdownContent")}
    >
      <Menu className="cardOverflow">
        <Menu.Item style={{ height: "100%", margin: "4px 0" }}>
          <Radio.Group
            onChange={value => saveChangeFunction(value ? value : [])}
            value={orderRibbonSettings?.selectedDefaultOrderLifespan}
          >
            {orderLifespanOptions.map((option, i) => {
              return (
                <Radio
                  key={`${option.value}.${i}`}
                  value={option.value}
                  // making radio buttons look like select
                  className={clsx(
                    "ant-select-dropdown-menu-item",
                    orderRibbonSettings?.selectedDefaultOrderLifespan?.[0] === option.value &&
                      "ant-select-dropdown-menu-item-selected"
                  )}
                >
                  {option.label}
                </Radio>
              );
            })}
          </Radio.Group>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={resetFunction}>Reset all cards to default size</Menu.Item>
      </Menu>
    </div>
  );
};

import React from "react";
import { connect } from "react-redux";
import styles from "./CurrencyPairSelector.module.scss";
import { allSymbolsSelector } from "../../state/currency/symbols/symbolsState";
import { activeGroupSymbolsSelector } from "../../state/settings/settingsState";
import { SymbolsListBasic } from "../symbolsList/SymbolsListBasic";

type CurrencyPairSelectorProps = {
  symbolsOptions: any;
  selectedSymbols: any;
  isVisible: any;
  onOk: any;
  onCancel: any;
};
function CurrencyPairSelector({
  symbolsOptions,
  selectedSymbols,
  isVisible,
  onOk,
  onCancel,
}: CurrencyPairSelectorProps) {
  return (
    <div className={styles.component}>
      {isVisible && (
        <SymbolsListBasic
          allSymbols={symbolsOptions}
          selectedSymbols={selectedSymbols}
          onOk={onOk}
          onCancel={onCancel}
          config={{
            listHeight: "194px",
            footerButtonSize: "small",
            footerActionLabel: "Add",
            filterPlaceholder: "Filter",
          }}
        />
      )}
    </div>
  );
}

const CurrencyPairSelectorConnect = connect(
  (state: any, ownProps: any) => ({
    symbolsOptions: allSymbolsSelector(state),
    selectedSymbols: activeGroupSymbolsSelector(state),
  }),
  {}
)(CurrencyPairSelector);

export default CurrencyPairSelectorConnect;

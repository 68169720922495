import React from "react";
import { Input, Select, Icon, Switch } from "antd";

import styles from "./Settings.module.scss";
import { addCommas, priceFormatValidator, removeCommasAndAddMultiplierShortcuts } from "../../lib/formatUtil";
import { CardSize, ClickType, SpreadValue } from "../../state/settings/settingsState";

type MarketSettingsProps = {
  disableTypeSelection: boolean;
  saveSettings: any;
  settings: any;
};

const MarketSettings = ({ saveSettings, settings }: MarketSettingsProps) => {
  const clickOptions = [
    {
      label: "Single click",
      value: ClickType.Single,
    },
    {
      label: "Double click",
      value: ClickType.Double,
    },
  ];
  const priceLevelOptions = [
    {
      label: "Send all data",
      value: 1,
    },
    {
      label: "Send only visible levels",
      value: 0,
    },
  ];
  const spreadValueOptions = [
    {
      label: "Pip",
      value: SpreadValue.Pip,
    },
    {
      label: "Decimal",
      value: SpreadValue.Decimal,
    },
  ];

  const cardSizeOptions = [
    {
      label: "Small",
      value: CardSize.Small,
    },
    {
      label: "Medium",
      value: CardSize.Medium,
    },
    {
      label: "Large",
      value: CardSize.Large,
    },
  ];

  const saveNotional = (event: React.ChangeEvent<HTMLInputElement>) => {
    const notional = removeCommasAndAddMultiplierShortcuts(event.target.value);
    saveSettings({ ...settings, notional });
  };

  return (
    <div className={styles.settingsContent}>
      <section className={styles.formBlock}>
        <h5>market</h5>
        <div className={styles.switchBlock}>
          <label>Spread and trend</label>
          <Switch
            checked={settings.showPriceTrendAndSpread}
            onClick={() => {
              saveSettings({ ...settings, showPriceTrendAndSpread: !settings.showPriceTrendAndSpread });
            }}
          />
        </div>
        <div className={styles.switchBlock}>
          <label>Slippage</label>
          <Switch
            checked={settings.showSlippage}
            onClick={() => {
              saveSettings({ ...settings, showSlippage: !settings.showSlippage });
            }}
          />
        </div>
      </section>
      <section className={styles.formBlock}>
        <div className={styles.inputBlock}>
          <label>Slippage left-click increment</label>
          <Input
            size="large"
            type="text"
            value={settings && settings.slippageLeftIncrement}
            onChange={event => saveSettings({ ...settings, slippageLeftIncrement: event.target.value })}
            onKeyDown={(event: any) => priceFormatValidator(event, event.target.value)}
            // onBlur={() => { }}
          />
        </div>
        <div className={styles.inputBlock}>
          <label>Slippage right-click increment</label>
          <Input
            size="large"
            type="text"
            value={settings && settings.slippageRightIncrement}
            onChange={event => saveSettings({ ...settings, slippageRightIncrement: event.target.value })}
            onKeyDown={(event: any) => priceFormatValidator(event, event.target.value)}
            // onBlur={() => { }}
          />
        </div>
        <div className={styles.inputBlock}>
          <label>Default size</label>
          <Input
            size="large"
            type="text"
            value={settings && settings.notional && addCommas(settings.notional)}
            onChange={saveNotional}
            onKeyDown={(e: any) => priceFormatValidator(e, e.target.value)}
            onBlur={() => {
              const settingsNotional: number = Number(settings.notional);

              if (settingsNotional >= 1) {
                // If it's an integer
                if (Number.isInteger(settingsNotional)) {
                  saveSettings({ ...settings, notional: settingsNotional });
                  // If it's not an integer
                } else {
                  saveSettings({ ...settings, notional: Math.floor(settingsNotional) });
                }
                // If it's smaller than 1 (0, 0.000, 0.0003)
              } else {
                saveSettings({ ...settings, notional: 1 });
              }
            }}
          />
        </div>
        <div className={styles.inputBlock}>
          <label>Price levels</label>
          <Select
            size="large"
            style={{ width: "100%" }}
            value={settings.getAllPriceLevels ? 1 : 0}
            onSelect={(value: number) => {
              saveSettings({ ...settings, getAllPriceLevels: Boolean(value) });
            }}
            suffixIcon={Icon({ type: "caret-down" })}
          >
            {priceLevelOptions.map((option, i) => (
              <Select.Option key={`${option.value}.${i}`} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.inputBlock}>
          <label>Click to trade</label>
          <Select
            size="large"
            style={{ width: "100%" }}
            value={settings.clickType}
            onSelect={(value: string) => {
              saveSettings({ ...settings, clickType: value });
            }}
            suffixIcon={Icon({ type: "caret-down" })}
          >
            {clickOptions.map((option, i) => (
              <Select.Option key={`${option.value}.${i}`} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.inputBlock}>
          <label>Spread value</label>
          <Select
            size="large"
            style={{ width: "100%" }}
            value={settings.spreadValue}
            onSelect={(value: string) => {
              saveSettings({ ...settings, spreadValue: value });
            }}
            suffixIcon={Icon({ type: "caret-down" })}
          >
            {spreadValueOptions.map((option, i) => (
              <Select.Option key={`${option.value}.${i}`} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.inputBlock}>
          <label>Card size</label>
          <Select
            size="large"
            style={{ width: "100%" }}
            value={settings.cardSize}
            onSelect={(value: string) => {
              saveSettings({ ...settings, cardSize: value });
            }}
            suffixIcon={Icon({ type: "caret-down" })}
          >
            {cardSizeOptions.map((option, i) => (
              <Select.Option key={`${option.value}.${i}`} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </section>
      <section className={styles.formBlock}>
        <h5>depth of book</h5>

        <div className={styles.switchBlock}>
          <label>Filter prices on size update</label>
          <Switch
            checked={settings.enablePriceFiltering}
            onClick={() => {
              saveSettings({ ...settings, enablePriceFiltering: !settings.enablePriceFiltering });
            }}
          />
        </div>
        <div className={styles.switchBlock}>
          <label>Trade on depth of book</label>
          <Switch
            checked={settings.enableDepthOfBookTrading}
            onClick={() => {
              saveSettings({ ...settings, enableDepthOfBookTrading: !settings.enableDepthOfBookTrading });
            }}
          />
        </div>
        <div className={styles.switchBlock}>
          <label>Show spread on each level</label>
          <Switch
            checked={settings.displaySpreadOnDepthOfBook}
            onClick={() => {
              saveSettings({ ...settings, displaySpreadOnDepthOfBook: !settings.displaySpreadOnDepthOfBook });
            }}
          />
        </div>
        <div className={styles.switchBlock}>
          <label>Show size abbreviations</label>
          <Switch
            checked={settings.showSizeAbbreviation}
            onClick={() => {
              saveSettings({ ...settings, showSizeAbbreviation: !settings.showSizeAbbreviation });
            }}
          />
        </div>
        <div className={styles.inputBlock}>
          <label>Depth of book</label>
          <Select
            size="large"
            style={{ width: "100%" }}
            value={settings.depthDisplay}
            onSelect={(value: string) => {
              saveSettings({ ...settings, depthDisplay: value });
            }}
            suffixIcon={Icon({ type: "caret-down" })}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(numberVal => (
              <Select.Option key={`${numberVal.toString()}.${numberVal}`} value={numberVal}>
                {numberVal.toString()}
              </Select.Option>
            ))}
          </Select>
        </div>
      </section>
    </div>
  );
};

export default MarketSettings;

type ServiceUrlOptions = {
  env: string;
  realm: string;
  service: string;
  hostname?: string;
  secure?: boolean;
  protocol?: "http" | "ws";
  path?: string;
};

/**
 * Maps a client url to client service urls using a combination of options and window location conditionals.
 * - For local/dev/stage the mapping is straight forward. We keep the env prefix, and return the service name with the same env
 * - For demo we add in a realm, and support for an `sap` url mode. This is the same concept as productions `xc`. Demo keeps all realms _except_ `ny4`
 * - For production we add in a `xc` url case, as well as dropping the client url prefix. For example a url like `daiwa-ny4-treasury` would drop `daiwa` when creating the sso url.
 */
export const getServiceUrl = ({
  env,
  realm,
  service,
  hostname = window.location.hostname,
  secure = true,
  protocol = "http",
  path,
}: ServiceUrlOptions) => {
  let url = service;

  // if cross connect, add a -xc suffix at the end of the service name
  // if (hostname.includes("xc")) {
  //   url = `${url}-xc`;
  // }

  url = `${url}.lucera.com`;

  if (hostname.includes("soa")) {
    url = `soa-${url}`;
  }

  // for all cases except prod, prepend the environment
  if (env !== "prod") {
    url = `${env === "local" ? "dev" : env}-${url}`;
  }

  // for prod, and some demo cases, prepend the realm
  if (env === "prod" || (env === "demo" && realm !== "ny4")) {
    url = `${realm}-${url}`;
  }

  // prepend the protocol, and secure mode if applicable
  url = `${protocol}${secure ? "s" : ""}://${url}`;

  // add a path if we have it
  if (path) {
    url = `${url}${path}`;
  }

  return url;
};

export const getAuthUrls = url => {
  let [initialProtocol, host] = url.split("://");
  let [hostname] = host.split(":");
  let [initialSubdomain, domain, ...tld] = hostname.split(".");

  let protocol = initialProtocol === "http" ? "https" : initialProtocol === "ws" ? "wss" : initialProtocol;
  let subdomain = initialSubdomain.replace(/local/, "dev");

  let appRegex = /(spot|lumeexchange|exchange)/i;
  let domainName = `${domain}.${tld.join(".")}`;

  return {
    sso: `${protocol}://${subdomain.replace(appRegex, "sso")}.${domainName}`,
    identity: `${protocol}://${subdomain.replace(appRegex, "identity")}.${domainName}`,
  };
};

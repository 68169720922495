import React, { useEffect, useState } from "react";
import { Icon, InputNumber, Select, Switch } from "antd";
import cloneDeep from "lodash/cloneDeep";

import settingsStyles from "./Settings.module.scss";
import styles from "./OrderRibbonSettings.module.scss";
import { positiveValueEnforcer, positiveValueMaxLengthEnforcer } from "../../lib/formatUtil";
import {
  CUSTOM_SIZE_UNIT_OPTIONS,
  CUSTOM_SIZE_UNIT_OPTIONS_LIST,
  getCantorAlgoEnabled,
} from "../../state/settings/settingsState";
import { isEqual } from "lodash";
import { connect } from "react-redux";

// tslint:disable: jsx-no-lambda

const OrderRibbonSettings = ({ saveSettings, settings, traders, cantorAlgoEnabled }) => {
  const [oboDisplayOptions, setOboDisplayOptions] = useState<any>([]);

  useEffect(() => {
    if (traders) {
      const options = [{ label: "None", value: "none" }];
      traders.forEach(trader => {
        options.push({ label: trader, value: trader });
      });
      setOboDisplayOptions(options);
    }
  }, [traders]);

  // TODO1 refactor and remove orderSize code
  const calculateOrderSizeFromCustomSize = (customSize, multiplier) => {
    if (multiplier === CUSTOM_SIZE_UNIT_OPTIONS.U) {
      customSize = customSize / 1000000;
    } else if (multiplier === CUSTOM_SIZE_UNIT_OPTIONS.K) {
      customSize = customSize / 1000;
    } else if (multiplier === CUSTOM_SIZE_UNIT_OPTIONS.B) {
      customSize = customSize * 1000;
    }
    return customSize;
  };

  const getNewMultiplierSettingsUpdates = (multiplier, updatedFieldIndex) => {
    const newOrderSize = calculateOrderSizeFromCustomSize(
      settings.customSizes[updatedFieldIndex].customSize,
      multiplier
    );
    const updatedCustomSizes = cloneDeep(settings.customSizes);
    updatedCustomSizes[updatedFieldIndex].multiplier = multiplier;
    const updatedOrderSizes = [...settings.orderSizes];
    updatedOrderSizes[updatedFieldIndex] = newOrderSize;
    return { customSizes: updatedCustomSizes, orderSizes: updatedOrderSizes };
  };

  const getNewSizeSettingsUpdates = (newSize, updatedFieldIndex) => {
    const newValue = newSize ? newSize.toString() : "";
    const updatedCustomSizes = cloneDeep(settings.customSizes);
    updatedCustomSizes[updatedFieldIndex].customSize = newValue;
    const updatedOrderSizes = [...settings.orderSizes];
    const newOrderSize = calculateOrderSizeFromCustomSize(newValue, settings.customSizes[updatedFieldIndex].multiplier);
    updatedOrderSizes[updatedFieldIndex] = newOrderSize;
    const newSizeSettings = { customSizes: updatedCustomSizes, orderSizes: updatedOrderSizes };

    return newSizeSettings;
  };

  const getInput = fieldIndex => {
    const { customSize } = settings.customSizes[fieldIndex];
    const customSizeFloat = parseFloat(customSize);
    return (
      <div className={styles.inputBlock}>
        <InputNumber
          data-testid={`customSizesInputField${fieldIndex}`}
          size="large"
          value={customSizeFloat > 0 ? customSizeFloat : undefined}
          min={0.0001}
          max={999.9999}
          onKeyDown={event => {
            // If any of the input is highlighted, no need to enforce a max length since it will
            // replace instead of append.
            const selectedText = window.getSelection();
            if (selectedText && selectedText.toString().length > 0) {
              positiveValueEnforcer(event);
            } else {
              positiveValueMaxLengthEnforcer(event);
            }
          }}
          onChange={value => {
            const newSettings = getNewSizeSettingsUpdates(value, fieldIndex);
            saveSettings({ ...settings, ...newSettings });
          }}
          onBlur={value => {
            if (!value.currentTarget.value) {
              const newSettings = getNewSizeSettingsUpdates(1, fieldIndex);
              saveSettings({ ...settings, ...newSettings });
            }
          }}
        />
        <Select
          data-testid={`customSizesSelectField${fieldIndex}`}
          size="large"
          className={styles.unitColumn}
          value={settings.customSizes[fieldIndex].multiplier || undefined}
          onSelect={value => {
            const newSettings = getNewMultiplierSettingsUpdates(value, fieldIndex);
            saveSettings({ ...settings, ...newSettings });
          }}
          suffixIcon={Icon({ type: "caret-down" })}
        >
          {CUSTOM_SIZE_UNIT_OPTIONS_LIST.map((option, i) => (
            <Select.Option key={`${option}.${i}`} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };

  const getOBOSelect = oboObjectIndex => {
    // oboArrayIndex index in the array of onBehalfOfButtons, oboObjectIndex the field index in the obo object
    const oboArrayIndex = settings.onBehalfOfButtons.findIndex(btnObj => btnObj.index === oboObjectIndex);

    const isOptionDisabled = option => {
      const optionSelectedIndex = settings.onBehalfOfButtons.findIndex(btnObj => btnObj.value === option.value);
      return (
        option.value !== "none" &&
        optionSelectedIndex >= 0 &&
        settings.onBehalfOfButtons[oboArrayIndex].index !== settings.onBehalfOfButtons[optionSelectedIndex].index
      );
    };
    return (
      <Select
        data-testid={`onBehalfOfSelectField${oboObjectIndex}`}
        size="large"
        style={{ width: "100%" }}
        value={settings.onBehalfOfButtons[oboArrayIndex].value}
        onSelect={(value: string) => {
          const buttons = settings.onBehalfOfButtons.slice();
          const foundIndex = buttons.findIndex(btnObj => btnObj.index === oboObjectIndex);
          buttons[foundIndex] = { index: oboObjectIndex, value };

          if (!isEqual(buttons, settings.onBehalfOfButtons)) {
            saveSettings({ ...settings, onBehalfOfButtons: buttons });
          }
        }}
        suffixIcon={Icon({ type: "caret-down" })}
      >
        {oboDisplayOptions.map((option, i) => (
          <Select.Option key={`${option.value}.${i}`} value={option.value} disabled={isOptionDisabled(option)}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    );
  };

  return (
    <div className={settingsStyles.settingsContent}>
      <section className={settingsStyles.formBlock}>
        <h5>display</h5>
        <div className={settingsStyles.switchBlock}>
          <label>Order ribbon</label>
          <Switch
            data-testid={"displayOrderRibbonSwitch"}
            checked={settings?.showOrderRibbon}
            onClick={() => {
              saveSettings({ ...settings, showOrderRibbon: !settings.showOrderRibbon });
            }}
          />
        </div>
        {cantorAlgoEnabled && (
          <div className={settingsStyles.switchBlock}>
            <label>Iceberg strategy</label>
            <Switch
              data-testid={"displayIcebergSwitch"}
              checked={!settings?.hideIceberg}
              onClick={() => {
                saveSettings({ ...settings, hideIceberg: !settings.hideIceberg });
              }}
            />
          </div>
        )}
      </section>

      <section className={settingsStyles.formBlock}>
        <h5>custom sizes</h5>
        <div className={styles.form}>
          {getInput(0)}
          {getInput(1)}
          {getInput(2)}
          {getInput(3)}
          {getInput(4)}
        </div>
      </section>

      <section className={settingsStyles.formBlock}>
        <h5>custom account buttons</h5>
        <div className={settingsStyles.switchBlock}>
          <label>On-behalf-of buttons</label>
          <Switch
            data-testid={"onBehalfOfSwitch"}
            checked={settings?.showOnBehalfOfButtons}
            onClick={() => {
              saveSettings({ ...settings, showOnBehalfOfButtons: !settings.showOnBehalfOfButtons });
            }}
          />
        </div>
        <div className={styles.inputBlock}>{getOBOSelect(0)}</div>
        <div className={styles.inputBlock}>{getOBOSelect(1)}</div>
        <div className={styles.inputBlock}>{getOBOSelect(2)}</div>
      </section>
    </div>
  );
};

// export default OrderRibbonSettings;

export default connect((state: any, ownProps: any) => {
  return {
    cantorAlgoEnabled: getCantorAlgoEnabled(state),
  };
})(OrderRibbonSettings);

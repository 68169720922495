import {
  startTimeOptions,
  startTimeOptionsBfix,
  orderSideOptions,
  orderStrategyOptions,
  orderTypeOptions,
  pegFloorTypeOptions,
  pegReferencePriceTypeOptions,
  priceTypeOptions,
  urgencyOptions,
} from "./selectOptions";
import { OrderStrategy } from "../../../state/orders/types";

export enum OrderFieldsEnum {
  allocationAfterFix = "allocationAfterFix",
  allocationBeforeFix = "allocationBeforeFix",
  currency = "currency",
  displaySize = "displaySize", // used for iceberg on Classic
  endTime = "endTime",
  endTimeTime = "endTimeTime",
  fixingDate = "fixingDate",
  instrumentId = "instrumentId",
  lifespan = "lifespan",
  lowerBandPrice = "lowerBandPrice",
  lowerBandTargetPercentVolume = "lowerBandTargetPercentVolume",
  maxParticipationRate = "maxParticipationRate",
  maximumFloor = "maximumFloor",
  minParticipationRate = "minParticipationRate",
  minimumExecutedQuantity = "minimumExecutedQuantity",
  pegFloorFloatingPoints = "pegFloorFloatingPoints",
  pegFloorPrice = "pegFloorPrice",
  pegFloorType = "pegFloorType",
  pegOffset = "pegOffset",
  pegReferencePriceType = "pegReferencePriceType",
  price = "price",
  priceLeg2 = "priceLeg2",
  marketPrice = "marketPrice",
  slippage = "slippage",
  priceType = "priceType",
  product = "product",
  quantity = "quantity",
  quantityFilled = "quantityFilled",
  quantityFilledLeg2 = "quantityFilledLeg2",
  randomizationPercent = "randomizationPercent",
  settleDate = "settleDate",
  side = "side",
  sideLeg2 = "sideLeg2",
  sliceInterval = "sliceInterval",
  sliceSize = "sliceSize",
  startTime = "startTime",
  startTimeTime = "startTimeTime",
  status = "status",
  statusLeg2 = "statusLeg2",
  stopLossPrice = "stopLossPrice",
  stopLossPriceLeg2 = "stopLossPriceLeg2",
  strategy = "strategy",
  strategyConfig = "strategyConfig",
  takerName = "takerName",
  takerNameLeg2 = "takerNameLeg2",
  targetParticipationRate = "targetParticipationRate",
  traderName = "traderName",
  type = "type",
  orderTypeLeg1 = "orderTypeLeg1",
  orderTypeLeg2 = "orderTypeLeg2",
  upperBandPrice = "upperBandPrice",
  upperBandTargetPercentVolume = "upperBandTargetPercentVolume",
  urgency = "urgency",
  valueDate = "valueDate",
  valueDateLeg2 = "valueDateLeg2",
  wouldPrice = "wouldPrice",
  wouldSizePercent = "wouldSizePercent",
}

export enum FieldType {
  select = "select",
  input = "input",
  textarea = "textarea",
}

enum InputFieldType {
  number = "number",
  text = "text",
}

export const commonFields = [
  {
    label: "Lifespan",
    type: FieldType.select,
    dataFieldName: OrderFieldsEnum.lifespan,
    // values defined in AuctionOrderForm
    required: [],
  },
  {
    label: "Side",
    type: FieldType.select,
    dataFieldName: OrderFieldsEnum.side,
    values: orderSideOptions,
    required: [],
  },
  {
    label: "Stop Price",
    type: FieldType.input,
    inputType: InputFieldType.text,
    dataFieldName: OrderFieldsEnum.stopLossPrice,
    // onBlurFunction: "getPricePrecision",
    onFocusFunction: "moveCursorToEnd",
    onKeyDownFunction: "priceFieldHandler",
    required: [],
  },
  {
    label: "Limit Price",
    type: FieldType.input,
    inputType: InputFieldType.text,
    dataFieldName: OrderFieldsEnum.price,
    // onBlurFunction: "getPricePrecision",
    onFocusFunction: "moveCursorToEnd",
    onKeyDownFunction: "priceFieldHandler",
    required: [],
  },
  {
    label: "Market Price",
    type: FieldType.input,
    inputType: InputFieldType.text,
    dataFieldName: OrderFieldsEnum.marketPrice,
    // onBlurFunction: "getPricePrecision",
    onFocusFunction: "moveCursorToEnd",
    onKeyDownFunction: "priceFieldHandler",
    required: [],
  },
  // {
  //   label: "",
  //   type: "",
  //   dataFieldName: "priceAdjusterButtons",
  //   values: [],
  //   required: [],
  // },
  // {
  //   label: "Type",
  //   type: FieldType.select,
  //   dataFieldName: OrderFieldsEnum.type,
  //   values: orderTypeOptions,
  //   required: [],
  // },
  {
    label: "Size",
    type: FieldType.input,
    inputType: InputFieldType.text,
    dataFieldName: OrderFieldsEnum.quantity,
    valueFormat: "addCommas",
    onKeyDownFunction: "sizeFieldHandler",
    onChangeFunction: "removeCommasAndAddMultiplierShortcutsHandler",
    onBlurFunction: "formatSizeBasedOnSettingsHandler",
    required: [],
  },
  {
    label: "Strategy",
    type: FieldType.select,
    dataFieldName: OrderFieldsEnum.strategy,
    values: orderStrategyOptions,
    required: ["ALL"],
  },
  {
    label: "Min qty",
    type: FieldType.input,
    inputType: InputFieldType.text,
    dataFieldName: OrderFieldsEnum.minimumExecutedQuantity,
    valueFormat: "addCommas",
    onKeyDownFunction: "mKeyHandler",
    onChangeFunction: "convertToNumber",
    required: [],
  },

  // for now this defaults to today in AuctionOrderForm
  // {
  //   label: "Start date",
  //   type: FieldType.input,
  //   inputType: InputFieldType.text,
  //   dataFieldName: OrderFieldsEnum.startTime,
  //   required: [],
  // },

  {
    label: "Start time",
    type: FieldType.input,
    inputType: InputFieldType.text,
    dataFieldName: OrderFieldsEnum.startTimeTime,
    onChangeFunction: "timeStampColonHandler",
    onKeyDownFunction: "timeStampKeyDownHandler",
    required: [OrderStrategy.Bfix, OrderStrategy.Fixing],
    placeholder: "hh:mm:ss",
    values: startTimeOptions, // used if strategy is Fixing
    valuesBfix: startTimeOptionsBfix, // used if strategy is BFix
  },
  // for now this defaults to today in AuctionOrderForm
  // {
  //   label: "End date",
  //   type: FieldType.input,
  //   inputType: InputFieldType.text,
  //   dataFieldName: OrderFieldsEnum.endTime,
  //   required: [],
  // },
  {
    label: "End time",
    type: FieldType.input,
    inputType: InputFieldType.text,
    dataFieldName: OrderFieldsEnum.endTimeTime,
    onChangeFunction: "timeStampColonHandler",
    onKeyDownFunction: "timeStampKeyDownHandler",
    required: [],
    placeholder: "hh:mm:ss",
  },
  {
    label: "Price type",
    type: FieldType.select,
    dataFieldName: OrderFieldsEnum.priceType,
    values: priceTypeOptions,
    required: [],
  },
];

export const algoOrderFields = [
  {
    label: "Upper band price",
    type: FieldType.input,
    inputType: InputFieldType.text,
    dataFieldName: OrderFieldsEnum.upperBandPrice,
    onKeyDownFunction: "priceFieldHandler",
    required: [],
  },
  {
    label: "Upper band % vol",
    type: FieldType.input,
    inputType: InputFieldType.number,
    dataFieldName: OrderFieldsEnum.upperBandTargetPercentVolume,
    onKeyDownFunction: "allowOnlyNumbers",
    onChangeFunction: "percentValue",
    required: [],
  },
  {
    label: "Lower band price",
    type: FieldType.input,
    inputType: InputFieldType.text,
    dataFieldName: OrderFieldsEnum.lowerBandPrice,
    onKeyDownFunction: "priceFieldHandler",
    required: [],
  },
  {
    label: "Lower band % vol",
    type: FieldType.input,
    inputType: InputFieldType.number,
    dataFieldName: OrderFieldsEnum.lowerBandTargetPercentVolume,
    onKeyDownFunction: "allowOnlyNumbers",
    onChangeFunction: "percentValue",
    required: [],
  },
  {
    label: "Disp qty",
    type: FieldType.input,
    inputType: InputFieldType.text,
    dataFieldName: OrderFieldsEnum.maximumFloor,
    valueFormat: "addCommas",
    onKeyDownFunction: "sizeFieldHandler",
    // onKeyDownFunction: "mKeyHandler",
    // onChangeFunction: "minValueZero",
    onChangeFunction: "removeCommasAndAddMultiplierShortcutsHandler",
    onBlurFunction: "formatDisplayQtyBasedOnSettingsHandler",
    required: [OrderStrategy.Iceberg],
  },
  {
    label: "Before fix %",
    type: FieldType.input,
    inputType: InputFieldType.number,
    dataFieldName: OrderFieldsEnum.allocationBeforeFix,
    onKeyDownFunction: "allowOnlyNumbers",
    onChangeFunction: "percentValue",
    required: [],
  },
  {
    label: "Would price",
    type: FieldType.input,
    inputType: InputFieldType.number,
    dataFieldName: OrderFieldsEnum.wouldPrice,
    onKeyDownFunction: "priceFieldHandler",
    required: [],
  },
  {
    label: "Would size %",
    type: FieldType.input,
    inputType: InputFieldType.number,
    dataFieldName: OrderFieldsEnum.wouldSizePercent,
    onKeyDownFunction: "allowOnlyNumbers",
    onChangeFunction: "percentValue",
    required: [],
  },
  {
    label: "Max part rate %",
    type: FieldType.input,
    inputType: InputFieldType.number,
    dataFieldName: OrderFieldsEnum.maxParticipationRate,
    onKeyDownFunction: "allowOnlyNumbers",
    onChangeFunction: "percentValue",
    required: [],
  },
  {
    label: "Target part rate",
    type: FieldType.input,
    inputType: InputFieldType.number,
    dataFieldName: OrderFieldsEnum.targetParticipationRate,
    onKeyDownFunction: "allowOnlyNumbers",
    onChangeFunction: "percentValue",
    required: [OrderStrategy.Pov],
  },
  {
    label: "Urgency",
    type: FieldType.select,
    dataFieldName: OrderFieldsEnum.urgency,
    values: urgencyOptions,
    required: [OrderStrategy.ISeek, OrderStrategy.Sor],
  },
  // {
  //   label: "Stop loss price",
  //   type: FieldType.input,
  //   inputType: InputFieldType.text,
  //   dataFieldName: OrderFieldsEnum.stopLossPrice,
  //   onKeyDownFunction: "priceFieldHandler",
  //   required: [],
  // },
  {
    label: "Slice size",
    type: FieldType.input,
    inputType: InputFieldType.text,
    dataFieldName: OrderFieldsEnum.sliceSize,
    valueFormat: "addCommas",
    onKeyDownFunction: "allowOnlyNumbers",
    onChangeFunction: "convertToNumber",
    required: [],
  },
  {
    label: "Slice interval",
    type: FieldType.input,
    inputType: InputFieldType.number,
    dataFieldName: OrderFieldsEnum.sliceInterval,
    onKeyDownFunction: "allowOnlyNumbers",
    onChangeFunction: "convertToNumber",
    required: [],
  },
  {
    label: "Randomization %",
    type: FieldType.input,
    inputType: InputFieldType.number,
    dataFieldName: OrderFieldsEnum.randomizationPercent,
    onKeyDownFunction: "allowOnlyNumbers",
    onChangeFunction: "percentValue",
    required: [],
  },
];

export const pegFields = [
  {
    label: "Peg ref price type",
    type: FieldType.select,
    dataFieldName: OrderFieldsEnum.pegReferencePriceType,
    values: pegReferencePriceTypeOptions,
    required: [],
  },
  {
    label: "Peg offset",
    type: FieldType.input,
    inputType: InputFieldType.number,
    dataFieldName: OrderFieldsEnum.pegOffset,
    onKeyDownFunction: "allowOnlyNumbers",
    onChangeFunction: "convertToNumber",
    required: [],
  },
  {
    label: "Peg floor type",
    type: FieldType.select,
    dataFieldName: OrderFieldsEnum.pegFloorType,
    values: pegFloorTypeOptions,
    required: [],
  },
  {
    label: "Peg floor price",
    type: FieldType.input,
    inputType: InputFieldType.text,
    dataFieldName: OrderFieldsEnum.pegFloorPrice,
    onKeyDownFunction: "priceFieldHandler",
    required: [],
  },
  {
    label: "Peg floor float pts",
    type: FieldType.input,
    inputType: InputFieldType.number,
    dataFieldName: OrderFieldsEnum.pegFloorFloatingPoints,
    onKeyDownFunction: "allowOnlyNumbers",
    onChangeFunction: "convertToNumber",
    required: [OrderStrategy.Peg], // conditional logic in auction card
  },
];

export const lastFields = [
  {
    label: "Strategy Config",
    type: FieldType.input,
    inputType: InputFieldType.text,
    dataFieldName: OrderFieldsEnum.strategyConfig,
    required: [],
  },
];

import React from 'react';
import { connect } from 'react-redux';
import { getMarginsTotals } from '../../../state/margins/marginsState';
import { CustomAggregationHeaderType } from './Margins.types';
import styles from '../AnalyticsPanel.module.scss';

const CustomAggregationHeader = ({ columnName, marginsTotals }: CustomAggregationHeaderType) => {

  const priceFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  });

  return marginsTotals ? (
    <div className="ag-header-group-cell-label">
      <span className={styles.customHeaderValue}>
        {typeof marginsTotals[columnName] === 'number' ? priceFormatter.format(marginsTotals[columnName]).replace("$", "") : marginsTotals[columnName]}
      </span>
    </div>
  ) : <></>;
};

export default connect((state: any) => ({
  marginsTotals: getMarginsTotals(state),
}))(CustomAggregationHeader);

import { ccyPairIdStripper } from "../../../lib/formatUtil";
import { SYMBOLS_LOADED } from "../currencyActions";
import { SYMBOLS_PIPS_LOADED } from "./symbolsActions";
import symbolsPips from "../../../commonFiles/symbolsPips.json";


const initialState = symbolsPips;

const symbolsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SYMBOLS_LOADED: {
      //TODO: add send symbols from vertex
      return action.payload || state;
    }
    case SYMBOLS_PIPS_LOADED: {
      return action.payload || state;   
    }
    default:
      return state;
  }
};

export default symbolsReducer;

// selectors
export const allSymbolsSelector = state => state.allCurrencyPairs.map(pair => pair.symbol);

// Striping out the tenors and removing duplicates
export const allSymbolsWithoutTenorsSelector = state =>
  Array.from(new Set(state.allCurrencyPairs.map(pair => pair?.symbol.split("-")[0])));

export const symbolsPipMapSelector = state => {
  const pairs = state.allCurrencyPairs;
  return pairs.reduce((obj, item) => {
    obj[item.symbol] = item;
    return obj;
  }, {});
};

const DEFAULT_SYMBOL = "EUR/USD";
export const pipForSymbol = (state, symbol) => {

  // const symbolsPipMap = initialState.reduce((obj, item) => {
  const symbolsPipMap = state.allCurrencyPairs.reduce((obj, item) => {
    obj[item.symbol] = item;
    return obj;
  }, {});

  const symbolWithoutId = ccyPairIdStripper(symbol);
  return (symbolsPipMap && symbolsPipMap[symbolWithoutId] && symbolsPipMap[symbolWithoutId].pip) || symbolsPipMap[DEFAULT_SYMBOL].pip;
}
  

import { Drawer } from "antd";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useKeycloakAuth } from "@lume/prism";
import { globalStyles } from "@lume/prism";

import Header from "./components/Header";
import OrdersPanel from "./components/orders/OrdersPanel";
import Settings from "./components/settings/Settings";

import CurrencyGrid from "./components/currency/CurrencyGrid";
// import OpenFinApp from "./openfin/OpenFinApp";
import AnalyticsPanel from "./components/analytics/AnalyticsPanel";
import Preloader, { ConnectionBanner } from "./components/connection/Preloader";
import OrderRibbon from "./components/currency/OrderRibbon";
import { getCardPanelHeight } from "./lib/util";
import FrameTitleBar from "./openfin/FrameTitleBar";
import { setupGrid } from "./shared/blotter/setup-grid";
import { Notifications } from "./shared/notifications";
import { getIsScreenLocked, setIsScreenLocked } from "./state/app/appState";
import { savePanelOffset } from "./state/settings/settingsActions";
import { getAnalyticsPanelOffset, getSettings } from "./state/settings/settingsState";
import "./styles/app.scss";
import "./styles/theme/antTheme.less";
import "./styles/theme/lumeTheme.scss";
import { setTheme } from "./theme";

import AuctionOrderForm from "./components/currency/AuctionOrderForm/AuctionOrderForm";
import AuctionOrderFormClassic from "./components/currency/AuctionOrderForm/AuctionOrderFormClassic";
import { getAuctionCardDetailSelector } from "./state/currency/currencyState";
import { OrderStrategy } from "./state/orders/types";
import { AuctionCardStyleType, getAuctionCardStyle } from "./state/settings/settingsState";
import { staticAssetServerURL } from "./lib/constants";

const AG_GRID_LICENSE =
  "Lucera_Financial_Infrastructures_LLC_Treasury_UI_Spot_UI_Single_Application_2_Devs_2_Deployment_Licenses_16_January_2021_[v2]_MTYxMDc1NTIwMDAwMA==b24a2441e67ee858454a07e0104f24f3";

// register ag-grid license app wide
setupGrid(AG_GRID_LICENSE || "");

declare global {
  interface Window {
    fin: any;
  }
}
// eslint-disable-next-line no-self-assign
window.fin = window.fin;

function App({
  analyticsPanelOffset,
  settings,
  savePanelOffset,
  isScreenLocked,
  setIsScreenLocked,
  auctionCardDetail,
  auctionCardStyle,
}) {
  const { loggedIn, errorMessage, logout, getProfile } = useKeycloakAuth(`${staticAssetServerURL}/keycloak.json`);

  const LOGO = `${staticAssetServerURL}/logo.svg`;
  const FAVICON = `${staticAssetServerURL}/favicon.ico`;

  const initialDataContactInfo = { phone: "", email: "" };

  const [showDrawer, setShowDrawer] = useState(false);
  const [panelOffset, setPanelOffset] = useState(analyticsPanelOffset);
  const [isResizing, setIsResizing] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string>();
  const [contactInfo, setContactInfo] = useState(initialDataContactInfo);

  const analyticsPanelDefaultHeight = 220;
  const minAnalyticsPanelHeight = 88;

  useEffect(() => {
    setPanelOffset(analyticsPanelOffset);
  }, [analyticsPanelOffset]);

  useEffect(() => {
    // Fetch, set title page and contact info
    fetch(`${staticAssetServerURL}/data.json`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        document.title = data.title;
        data?.contactInfo ? setContactInfo(data.contactInfo) : setContactInfo(initialDataContactInfo);
      });

    const prependElementToHeader = element => {
      document.getElementsByTagName("head")[0].appendChild(element);
    };

    const setFavIcon = faviconURL => {
      const favIconLink: any = document.querySelector("link[rel*='icon']"); // || document.createElement("link");
      favIconLink.type = "image/x-icon";
      favIconLink.rel = "shortcut icon";
      const favIcon32Link: any = document.querySelector("link[rel*='icon']");
      favIcon32Link.type = "image/png";
      favIcon32Link.rel = "shortcut icon";
      favIcon32Link.sizes = "32x32";
      // let favIconHref = generateImagePath("favicon.ico");
      // let favIcon32Href = generateImagePath("favicon_32.png");

      favIconLink.href = faviconURL;
      prependElementToHeader(favIconLink);
    };

    setLogoUrl(LOGO);
    setFavIcon(FAVICON);
  }, []);

  const fin = window.fin;
  // if (fin) {
  //   console.log("fin exists", fin);
  // } else {
  //   console.log("no openfin");
  // }

  const setCardWindowHeights = height => {
    const panels = window["externalPanels"] || {};
    for (const symbol in panels) {
      const panel = panels[symbol];
      panel.resizeTo(216, height);
    }
  };

  const handleSaveAllSettings = settings => {
    setShowDrawer(false);
    const { marketSettings } = settings;
    const { depthDisplay, displaySpreadOnDepthOfBook, showSlippage, showPriceTrendAndSpread, cardSize } =
      marketSettings || {};
    const height =
      getCardPanelHeight(depthDisplay, displaySpreadOnDepthOfBook, showSlippage, showPriceTrendAndSpread, cardSize) +
      50; //48 = window panel bar
    setCardWindowHeights(height);
  };

  const updateAnalyticsPanelHeight = event => {
    if (isResizing) {
      const minOffset = minAnalyticsPanelHeight - analyticsPanelDefaultHeight;
      const maxOffset = appRef?.current?.clientHeight ? appRef?.current?.clientHeight / 2 : minOffset;

      const offsetValue = appRef?.current?.clientHeight
        ? appRef?.current?.clientHeight - event.pageY - analyticsPanelDefaultHeight
        : minOffset;
      setPanelOffset(offsetValue >= minOffset ? (offsetValue <= maxOffset ? offsetValue : maxOffset) : minOffset);
    }
  };

  const getCardStyle = auctionCardDetailArg => {
    const style =
      auctionCardDetailArg && auctionCardDetailArg.strategy
        ? OrderStrategy.Common === auctionCardDetailArg.strategy
          ? AuctionCardStyleType.Classic
          : AuctionCardStyleType.Algo
        : auctionCardStyle === AuctionCardStyleType.Classic
        ? AuctionCardStyleType.Classic
        : AuctionCardStyleType.Algo;
    return style;
  };

  // Highlight an active card while its auction panel is open.
  const [activeCard, setActiveCard] = useState<HTMLDivElement | null>(null);
  const [activeCardSide, setActiveCardSide] = useState<string>("");
  const activeCardProps = {
    activeCard,
    setActiveCard,
    activeCardSide,
    setActiveCardSide,
  };

  const appRef = useRef<HTMLDivElement>(null);
  const renderedApp = (
    <>
      {fin ? <FrameTitleBar title="LumeExchange" primaryWindow={true} /> : <></>}
      <div
        className="App"
        ref={appRef}
        onMouseMove={event => {
          if (isResizing) {
            updateAnalyticsPanelHeight(event);
          }
        }}
        onMouseUp={() => {
          if (isResizing) {
            setIsResizing(false);
            savePanelOffset(panelOffset);
          }
        }}
        onDragStart={() => {
          return false;
        }}
      >
        <Preloader loggedIn={loggedIn} errorMessage={errorMessage} logout={logout} getProfile={getProfile} />
        <ConnectionBanner />

        <Drawer
          width="520"
          placement="right"
          closable={false}
          visible={showDrawer}
          onClose={() => {
            setShowDrawer(false);
          }}
        >
          <Settings
            onSaveAllSettings={handleSaveAllSettings}
            isOpen={showDrawer}
            closeDrawer={() => setShowDrawer(false)}
          />
        </Drawer>

        <div id="openfin" />

        <div className="container">
          <div className="header">
            <Header
              logoUrl={logoUrl}
              openDrawer={setShowDrawer}
              onToggleLock={() => setIsScreenLocked(!isScreenLocked)}
              logout={logout}
              contactInfo={contactInfo}
            />
          </div>

          {/* HERE */}
          {/* <AuctionTest /> */}

          <div className="currency">
            {settings.orderRibbonSettings.showOrderRibbon && <OrderRibbon />}
            <CurrencyGrid {...activeCardProps} />
          </div>

          {auctionCardDetail || settings.ordersSettings.showOrders ? (
            <div className="orders">
              {auctionCardDetail ? (
                getCardStyle(auctionCardDetail) === AuctionCardStyleType.Classic ? (
                  <AuctionOrderFormClassic {...auctionCardDetail} setActiveCardSide={setActiveCardSide} />
                ) : (
                  <AuctionOrderForm {...auctionCardDetail} setActiveCardSide={setActiveCardSide} />
                )
              ) : (
                <OrdersPanel />
              )}
            </div>
          ) : (
            <></>
          )}

          {(settings.blotterSettings.showBlotter ||
            settings.blotterSettings.showAggregatedPnl ||
            settings.blotterSettings.showTicker) && (
            <AnalyticsPanel
              appRef={appRef}
              panelOffset={panelOffset}
              setIsResizing={setIsResizing}
              defaultHeight={analyticsPanelDefaultHeight}
            />
          )}
        </div>

        <Notifications />
      </div>
    </>
  );
  return renderedApp;
}

export default connect(
  (state: any) => ({
    settings: getSettings(state),
    analyticsPanelOffset: getAnalyticsPanelOffset(state),
    isScreenLocked: getIsScreenLocked(state),
    auctionCardDetail: getAuctionCardDetailSelector(state),
    auctionCardStyle: getAuctionCardStyle(state),
  }),
  { savePanelOffset, setIsScreenLocked }
)(App);

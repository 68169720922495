import React, { useState } from "react";
import { Button, Icon } from "antd";

import styles from "./SymbolsList.module.scss";
import { getRandomInt } from "../../lib/util";
import { minRandomCard, maxRandomCard } from "../../lib/constants";

import { SymbolTenorItem } from "./SymbolTenorItem";
import { SymbolsListProps, SymbolsTenorsType } from "./symbolsList.types";

const symbolsTenorsInitialState: SymbolsTenorsType[] = [
  {
    index: 0,
    symbol: "Select",
    symbolValue: "",
    tenor: "Spot",
    tenorValue: "",
    brokenDateValue: "",
  },
];

export function SymbolsList({ allSymbols, selectedSymbols, onOk, onCancel, config }: SymbolsListProps) {
  const { listHeight1, listHeight2, title, footerButtonSize, footerActionLabel, filterPlaceholder } = config || {};

  const [symbolsTenorsList, setSymbolsTenorsList] = useState<any>(symbolsTenorsInitialState);

  const handleOk = () => {
    const selectedSymbols = symbolsTenorsList.reduce(function(result, item) {
      // We are adding an id to the ccy pair so we can use multiple cards with the same ccy pair
      const id = getRandomInt(minRandomCard, maxRandomCard);

      if (item?.symbolValue) {
        if (item?.brokenDateValue) {
          result.push(`${item.symbol}-${item.brokenDateValue}_${id}`);
        } else if (item?.tenorValue && item?.tenorValue !== "brokenDate") {
          result.push(`${item.symbol}-${item.tenorValue}_${id}`);
        } else {
          result.push(`${item.symbol}_${id}`);
        }
      }

      return result;
    }, []);

    onOk(null, selectedSymbols);
  };

  const handleCancel = () => {
    onCancel();
  };

  const addRow = () => {
    // Add a blank row (with default values)to the state list. The index inside the object is to be able to delete rows in the future.
    const lastIndex = symbolsTenorsList[symbolsTenorsList.length - 1].index;
    setSymbolsTenorsList([
      ...symbolsTenorsList, //
      { index: lastIndex + 1, symbol: "Select", symbolValue: "", tenor: "Spot", tenorValue: "", brokenDateValue: "" },
    ]);
  };

  return (
    <>
      <div className={styles.header}>{title && <h2 className={styles.headerItem}>{title}</h2>}</div>

      <div className={styles.content}>
        <div className={styles.titles}>
          <h3 className={styles.title}>Currency Pair</h3>
          <h3 className={styles.title}>Tenor</h3>
        </div>

        {symbolsTenorsList.map(elementValues => (
          <SymbolTenorItem
            key={elementValues.index}
            allSymbols={allSymbols}
            selectedSymbols={selectedSymbols}
            config={{
              listHeight1: listHeight1,
              listHeight2: listHeight2,
              filterPlaceholder: filterPlaceholder,
            }}
            elementValues={elementValues}
            globalList={symbolsTenorsList}
            setGlobalList={setSymbolsTenorsList}
            //   deleteAction={deleteRow}
          ></SymbolTenorItem>
        ))}

        <Button className={styles.addRow} onClick={addRow}>
          <Icon type="plus-circle" />
          Add Currency Pair
        </Button>
      </div>

      <div className={styles.footer}>
        <Button className={styles.footerElement} onClick={handleCancel} size={footerButtonSize || "large"}>
          Cancel
        </Button>
        <Button
          className={styles.footerElement}
          size={footerButtonSize || "large"}
          type="primary"
          disabled={symbolsTenorsList.every(item => {
            return item.symbolValue === "";
          })}
          onClick={handleOk}
        >
          {footerActionLabel}
        </Button>
      </div>
    </>
  );
}

// actions

export const ACTIVE_TAKER_CHANGED = "ACTIVE_TAKER_CHANGED";
export const ACTIVE_TRADER_CHANGED = "ACTIVE_TRADER_CHANGED";
export const AUCTION_CARD_STYLE_SELECTED = "AUCTION_CARD_STYLE_SELECTED";
export const CURRENCY_PAIRS_SELECTED = "CURRENCY_PAIRS_SELECTED";
export const CURRENCY_PAIRS_TOGGLED = "CURRENCY_PAIRS_TOGGLED";
export const CURRENCY_PAIR_REMOVED = "CURRENCY_PAIR_REMOVED";
export const ICEBERG_QUANTITY_SELECTED = "ICEBERG_QUANTITY_SELECTED";
export const ICEBERG_SELECTED = "ICEBERG_SELECTED";
export const LOAD_PROFILE = "LOAD_PROFILE";
export const ON_CURRENCY_GROUP_ADD = "ON_CURRENCY_GROUP_ADD";
export const ON_CURRENCY_GROUP_DELETE = "ON_CURRENCY_GROUP_DELETE";
export const ON_CURRENCY_GROUP_MOVE = "ON_CURRENCY_GROUP_MOVE";
export const ON_CURRENCY_GROUP_RENAME = "ON_CURRENCY_GROUP_RENAME";
export const ON_CURRENCY_GROUP_SELECT = "ON_CURRENCY_GROUP_SELECT";
export const ORDER_LIFESPAN_SELECTED = "ORDER_LIFESPAN_SELECTED";
export const ORDER_QUANTITY_SELECTED = "ORDER_QUANTITY_SELECTED";
export const ORDER_TYPE_SELECTED = "ORDER_TYPE_SELECTED";
export const RESET_ALL_CARD_SIZES = "RESET_ALL_CARD_SIZES";
export const SAVE_CURRENCY_SETTINGS = "SAVE_CURRENCY_SETTINGS";
export const SAVE_PANEL_OFFSET = "SAVE_PANEL_OFFSET";
export const SAVE_SETTINGS = "settings/SAVE_SETTINGS";
export const SAVE_TABLE_SETTINGS = "SAVE_TABLE_SETTINGS";

export const loadProfileFromVertex = profileSettings => ({
  type: LOAD_PROFILE,
  payload: profileSettings,
});

export const resetAllCardSizes = () => ({
  type: RESET_ALL_CARD_SIZES,
});

export const savePanelOffset = (panelOffset: number) => ({
  type: SAVE_PANEL_OFFSET,
  payload: {
    panelOffset,
  },
});

export const saveTableSettings = (id: number, tableSettings: any) => ({
  type: SAVE_TABLE_SETTINGS,
  payload: {
    id,
    tableSettings,
  },
});

export const setIcebergQuantitySelected = (icebergQuantity: number) => ({
  type: ICEBERG_QUANTITY_SELECTED,
  payload: {
    icebergQuantity,
  },
});

export const setActiveTaker = (activeTaker: string) => ({
  type: ACTIVE_TAKER_CHANGED,
  payload: {
    activeTaker,
  },
});

export const setActiveTrader = (activeTrader: string) => ({
  type: ACTIVE_TRADER_CHANGED,
  payload: {
    activeTrader,
  },
});

export const setIcebergSelected = (useIceberg: string | null) => ({
  type: ICEBERG_SELECTED,
  payload: {
    useIceberg,
  },
});

export const setAuctionCardStyle = (auctionCardStyle: string) => ({
  type: AUCTION_CARD_STYLE_SELECTED,
  payload: {
    auctionCardStyle,
  },
});

export const orderQuantitySelected = (selectedDefaultQuantityIndex: string | null) => ({
  type: ORDER_QUANTITY_SELECTED,
  payload: {
    selectedDefaultQuantityIndex,
  },
});

export const orderLifespanSelected = (selectedDefaultOrderLifespan: string[]) => ({
  type: ORDER_LIFESPAN_SELECTED,
  payload: {
    selectedDefaultOrderLifespan,
  },
});

export const orderTypeSelected = (selectedDefaultOrderType: string) => ({
  type: ORDER_TYPE_SELECTED,
  payload: {
    selectedDefaultOrderType,
  },
});

export const onCurrencyGroupAdd = (groupId: string, name: string) => ({
  type: ON_CURRENCY_GROUP_ADD,
  payload: {
    groupId,
    name,
  },
});

export const onCurrencyGroupRename = (groupId: string, newName: string) => ({
  type: ON_CURRENCY_GROUP_RENAME,
  payload: {
    groupId,
    newName,
  },
});

export const onCurrencyGroupDelete = (groupId: string) => ({
  type: ON_CURRENCY_GROUP_DELETE,
  payload: {
    groupId,
  },
});

export const onCurrencyGroupSelect = (groupId: string) => ({
  type: ON_CURRENCY_GROUP_SELECT,
  payload: {
    groupId,
  },
});

export const onCurrencyGroupMove = (newIndex: number, oldIndex: number) => ({
  type: ON_CURRENCY_GROUP_MOVE,
  payload: {
    newIndex,
    oldIndex,
  },
});

export const currencyPairsToggled = ({ selectedCurrencyPairIds, selectedSymbolsInsertPosition, removedSymbol }) => ({
  type: CURRENCY_PAIRS_TOGGLED,
  payload: {
    selectedCurrencyPairIds,
    selectedSymbolsInsertPosition,
    removedSymbol,
  },
});

export const currencyPairsSelected = (selectedCurrencyPairIds: string[], selectedSymbolsInsertPosition) => ({
  type: CURRENCY_PAIRS_SELECTED,
  payload: {
    selectedCurrencyPairIds,
    selectedSymbolsInsertPosition,
  },
});

export const currencyPairRemoved = (symbol: string) => ({
  type: CURRENCY_PAIR_REMOVED,
  payload: {
    symbol,
  },
});

export const saveAllSettings = (allSettings: any) => ({
  type: SAVE_SETTINGS,
  payload: allSettings,
});

export const saveCurrencySettings = (currencySettings: any, symbol: string) => ({
  type: SAVE_CURRENCY_SETTINGS,
  payload: { currencySettings, symbol },
});

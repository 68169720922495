import { ofType } from "redux-observable";
import { EMPTY, merge, of } from "rxjs";

import { map, mergeMap, catchError, withLatestFrom } from "rxjs/operators";

import { CONNECTED, DISCONNECTED, APP_LOAD_FAIL } from "../connection/connectionState";
import { loadOrders, updateOrder } from "../orders/ordersState";
import { symbolsLoaded, currencyDataUpdate } from "../currency/currencyActions";
import { loadProfileFromVertex } from "../settings/settingsActions";
import { updateMargins } from "../margins/marginsState";
import { loadSymbolsPipsFromAssetServer } from "../currency/symbols/symbolsActions";

export const RESET_FLAGS_ON_DISCONNECT = "RESET_FLAGS_ON_DISCONNECT";
export const HANDLE_SETTINGS_LOADED = "HANDLE_SETTINGS_LOADED";
export const HANDLE_ORDERS_LOADED = "HANDLE_ORDERS_LOADED";
export const STORE_ORDERS_TILL_PROFILE_LOADS = "STORE_ORDERS_TILL_PROFILE_LOADS";

export const resetFlagsOnDisconnect = () => ({
  type: RESET_FLAGS_ON_DISCONNECT,
});

export const handleSettingsLoaded = () => ({
  type: HANDLE_SETTINGS_LOADED,
});

export const handleOrdersLoaded = () => ({
  type: HANDLE_ORDERS_LOADED,
});

export const storeOrdersUntilProfileLoads = orders => ({
  type: STORE_ORDERS_TILL_PROFILE_LOADS,
  payload: orders,
});

const initState = {
  settingsLoaded: false,
  ordersLoaded: false,
  unprocessedOrders: null,
};

export const messagesReducer = (state = initState, action) => {
  switch (action.type) {
    case RESET_FLAGS_ON_DISCONNECT: {
      return { ...state, settingsLoaded: false, ordersLoaded: false };
    }
    case HANDLE_SETTINGS_LOADED: {
      return { ...state, settingsLoaded: true };
    }
    case HANDLE_ORDERS_LOADED: {
      return { ...state, ordersLoaded: true };
    }
    case STORE_ORDERS_TILL_PROFILE_LOADS: {
      return { ...state, unprocessedOrders: action.payload };
    }
    default:
      return state;
  }
};

/**
 * Handles incoming messages from the webSocketService and
 * passes them to the appropriate actions based on message type
 */

export const messagesEpic = (action$, state$) =>
  action$.pipe(
    ofType(CONNECTED),
    mergeMap((action: any) => {
      return action.messages$.pipe(
        map((payload: any) => {
          switch (payload.type) {
            case "symbols": {
              return symbolsLoaded(payload.data);
            }
            case "symbolsPips": {
              return loadSymbolsPipsFromAssetServer();
            }
            case "currencyPairs": {
              const data = payload.data && payload.data.currencyPairs;
              return currencyDataUpdate(data);
            }
            case "margin": {
              const data = payload.data;
              return updateMargins(data);
            }
            case "orders": {
              const ordersLoaded: boolean = state$.value.messages.ordersLoaded;
              // need profile settings to properly load and mark orders
              if (state$.value.messages.settingsLoaded) {
                if (!ordersLoaded) {
                  return loadOrders(payload);
                } else {
                  const data = payload && payload.data && payload.data.length > 0 ? payload.data[0] : null;
                  return updateOrder(data, state$.value.settings.ordersReadOnly);
                }
              } else {
                return storeOrdersUntilProfileLoads(payload.data);
              }
            }
            case "profile": {
              if (!state$.value.messages.settingsLoaded) {
                try {
                  const settings = payload.data.profile;

                  return loadProfileFromVertex(settings);
                } catch (err) {
                  console.log("error saving settings to local state");
                  
                }
              }
            }
            default:
              // console.info("unhandled server message: ", payload);
              return {
                type: "DATA_STREAM_MESSAGE",
                payload,
              };
          }
        }),
        catchError((err: any) => {
          console.log("error", err);
          if (err && err.code !== 1008) {
            return merge(of(resetFlagsOnDisconnect()).pipe(), of({ type: DISCONNECTED }));
          }
          return merge(of(resetFlagsOnDisconnect()).pipe(), of({ type: APP_LOAD_FAIL }));
        })
      );
    })
  );

export const ordersMessageEpic = (action$, state$) =>
  action$.pipe(
    ofType(HANDLE_SETTINGS_LOADED),
    withLatestFrom(state$),
    mergeMap((action: any) => {
      if (
        state$.value.messages.settingsLoaded &&
        !state$.value.messages.ordersLoaded &&
        state$.value.messages.unprocessedOrders
      ) {
        const orders = state$.value.messages.unprocessedOrders;
        // investigate how orders load from vertex. why nested as action.payload.data.data?
        return [loadOrders({ data: orders })];
      } else {
        return EMPTY;
      }
    })
  );

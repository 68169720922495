import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import styles from "./ConnectionIndicator.module.scss";
import { CONNECTION_FAILED, CONNECTING, CONNECTED } from "../../state/connection/connectionState";

const Disconnected = () => (
  <svg width="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 12.4434C19.8094 12.2647 20.1906 12.2647 20.5 12.4434L26.2942 15.7887C26.6036 15.9673 26.7942 16.2974 26.7942 16.6547V23.3453C26.7942 23.7026 26.6036 24.0327 26.2942 24.2113L20.5 27.5566C20.1906 27.7353 19.8094 27.7353 19.5 27.5566L13.7058 24.2113C13.3964 24.0327 13.2058 23.7026 13.2058 23.3453V16.6547C13.2058 16.2974 13.3964 15.9673 13.7058 15.7887L19.5 12.4434Z"
      stroke="#FF6768"
      strokeWidth="2"
    />
    <path d="M20 20V17" stroke="#FF6768" strokeWidth="2" strokeLinecap="round" />
    <rect x="19" y="22" width="2" height="2" rx="1" fill="#FF6768" />
  </svg>
);

const ConnectionIndicator = (props: any) => {
  return (
    <div className={styles.connectionIndicator}>
      {props.connectionState && props.connectionState.status === CONNECTION_FAILED && <Disconnected />}
      {props.connectionState && props.connectionState.status === CONNECTED && (
        <div className={clsx(styles.iconConnection, styles.iconConnectionGood)}></div>
      )}
      {props.connectionState && props.connectionState.status === CONNECTING && (
        <div className={clsx(styles.iconConnection, styles.iconConnectionReconnect)}></div>
      )}
    </div>
  );
};

export default connect((state: any) => ({ connectionState: state.connectionState }))(ConnectionIndicator);

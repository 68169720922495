import React, { useEffect } from "react";
import { Button, Dropdown } from "antd";

import clsx from "clsx";
import styles from "./OrdersPanel.module.scss";
import { OrderStrategy, OrderType } from "../../state/orders/types";
import { capitalizeFirstLetter } from "../../lib/formatUtil";
import OrderProgressBar from "./OrderProgressBar";

import { ReactComponent as IconCancel } from "../../assets/images/CancelIcon-20.svg";
import OrderStatusLabel from "../../shared/OrderStatusLabel";
import { orderTypeOptions } from "../currency/AuctionOrderForm/selectOptions";
import { OrderStatus } from "../../state/orders/types";
import { decimalToPriceObjectConverterBySymbol } from "../../lib/util";

const OrderDetail = ({
  orderData,
  isModalOrder,
  typeName,
  priceName,
  stopLossPriceName,
  sideOption,
  quantityFilledName,
  statusName,
  showOrderDetails,
  showAuctionCard,
  orderStatusOverrides,
  isOrderStatusClosed,
  isUnCancelableStrategy,
  isInFlight,
  readOnly,
  cancelOrder,
  renderMenu,
  disableActions,
}) => {
  const stopLossPriceObj = decimalToPriceObjectConverterBySymbol(
    orderData?.[stopLossPriceName],
    orderData.instrumentId
  );
  const limitPriceObj = decimalToPriceObjectConverterBySymbol(orderData?.[priceName], orderData.instrumentId);

  return (
    <>
      <div className={clsx(!isModalOrder && styles.orderDetails, isModalOrder && styles.orderTitleModal)}>
        <span className={styles.modalTitle}>
          {orderData.strategy !== OrderStrategy.Common && isModalOrder
            ? `${capitalizeFirstLetter(orderData?.[typeName])} `
            : ""}
          {sideOption
            ? `${sideOption.label} ${
                orderData?.[typeName] === OrderType.Oco
                  ? orderTypeOptions.find(item => item.value === orderData?.[typeName])?.label
                  : ""
              }`
            : " "}{" "}
          @{" "}
          {orderData?.[typeName] === OrderType.Market ? (
            "0"
          ) : orderData?.[typeName] === OrderType.Stop ? (
            // orderData?.[stopLossPriceName]
            <>
              <span>{stopLossPriceObj.basePrice}</span>
              {stopLossPriceObj?.varBig && (
                <>
                  <span className={styles.bigNum}>{stopLossPriceObj.varBig}</span>
                  <span>{stopLossPriceObj.varSm}</span>
                </>
              )}
            </>
          ) : orderData?.[typeName] === OrderType.StopLimit ? (
            // `${orderData?.[stopLossPriceName]} @ ${orderData?.[priceName]}`
            <>
              <span>{stopLossPriceObj.basePrice}</span>
              {stopLossPriceObj?.varBig && (
                <>
                  <span className={styles.bigNum}>{stopLossPriceObj.varBig}</span>
                  <span>{stopLossPriceObj.varSm}</span>
                </>
              )}{" "}
              @ <span>{limitPriceObj.basePrice}</span>
              {limitPriceObj?.varBig && (
                <>
                  <span className={styles.bigNum}>{limitPriceObj.varBig}</span>
                  <span>{limitPriceObj.varSm}</span>
                </>
              )}
            </>
          ) : (
            // orderData?.[priceName]
            <>
              <span>{limitPriceObj.basePrice}</span>
              {limitPriceObj?.varBig && (
                <>
                  <span className={styles.bigNum}>{limitPriceObj.varBig}</span>
                  <span>{limitPriceObj.varSm}</span>
                </>
              )}
            </>
          )}
          {isModalOrder ? "" : ` / ${orderData?.takerName}`}
        </span>

        {isModalOrder && !disableActions && (
          <div className={styles.orderActions}>
            {isOrderStatusClosed || isUnCancelableStrategy || isInFlight || readOnly ? (
              <Button size="small" icon="stop" disabled={true} />
            ) : (
              <div className={styles.cancelButton} onClick={() => cancelOrder(orderData.id)}>
                <IconCancel className={styles.orderActionIcon} />
              </div>
            )}

            <Dropdown
              placement="bottomRight"
              overlay={renderMenu}
              trigger={["click"]}
              disabled={isOrderStatusClosed || isUnCancelableStrategy || isInFlight}
            >
              <Button size="small" icon="more" className={styles.orderActionIcon} />
            </Dropdown>
          </div>
        )}
      </div>

      <div className={styles.statusRow}>
        <OrderProgressBar quantity={orderData?.quantity} quantityFilled={orderData?.[quantityFilledName]} />
        <div
          className={clsx(styles.orderStatusBox, showOrderDetails && styles.orderStatusClickable)}
          onClick={() => {
            if (showOrderDetails) {
              showAuctionCard(orderData);
            }
          }}
        >
          <OrderStatusLabel
            status={orderData?.[statusName]}
            type="pill"
            labelOverride={orderStatusOverrides[orderData?.[statusName]]}
          />
        </div>
      </div>
    </>
  );
};

export default OrderDetail;

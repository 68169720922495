import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import memoize from 'proxy-memoize';

import { State } from './Notifications.types';
import { VisibleNotifications } from './VisibleNotifications';
import { useNotifications } from './useNotifications';
import { connect } from 'react-redux';
import { getNotificationSettings } from '../../state/settings/settingsState';

const getVisibleNotifications = memoize((state: State) => {
  return state.filter(({ isVisible }) => isVisible);
});

/**
 * Provider to expose notifications context state to any child
 */
const _Notifications = ({ isEnabledStored }) => {
  const { current: portalRef } = useRef(document.createElement('div'));
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  useEffect(() => {
    setIsEnabled(isEnabledStored);
  }, [isEnabledStored]);

  const { state, toggleEnabled } = useNotifications({ isEnabled });

  // Attach portal target to body on mount, and remove it on un-mount
  useEffect(() => {
    portalRef.classList.add('__suiNotificationsPortal');
    document.body.appendChild(portalRef);
    return () => {
      document.body.removeChild(portalRef);
    };
  }, [portalRef]);

  // Toggle notifications based on profile settings.
  useEffect(() => {
    toggleEnabled(isEnabled);
  }, [isEnabled, toggleEnabled]);

  const visibleNotifications = getVisibleNotifications(state);

  return ReactDOM.createPortal(<VisibleNotifications items={visibleNotifications} />, portalRef);
};

export const Notifications = connect((state: any) => {
  const { showOrderAcknowlegedBanner } = getNotificationSettings(state);
  return { isEnabledStored: showOrderAcknowlegedBanner };
})(_Notifications);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import clsx from "clsx";
import styles from "./BlockingOverlay.module.scss";

import Loader from "../../shared/Loader";
import {
  openConnection,
  APP_IS_LOADING,
  APP_LOAD_FAIL,
  CONNECTION_FAILED,
  CONNECTING,
  DISCONNECTED,
  APP_LOAD_SUCCESS,
} from "../../state/connection/connectionState";
import { IS_AUTH_ENABLED, SSO_URL, IDENTITY_SERVER_URL, REALM } from "../../environment";
import moment from "moment";
import { updateUserInfo } from "../../state/app/appState";

const LoadingMessage = () => {
  return (
    <div className={styles.loadingMessage}>
      <Loader size="large" />
      <div className={styles.loadingText}>Loading...</div>
    </div>
  );
};

const Preloader = ({ connectionState, openConnection, loggedIn, errorMessage, logout, getProfile }) => {
  const isAppLoading = connectionState.appLoadingState === APP_IS_LOADING;
  const appLoadFailed = connectionState.appLoadingState === APP_LOAD_FAIL;
  const isPreloaderVisible = isAppLoading || appLoadFailed;

  useEffect(() => {
    if (IS_AUTH_ENABLED) {
      (async () => {
        if (loggedIn) {
          openConnection(true);
        }
      })();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn && connectionState.appLoadingState === APP_LOAD_SUCCESS) {
      getProfile().then(
        profile => {
          const simplifiedProfile = {
            userId: profile.username,
            userName: profile.username,
          };

          updateUserInfo(simplifiedProfile);
        },
        e => console.error("Error getting profile", e)
      );
    }
  }, [loggedIn, connectionState.appLoadingState]);

  return (
    <BlockingOverlay isVisible={isPreloaderVisible}>
      {isAppLoading && <LoadingMessage />}
      {appLoadFailed && <ConnectionErrorMessage reconnect={openConnection} />}
    </BlockingOverlay>
  );
};

const ConnectionErrorMessage = ({ reconnect }) => {
  return (
    <div className={styles.error}>
      <h4>An Error Occurred</h4>
      <p>
        We experienced an issue attempting to sign you in. Please ensure you have a stable internet connection and try a
        reconnect below.
      </p>
      <p>If the issue persists after a reconnect attempt, please contact support.</p>
      <Button type="primary" onClick={() => reconnect(true)}>
        Reconnect
      </Button>
    </div>
  );
};

const BlockingOverlay = (props: { isVisible: boolean; children: any }) => {
  return <div className={clsx(styles.blockingOverlay, !props.isVisible && styles.hide)}>{props.children}</div>;
};

export const ConnectionBannerView = ({ connectionState, openConnection }) => {
  const isAppLoaded = connectionState.appLoadingState !== APP_IS_LOADING;
  const isConnecting = connectionState.status === CONNECTING;
  const isDisconnected = connectionState.status === DISCONNECTED;
  const connectionFailed = connectionState.status === CONNECTION_FAILED;
  const isBannerVisible = isAppLoaded && (isDisconnected || isConnecting || connectionFailed);
  if (!isBannerVisible) {
    return null;
  }
  return (
    <div className={styles.alertContainer}>
      <div className={styles.alertOverlay} />
      <div className={styles.alertContent}>
        {isConnecting && (
          <div className={clsx(styles.alert, styles.alertWarning)}>
            <div>Your connection has been interrupted. {connectionState.message || "Reconnecting..."} </div>
          </div>
        )}
        {isDisconnected && (
          <div className={clsx(styles.alert, styles.alertWarning)}>
            <div>Your connection has been interrupted. Reconnecting you now...</div>
          </div>
        )}
        {connectionFailed && (
          <div className={clsx(styles.alert, styles.alertError)}>
            <div>Your connection has been interrupted and reconnect has failed.</div>
            <div className={styles.alertAction}>
              <Button className="themeDark" onClick={() => openConnection(false)}>
                Reconnect
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const ConnectionBanner = connect(
  (state: any) => ({
    connectionState: state.connectionState,
  }),
  { openConnection }
)(ConnectionBannerView);

export default connect(
  (state: any) => ({
    connectionState: state.connectionState,
  }),
  { openConnection, updateUserInfo }
)(Preloader);

import React from "react";
import clsx from "clsx";

import styles from "./Loader.module.scss";

type Props = {
  size?: "small" | "medium" | "large";
};

function Loader({ size = "medium" }: Props) {
  return (
    <div
      className={clsx(
        styles.frame,
        size === "small" && styles.frameSmall,
        size === "medium" && styles.frameMedium,
        size === "large" && styles.frameLarge,
      )}
    >
      <div className={styles.shape}>
        <div className={styles.scan}></div>
        <div className={styles.fill}></div>
      </div>
    </div>
  );
}

export default Loader;

import * as constants from "./currencyActions";

import { ccyPairIdStripper } from "../../lib/formatUtil";

// reducer
export type CurrencyGroupsState = {
  currencyPairs: any;
  auctionCardDetail: constants.AuctionCardDetail | null;
  undockedCards: string[];
  currentPrice: number;
};

const currencyPairs = {};

const initialState = {
  currencyPairs,
  auctionCardDetail: null,
  undockedCards: [],
  currentPrice: 0,
};

const currencyGroupsReducer = (state: CurrencyGroupsState = initialState, action: any) => {
  switch (action.type) {
    case constants.CURRENCY_DATA_UPDATE: {
      return {
        ...state,
        currencyPairs: action.payload,
      };
    }
    case constants.SET_CURRENT_PRICE:
      return {
        ...state,
        currentPrice: action.payload,
      };
    case constants.SHOW_AUCTION_CARD:
      return {
        ...state,
        auctionCardDetail: action.payload,
      };
    case constants.DELETE_CURRENCY_DATA: {
      return {
        ...state,
        currencyPairs: {},
      };
    }
    case constants.CARD_UNDOCKED: {
      return {
        ...state,
        undockedCards: state.undockedCards.concat(action.payload),
      };
    }
    case constants.DOCK_CARD: {
      const symbol = action.payload;

      if (window["externalPanels"] && window["externalPanels"][symbol]) {
        window["externalPanels"][symbol].close();
        delete window["externalPanels"][symbol];
      }

      const updatedCardList = state.undockedCards.filter(cardSymbol => {
        return cardSymbol !== symbol;
      });
      return {
        ...state,
        undockedCards: updatedCardList,
      };
    }
    case constants.CLOSE_UNDOCKED_CARDS: {
      state.undockedCards.forEach(cardSymbol => {
        if (window["externalPanels"] && window["externalPanels"][cardSymbol]) {
          window["externalPanels"][cardSymbol].close();
        }
      });
      return {
        ...state,
        undockedCards: [],
      };
    }
    default:
      return state;
  }
};

export default currencyGroupsReducer;

// selectors

export const currencyPairByIdSelector = (state, symbol) => {

  // We are removing the added id to the symbol
  const symbolWithoutId = ccyPairIdStripper(symbol);

  return state.currencyGroups.currencyPairs && state.currencyGroups.currencyPairs[symbolWithoutId];
  // return state.currencyGroups.currencyPairs && state.currencyGroups.currencyPairs[id];
};

export const getAuctionCardDetailSelector = state => state.currencyGroups.auctionCardDetail;
export const getAuctionCardDetailByIdSelector = (state, instrumentId) => {
  if (state.currencyGroups.auctionCardDetail) {
    return state.currencyGroups.auctionCardDetail.instrumentId === instrumentId
      ? state.currencyGroups.auctionCardDetail
      : null;
  } else {
    return null;
  }
};

export const getCurrentPrice = state => state.currentPrice;

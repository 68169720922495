import { isEqual, merge } from "lodash";
import { ndfInstrumentStripper } from "../../lib/formatUtil";
import { DefaultAggressiveOrderStrategy, orderStrategyOptions } from "../../lib/settings/constantsSettings";
import { OrderLifespan, OrderStatus, OrderType, OrderStrategy } from "../orders/types";
import {
  SAVE_TABLE_SETTINGS,
  SAVE_CURRENCY_SETTINGS,
  AUCTION_CARD_STYLE_SELECTED,
  ICEBERG_SELECTED,
  ACTIVE_TAKER_CHANGED,
  ACTIVE_TRADER_CHANGED,
  ICEBERG_QUANTITY_SELECTED,
  ORDER_QUANTITY_SELECTED,
  ORDER_LIFESPAN_SELECTED,
  ORDER_TYPE_SELECTED,
  RESET_ALL_CARD_SIZES,
  SAVE_PANEL_OFFSET,
  LOAD_PROFILE,
  SAVE_SETTINGS,
  CURRENCY_PAIRS_TOGGLED,
  CURRENCY_PAIRS_SELECTED,
  CURRENCY_PAIR_REMOVED,
  ON_CURRENCY_GROUP_SELECT,
  ON_CURRENCY_GROUP_MOVE,
  ON_CURRENCY_GROUP_ADD,
  ON_CURRENCY_GROUP_RENAME,
  ON_CURRENCY_GROUP_DELETE,
} from "./settingsActions";

// types
export interface CurrencyGroup {
  id: string;
  displayName: string;
  symbols: string[];
}

export enum OrderDelayValues {
  Now = "now",
  Time = "time",
}

export enum ClickType {
  Single = "single",
  Double = "double",
}

export enum SpreadValue {
  Pip = "pip",
  Decimal = "decimal",
}

export enum AuctionCardStyleType {
  Classic = "classic",
  Algo = "algo",
}

export enum DisplayOrdersByTimeType {
  today = "today",
  all = "all",
}

export enum CUSTOM_SIZE_UNIT_OPTIONS {
  U = "unit",
  K = "K",
  M = "M",
  B = "B",
}

export enum CardSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export const CUSTOM_SIZE_UNIT_OPTIONS_LIST = [
  CUSTOM_SIZE_UNIT_OPTIONS.U,
  CUSTOM_SIZE_UNIT_OPTIONS.K,
  CUSTOM_SIZE_UNIT_OPTIONS.M,
  CUSTOM_SIZE_UNIT_OPTIONS.B,
];

// functions
const createNewCurrencyGroup = (id: string, displayName: string) => {
  return {
    id,
    displayName,
    symbols: [],
  };
};

const moveSymbol = (symbols, newIndex, oldIndex) => {
  const temp = symbols[newIndex];
  symbols[newIndex] = symbols[oldIndex];
  symbols[oldIndex] = temp;
  return symbols;
};

export const getOrderSizeQuantity = orderSize => {
  if (!orderSize.customSize) {
    return 0;
  } else {
    return orderSize.customSize * 1000;
  }
};

// state
const currencyGroups = [
  {
    id: "spot",
    displayName: "Spot",
    symbols: [],
  },
];

const initialState = {
  blotterSettings: {
    showBlotter: false,
    showAggregatedPnl: false,
    showOHLC: false,
    showTicker: false,
    orderTypes: "executions",
    displayOrdersByTime: DisplayOrdersByTimeType.today,
  },
  marketSettings: {
    cardSize: CardSize.Small,
    clickType: ClickType.Double,
    defaultWidget: "ccypair",
    depthDisplay: 0,
    displaySpreadOnDepthOfBook: true,
    enableDepthOfBookTrading: true,
    enablePriceFiltering: true,
    getAllPriceLevels: true,
    notional: 1000000,
    showPriceTrendAndSpread: true,
    showSizeAbbreviation: true,
    showSlippage: true,
    slippageLeftIncrement: "1.0",
    slippageRightIncrement: "0.1",
    spreadValue: SpreadValue.Pip,
  },
  notificationSettings: {
    filledOrders: true,
    ignoreFollowingFilledInSeconds: 0,
    cancelOrRejectedOrders: true,
    ignoreFollowingCancelOrRejectedInSeconds: 0,
    showOrderAcknowlegedBanner: false,
    displayOrderAcknowlegedBannerInSeconds: 0,
  },
  ordersSettings: {
    showOrders: true,
    confirmCancelation: true,
    ordersListOrder: "recent",
    removeCancelled: OrderDelayValues.Now,
    removeCancelledTime: 0,
    removeFilled: OrderDelayValues.Time,
    removeFilledTime: 3,
    marketOrdersEnabled: false,
    // HERE 
    priceSlippageSeparated: false,
    defaultPassiveOrderType: OrderType.Limit,
    defaultPassiveOrderLifespan: OrderLifespan.Day,
    defaultAggressiveOrderStrategy: OrderStrategy.Common,
    defaultAggressiveOrderLifespan: OrderLifespan.Day,
  },
  orderStatusesSettings: {
    statusOverrides: {
      [OrderStatus.Canceling]: null,
      [OrderStatus.Filled]: null,
      [OrderStatus.PartialCancel]: null,
      [OrderStatus.PartialFill]: null,
      [OrderStatus.Pending]: null,
      [OrderStatus.Rejected]: null,
      [OrderStatus.Replacing]: null,
      [OrderStatus.UnsolCancel]: null,
      [OrderStatus.UnsolPartial]: null,
      [OrderStatus.UserCancel]: null,
      [OrderStatus.Working]: null,
    },
  },
  orderRibbonSettings: {
    customSizes: [
      { customSize: "5", multiplier: CUSTOM_SIZE_UNIT_OPTIONS.M },
      { customSize: "10", multiplier: CUSTOM_SIZE_UNIT_OPTIONS.M },
      { customSize: "15", multiplier: CUSTOM_SIZE_UNIT_OPTIONS.M },
      { customSize: "30", multiplier: CUSTOM_SIZE_UNIT_OPTIONS.M },
      { customSize: "60", multiplier: CUSTOM_SIZE_UNIT_OPTIONS.M },
    ],
    hideIceberg: false,
    icebergQuantity: 1000000,
    onBehalfOfButtons: [
      { index: 0, value: "none" },
      { index: 1, value: "none" },
      { index: 2, value: "none" },
    ],
    orderSizes: ["5", "10", "15", "30", "60"],
    selectedDefaultOrderType: OrderType.Market,
    selectedDefaultOrderLifespan: null,
    selectedDefaultQuantityIndex: null,
    showOnBehalfOfButtons: true,
    showOrderRibbon: true,
    useIceberg: [], // populates as ["Iceberg"] if enabled, due to how radiogroup works
  },
  analyticsSettings: {
    panelOffset: 0,
    tabSettings: [
      { id: 0, name: "Blotter", type: "blotter" },
      { id: 1, name: "Aggregated Pnl", type: "aggregatedPNL" },
      { id: 2, name: "Individual Pnl", type: "individualPNL" },
      { id: 3, name: "Ticker", type: "ticker" },
    ],
  },
  currencySettings: {
    // example data
    // "AUD/USD": {
    //   currencySelected: "AUD",
    //   orderTypeSelected: OrderType.Limit,
    //   slippage: 0.04
    //   flywheelIncrement: 0.1
    //   hotButtonIncrement: 0.2
    // }
  },
  currencyGroups,
  selectedCurrencyGroupId: currencyGroups[0] && currencyGroups[0].id,
  auctionCardStyle: AuctionCardStyleType.Classic,
  availableTakers: [],
  activeTaker: "",
  traders: [],
  cantorAlgoEnabled: false,
  ordersReadOnly: true,
};

const removeCurrencyPairs = (currencyGroups, selectedCurrencyGroupId, currencySettings, removedSymbol) => {
  let allUsersSymbols: string[] = [];
  // add new currency pairs to active group
  const newCurrencyGroups = currencyGroups.map(group => {
    allUsersSymbols = allUsersSymbols.concat(group.symbols);
    return group.id === selectedCurrencyGroupId
      ? {
          ...group,
          symbols: group.symbols.filter(existingSymbol => existingSymbol !== removedSymbol),
        }
      : group;
  });
  const newCurrencySettings = { ...currencySettings };
  if (allUsersSymbols.indexOf(removedSymbol) === allUsersSymbols.lastIndexOf(removedSymbol)) {
    delete newCurrencySettings[removedSymbol];
  }
  return { currencyGroups: newCurrencyGroups, currencySettings: newCurrencySettings };
};

const addCurrencyPairs = (
  currencyGroups,
  selectedCurrencyGroupId,
  currencySettings,
  selectedSymbols,
  selectedSymbolsInsertPosition,
  quantity // this is needed to set the default quantity for new pairs
) => {
  // add new currency pairs to active group
  const newCurrencyGroups = currencyGroups.map(group => {
    if (group.id === selectedCurrencyGroupId) {
      const symbols: any = [...group.symbols];
      selectedSymbolsInsertPosition =
        selectedSymbolsInsertPosition || selectedSymbolsInsertPosition === 0
          ? selectedSymbolsInsertPosition
          : symbols.length;
      symbols.splice.apply(symbols, [selectedSymbolsInsertPosition, 0].concat(selectedSymbols));
      return {
        ...group,
        // We are using a set since we don't want repeated ccy pairs in the curency grid
        symbols: Array.from(new Set(symbols)),
      };
    } else {
      return group;
    }
  });
  const newCurrencySettings = { ...currencySettings };

  selectedSymbols.forEach(symbol => {
    if (!newCurrencySettings[symbol]) {
      newCurrencySettings[symbol] = {
        currencySelected: ndfInstrumentStripper(symbol).split("/")[0],
        orderTypeSelected: OrderType.Limit,
        slippage: 0,
        flywheelIncrement: 0,
        hotButtonIncrement: 0,
        quantity,
      };
    }
  });
  return { currencyGroups: newCurrencyGroups, currencySettings: newCurrencySettings };
};

// reducers
const settings = (state = initialState, action: any) => {
  switch (action.type) {
    case SAVE_TABLE_SETTINGS: {
      const prevAnalyticSettings = state.analyticsSettings;
      const idToUpdate = action.payload.id;
      const tabSettings = prevAnalyticSettings.tabSettings.map(tab =>
        tab.id === idToUpdate ? { ...tab, tableSettings: action.payload.tableSettings } : tab
      );
      return { ...state, analyticsSettings: { panelOffset: state.analyticsSettings.panelOffset, tabSettings } };
    }
    case SAVE_CURRENCY_SETTINGS: {
      const currencySettings = state.currencySettings;
      currencySettings[action.payload.symbol] = {
        ...currencySettings[action.payload.symbol],
        ...action.payload.currencySettings,
      };
      return {
        ...state,
        currencySettings,
      };
    }
    case AUCTION_CARD_STYLE_SELECTED: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ICEBERG_SELECTED: {
      return {
        ...state,
        orderRibbonSettings: { ...state.orderRibbonSettings, ...action.payload },
      };
    }
    case ACTIVE_TAKER_CHANGED: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ACTIVE_TRADER_CHANGED: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ICEBERG_QUANTITY_SELECTED: {
      return {
        ...state,
        orderRibbonSettings: { ...state.orderRibbonSettings, ...action.payload },
      };
    }
    case ORDER_QUANTITY_SELECTED: {
      return {
        ...state,
        orderRibbonSettings: { ...state.orderRibbonSettings, ...action.payload },
      };
    }
    case ORDER_LIFESPAN_SELECTED: {
      return {
        ...state,
        orderRibbonSettings: { ...state.orderRibbonSettings, ...action.payload },
      };
    }
    case ORDER_TYPE_SELECTED: {
      return {
        ...state,
        orderRibbonSettings: { ...state.orderRibbonSettings, ...action.payload },
      };
    }
    case RESET_ALL_CARD_SIZES: {
      const updatedCurrencies = {};
      Object.keys(state.currencySettings).forEach(currency => {
        updatedCurrencies[currency] = { ...state.currencySettings[currency], quantity: state.marketSettings.notional };
      });

      return {
        ...state,
        currencySettings: updatedCurrencies,
      };
    }
    case SAVE_PANEL_OFFSET: {
      return {
        ...state,
        analyticsSettings: { ...action.payload, tabSettings: state.analyticsSettings.tabSettings },
      };
    }

    // LOAD_PROFILE is saving in the redux store all the profile info grabbed from the DB
    case LOAD_PROFILE: {
      // loads profile from vertex
      const profile = action.payload;
      let currencySettings = {};
      const currencyPairs = new Set();
      profile.currencyGroups.forEach(currencyGroup => {
        currencyGroup.symbols.forEach(symbol => {
          currencyPairs.add(symbol);
        });
      });
      // clone orderSizes settings into customSizes if customSizes prop not set
      // TODO1 will be removed with refactor of orderSizes > customSizes
      if (profile.orderRibbonSettings?.orderSizes) {
        if (!profile.orderRibbonSettings.customSizes) {
          profile.orderRibbonSettings.customSizes = profile.orderRibbonSettings.orderSizes.map(orderSize => {
            return { multiplier: CUSTOM_SIZE_UNIT_OPTIONS.M, customSize: orderSize };
          });
        }
      }
      // if currency settings does not exist or is missing settings for some pairs
      if (!profile.currencySettings || Object.keys(profile.currencySettings).length !== currencyPairs.size) {
        currencyPairs.forEach((currencyPair: any) => {
          // don't overwrite existing settings
          if (!profile.currencySettings || !profile.currencySettings[currencyPair]) {
            currencySettings[currencyPair] = {
              currencySelected: currencyPair.split("/")[0],
              orderTypeSelected: OrderType.Limit,
              slippage: 0,
              flywheelIncrement: 0,
              hotButtonIncrement: 0,
              quantity: state.marketSettings.notional,
            };
          } else {
            currencySettings[currencyPair] = profile.currencySettings[currencyPair];
          }
        });
      } else {
        currencySettings = { ...profile.currencySettings };
      }

      // Taking care of a potential inconsistency in the data: CantorAlgo users cannot access the Classic Auction Form and non-CantorAlgo users cannot access the Cantor Algo form
      if (profile.cantorAlgoEnabled) {
        profile.auctionCardStyle = AuctionCardStyleType.Algo;

        if (!profile.ordersSettings.defaultAggressiveOrderStrategy) {
          profile.ordersSettings.defaultAggressiveOrderStrategy = DefaultAggressiveOrderStrategy.cagoUser;
        }
        // Standard user
      } else {
        profile.auctionCardStyle = AuctionCardStyleType.Classic;

        if (!profile.ordersSettings.defaultAggressiveOrderStrategy) {
          profile.ordersSettings.defaultAggressiveOrderStrategy = DefaultAggressiveOrderStrategy.standardUser;
        }
      }

      return merge({}, state, { ...profile, currencySettings });
    }

    case SAVE_SETTINGS: {
      // the only thing calling this should be the settings panel
      const stateUpdate = action.payload;
      const isEqualState = isEqual(state, stateUpdate);

      if (!isEqualState) {
        return merge({}, state, stateUpdate);
      } else {
        return state;
      }
    }
    case CURRENCY_PAIRS_TOGGLED: {
      const { currencyGroups, selectedCurrencyGroupId, currencySettings } = state;
      const { removedSymbol, selectedSymbolsInsertPosition, selectedCurrencyPairIds } = action.payload || {};
      const newCurrencyPairs = removeCurrencyPairs(
        currencyGroups,
        selectedCurrencyGroupId,
        currencySettings,
        removedSymbol
      );
      const currencyGroupsAfterRemove = newCurrencyPairs.currencyGroups || {};
      const currencySettingsAfterRemove = newCurrencyPairs.currencySettings || {};
      const result = addCurrencyPairs(
        currencyGroupsAfterRemove,
        selectedCurrencyGroupId,
        currencySettingsAfterRemove,
        selectedCurrencyPairIds,
        selectedSymbolsInsertPosition,
        null
      );
      return { ...state, ...result };
    }
    case CURRENCY_PAIRS_SELECTED: {
      const { currencyGroups, selectedCurrencyGroupId, currencySettings } = state;
      const { selectedSymbolsInsertPosition, selectedCurrencyPairIds } = action.payload || {};
      const result = addCurrencyPairs(
        currencyGroups,
        selectedCurrencyGroupId,
        currencySettings,
        selectedCurrencyPairIds,
        selectedSymbolsInsertPosition,
        state.marketSettings.notional
      );
      return { ...state, ...result };
    }

    case CURRENCY_PAIR_REMOVED: {
      const { currencyGroups, selectedCurrencyGroupId, currencySettings } = state;
      const { symbol } = action.payload || {};
      const result = removeCurrencyPairs(currencyGroups, selectedCurrencyGroupId, currencySettings, symbol);
      return { ...state, ...result };
    }
    case ON_CURRENCY_GROUP_SELECT: {
      const { groupId } = action.payload;
      return {
        ...state,
        currencyGroups: state.currencyGroups.map(currencyGroup => ({
          ...currencyGroup,
        })),
        selectedCurrencyGroupId: groupId,
      };
    }
    case ON_CURRENCY_GROUP_MOVE: {
      const { newIndex, oldIndex } = action.payload;
      const currencyGroups = state.currencyGroups.map(group =>
        group.id === state.selectedCurrencyGroupId
          ? {
              ...group,
              symbols: moveSymbol([...group.symbols], newIndex, oldIndex),
            }
          : group
      );
      return {
        ...state,
        currencyGroups,
      };
    }
    case ON_CURRENCY_GROUP_ADD: {
      const { groupId, name } = action.payload;
      return {
        ...state,
        currencyGroups: [...state.currencyGroups, createNewCurrencyGroup(groupId, name)],
      };
    }
    case ON_CURRENCY_GROUP_RENAME: {
      const { groupId, newName } = action.payload;
      return {
        ...state,
        currencyGroups: state.currencyGroups.map(currencyGroup =>
          currencyGroup.id === groupId ? { ...currencyGroup, displayName: newName } : currencyGroup
        ),
      };
    }

    case ON_CURRENCY_GROUP_DELETE: {
      const { groupId } = action.payload;
      const indexOfDeletedGroup = state.currencyGroups.findIndex(group => group.id === groupId);
      let newSelectedGroupId: any = null;
      if (indexOfDeletedGroup > 0) {
        // auto-select n-1 tab if a selected tab is deleted and is not the first tab
        newSelectedGroupId = state.currencyGroups[indexOfDeletedGroup - 1].id;
      } else if (indexOfDeletedGroup <= 0 && state.currencyGroups.length > 1) {
        newSelectedGroupId = state.currencyGroups[indexOfDeletedGroup + 1].id;
      }
      return {
        ...state,
        selectedCurrencyGroupId: newSelectedGroupId,
        currencyGroups: state.currencyGroups.filter(group => group.id !== groupId),
      };
    }

    default:
      return state;
  }
};

export default settings;

// function utilities
export const getDefaultOrderQuantityFromIndex = (selectedDefaultQuantityIndex, orderSizes) => {
  return selectedDefaultQuantityIndex && orderSizes[selectedDefaultQuantityIndex]
    ? orderSizes[selectedDefaultQuantityIndex]
    : 0;
};

// selectors
export const getSettings = state => state.settings;
export const getBlotterSettings = state => state.settings.blotterSettings;
export const getMarketSettings = state => state.settings.marketSettings;
export const getOrdersSettings = state => state.settings.ordersSettings;
export const getOrdersReadOnly = state => state.settings.ordersReadOnly;

export const getOrderRibbonSettings = state => state.settings.orderRibbonSettings;
export const getStatusOverrides = state => state.settings.orderStatusesSettings.statusOverrides;
export const getCurrencySettings = state => state.settings.currencySettings;
export const getCurrencySettingsBySymbol = (state, symbol) => {
  return state.settings.currencySettings[symbol];
};
export const getNotificationSettings = state => state.settings.notificationSettings;

export const getAvailableTakers = state =>
  state.settings.availableTakers.filter(taker => !taker.toLowerCase().startsWith("cago")) || null;
export const getTraders = state => state.settings.traders;
export const getActiveTaker = state => state.settings.activeTaker || null;
export const getActiveTrader = state => state.settings.activeTrader || null;
export const getCantorAlgoEnabled = state => state.settings.cantorAlgoEnabled;
export const getDefaultOrderQuantitySettings = state =>
  getDefaultOrderQuantityFromIndex(
    state.settings.orderRibbonSettings.selectedDefaultQuantityIndex,
    state.settings.orderRibbonSettings.orderSizes
  );
export const getAuctionCardStyle = state => state.settings.auctionCardStyle || AuctionCardStyleType.Classic;
export const getActiveAnalyticsTabSettings = state =>
  state.settings.analyticsSettings.tabSettings.filter(
    tab =>
      (tab.type === "blotter" && state.settings.blotterSettings.showBlotter) ||
      (tab.type === "aggregatedPNL" && state.settings.blotterSettings.showAggregatedPnl) ||
      (tab.type === "ticker" && state.settings.blotterSettings.showTicker)
  );
export const getAnalyticsPanelOffset = state => state.settings.analyticsSettings.panelOffset;
export const getActiveSymbols = state =>
  state.settings.currencyGroups.reduce((memo, currencyGroup) => memo.concat(currencyGroup.currencyPairs), []);

export const allCurrencyGroupsSelector = state => state.settings.currencyGroups;
export const selectedCurrencyGroupIdSelector = state => state.settings.selectedCurrencyGroupId;
export const selectedCurrencyGroupSelector = state => {
  return (
    state.currencyGroups &&
    state.settings.currencyGroups &&
    state.settings.currencyGroups.find(groups => groups.id === state.settings.selectedCurrencyGroupId)
  );
};

export const currencyGroupByIdSelector = (state, id) => state.settings.currencyGroups.find(group => group.id === id);
export const activeGroupSymbolsSelector = state => {
  const selectedCurrencyGroup = selectedCurrencyGroupSelector(state);
  const symbols = selectedCurrencyGroup && selectedCurrencyGroup.symbols;
  return symbols;
};

import React from "react";
import clsx from "clsx";

import styles from "./PriceButton.module.scss";
import { ClickType } from "../../../state/settings/settingsState";

type PriceButtonProps = {
  clickType: string;
  isTradingEnabled?: boolean;
  onQuickTrade: () => void;
  priceObj: any;
  size?: string; //sm, lg
  testid?: string;
  isCrypto: boolean;
  quantity?: any;
};

export const PriceButton = ({
  isTradingEnabled,
  priceObj,
  clickType,
  onQuickTrade,
  size,
  testid,
  isCrypto,
  quantity,
}: PriceButtonProps) => {
  size = size || "sm";

  const isDepthTradingEnabled = isTradingEnabled === undefined ? true : isTradingEnabled;

  const optionalAttribute = testid ? { "data-testid": testid } : {};

  const priceButton = !isDepthTradingEnabled ? (
    <div
      className={clsx(
        styles.priceCell,
        size === "lg" ? `priceCellLg ${styles.priceCellLg}` : `priceCellSm ${styles.priceCellSm}`,
        styles.priceTradeDisabled
      )}
    >
      {/* If varBig empty then only render basePrice  */}
      <div className={`price ${styles.price}`} {...optionalAttribute}>
        <span className={styles.left}>{priceObj && priceObj.basePrice ? priceObj.basePrice : ""}</span>

        {priceObj ? (
          priceObj.varBig && (
            <>
              <span className={clsx(!priceObj && styles.blank, `bigNum ${styles.bigNum}`)}>
                <div>
                  <span>{priceObj.varBig}</span>
                </div>
              </span>
              <span className={styles.right}>{priceObj?.varSm ? priceObj.varSm : ""}</span>
            </>
          )
        ) : (
          <span className={styles.blank}>&mdash;</span>
        )}
      </div>
    </div>
  ) : (
    <div
      className={clsx(
        styles.priceCell,
        (!priceObj || quantity === null || quantity === "" || quantity === 0) && styles.disabled,
        size === "lg" ? `priceCellLg ${styles.priceCellLg}` : `priceCellSm ${styles.priceCellSm}`
      )}
      onDoubleClick={() => {
        if (clickType === ClickType.Double) {
          if (priceObj && quantity !== null && quantity !== "" && quantity !== 0) {
            onQuickTrade();
          } else {
            return null;
          }
        }
      }}
      onClick={() => {
        if (clickType === ClickType.Single) {
          if (priceObj && quantity !== null && quantity !== "" && quantity !== 0) {
            onQuickTrade();
          } else {
            return null;
          }
        }
      }}
    >
      {/* If varBig empty then only render basePrice  */}
      <div className={`price ${styles.price}`} {...optionalAttribute}>
        <span className={styles.left}>{priceObj && priceObj.basePrice ? priceObj.basePrice : ""}</span>

        {priceObj ? (
          priceObj.varBig && (
            <>
              <span className={`bigNum ${styles.bigNum}`}>
                <div>
                  <span>{priceObj.varBig}</span>
                </div>
              </span>
              <span className={styles.right}>{priceObj?.varSm ? priceObj.varSm : ""}</span>
            </>
          )
        ) : (
          <span className={styles.blank}>&mdash;</span>
        )}
      </div>
    </div>
  );

  return priceButton;
};

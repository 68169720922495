import React, { useEffect, useState } from "react";
import { InputNumber, Select, Switch, Icon } from "antd";

import styles from "./Settings.module.scss";
import { positiveNumberEnforcer } from "../../lib/formatUtil";

import { orderStrategyOptions } from "../../lib/settings/constantsSettings";

import { orderTypeOptionsToDirectlyTrade } from "../currency/AuctionOrderForm/selectOptions";
import { OrderLifespan } from "../../state/orders/types";

type OrderSettingsProps = {
  saveSettings: any;
  setOrdersSettingsRibbonUpdates: any;
  settings: any;
  cantorAlgoEnabled: boolean;
};

const OrdersSettings = ({
  saveSettings,
  setOrdersSettingsRibbonUpdates,
  settings,
  cantorAlgoEnabled,
}: OrderSettingsProps) => {
  const removeOptions = [
    {
      label: "Immediately",
      value: "now",
    },
    {
      label: "After a set number of seconds",
      value: "time",
    },
  ];

  const [orderLifespanOptions, setOrderLifespanOptions] = useState<any>([]);

  useEffect(() => {
    const orderLifespanBasicOptions = [
      {
        label: "Day",
        value: OrderLifespan.Day,
      },
      {
        label: "Immediate or cancel (IOC)",
        value: OrderLifespan.IOC,
      },
    ];

    const fokOption = {
      label: "Fill or Kill (FOK)",
      value: OrderLifespan.FOK,
    };

    cantorAlgoEnabled
      ? setOrderLifespanOptions(orderLifespanBasicOptions)
      : setOrderLifespanOptions([...orderLifespanBasicOptions, fokOption]);
  }, [cantorAlgoEnabled]);

  return (
    <div className={styles.settingsContent}>
      <section className={styles.formBlock}>
        <h5>display</h5>
        <div className={styles.switchBlock}>
          <label>Orders</label>
          <Switch
            checked={settings.showOrders}
            onClick={() => {
              saveSettings({ ...settings, showOrders: !settings.showOrders });
            }}
          />
        </div>
        <div className={styles.switchBlock}>
          <label>Confirm cancelations</label>
          <Switch
            checked={settings.confirmCancelation}
            onClick={() => {
              saveSettings({ ...settings, confirmCancelation: !settings.confirmCancelation });
            }}
          />
        </div>
      </section>

      <section className={styles.formBlock}>
        <h5>orders dashboard</h5>

        <div className={styles.inputBlock}>
          <label>Remove canceled orders</label>
          <Select
            size="large"
            style={{ width: "100%" }}
            value={settings.removeCancelled}
            onSelect={(value: string) => {
              saveSettings({ ...settings, removeCancelled: value });
            }}
            suffixIcon={Icon({ type: "caret-down" })}
          >
            {removeOptions.map((option, i) => (
              <Select.Option key={`${option.value}.${i}`} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.inputBlock}>
          <InputNumber
            size="large"
            value={settings.removeCancelledTime}
            disabled={settings.removeCancelled !== "time"}
            min={0}
            max={3600}
            onKeyDown={event => {
              positiveNumberEnforcer(event);
            }}
            onChange={value => {
              saveSettings({ ...settings, removeCancelledTime: value === null ? 0 : value });
            }}
          />
        </div>

        <div className={styles.inputBlock}>
          <label>Remove filled orders</label>
          <Select
            size="large"
            style={{ width: "100%" }}
            value={settings.removeFilled}
            onSelect={(value: string) => {
              saveSettings({ ...settings, removeFilled: value });
            }}
            suffixIcon={Icon({ type: "caret-down" })}
          >
            {removeOptions.map((option, i) => (
              <Select.Option key={`${option.value}.${i}`} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.inputBlock}>
          <InputNumber
            size="large"
            value={settings.removeFilledTime}
            disabled={settings.removeFilled !== "time"}
            min={0}
            max={3600}
            onKeyDown={event => {
              positiveNumberEnforcer(event);
            }}
            onChange={value => {
              saveSettings({ ...settings, removeFilledTime: value === null ? 0 : value });
            }}
          />
        </div>
      </section>
      <section className={styles.formBlock}>
        <h5>order options</h5>
        <div className={styles.switchBlock}>
          <label>Allow market orders</label>
          <Switch
            checked={settings.marketOrdersEnabled}
            onClick={() => {
              saveSettings({ ...settings, marketOrdersEnabled: !settings.marketOrdersEnabled });
            }}
          />
        </div>
        <div className={styles.switchBlock}>
          <label>Send price and slippage separately</label>
          <Switch
            checked={settings.priceSlippageSeparated}
            onClick={() => {
              saveSettings({ ...settings, priceSlippageSeparated: !settings.priceSlippageSeparated });
            }}
          />
        </div>
      </section>
      <section className={styles.formBlock}>
        <h5>order types</h5>
        <div className={styles.inputBlock}>
          <label>Default aggressive order lifespan</label>
          <Select
            size="large"
            style={{ width: "100%" }}
            value={settings.defaultAggressiveOrderLifespan}
            onSelect={(value: string) => {
              saveSettings({ ...settings, defaultAggressiveOrderLifespan: value });
            }}
            suffixIcon={Icon({ type: "caret-down" })}
          >
            {orderLifespanOptions.map((option, i) => (
              <Select.Option key={`${option.value}.${i}`} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.inputBlock}>
          <label>Default passive order lifespan</label>
          <Select
            size="large"
            style={{ width: "100%" }}
            value={settings.defaultPassiveOrderLifespan}
            onSelect={(value: string) => {
              saveSettings({ ...settings, defaultPassiveOrderLifespan: value });
            }}
            suffixIcon={Icon({ type: "caret-down" })}
          >
            {orderLifespanOptions.map((option, i) => (
              <Select.Option key={`${option.value}.${i}`} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        {cantorAlgoEnabled && (
          <div className={styles.inputBlock}>
            <label>Default aggressive order strategy</label>
            <Select
              size="large"
              style={{ width: "100%" }}
              value={settings.defaultAggressiveOrderStrategy}
              onSelect={(value: string) => {
                saveSettings({ ...settings, defaultAggressiveOrderStrategy: value });
              }}
              suffixIcon={Icon({ type: "caret-down" })}
            >
              {orderStrategyOptions.map((option, i) => (
                <Select.Option key={`${option.value}.${i}`} value={option.value || ""}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
        <div className={styles.inputBlock}>
          <label>Default passive order type</label>
          <Select
            size="large"
            style={{ width: "100%" }}
            value={settings.defaultPassiveOrderType}
            onSelect={(value: string) => {
              saveSettings({ ...settings, defaultPassiveOrderType: value });
              setOrdersSettingsRibbonUpdates({ selectedDefaultOrderType: value });
            }}
            suffixIcon={Icon({ type: "caret-down" })}
          >
            {orderTypeOptionsToDirectlyTrade.map((option, i) => (
              <Select.Option key={`${option.value}.${i}`} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </section>
    </div>
  );
};

export default OrdersSettings;

import React from "react";

import styles from "./OrderProgressBar.module.scss";
import { addCommas } from "../../lib/formatUtil";

type OrderProgressBarProps = {
  quantity: string;
  quantityFilled: string;
};

function OrderProgressBar({ quantity, quantityFilled }: OrderProgressBarProps) {
  const quantityNum = parseFloat(quantity) || 0;
  const quantityFilledNum = parseFloat(quantityFilled) || 0;

  // Avoid possible division by zero.
  const quantityRatio = quantityNum ? quantityFilledNum / quantityNum : 1;

  return (
    <div className={styles.orderProgressBar}>
      <div
        className={styles.bar}
        style={{
          width: `${quantityRatio * 100}%`,
          transition: quantityFilledNum > 0 ? "width 1s" : "none",
        }}
      />
      <div className={styles.progressInfo}>
        {addCommas(quantityFilledNum)} / {addCommas(quantityNum)}
      </div>
    </div>
  );
}

export default OrderProgressBar;

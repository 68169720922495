import clsx from "clsx";
import moment from "moment-timezone";
import { connect } from "react-redux";

import { ReactComponent as CloseIcon } from "../../../assets/images/CloseIcon-16.svg";
import { displayInstrumentLabel, strippedCurrencyLabel } from "../../../lib/formatUtil";
import { decimalToPriceObjectConverterBySymbol, getPriceStringWithPrecision } from "../../../lib/util";
import { currencyPairByIdSelector } from "../../../state/currency/currencyState";
import styles from "./AuctionOrderForm.module.scss";

const AuctionCardHeader = ({ closeModal, currencyPair, instrumentId }) => {
  const settleDate = currencyPair?.settlementDate ?? null;
  const fixingDate = currencyPair?.fixingDate ?? null;

  const bestAskPriceObj = decimalToPriceObjectConverterBySymbol(currencyPair.bestAskPrice, instrumentId);
  const bestBidPriceObj = decimalToPriceObjectConverterBySymbol(currencyPair.bestBidPrice, instrumentId);

  return (
    <>
      <div className={styles.closeIcon} onClick={() => closeModal()} data-testid="auction_close_button">
        <CloseIcon />
      </div>

      <div className={styles.headerInfo}>
        <div className={styles.headerRow}>
          <div className={styles.firstColumn} data-testid="auction_symbol">
            <span className={styles.currencyCode}>{strippedCurrencyLabel(instrumentId)}</span>
            {displayInstrumentLabel(instrumentId)}
          </div>
          <div className={styles.bestPrice} data-testid="auction_buy_price">
            B{" "}
            {currencyPair && currencyPair.bestAskPrice ? (
              // getPriceStringWithPrecision(currencyPair.bestAskPrice, currencyPair.symbol)
              <>
                <span>{bestAskPriceObj.basePrice}</span>
                {bestAskPriceObj.varBig && (
                  <>
                    <span className={styles.bigNum}>{bestAskPriceObj.varBig}</span>
                    <span>{bestAskPriceObj.varSm}</span>
                  </>
                )}
              </>
            ) : (
              <>&mdash;</>
            )}
          </div>
          <div className={clsx(styles.firstColumn, styles.date)}>
            <span data-testid="auction_fixing_date">
              {fixingDate &&
                `${moment(fixingDate)
                  .utc()
                  .format("DDMMM")
                  .toUpperCase()}/`}
            </span>
            <span data-testid="auction_settlement_date">
              {settleDate &&
                moment(settleDate)
                  .utc()
                  .format("DDMMM")
                  .toUpperCase()}
            </span>
          </div>
          <div className={styles.bestPrice} data-testid="auction_sell_price">
            S{" "}
            {currencyPair && currencyPair.bestBidPrice ? (
              // getPriceStringWithPrecision(currencyPair.bestBidPrice, currencyPair.symbol)
              <>
                <span>{bestBidPriceObj.basePrice}</span>
                {bestBidPriceObj.varBig && (
                  <>
                    <span className={styles.bigNum}>{bestBidPriceObj.varBig}</span>
                    <span>{bestBidPriceObj.varSm}</span>
                  </>
                )}
              </>
            ) : (
              <>&mdash;</>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state, ownProps: any) => ({
    currencyPair: currencyPairByIdSelector(state, ownProps.instrumentId),
  }),
  {}
)(AuctionCardHeader);

import type { Notification } from './Notifications.types';
import last from 'lodash/last';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

/**
 * Takes a string and returns a hashed code so that the same string always results in the same code
 */
export const toCode = (...args: string[]): number => {
  return (
    args
      // combine args into a single string
      .join('')
      // remove all whitespace and punctuation
      .replace(/[^\w\\s]/g, '')
      // split into array of individual characters
      .split('')
      // javascript magic... *wizard noises* WOOoooo...
      .reduce((result, next) => (Math.imul(31, result) + next.charCodeAt(0)) | 0, 0)
  );
};

/**
 * Returns true if the next notification is the same code as the provided list,
 * and if the next notification is within a certain time window from the most recent list item.
 * Otherwise returns false;
 */
export const isProximal = (list: Notification[], next: Notification, timeWindow = 500) => {
  if (!list.every(({ isVisible }) => isVisible)) {
    return false;
  }
  let isDuplicate = list.every(({ code }) => code === next.code);
  let mostRecent = last(list) as Notification;
  let isInTimeWindow = differenceInMilliseconds(next.createdAt, mostRecent.createdAt) < timeWindow;
  return isDuplicate && isInTimeWindow;
};

/**
 * Given a list of Notifications and a max visible count, hides any overflowing visible
 * notifications starting from the oldest
 */
export const trimVisibleOverflow = (list: Notification[], max = 5) => {
  let numVisible = list.filter(({ isVisible }) => isVisible);
  if (numVisible.length > max) {
    let idsToRemove = numVisible.slice(0, numVisible.length - max).map(({ id }) => id);
    return list.map((notification) => {
      return idsToRemove.includes(notification.id)
        ? {
            ...notification,
            isVisible: false,
          }
        : notification;
    });
  } else {
    return list;
  }
};

/**
 * Given a list of Notifications, a max number of items, and the number of items to drop when
 * trimming, returns a trimmed list with the oldest removed.
 */
export const trimOverflow = (list: Notification[], max = 40, drop = 15) => {
  return list.length > max ? list.slice(drop, list.length) : list;
};

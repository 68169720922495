import React from "react";
import { Button } from "antd";

import styles from "./Settings.module.scss";

type SAProps = {
  saveAction: any;
  closeDrawer: any;
  isDisabled: any;
};

const SettingsActions = ({ saveAction, closeDrawer, isDisabled }: SAProps) => {
  return (
    <div className={styles.tabsLeftSaveBtn}>
      <Button type="primary" size="large" onClick={saveAction} disabled={isDisabled} className={styles.saveButton}>
        Save changes
      </Button>
      <Button className={styles.closeButton} type="default" size="large" onClick={() => closeDrawer()}>
        Close
      </Button>
    </div>
  );
};

export default SettingsActions;

import { useState, useEffect, useReducer, useCallback } from "react";
import { State, Action, Api, DismissFn, Notification, NotifyFn } from "./Notifications.types";
import takeRight from "lodash/takeRight";
import uuid from "uuid";

import { isProximal, trimVisibleOverflow, trimOverflow, toCode } from "./notificationUtils";

type UseNotificationsProps = {
  isEnabled?: boolean;
};

// Initial API fn callback with a warning in case the api is called before the provider is initialized
const apiDefaultFn = () => {
  console.log(
    "notifications method was called before <NotificationsProvider /> could bind the proper callbacks. Make sure that the provider is being set up before any calls to the notifications api."
  );
};

// API object to expose abstract dispatch methods to any JS method (React or not)
export const notifications: Api = {
  notify: apiDefaultFn,
  dismiss: apiDefaultFn,
  log: apiDefaultFn,
  warn: apiDefaultFn,
  error: apiDefaultFn,
  success: apiDefaultFn,
  register({ notify, dismiss }) {
    this.notify = notify;
    this.dismiss = dismiss;
    this.log = props => notify({ level: "log", ...props });
    this.warn = props => notify({ level: "warning", ...props });
    this.error = props => notify({ level: "error", ...props });
    this.success = props => notify({ level: "success", ...props });
  },
  unregister() {
    this.notify = () => "";
    this.dismiss = () => "";
    this.log = () => "";
    this.warn = () => "";
    this.error = () => "";
    this.success = () => "";
  },
};

/**
 * Notifications list reducer which supports adding, removing, and maintaining a collection of notifications
 */
export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    // Add a new Notification to list. By design, new Notifications should always start as visible.
    case "add": {
      let props = action.payload;

      // create a notification object
      let notification: Notification = {
        ...props,
        code: toCode(props.title, props.message ?? ""),
        isVisible: true,
      };

      // If the next notification is proximal, don't add it
      let mostRecent = takeRight(state, 3);
      if (mostRecent.length > 2 && isProximal(mostRecent, notification)) {
        return state;
      }

      // Otherwise add new notification to array
      let next = [...state, notification];

      // Trim older visible notifications if we have too many
      next = trimVisibleOverflow(next, 5);

      // Finally, drop older notifications if we've gone past our threshold.
      next = trimOverflow(next);

      return next;
    }

    // Mark a visible Notification as hidden. Hidden Notifications cannot be visible again
    case "dismiss": {
      let { id } = action.payload;

      let idsToDismiss = Array.isArray(id) ? id : [id];
      let next = state.map(notification => {
        return idsToDismiss.includes(notification.id)
          ? {
              ...notification,
              isVisible: false,
            }
          : notification;
      });
      return next;
    }

    // clear out all messages. mostly for dev or panic scenarios
    case "clear": {
      let next = [];
      return next;
    }

    default: {
      return state;
    }
  }
};

const initialState: State = [];

/**
 * Exposes notifications state, as well as binds/unbinds API methods to external exported object so they can
 * be called from anywhere in the app.
 */
export const useNotifications = ({ isEnabled: isEnabledProp = true }: UseNotificationsProps) => {
  const [state, dspt] = useReducer(reducer, initialState);
  const [isEnabled, setIsEnabled] = useState(isEnabledProp);

  const notify: NotifyFn = useCallback(
    props => {
      dspt({
        type: "add",
        payload: {
          id: uuid(),
          createdAt: new Date(),
          ...props,
        },
      });
    },
    [dspt],
  );

  const dismiss: DismissFn = useCallback(
    (id: string) => {
      dspt({ type: "dismiss", payload: { id } });
    },
    [dspt],
  );

  const toggleEnabled = useCallback(isEnabled => {
    setIsEnabled(isEnabled);
  }, []);

  // Register API methods with publicly exposed object.
  useEffect(() => {
    if (isEnabled) {
      notifications.register({ notify, dismiss });
    } else {
      notifications.unregister();
    }
    return () => {
      notifications.unregister();
    };
  }, [isEnabled, notify, dismiss]);

  return { state, toggleEnabled };
};

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import Big from "big.js";

import { getActiveAnalyticsTabSettings, getBlotterSettings } from "../../state/settings/settingsState";
import PageBar from "../PageBar";
import OrderHistory from "./OrderHistory";
import Ticker from "./Ticker";
import { addCommas } from "../../lib/formatUtil";
import { OrderSide } from "../../state/orders/types";
import { getSelectedRowsOrderData } from "../../state/orders/ordersState";
import AggregatedPNL from "./margins/AggregatedPNL";
import { groupBy } from "lodash";
import { saveTableSettings } from "../../state/settings/settingsActions";
// import IndividualPNL from "./margins/IndividualPNL";

type AnalyticsPanelProps = {
  analyticsTabSettings: any;
  appRef: any;
  blotterSettings: any;
  defaultHeight: any;
  panelOffset: any;
  setIsResizing: any;
  saveTableSettings: any;
  orderHistorySelectedRows: any;
};

const AnalyticsPanel = ({
  analyticsTabSettings,
  blotterSettings,
  defaultHeight,
  panelOffset,
  setIsResizing,
  saveTableSettings,
  orderHistorySelectedRows,
}: AnalyticsPanelProps) => {
  const [analyticsTabs, setAnalyticsTabs] = useState<any>(analyticsTabSettings);
  const [activeBlotterTabId, setActiveBlotterTabId] = useState<any>(analyticsTabs[0] ? analyticsTabs[0].id : 0);
  const [scrollBarOffset, setScrollBarOffset] = useState<number>(0);

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  // const orderHistoryDataArray = [{
  //   instrumentId: "EUR/USD",
  //   rowCount: 0,
  //   vwap: 0,
  //   size: 0,
  // }];

  const [orderHistoryDataArray, setOrderHistoryDataArray] = useState<any>([]);

  useEffect(() => {
    // we can use these if we want to specify max decimal places and which rounding method to use
    Big.DP = 7;
    Big.RM = 1;

    const getPriceTimesDirection = (side: string, quantity: number): number => {
      return side === OrderSide.Buy ? 1 * quantity : -1 * quantity;
    };

    if (orderHistorySelectedRows && orderHistorySelectedRows.length > 0) {
      const vwapReducer = (accumulator, currentValue) => {
        if (currentValue.averagePrice && currentValue.quantityFilled && currentValue.side) {
          const returnValue = Big(getPriceTimesDirection(currentValue.side, currentValue.averagePrice));
          const calc = Number(
            returnValue
              .times(currentValue.quantityFilled)
              .add(accumulator)
              .toString()
          );
          return calc;
        }
        return accumulator;
      };
      const sizeReducer = (accumulator, currentValue) => {
        const realPrice = getPriceTimesDirection(currentValue.side, currentValue.quantityFilled || 0);
        return accumulator + realPrice;
      };

      const calculateInstrumentIdOrderHistory = orderHistoryGroup => {
        const count = orderHistoryGroup.length;
        const sizeCalc = orderHistoryGroup.reduce(sizeReducer, 0);
        const notionals = Big(orderHistoryGroup.reduce(vwapReducer, 0));
        const vwapCalc = sizeCalc !== 0 ? Number(notionals.div(sizeCalc).toString()) : null;
        const instrumentId = count > 0 ? orderHistoryGroup[0].instrumentId : null;
        return { instrumentId, rowCount: count, vwap: vwapCalc, size: sizeCalc };
      };

      const groupedOrderHistory = groupBy(orderHistorySelectedRows, "instrumentId");
      let newOrderHistoryDataArray: any = [];
      for (const instrumentIdKey in groupedOrderHistory) {
        const orderHistoryGroup = groupedOrderHistory[instrumentIdKey];
        newOrderHistoryDataArray.push(calculateInstrumentIdOrderHistory(orderHistoryGroup));
      }
      setOrderHistoryDataArray(newOrderHistoryDataArray);
    }
  }, [orderHistorySelectedRows]);

  useEffect(() => {
    if (analyticsTabSettings) {
      setAnalyticsTabs(analyticsTabSettings);
    }
    if (!analyticsTabSettings.find(tab => tab.id === activeBlotterTabId)) {
      setActiveBlotterTabId(analyticsTabSettings[0] ? analyticsTabSettings[0].id : 0);
    }
  }, [analyticsTabSettings, activeBlotterTabId]);

  useEffect(() => {
    if (isDataLoaded) {
      handleScreenResize();
      window.addEventListener("resize", handleScreenResize);
    }
  }, [isDataLoaded]);

  const handleScreenResize = () => {
    const analyticsEl = document.querySelector(".analytics");
    if (analyticsEl) {
      // no need to update if component isn't mounted
      const element = analyticsEl.querySelector(".ag-body-horizontal-scroll");
      const offset = element ? element.clientHeight : 0;

      setScrollBarOffset(offset);
    }
  };

  const handleSetActiveTabId = page => {
    setActiveBlotterTabId(page.id);
    // load blotter page
  };

  // const handleAddTab = () => {
  //   // this will add a new blotter table with all of the data. Not currently used
  //   saveTableSettings({ id: tabCount, name: `Tab ${tabCount}`, type: "blotter", tableSettings: {} });
  //   setTabCount(tabCount + 1);
  // };

  const saveSettings = (id, tableSettings) => {
    // wait until data is loaded before responding to ag-grids on change events
    saveTableSettings(id, tableSettings);
  };

  const blotterTabSettings = analyticsTabs.find(tab => tab.type === "blotter");
  const tickerSettings = analyticsTabs.find(tab => tab.type === "ticker");
  const aggregatedPNLSettings = analyticsTabs.find(tab => tab.type === "aggregatedPNL");
  // const individualPNLSettings = analyticsTabs.find(tab => tab.type === "individualPNL");

  const tabComponents = (
    <>
      {blotterTabSettings && blotterTabSettings.type && (
        <span className={clsx(activeBlotterTabId !== 0 && "hiddenPanel")}>
          <OrderHistory
            id={0}
            saveSettings={saveSettings}
            tableSettings={!blotterTabSettings ? blotterTabSettings.tableSettings : null}
            setDataLoaded={() => setIsDataLoaded(true)}
            tabType={blotterTabSettings.type}
          />
        </span>
      )}

      {aggregatedPNLSettings && aggregatedPNLSettings.type && (
        <span className={clsx(activeBlotterTabId !== 1 && "hiddenPanel")}>
          <AggregatedPNL
            id={1}
            saveSettings={saveSettings}
            tableSettings={aggregatedPNLSettings ? aggregatedPNLSettings.tableSettings : null}
            setDataLoaded={() => {
              setIsDataLoaded(true);
            }}
            tabType={aggregatedPNLSettings.type}
          />
        </span>
      )}

      {/* <span className={clsx(activeBlotterTabId !== 2 && "hiddenPanel")}>
        <IndividualPNL
          id={2}
          saveSettings={saveSettings}
          tableSettings={individualPNLSettings ? individualPNLSettings.tableSettings : null}
          setDataLoaded={() => {
            setIsDataLoaded(true);
          }}
          tabType={individualPNLSettings ? individualPNLSettings.type : null}
        />
      </span> */}

      {tickerSettings && tickerSettings.type && (
        <span className={clsx(activeBlotterTabId !== 3 && "hiddenPanel")}>
          <Ticker
            id={3}
            saveSettings={saveSettings}
            tableSettings={tickerSettings ? tickerSettings.tableSettings : null}
            setDataLoaded={() => {
              setIsDataLoaded(true);
            }}
            tabType={tickerSettings.type}
          />
        </span>
      )}
    </>
  );

  const handleResize = () => {
    setIsResizing(true);
  };

  return (
    <div
      className="analytics"
      style={{
        height: `calc(${defaultHeight}px + ${panelOffset}px + ${scrollBarOffset}px)`,
      }}
    >
      <div className="resizeIconHoverArea" onMouseDown={handleResize} />
      <div className="tabsHeader">
        <PageBar
          tabs={analyticsTabs}
          onSetCurrentPage={handleSetActiveTabId}
          // onAddTab={handleAddTab}
          size={"small"}
          isDynamic={false}
          selectedTabId={activeBlotterTabId}
          isMainHeader={false}
        />
        {blotterSettings.showBlotter &&
          activeBlotterTabId === 0 &&
          orderHistorySelectedRows &&
          orderHistorySelectedRows.length > 0 && (
            <div className="selectedRowsCards">
              <div className="selectedRowsFlexList">
                {orderHistoryDataArray.map((orderHistory, i) => (
                  <div key={i}>
                    <div className="selectedRowsItem">
                      <div className="selectedRow">
                        <span className="selectedRowsValue">&nbsp;{orderHistory.instrumentId}</span>
                        {` x ${orderHistory.rowCount} ${orderHistory.rowCount === 1 ? "row" : "rows"}`}
                      </div>
                      <div className="selectedRow">
                        <span className="selectedRowsValue">{addCommas(orderHistory.size)}</span>
                        {" @ "}
                        <span className="selectedRowsValue">{orderHistory.vwap || <>&mdash;</>}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
      </div>

      <div className="analyticsPanel">{tabComponents}</div>
    </div>
  );
};

export default connect(
  (state: any) => ({
    analyticsTabSettings: getActiveAnalyticsTabSettings(state),
    blotterSettings: getBlotterSettings(state),
    orderHistorySelectedRows: getSelectedRowsOrderData(state),
  }),
  { saveTableSettings }
)(AnalyticsPanel);

import React, { useMemo } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import cx from 'clsx';

import styles from './Notifications.module.scss';
import type { Notification } from './Notifications.types';
import { NotificationCard } from './NotificationCard';
// import { useNotificationsSettings } from './useNotificationsSettings';

type VisibleNotificationsProps = {
  items: Notification[];
};
//
/**
 * Renders a column of currently visible notifications in the viewport
 *
 * Note on `animationDuration`:
 * React doesn't track css animation durations because it's unreliable. So when using CssTransition it's
 * our job to sync up the CssTransition `timeout` with css's `transition-duration` to make sure they take the
 * same amount of time so React can move onto the next transition cycle post css animation finish
 */
export const VisibleNotifications = ({ items }: VisibleNotificationsProps) => {
  // const { position } = useNotificationsSettings();
  const position = "bottomRight";
  const css = useMemo(() => {
    return {
      animationClassName: {
        enter: position === 'bottomRight' ? styles.enterDown : styles.enterUp,
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exit: styles.exit,
        exitActive: styles.exitRightActive,
      },
      animationDuration: 250,
      layoutClassName: cx(
        styles.layout,
        position === 'bottomRight' && styles.bottomRight
        // position === 'topRight' && styles.topRight
      ),
      listClassName: cx(styles.list,
        position === 'bottomRight' && styles.up
        // position === 'topRight' && styles.down,
      ),
    };
  }, [position]);

  return (
    <div className={css.layoutClassName}>
      <div className={css.listClassName}>
        <TransitionGroup>
          {items.map((props) => {
            return (
              <CSSTransition
                key={props.id}
                classNames={{ ...css.animationClassName }}
                timeout={css.animationDuration} // See note #1 above regarding timeout
              >
                <div className={styles.cell} style={{ transitionDuration: `${css.animationDuration}ms` }}>
                  <NotificationCard {...props} />
                </div>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </div>
    </div>
  );
};

import React from "react";
import clsx from "clsx";

import styles from "../AuctionOrderFormClassic.module.scss";
import { Radio } from "antd";
import { OrderFieldsEnum } from "../fields";
import { orderSideOptions } from "../selectOptions";

const Side = ({
  sideName,
  isGenericFieldDisabledByOrderType,
  isBuyAction,
  auctionOrderStateSideByOrderType,
  setAuctionOrderState,
  orderType,
  setActiveCardSide,
  order,
}) => {
  return (
    <div className={clsx(styles.inputBlock, styles.inputBlockTwoColPill)}>
      <label>Side</label>
      <div
        className={clsx(
          styles.inputSection,
          isGenericFieldDisabledByOrderType && styles.inputDisabled,
          isBuyAction ? styles.buy : styles.sell
        )}
      >
        <Radio.Group
          data-testid="auction_side"
          value={auctionOrderStateSideByOrderType}
          disabled={isGenericFieldDisabledByOrderType}
          onChange={event => {
            setAuctionOrderState(prevState => ({
              ...prevState,
              [orderType]: { ...prevState[orderType], [sideName]: event.target.value },
            }));

            // Update the color of the highlighted card when "Buy/Sell" is changed. If it's OCO, only when the first "Buy/Sell" is changed.
            if (sideName === OrderFieldsEnum.side) {
              setActiveCardSide(event.target.value);
            }
          }}
        >
          {orderSideOptions.map((option, i) => (
            <Radio
              key={`${option.value}.${i}`}
              value={option.value}
              className={clsx(styles.radioToggle, order && styles.radioDisabled)}
            >
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    </div>
  );
};

export default Side;
